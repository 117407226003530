import React from 'react';
import { observer } from 'mobx-react';
import TaskBox from './TaskBox';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

const Tasks = observer(({ task }) => {
  const classes = useStyles();

  const { detail } = task;
  const { tasks } = detail;

  return <>
    <Box className={classes.root}>
      {tasks.map(task => <TaskBox key={task.id} task={task} />)}
    </Box>
  </>
});

export default Tasks;
