import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
// import { tradePartyService, toastService, terminalService } from '../../../services';
import { isEmpty, uniqueId, isArray, get, debounce } from 'lodash';
import cx from 'classnames';
// import Input from '../Input';
import { toast, tradePartyService } from '../../services';
import { Box, FormControl, OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  autoSuggestionContainer: {
    // .v2-auto-suggestion {

    // }

    '& .v2-auto-suggestion-append-button': {
      paddingLeft: '10px',
      paddingRight: '10px'
    },

    '& .react-autosuggest__suggestions-container': {
      position: 'relative',
      marginTop: '5px',

      '& ul': {
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
        marginTop: '5px',
        listStyle: 'none',
        position: 'absolute',
        zIndex: '10',
        margin: '0px',
        padding: '0px',
        overflow: 'auto',
        width: '100%',
        backgroundColor: 'white',
        maxHeight: '300px',

        '& li': {
          fontSize: '14px',
          cursor: 'pointer',
          borderBottom: '1px solid grey',
        }
      },
    }
  },
  notfound: {
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
    marginTop: '5px',
    listStyle: 'none',
    position: 'absolute',
    zIndex: '10',
    margin: '0px',
    padding: '10px',
    overflow: 'auto',
    // width: '100%',
    backgroundColor: 'white',
  }
}));


const populateSuggestion = (suggestion) => {
  return `${suggestion.code}|${suggestion.name}`
}

const TradePartySelect = ({
  onChange,
  selected,
  inputProps = {},
  required = false,
  limit = 50,
  disabled,
  types = [],
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [notFound, setNotFound] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setQuery(!isEmpty(selected) ? populateSuggestion(selected) : '');
  }, [selected]);


  const handleInputChange = (event, { newValue: newQuery }) => {
    setNotFound(false);
    setQuery(newQuery);
  }

  const getSuggestions = (q, reason) => {
    if (reason !== 'input-changed') {
      return [];
    }

    if (q.length < 3) {
      return [];
    }

    const query = { query: q, limit: limit, types, };
    // const terminalQuery = { ...query };

    if (props.type) {
      query['type'] = props.type;
    }

    const promises = [tradePartyService.index(query)];


    setIsLoading(true);
    Promise.all(promises).then((responses) => {
      const tradePartys = get(responses, '0.data.data', []);
      if (!tradePartys.length) {
        setNotFound(true)
      }
      setSuggestions(tradePartys);
    })
      .catch(error => toast.error(error))
      .then(() => {
        setIsLoading(false);
      });
  };

  const onSuggestionsFetchRequested = React.useCallback(debounce(({ value, reason }) => {
    getSuggestions(value, reason);
  }, 500), [])

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderInputComponent = ((props) => {
    return <FormControl size="small"
      variant="outlined" fullWidth >
      <OutlinedInput
        disabled={disabled}
        fullWidth
        {...props}
        // endAdornment={<InputAdornment position="end">LBS</InputAdornment>}
        labelWidth={0}
      />
      {/* <FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText> */}
    </FormControl>
  });

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        {children}
      </div>
    );
  }



  const renderSuggestion = (tradeParty) => (
    <Box p={1} style={{ fontSize: '12px' }}>
      <Box px={1}><strong>{tradeParty.code}</strong></Box>
      <Box px={1}><strong>{tradeParty.name}</strong></Box>
      <Box px={1} style={{ whiteSpace: 'pre-line' }}>{tradeParty.address}</Box>
      <Box px={1}>{tradeParty.type}</Box>
    </Box>
  );

  const onSuggestionSelected = (event, { suggestion }) => {
    onChange(suggestion);
  }

  const { className: inputPropsClassname, ...restInputProps } = inputProps;

  const _inputProps = {
    value: query,
    onChange: handleInputChange,
    id: uniqueId('tradePartySelect'),
    ...restInputProps,
  }

  return (
    <div className={classes.autoSuggestionContainer}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={populateSuggestion}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={_inputProps}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
      />
      {notFound && <Box p={1} className={classes.notfound}>
        <small>No items found.</small>
      </Box>}
    </div>
  );

}

export default TradePartySelect;
