import request from './request';

export const whsArea = {
  index(params = {}) {
    return request.get('whsAreas', { params });
  },
  store(params = {}) {
    return request.post(`whsAreas`, params);
  },
  update(id, params = {}) {
    return request.put(`whsAreas/${id}`, params);
  },
  show(id) {
    return request.get(`whsAreas/${id}`);
  },
  destory(id) {
    return request.delete(`whsAreas/${id}`);
  },
  setDefauleWhsArea(id) {
    return request.patch(`whsAreas/${id}`);
  }
}
