import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import { observer } from 'mobx-react';
import Link from '@material-ui/core/Link';


const PDF = observer(({ detail }) => {
  const [show, setShow] = React.useState(false);

  const handleShow = () => {
    if (!detail.otr_carriers || detail.otr_carriers.length === 0) {
      detail.viewPDF();
    } else {
      setShow(true);
    }
  }

  return <>
    <Button variant="outlined" color="primary" onClick={handleShow}>VIEW BOL</Button>
    {show && <Dialog
      fullWidth
      maxWidth="sm"
      open={show}
      onClose={() => setShow(false)}
    >
      <DialogContent>
        <Box >
          {detail.otr_carrier && <Carrier carrier={detail.otr_carrier} detail={detail} />}
          {detail.otr_carriers && detail.otr_carriers.map((carrier, index) => <Box key={index} mt={3}><Carrier carrier={carrier} detail={detail} /></Box>)}
        </Box>
      </DialogContent>
    </Dialog>}
  </>
})

const Carrier = observer(({ carrier, detail }) => {
  const handlePDF = (e) => {
    e.preventDefault();
    carrier.viewPDF();
  }

  return <Box>
    <Box>
      <Typography variant="h6" gutterBottom>{carrier.name}</Typography>
    </Box>
    <Divider />
    <Box mt={1}>
      <Link href="#" onClick={handlePDF}>BOL#{detail.id}.pdf</Link>
    </Box>
  </Box>
});

export default PDF;
