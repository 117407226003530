import React from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import jspreadsheet from 'jspreadsheet-ce';
import { parseDataIntoExcelTableData, toExcelTableData } from '../../../common/ExcelTable';
import { LOAD_TYPE_MAP } from '../../../../stores/inventory/constants';
import { invert, isEmpty } from 'lodash'

const useStyles = makeStyles(theme => ({
  root: {

  },
  buttonArrow: {
    '&,&:focus,&:hover': {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
}))

const InventoryNewGroup = observer(({ detail }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return <>
    <ButtonGroup size="small" variant="outlined" color="primary" ref={anchorRef}>
      <Button size="small" onClick={handleToggle}>Create New Group</Button>
      <Button
        color="primary"
        size="small"
        className={classes.buttonArrow}
        onClick={handleToggle}
      >
        <ArrowDropDownIcon style={{ fontSize: 18 }} />
      </Button>
    </ButtonGroup>
    {open && <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>
                <MenuItem
                  onClick={detail.addGroup}
                >
                  Create One Group
                </MenuItem>
                <MenuItem>
                  <CreateMultipleGroups detail={detail} />
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
    }
  </>
})

const CreateMultipleGroups = observer(({ detail }) => {
  const [open, setOpen] = React.useState(false)
  const jRef = React.useRef(null)

  const columns = React.useMemo(() => [
    {
      type: 'dropdown',
      title: 'Create From Marking*',
      width: 130,
      attribute: 'create_from',
      source: detail.groups.map(g => g.marking),
    },
    {
      type: 'dropdown',
      title: 'CNTR*',
      width: 130,
      attribute: 'ib_number',
      source: detail.groups.map(g => g.ib_number),
    },
    {
      type: 'text',
      title: 'WHS Marking*',
      width: 130,
      attribute: 'marking',
    },
    {
      type: 'dropdown',
      title: 'Load Type*',
      width: 130,
      attribute: 'type',
      source: Object.values(LOAD_TYPE_MAP),
    },
    {
      type: 'number',
      title: 'Current Qty*',
      width: 130,
      attribute: 'current_qty',
    },
    {
      type: 'number',
      title: 'Weight LB*',
      width: 130,
      attribute: 'weight_lb',
    },
    {
      type: 'number',
      title: 'WHS PLT*',
      width: 130,
      attribute: 'whs_pallet_count',
    },
    {
      type: 'number',
      title: 'CBM*',
      width: 130,
      attribute: 'volume_cbm',
    },
    {
      type: 'number',
      title: 'Receiver Shipment ID',
      width: 160,
      attribute: 'receiver_reference_id',
    },
    {
      type: 'number',
      title: 'Receiver Reference ID',
      width: 160,
      attribute: 'receiver_shipment_id',
    },
  ], [detail.groups]);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleConfirm = () => {
    const values = toExcelTableData(jRef.current.jspreadsheet.getData(), columns)

    const groupsData = values.map(row => {
      const type = invert(LOAD_TYPE_MAP)[row.type]

      const groupData = {
        ib_number: row.ib_number,
        marking: row.marking,
        current_qty: row.current_qty,
        weight_lb: row.weight_lb,
        whs_pallet_count: row.whs_pallet_count,
        volume_cbm: row.volume_cbm,
        receiver_shipment_id: row.receiver_shipment_id,
        receiver_reference_id: row.receiver_reference_id,
        type: type === undefined ? null : type,
      };
      const createFrom = row.create_from;

      const groupCreateFrom = detail.groups.find(g => g.id && g.marking == createFrom)

      if (groupCreateFrom) {
        groupData['create_from_load_ids'] = groupCreateFrom.loads.map(l => l.id)
      }

      return groupData
    })

    detail.addGroups(groupsData);
  }

  const autoCalculate = () => {
    const values = toExcelTableData(jRef.current.jspreadsheet.getData(), columns)

    for (let i = 0; i < values.length; i++) {
      const row = values[i]
      if (!row.create_from || !row.current_qty) {
        detail.root.ui.toast.error(new Error('"Create From Marking" and "Current Qty" cell cant be empty if do calculation.'))
        return
      }
    }


    const data = values.map((row) => {
      if (row.create_from && row.current_qty) {
        const group = detail.groups.find(g => g.marking == row.create_from)
        if (group) {
          const qty = parseFloat(row.current_qty)

          return {
            ...row,
            weight_lb: (qty / parseFloat(group.total_current_qty) * parseFloat(group.total_weight_lb || 0)).toFixed(2),
            whs_pallet_count: (qty / parseFloat(group.total_current_qty) * parseFloat(group.total_whs_pallet_count || 0)).toFixed(2),
            volume_cbm: (qty / parseFloat(group.total_current_qty) * parseFloat(group.total_volume_cbm || 0)).toFixed(2),
          }
        }
      }
      return row
    })

    jRef.current.jspreadsheet.setData(parseDataIntoExcelTableData(data, columns))
  }

  const options = React.useMemo(() => ({
    data: [],
    columns: columns,
    allowInsertColumn: false,
    allowRenameColumn: false,
    allowDeleteColumn: false,
    minDimensions: [columns.length, 20],
  }), [])

  const runSpreadsheet = () => {
    setTimeout(() => {
      if (jRef && jRef.current && !jRef.current.jspreadsheet) {
        jspreadsheet(jRef.current, options)
      }
    }, 1000)
  }

  React.useEffect(() => {
    runSpreadsheet();
  }, [open])

  return <>
    <div onClick={handleOpen}>Create Multiple Groups</div>
    {open && <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Box>
          <Box mb={2}>
            <div ref={jRef}></div>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={autoCalculate}
              >
                Calculate Weight, PLT and CBM
              </Button>
            </Box>
            <Box mr={2}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>}
  </>
})

export default InventoryNewGroup;
