import { types, getParent } from 'mobx-state-tree';
import { isArray } from 'lodash';


const Option = types
  .model({
    text: types.string,
    value: types.string,
  })

export const FilterStore = types
  .model({
    id: '',
    bol_id: '',
    carrier: '',
    search: '',
  })
  .volatile(self => ({

  }))
  .views(self => ({
  }))
  .actions(self => ({
    renderSelectOption(o) {
      return o.text
    },
    handleSelect(name) {
      return (option) => {
        self.setValue(name, option);
      }
    },
    isSelectedEqualToOption(selected, option) {
      return selected && selected.value === option.value;
    },
    toValues() {
      const values = { ...self.toJSON() };

      for (const k in values) {
        if (isArray(values[k])) {
          values[k] = values[k].map(o => o['value']);
        } else if (values[k] && values[k].value !== undefined) {
          values[k] = values[k].value;
        }
      }

      return values;
    },
    setValue(name, value) {
      self[name] = value;
    },
    setValueAndSubmit(name, value) {
      self.setValue(name, value);
      self.submit();
    },
    setValueByHtmlElement(e) {
      const { value, name, type, checked } = e.target;
      self.setValue(name, type === 'checkbox' ? checked : value);
    },
    submit() {
      getParent(self, 1).loadCollection();
    },
    reset() {
      self.id = '';
      self.bol_id = '';
      self.carrier = '';
      self.search = '';
    },
  }));
