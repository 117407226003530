import { types, getRoot, flow } from 'mobx-state-tree';
import DocumentsStore from '../document/DocumentsStore';
import { autorun } from 'mobx';
import { AddonService } from '../addonService/models';

const WhsAlertTroubleShootStore = types
  .model('WhsAlertTroubleShootStore', {
    id: types.number,
    status: types.maybeNull(types.number),
    description: types.string,
    update_by_id: types.number,
    update_by_name: types.string,
    updated_at: types.string,
    warehouse_alert_id: types.maybeNull(types.number),
    has_revise: types.maybeNull(types.boolean),
    created_at: types.string,
    container_number: types.maybeNull(types.string),
    solved_at: types.maybeNull(types.string),
    canceled_at: types.maybeNull(types.string),
    fromWhsDocuments: types.optional(DocumentsStore, { target: 'whs_alert_trouble_shoot_from_whs' }),
    toWhsDocuments: types.optional(DocumentsStore, { target: 'whs_alert_trouble_shoot_to_whs' }),
    isOpened: types.optional(types.boolean, false),
    addon_services: types.array(AddonService),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    },
  }))
  .volatile(self => ({
    autorunDisposer: null,
  }))
  .actions(self => ({
    open() {
      self.isOpened = true;
    },
    close() {
      self.isOpened = false;
    },
    switchOpened() {
      self.isOpened = !self.isOpened
    },
    loadTroubleShoot: flow(function* (id) {
      try {
        self.loading = true;
        const response = yield self.root.api.troubleShoot.show(id);
        self.loading = false;
        return response.data.data
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateTroubleShoot: flow(function* (params) {
      try {
        const response = yield self.root.api.troubleShoot.update(self.id, params);
        if (response) {
          self.description = response.data.data.description;
          self.status = response.data.data.status
          self.messages = response.data.data.messages
          self.updated_at = response.data.data.updated_at
          self.solved_at = response.data.data.solved_at
          self.canceled_at = response.data.data.canceled_at
          self.addon_services = response.data.data.addon_services
          self.root.ui.toast.success('Success')
        }

      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadDocuments(id) {
      self.fromWhsDocuments.load(id);
      self.toWhsDocuments.load(id);
    },
    afterAttach() {
      self.autorunDisposer = autorun(() => {
        if (self.isOpened && self.id) {
          self.loadDocuments(self.id);
        }
      });
    },
    beforeDetach() {
      self.autorunDisposer && self.autorunDisposer();
    },
  }))

export default WhsAlertTroubleShootStore;
