import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { observer } from 'mobx-react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MenuItem, Select } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  content: {
  },
  table: {
    width: '100%',
  },
}));

const PrintPltLabels = observer(({ inbound }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(1);
  const { groupSelected, autoGroupSetSelectedPrintPalletCount, detail, loading } = inbound;

  const handleOpen = () => {
    autoGroupSetSelectedPrintPalletCount()
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return <>
    <Button style={{ width: '100%' }} color="primary" onClick={handleOpen}>PRINT PLT LABELS</Button>
    {open && <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Box className={classes.content}>
          {loading && <LinearProgress />}
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Carton Marking</TableCell>
                  <TableCell>EST Plt Count</TableCell>
                  <TableCell>Label Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupSelected.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {row.markings}
                    </TableCell>
                    <TableCell>{row.estimate_pallet_count ? row.estimate_pallet_count.toFixed(2) : ''}</TableCell>
                    <TableCell><TextField size="small" variant="outlined" value={row.print_pallet_count} onChange={e => row.setPrintPalletCount(e.target.value)} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Box mr={2} display="flex" alignContent="center">
              <Box mt={1} mr={2} display="flex" alignContent="center">
                Page size:
              </Box>
              <Box mr={2} >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageSize}
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <MenuItem value={1}>4 X 6</MenuItem>
                  <MenuItem value={2}>Letter</MenuItem>
                </Select>
              </Box>
            </Box>
            <Box mr={2} display="flex" alignContent="center">
              <Box mr={2}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={groupSelected.length === 0 || loading}
                  onClick={() => detail.viewMultipleLabels(pageSize)}
                >
                  Print
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>}
  </>
})

export default PrintPltLabels;
