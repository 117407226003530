import React from 'react';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format, isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toJS } from 'mobx';

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: '230px',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    color: '#243967',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '10px 15px',

    borderBottom: '1px solid #F3F5F9',


    '&:first-child': {
      borderTop: '1px solid #F3F5F9',
    },

    '&.active': {
      background: '#F3F5F9',
    },
  },
  optionText: {
    marginLeft: '5px',
    cursor: 'pointer',
  },
  check: {
    cursor: 'pointer',
  },
}));

const Move = inject('root')(
  observer(({ root, inventorys, size = "small" }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { areas, groupArea, loadArea, selected } = inventorys;

    const handleClick = (event) => {
      loadArea(selected.map(s => s.id)).then(() => {
        if (!groupArea.isAllSameArea()) {
          handleClose();
          root.ui.toast.error('Attention! Selected groups are not at the same area.');
        }
      });
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleSelect = (i) => {
      groupArea.move(i);
    }

    const isActive = (i) => {
      return groupArea.area_id == i.id;
    }

    const handleDateChange = (i, date) => {
      groupArea.move(i, date);
    }


    return <>
      <Button size={size} variant="contained" color="primary" onClick={handleClick}>Move</Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box py={1} className={classes.container}>
          {areas.map((i) => <Box key={i} className={clsx(classes.option, { active: isActive(i) })} >
            <Box onClick={() => handleSelect(i.id)} className={classes.check}>
              <input type="radio" checked={isActive(i)} />
            </Box>
            <Box className={classes.optionText} onClick={() => handleSelect(i.id)}>{i.name}</Box>
            {isActive(i) && <Box ml={2}><Date date={groupArea.moved_at} timezone={root.app.timezone} onSubmit={(date) => handleDateChange(i, date)} /></Box>}
          </Box>)}

          {groupArea.loading && <LinearProgress />}
        </Box>
      </Popover>
    </>
  })
);

const useDateStyles = makeStyles(theme => ({
  root: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  at: {
    color: '#80889A',
  },
  dateText: {
    color: theme.palette.primary.main,
    marginLeft: '4px',
    textDecoration: 'underline',
    fontStyle: 'italic',
    cursor: 'pointer',
  },
}));

const Date = observer(({ date, timezone, onSubmit }) => {
  const classes = useDateStyles();

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);

  const valid = isValid(value);

  const handleSubmit = () => {
    if (valid) {
      onSubmit(format(value, 'yyyy-MM-dd'));
      setOpen(false);
    }
  }

  React.useEffect(() => setValue(date ? utcToZonedTime(date, timezone) : null), [date, timezone]);

  return <Box className={classes.root}>
    {!!date && <span className={classes.at}>at</span>}
    <span className={classes.dateText} onClick={() => setOpen(true)}>{date || 'No Date'}</span>
    {open && <Dialog
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Update moved date</DialogTitle>
      <DialogContent>
        <KeyboardDatePicker
          fullWidth
          variant="inline"
          value={value}
          onChange={setValue}
          format="yyyy-MM-dd"
        />
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" color="cancel" onClick={() => setOpen(false)}>Cancel</Button>
        <Button size="small" variant="outlined" color="primary" disabled={!isValid} onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
    }
  </Box >
})


export default Move;

