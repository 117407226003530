import React, { useState } from 'react';
import CommonDocuments from '../../../common/Documents';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { format } from 'date-fns';
import { toJS } from 'mobx';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    minHeight: '300px',
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const ConfirmComplete = observer(({ inbound }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [completedAt, setCompletedAt] = useState(new Date());

  const { detail, finishedReceiptDocuments, loadFinishedReceiptDocuments, isActualQtyFinished, isInboundPalletCountFinished, isAllMarkupPalletCountFilled } = inbound;

  const handleOpen = () => {
    loadFinishedReceiptDocuments();
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const isDateValid = (date) => {
    return date.getTime() === date.getTime();
  }

  const handleComplete = () => {
    detail.complete(format(completedAt, 'yyyy-MM-dd HH:mm:ss'))
  }

  return <>
    <Button variant="contained" size="small" color="primary" onClick={handleOpen}>IB COMPLETE</Button>
    {open && <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Box className={classes.content}>
          <Box mb={2} className={classes.subtitle}>1. Please Fill in Complete Time *</Box>
          <Box mb={2}>
            <FormControl required>
              <KeyboardDateTimePicker variant="inline" value={completedAt} onChange={setCompletedAt} format="yyyy-MM-dd HH:mm" />
            </FormControl>
          </Box>
          <Box mb={2} className={classes.subtitle}>2. Please Fill in all Received QTY, PLT Count, Markup PLT Count *</Box>
          <Box mb={2}>
            <Box>{isActualQtyFinished ? <DoneIcon style={{ fontSize: '12px', width: '20px', color: '#23B34B', }} /> : <div style={{ display: 'inline-block', width: '20px', }}>&nbsp;</div>}Received QTY</Box>
            <Box>{isInboundPalletCountFinished ? <DoneIcon style={{ fontSize: '12px', width: '20px', color: '#23B34B', }} /> : <div style={{ display: 'inline-block', width: '20px', }}>&nbsp;</div>}PLT Count</Box>
            <Box>{isAllMarkupPalletCountFilled ? <DoneIcon style={{ fontSize: '12px', width: '20px', color: '#23B34B', }} /> : <div style={{ display: 'inline-block', width: '20px', }}>&nbsp;</div>}Markup PLT Count</Box>
          </Box>
          <Box mb={2} className={classes.subtitle}>3. Upload Complete Document</Box>
          <CommonDocuments documents={finishedReceiptDocuments} onUploaded={inbound.loadWhsDocuments} onDeleted={inbound.loadWhsDocuments} />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => handleComplete()}
                // @Ben 2022-11-11 isAllMarkupPalletCountFilled is required after make markup pallet count editable
                disabled={!isActualQtyFinished || !isInboundPalletCountFinished || !isAllMarkupPalletCountFilled || !isDateValid(completedAt)}
              // disabled={!isActualQtyFinished || !isInboundPalletCountFinished || !unloadingReceiptDocuments.hasDocuments || !isDateValid(completedAt)}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>}
  </>
});

export default ConfirmComplete;
