import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { TaskDetail } from './models';

const TaskStore = types
  .model('TaskStore', {
    detail: types.optional(TaskDetail, {}),
  })
  .volatile(self => ({
    loading: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setDetail(detail) {
      applySnapshot(self.detail, detail);
      self.root.tasks.replaceCollection(detail);
    },
    loadData: flow(function* (id) {
      let response = null;

      try {
        self.loading = true;
        self.setDetail({});
        response = yield self.root.api.task.show(id);
        self.setDetail(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;

      return response;
    }),
    loadDocuments() {
      self.detail.tasks.forEach(task => {
        task.loadDocuments();
      });
    },
    loadDataAndDocuments: flow(function* (id) {
      const response = yield self.loadData(id);
      if (response) {
        self.loadDocuments(id);
      }
    }),
    complete: flow(function* (loadId, taskId) {
      try {
        self.loading = true;
        const response = yield self.root.api.task.complete(loadId, taskId);
        self.setDetail(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    uncomplete: flow(function* (loadId, taskId) {
      try {
        self.loading = true;
        const response = yield self.root.api.task.uncomplete(loadId, taskId);
        self.setDetail(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
  }))


export default TaskStore;
