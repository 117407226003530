import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { DownloadIcon, DeleteIcon } from '../../ui/Icon';
import { observer } from 'mobx-react';
import { truncate } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { isFunction } from 'lodash';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 1.5,
    borderBottom: '1px solid #DFE0E2',
  },
  filename: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecorationLine: 'underline',
  },
  icons: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    marginRight: (props) => props.actionPosition === 'center' ? '11px' : 0,
    marginLeft: (props) => props.actionPosition === 'end' ? '11px' : 0,
  },
  location: {
    color: '#80889A',
    marginRight: theme.spacing(2),
  },
  icon: {
    cursor: 'pointer',
  },
  fileinfo: {
    color: '#80889A',
  },
}));

const isDeleteable = (deletable, document) => {
  return isFunction(deletable) ? deletable(document) : deletable;
}

const isDownloadable = (downloadable, document) => {
  return isFunction(downloadable) ? downloadable(document) : downloadable;
}

const DocumentBox = observer(({ document, downloadable = true, deletable = true, locationable = false, actionPosition = 'center', onDeleted }) => {
  const classes = useStyles({ actionPosition });

  const handleDelete = () => {
    document.delete().then(document => document && onDeleted && onDeleted(document));
  }

  const actionsContent = <Box className={classes.icons}>
    {isDeleteable(deletable, document) && <DeleteIcon className={classes.icon} onClick={handleDelete} />}
    {isDownloadable(downloadable, document) && <DownloadIcon className={classes.icon} onClick={document.download} />}
  </Box>;

  return <Box className={classes.root}>
    <Box mr="auto" className={classes.filename} onClick={document.view}>
      <Tooltip title={document.name} placement="top">
        <span>{truncate(document.name, { length: 15 })}</span>
      </Tooltip>
    </Box>
    {locationable && !!document.location && <Box className={classes.location}>
      from {document.location}
    </Box>}

    {actionPosition === 'center' && actionsContent}

    <Box className={classes.fileinfo}>
      By {truncate(document.creator_name, { length: 10 })} at {document.created_at}
    </Box>

    {actionPosition === 'end' && actionsContent}
  </Box>
})

export default DocumentBox;
