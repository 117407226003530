import React from 'react';
import { withRouter } from "react-router-dom";
import OBbolForm from './OBbolForm';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core';


const styles = (theme) => ({
  root: { padding: '10px 50px' },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    color: theme.palette.primary.main
  },
})

class OBbolFormModal extends React.Component {

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={true}
        onClose={this.goBack}
        className={classes.root}
      >
        <DialogTitle  className={classes.title}>OB BOL</DialogTitle>
        <DialogContent>
          <OBbolForm goBack={this.goBack}/>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withRouter(withStyles(styles)(OBbolFormModal));
