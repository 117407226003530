import { types, getRoot, flow, applySnapshot } from 'mobx-state-tree';

const ColumnStore = types
  .model('ColumnStore', {
    table: types.string,
    selected: types.array(types.string),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    },
  }))
  .actions(self => ({
    setSelected(selected) {
      applySnapshot(self.selected, selected);
    },
    setColumns(columns) {
      applySnapshot(self.columns, columns);
    },
    load: flow(function* () {
      try {
        const response = yield self.root.api.column.show(self.table);
        if (response.data.data) {
          const { columns } = response.data.data;
          self.selected = columns;
        }
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    updateSelected: flow(function* (data) {
      try {
        const response = yield self.root.api.column.update(self.table, data);
        const { columns } = response.data.data;
        self.selected = columns;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
  }));

export default ColumnStore;
