import React, { useState } from 'react';
import CommonDocuments from '../../../common/Documents';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { isEmpty, sumBy, values } from 'lodash';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { format } from 'date-fns';
import { TableFooter } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    padding: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  table: {

  },
}));

const ConfirmPacking = observer(({ bol }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const { detail, loading } = bol;

  const handleOpen = () => {
    setSelected(detail.otr_carrier);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    detail.completePacking().then(response => {
      if (response) {
        setOpen(false);
      }
    });
  }

  const canSubmit = () => {
    return !loading;
  }


  return <>
    <Button variant="contained" color="primary" onClick={handleOpen}>CONFIRM PACKING</Button>
    {open && <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box className={classes.content}>
            <Box mt={3} mb={2} className={classes.subtitle}>1. Please Complete All Addon Service *</Box>
            {detail.addon_services.map(row => <Box display="flex">
              {row.isComplete ? <DoneIcon /> : <CloseIcon />}
              <Box>{row.name}</Box>
            </Box>)}
            <Box mt={3} mb={2} className={classes.subtitle}>2. SKU Outbound Qty Check *</Box>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell align="right">Outbound Qty</TableCell>
                  <TableCell align="right">Packed Qty</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detail.items.map((row) => (
                  <TableRow key={row.sku}>
                    <TableCell component="th" scope="row">
                      {row.sku}
                    </TableCell>
                    <TableCell align="right">{row.qty_outbound}</TableCell>
                    <TableCell align="right">{row.total_qty}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!canSubmit()}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>}
  </>
});

const OtrCarrierCheckbox = observer(({ carrier, selected, onChange }) => {
  return <FormControlLabel
    control={
      <Checkbox
        checked={selected == carrier}
        onChange={() => onChange(carrier)}
        color="primary"
      />
    }
    label={carrier.name}
  />
})

export default ConfirmPacking;

