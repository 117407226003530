import React, { useEffect, useMemo } from 'react';
import Table from '../../ui/Table';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { toast, whsPalletReport } from '../../../services';
import columns from './columns';
import { set } from 'lodash';
import { Button, ButtonGroup, CircularProgress, Divider } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { LOAD_TYPE_FBA, LOAD_TYPE_MAP } from '../../../stores/inventory/constants';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  title: {

  },
  titleItem: {
    paddingBottom: '15px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#262626',
    opacity: 0.7,
    position: 'relative',
    cursor: 'pointer',
  },
  titleActiveItem: {
    opacity: 1,
    color: theme.palette.primary.main,

    '&::after': {
      content: '""',
      margin: 'auto',
      background: theme.palette.primary.main,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '2.5px',
      width: '50%',
      maxWidth: '150px',
    },
  },
  showMore: {
    textAlign: 'center',
  },
  completedRow: {
    opacity: 0.5,
  },
  canceledRow: {
    opacity: 0.5,
  },
  rejectedRow: {
    opacity: 0.5,
  },
}));

const toTotal = (data, key) => {
  return data.reduce((acc, row) => acc + Number(row[key].replace(/,/g, "")), 0);
}


const List = inject('root')(
  observer(({ root }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [sort, setSort] = React.useState({ by: 'del', value: 'desc' });
    const [types, _setTypes] = React.useState(root.whsPalletReport.types.length > 0 ? root.whsPalletReport.types : Object.keys(LOAD_TYPE_MAP));
    // const [pagination, setPagination] = React.useState({ page: 1, perPage: 50, total: 0 });
    const { setTypes } = root.whsPalletReport;

    const exportExcel = () => {

      // Extract headers from data
      const headers = ['DEL', 'Type', 'In WHS', 'Outbound Scheduled (Cargo loaded)', 'Outbound Scheduled (Cargo not yet loaded)', 'Pending outbound'];

      let csvContent = headers.join(",") + "\n";

      let total = ['Total',
        '',
        toTotal(rows, 'in_whs'),
        toTotal(rows, 'cargo_loaded'),
        toTotal(rows, 'carge_not_loaded'),
        toTotal(rows, 'pending_outbound'),
      ];

      csvContent += total.join(",") + "\n";

      csvContent += rows.map(row =>
        [row.del, LOAD_TYPE_MAP[row.type], row.in_whs, row.cargo_loaded, row.carge_not_loaded, row.pending_outbound].join(",")).join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv' });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);

      link.download = 'WHS pallet report ' + (new Date().toISOString().slice(0, 10)) + '.csv';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }

    const fetch = async () => {
      setLoading(true);
      try {
        const response = await whsPalletReport.index();
        setData(response.data.data);
      } catch (error) {
        toast.error(error);
      }
      setLoading(false);

      // setPagination({
      //   page: pagination.page || 1,
      //   perPage: pagination.perPage || 50,
      //   total: Math.ceil(response.data.data.length / (pagination.paperPagege || 50)),
      // })
    }

    React.useEffect(() => {
      const interval = setInterval(() => {
        fetch();
      }, 1000 * 60);
      fetch();
      return () => clearInterval(interval);
    }, []);

    const rows = useMemo(() => {
      const _data = data.sort((a, b) => {

        if (sort.by === 'type') {
          return sort.value === 'asc' ? a[sort.by] > b[sort.by] ? 1 : -1 : a[sort.by] < b[sort.by] ? 1 : -1;
        }

        if (sort.value === 'asc' && sort.by !== 'type') {
          return Number(a[sort.by].replace(/,/g, "")) > Number(b[sort.by].replace(/,/g, "")) ? 1 : -1;
        } else {
          return Number(a[sort.by].replace(/,/g, "")) < Number(b[sort.by].replace(/,/g, "")) ? 1 : -1;
        }
      });

      return [..._data].filter((row) => {
        return types.includes(`${row.type}`);
      })
      // .slice((pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage);
    }, [sort, data, types]);

    useEffect(() => {
      setTypes(types);
    }, [types]);


    return <Box className={classes.root}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <ButtonGroup size="small" variant="outlined" color="primary">

          {Object.keys(LOAD_TYPE_MAP).map((type) => {
            return <Button
              color='primary'
              variant={types.includes(type) ? 'contained' : ''}
              key={type}
              onClick={() => {
                if (types.includes(type)) {
                  _setTypes(types.filter(t => t !== type));
                } else {
                  _setTypes([...types, type]);
                }
              }}
            // className={classes.titleItem}
            >
              {LOAD_TYPE_MAP[type]}
            </Button>
          })}
        </ButtonGroup>
        <Button variant="outlined" color="primary" onClick={exportExcel}>Export</Button>
      </Box>
      <br />
      <br />
      <Box>{loading && <CircularProgress size={20} />}</Box>
      {!loading && <Table
        subHeader={true}
        columns={columns}
        data={rows}
        state={{
          sortBy: (sortBy, sortByValue) => {
            setSort({ by: sortBy, value: sortByValue });
          },
          sortByName: sort.by,
          sortByValue: sort.value,
        }}
        footerable={true}
        topFooter
      />}
      {/* <Pagination count={pagination.total} page={pagination.page} onChange={(e, page) => setPagination({ ...pagination, page })} /> */}
    </Box>
  })
)

export default List;
