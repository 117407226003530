import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Select from '../../../ui/Select';
import Input from '../../../ui/Input';
import { observer } from 'mobx-react';


const Filter = observer(({ filter, exportExcel }) => {
  const {
    id,
    ib_number,
    marking,
    renderSelectOption,
    handleSelect,
    isSelectedEqualToOption,
    setValueByHtmlElement,
    reset,
    submit,
  } = filter;


  const handleSubmit = (e) => {
    e.preventDefault()
    submit();
  }

  return <form onSubmit={handleSubmit}>
    <Box display="flex" alignItems="flex-end">
      <Box ml={1}>
        <Input
          title="WO#"
          name="id"
          value={id}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1}>
        <Input
          title="IB#"
          name="ib_number"
          value={ib_number}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1}>
        <Input
          title="Marking"
          name="marking"
          value={marking}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1} display="flex">
        <Box><Button variant="outlined" color="primary" type="submit">Apply</Button></Box>
        <Box ml={1}><Button variant="outlined" color="primary" onClick={reset}>Reset Filter</Button></Box>
      </Box>
      <Box ml={1}>
        <Button variant="outlined" color="primary" onClick={exportExcel}>Export</Button>
      </Box>
    </Box>
  </form>
})

export default Filter;
