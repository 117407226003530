import React, { useState } from "react";
import CommonDocuments from "../../../common/Documents";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { Checkbox, TextField } from "@material-ui/core";
import { toJS } from "mobx";
// import { toJS } from 'mobx';
// import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: 500,
  },
  content: {
    minHeight: "300px",
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: 500,
  },
}));

const ConfirmEmpty = observer(({ inbound }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [emptyAt, setEmptyAt] = useState(new Date());
  const [isNotify, setIsNotify] = useState(false);

  const { detail, emptyReceiptDocuments, loadEmptyReceiptDocuments } =
    inbound;
  const [notification, setNotification] = useState({
    to: toJS(detail.trucker_emails),
    cc: toJS(detail.cc_emails),
    subject: `${detail.ib_shipment_number} Empty Container`,
    body: `Hi Dispatch,

${detail.ib_shipment_number} is empty. Thank you.
    `,
  });
  const handleOpen = () => {
    loadEmptyReceiptDocuments();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isDateValid = (date) => {
    return date.getTime() === date.getTime();
  };

  const handleNotificationChange = (name, value, idx) => {
    const copy = { ...notification };

    if (idx !== undefined) {
      copy[name][idx] = value;
    } else {
      copy[name] = value;
    }

    setNotification(copy);
  };

  const handleAddEmail = (name) => {
    const copy = { ...notification };
    copy[name].push("");
    setNotification(copy);
  };

  const handleRemoveEmail = (name, idx) => {
    const copy = { ...notification };
    copy[name].splice(idx, 1);
    setNotification(copy);
  };

  const handelConfirm = () => {
    detail.empty({
      emptyAt: format(emptyAt, "yyyy-MM-dd HH:mm:ss"),
      isNotify: isNotify,
      ...(isNotify
        ? {
          ...notification,
          to: notification.to.filter((email) => !!email),
          cc: notification.cc.filter((email) => !!email),
        }
        : {}),
    });
  };

  return (
    <>
      <Button variant="contained" size="small" color="primary" onClick={handleOpen}>
        CONFIRM EMPTY
      </Button>
      {open && (
        <Dialog
          fullWidth
          maxWidth={isNotify ? "lg" : "md"}
          open={open}
          onClose={handleClose}
        >
          <DialogContent>
            <Box display={"flex"}>
              <Box padding={2} flex={1} className={classes.content}>
                <Box mb={2} className={classes.subtitle}>
                  1. Please Fill in Empty Time *
                </Box>
                <Box mb={2}>
                  <FormControl required>
                    <KeyboardDateTimePicker
                      variant="inline"
                      value={emptyAt}
                      onChange={setEmptyAt}
                      format="yyyy-MM-dd HH:mm"
                    />
                  </FormControl>
                </Box>
                <Box mb={2} className={classes.subtitle}>
                  2. Empty Receipt if have
                </Box>
                <CommonDocuments
                  documents={emptyReceiptDocuments}
                  onUploaded={inbound.loadWhsDocuments}
                  onDeleted={inbound.loadWhsDocuments}
                />
                <Box mb={2} alignItems="center" className={classes.subtitle}>
                  <span>3. Notify Carrier of Empty Container</span>
                  <Checkbox
                    checked={isNotify}
                    onChange={() => setIsNotify(!isNotify)}
                  />
                </Box>
              </Box>
              {isNotify && (
                <Box padding={2} flex={1} className={classes.content}>
                  <Box md={2} className={classes.subtitle}>Email</Box>
                  <Box mb={2}>
                    <Box
                      display={"flex"}
                      className={classes.subtitle}
                      alignItems="center"
                    >
                      <span>To</span>{" "}
                      <Button onClick={() => handleAddEmail("to")}>
                        <AddIcon />
                      </Button>{" "}
                    </Box>
                    {notification.to.map((email, idx) => (
                      <Box display={"flex"} mb={1}>
                        <TextField
                          value={email}
                          variant="outlined"
                          type="email"
                          size="small"
                          fullWidth
                          name="to"
                          onChange={(e) =>
                            handleNotificationChange(
                              e.target.name,
                              e.target.value,
                              idx
                            )
                          }
                        />
                        <Button
                          size="small"
                          onClick={() => handleRemoveEmail("to", idx)}
                        >
                          <RemoveIcon />
                        </Button>
                      </Box>
                    ))}
                  </Box>
                  <Box mb={2}>
                    <Box
                      display={"flex"}
                      className={classes.subtitle}
                      alignItems="center"
                    >
                      <span>CC</span>{" "}
                      <Button size="small" onClick={() => handleAddEmail("cc")}>
                        <AddIcon />
                      </Button>
                    </Box>
                    {notification.cc.map((email, idx) => (
                      <Box display={"flex"} mb={1}>
                        <TextField
                          value={email}
                          variant="outlined"
                          type="email"
                          size="small"
                          fullWidth
                          name="cc"
                          onChange={(e) =>
                            handleNotificationChange(
                              e.target.name,
                              e.target.value,
                              idx
                            )
                          }
                        />
                        <Button
                          size="small"
                          onClick={() => handleRemoveEmail("cc", idx)}
                        >
                          <RemoveIcon />
                        </Button>
                      </Box>
                    ))}
                  </Box>
                  <Box mb={2}>
                    <Box className={classes.subtitle}>Subject</Box>
                    <TextField
                      value={notification.subject || ""}
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="subject"
                      onChange={(e) =>
                        handleNotificationChange(e.target.name, e.target.value)
                      }
                    />
                  </Box>
                  <Box mb={2}>
                    <Box className={classes.subtitle}>Body</Box>
                    <TextField
                      multiline
                      rows={4}
                      value={notification.body || ""}
                      variant="outlined"
                      fullWidth
                      name="body"
                      onChange={(e) =>
                        handleNotificationChange(e.target.name, e.target.value)
                      }
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handelConfirm}
                  disabled={!isDateValid(emptyAt) || inbound.loading || !emptyReceiptDocuments.hasDocuments}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
});

export default ConfirmEmpty;
