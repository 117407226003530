import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { uniq } from 'lodash';
import { isFunction } from 'lodash';
import { observer } from 'mobx-react';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  popover: {
    zIndex: '100',
    position: 'absolute',
    top: '40px',
    right: '0px',
    backgroundColor: '#FFFFFF',
    padding: '10px 10px 10px 15px',
    minWidth: '200px',
    textAlign: 'left',
    maxHeight: '600px',
    overflowY: 'auto',
  },
  label: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#333333',
  },
  children: {
    cursor: 'pointer',
  },
}));

const defaultRenderTitle = (column) => column.columnTitle;
const toAccessor = (column, accessor) => isFunction(accessor) ? accessor(column) : column[accessor];

const Columns = observer(({ children, columns, selected, onSelect, renderTitle = defaultRenderTitle, accessor = 'accessor', }) => {
  const classes = useStyles();

  const [showColumnFilter, setShowColumnFilter] = useState(false);

  const handleShowColumnFilter = () => {
    setShowColumnFilter(!showColumnFilter);
  }

  const checkColumnSelected = (column) => {
    return selected.indexOf(toAccessor(column, accessor)) != -1;
  }

  const handleColumnChange = (e, column) => {
    onSelect(e.target.checked ? uniq([toAccessor(column, accessor), ...selected]) : selected.filter(selectedColumn => selectedColumn != toAccessor(column, accessor)));
  }

  return (<Box className={classes.root}>
    <Box component="span" className={classes.children} onClick={handleShowColumnFilter}>{children}</Box>
    {showColumnFilter && <Box className={classes.popover} boxShadow={3}>
      {columns.map((column, index) => <Box key={index}>
        <FormControlLabel
          classes={{ label: classes.label }}
          control={<Checkbox size="small" color="primary" checked={checkColumnSelected(column)} onChange={(e) => handleColumnChange(e, column)} />}
          label={renderTitle(column)}
        />
      </Box>)}
    </Box>
    }
  </Box>);
})

export default Columns;
