export const OUTBOUND_LIST_TYPE_ALL = 'all';
export const OUTBOUND_LIST_TYPE_TODAY = 'today';
export const OUTBOUND_LIST_TYPE_UPCOMING = 'upcoming';
export const OUTBOUND_LIST_TYPE_OVER_DUE = 'overdue';
export const OUTBOUND_LIST_TYPE_COMPLETED = 'completed';
export const OUTBOUND_LIST_TYPE_CANCELED = 'canceled';

export const OUTBOUND_LIST_TYPES_MAP = {
  [OUTBOUND_LIST_TYPE_ALL]: 'Total',
  [OUTBOUND_LIST_TYPE_TODAY]: 'Today\'s Outbound',
  [OUTBOUND_LIST_TYPE_UPCOMING]: 'Upcoming Outbound',
  [OUTBOUND_LIST_TYPE_OVER_DUE]: 'Overdue Outbound',
  [OUTBOUND_LIST_TYPE_COMPLETED]: 'Completed Outbound',
  [OUTBOUND_LIST_TYPE_CANCELED]: 'Canceled Outbound',
};
