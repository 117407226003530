import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DocumentList from './DocumentList';
import Dropzone from './Dropzone';
import ReactPropTypes from 'prop-types';
import { observer, PropTypes } from 'mobx-react';
import DocumentsStore from '../../../stores/document/DocumentsStore';


const useStyles = makeStyles(theme => ({
  root: {
  },
}));

const defaultEmptyFunc = () => { }

const Documents = observer(({ documents, pastable = false, uploadable = true, downloadable = true, deletable = true, dropzonePosition = 'auto-right', documentListProps = {}, onUploaded = defaultEmptyFunc, onDeleted = defaultEmptyFunc }) => {
  const classes = useStyles();

  const hasDocuments = documents.collection.length > 0;

  const documentListContent = hasDocuments && <DocumentList {...documentListProps} documents={documents.collection} downloadable={downloadable} deletable={deletable} onDeleted={onDeleted} />

  const dropzoneContent = uploadable && <Dropzone documents={documents} onUploaded={onUploaded} pastable={pastable} />

  let content = null;

  if (dropzonePosition === 'top') {
    content = <>
      {uploadable && <Grid item xs={12}>
        {dropzoneContent}
      </Grid>}
      {hasDocuments && <Grid item xs={12}>
        {documentListContent}
      </Grid>}
    </>
  } else {
    content = <>
      {hasDocuments && <Grid item xs={12} md={uploadable ? 8 : 12}>
        {documentListContent}
      </Grid>}

      {uploadable && <Grid item xs={12} md={hasDocuments ? 4 : 12}>
        {dropzoneContent}
      </Grid>}
    </>
  }

  return <Grid container className={classes.root} spacing={3}>
    {content}
  </Grid >
})


Documents.prototype = {
  documents: PropTypes.observableObject(DocumentsStore),
  downloadable: ReactPropTypes.bool,
  deletable: ReactPropTypes.bool,
  dropzonePosition: ReactPropTypes.arrayOf(['auto-right', 'top']),
}


export default Documents;
