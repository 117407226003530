import React from 'react';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";


import Login from '../components/pages/Login';

import Dashboard from '../components/pages/dashboard/Dashboard';

import OceanInboundList from '../components/pages/inbound/OceanList';
import GroundInboundList from '../components/pages/inbound/GroundList';
import AirInboundList from '../components/pages/inbound/AirList';
import InboundDetail from '../components/pages/inbound/Detail';
import TroubleShootList from '../components/pages/inbound/TroubleShoots';
import InventoryList from '../components/pages/inventory/InventoryList';
import BolList from '../components/pages/bol/List';
import BolDetail from '../components/pages/bol/Detail';
import TaskList from '../components/pages/task/List';
import TaskDetail from '../components/pages/task/Detail';
import Report from '../components/pages/report/Report';
import whsPalletReport from '../components/pages/whsPalletReport';
import Summary from '../components/pages/Summary';
import WorkOrderDetail from '../components/pages/workOrder/Detail';
import WorkOrderList from '../components/pages/workOrder/List';
import ChatRoomNotification from '../components/pages/notification/ChatRoomNotification';
import ChatRoomNotificationModal from '../components/pages/notification/ChatRoomNotificationModal';
import OutboundList from '../components/pages/outbound/List';
import OBbolFormModal from '../components/pages/workOrder/Detail/OBbolFormModal';
import stockInventory from '../components/pages/stockInventory';


const Routes = ({ isAuthenticated }) => {
  const location = useLocation();
  const background = location.state && location.state.background;

  return <>
    <Switch location={background || location}>
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/" component={Dashboard} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/inbound/ocean" component={OceanInboundList} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/inbound/air" component={AirInboundList} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/inbound/ground" component={GroundInboundList} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/troubleshoots" component={TroubleShootList} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/inbound/:warehouseAlertId" component={InboundDetail} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/inventory" component={InventoryList} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/outbounds" component={OutboundList} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/bols" component={BolList} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/bols/:bolId" component={BolDetail} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/tasks" component={TaskList} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/tasks/:loadId" component={TaskDetail} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/report" component={Report} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/workOrders" component={WorkOrderList} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/workOrders/:workOrderId" component={WorkOrderDetail} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/notification/chatroom" component={ChatRoomNotification} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/stockInventory" component={stockInventory} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/summary" component={Summary} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/whsPalletReport" component={whsPalletReport} />

      <Route exact path="/login" component={Login} />

      <Route render={() => 'Not Found'} />
    </Switch>

    {background && <Switch>
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/notification/chatroom" component={ChatRoomNotificationModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/workOrders/OBbol/:id" component={OBbolFormModal} />
    </Switch>}
  </>
}


const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component  {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default Routes;
