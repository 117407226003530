import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { DocumentsIcon, UploadIcon } from '../../../ui/Icon';
import CommonDocuments from '../../../common/Documents';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  tabs: {
    display: 'inline-block',
    fontSize: '18px',
    lineHeight: 1.5,
    textAlign: 'center',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#80889A',
    backgroundColor: '#F1F3F9',
  },
  tab: {
    display: 'inline-block',
    padding: '15px 25px',
    cursor: 'pointer',
    borderRadius: '3px 3px 0px 0px',
  },
  tabIcon: {
    marginRight: '11px',
    verticalAlign: 'middle',
  },
  tabActive: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.lightGray.main,
  },
  tabsContent: {
    padding: '15px',
    minHeight: '145px',
    backgroundColor: theme.palette.lightGray.main,
  },
}));


const Documents = observer(({ whsDocuments, adminDocuments }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  const tabsData = [
    {
      value: 0,
      text: whsDocuments.collection.length ? `Upload WHS File (${whsDocuments.collection.length})` : 'Upload WHS File',
      icon: <UploadIcon />
    },
    {
      value: 1,
      text: adminDocuments.collection.length ? `Admin Document (${adminDocuments.collection.length})` : 'Admin Document',
      icon: <DocumentsIcon />
    },
  ];

  return <Box className={classes.root}>
    <Paper elevation={0} className={classes.tabs}>
      {tabsData.map(data => <Box key={data.value} className={clsx(classes.tab, { [classes.tabActive]: tab === data.value })} onClick={() => setTab(data.value)}>
        <span className={classes.tabIcon}>{data.icon}</span>
        <span>{data.text}</span>
      </Box>)}
    </Paper>
    <Paper elevation={0} className={classes.tabsContent}>
      {tab === 0 && <Box>
        <CommonDocuments documents={whsDocuments}  />
      </Box>}
      {tab === 1 && <Box>
        <CommonDocuments documents={adminDocuments} uploadable={false}  deletable={false} />
      </Box>}
    </Paper>
  </Box>
})



export default Documents;
