import { types, getParent, } from 'mobx-state-tree';

const Input = types.model({
  id: types.string
}).volatile(self => ({
  action: () => { }
})).actions(self => ({
  set(action) {
    Object.assign(self, { action: action })
  }
}))

const InputStore = types
  .model('InputStore', {
    currentInput: types.maybeNull(Input),
    registeredInput: types.optional(types.array(Input), [])
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setCurrentInput(id) {
      let select = self.registeredInput.find(input => input.id === id)
      self.currentInput = { ...select }
    },
    clear() { self.registeredInput = []; },
    register(input, cb) {
      let inputInstance = Input.create({ id: input })
      inputInstance.set(cb)
      self.registeredInput.push(inputInstance);
    },
    next() {
      if (self.currentInput) {
        let nextIndex = self.registeredInput.indexOf(self.registeredInput.find(input => input.id === self.currentInput.id)) + 1
        let select = self.registeredInput[nextIndex > self.registeredInput.length ? nextIndex - 1 : nextIndex]
        self.currentInput = { ...select }
        self.currentInput.set(select.action)
        self.currentInput.action();
      }
    }
  }));

export default InputStore;
