import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
  },
}))

const ACTIVE_COLOE = '#2E52C5';
const INACTIVE_COLOE = '#80889A';


const SortBy = ({ name, by, value, onSort }) => {
  const classes = useStyles();

  return <svg className={classes.root} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.78963 5.72969H3.2115C2.70994 5.72969 2.45916 5.12266 2.81306 4.76875L5.60213 1.97969C5.82244 1.75937 6.17869 1.75937 6.39666 1.97969L9.18572 4.76875C9.54197 5.12266 9.29119 5.72969 8.78963 5.72969Z"
      fill={name === by && value === 'asc' ? ACTIVE_COLOE : INACTIVE_COLOE}
      onClick={() => onSort(name, 'asc')}
    />
    <path
      d="M3.2115 6.75H8.78963C9.29119 6.75 9.54197 7.35703 9.18806 7.71094L6.399 10.5C6.17869 10.7203 5.82244 10.7203 5.60447 10.5L2.81306 7.71094C2.45916 7.35703 2.70994 6.75 3.2115 6.75Z"
      fill={name === by && value === 'desc' ? ACTIVE_COLOE : INACTIVE_COLOE}
      onClick={() => onSort(name, 'desc')}
    />
  </svg>
}

export default SortBy;
