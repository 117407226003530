import { types } from 'mobx-state-tree';

const Url = types.model({
  pathname: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  hash: types.maybeNull(types.string),
  state: types.maybeNull(types.map(types.union(types.string, types.number, types.null))),
  background: false,
})

export const SearchedItem = types
  .model({
    topic: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    text: types.maybeNull(types.union(types.number, types.string)),
    uid: types.maybeNull(types.union(types.number, types.string)),
    url: Url,
  });

