import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { INBOUND_SHIPMENT_TYPE_MAP } from '../../../stores/inbound/constants';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default inject('root')(
  observer(({ root }) => {
    const { report } = root;
    const classes = useStyles();

    const handleSubmit = (e) => {
      e.preventDefault();
      report.downloadUnloadingHistory();
    }

    useEffect(() => {
      report.setRange('from', new Date())
      report.setRange('to', new Date())
    }, [])

    return <>
      <form onSubmit={handleSubmit} className={classes.container}>
        <Box mx="2px">
          <KeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="From Date"
            format="MM/dd/yyyy"
            value={report.from}
            InputAdornmentProps={{ position: "start" }}
            onChange={date => report.setRange('from', date)}
          />
        </Box>
        <Box mx="5px">
          <KeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="To Date"
            format="MM/dd/yyyy"
            value={report.to}
            InputAdornmentProps={{ position: "start" }}
            onChange={date => report.setRange('to', date)}
          />
        </Box>
        <Box mx="5px">
          <FormControl variant="outlined">
            <InputLabel>Shipment Type</InputLabel>
            <Select
              value={report.shipment_type}
              onChange={e => report.setShipmentType(e.target.value)}
            >
              {Object.keys(INBOUND_SHIPMENT_TYPE_MAP).map(k => <MenuItem value={k}>{INBOUND_SHIPMENT_TYPE_MAP[k]}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <Button type="submit" variant="contained" color="primary">Download Unloading History</Button>
      </form>

    </>
  })
)
