import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '../../../ui/Table';
import { TrashIcon, EditIcon } from '../../../ui/Icon';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import Popper from '@material-ui/core/Popper';
import { ClickAwayListener, MenuItem, Tooltip } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Badge from '../../../common/Badge';
import InventoryHistory from './InventoryHistory';
import { truncate, uniq } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import OBbolForm from './OBbolForm';
import CollectionText from '../../../common/CollectionText';
import { Link, useLocation } from 'react-router-dom';
import { LOAD_TYPE_MAP } from '../../../../stores/inventory/constants';
import Switch from '@material-ui/core/Switch';
import InventoryNewGroup from './InventoryNewGroup';


const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.5,
    color: '#000000',
  },
  loads: {
    background: '#C4C4C4',
  },
  table: {
    width: '100%',

    fontSize: '12px',
    lineHeight: 1.5,
    color: '#000000',
    borderCollapse: 'collapse',

    '& tr': {
      textAlign: 'left',

      '& td, th': {
        margin: '0px 10px',
        padding: '15px 0',
      },

      '& th': {
        border: 0,
        color: '#000000',
        background: '#E0E0E0',
        fontWeight: 'normal',
      },
    },

    '& tr:first-child': {
      '& td': {
        borderTop: 0,
      },
    },
  },
  summary: {
    position: 'fixed',
    bottom: '5px',
    right: '100px',
    zIndex: 10,
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    padding: '5px 10px',
    borderRadius: '5px',
    display: 'flex',
  },
  summaryItem: {
    margin: '0 20px',
  },
  summaryItemTitle: {
    fontWeight: 'bold',
  },
  summaryItemValue: {
    fontWeight: 'normal',
  },
}));


const TaskAttributeInput = withStyles(theme => ({
  root: {
    background: '#FFFFFF',
  },
  input: {
    padding: '8px',
    fontSize: '12px',
  }
}))(OutlinedInput);


const TaskAttributeSelect = withStyles(theme => ({
  root: {
    background: '#FFFFFF',
  },
  select: {
    paddingTop: '5.5px',
    paddingBottom: '5.5px',
    fontSize: '12px',
    background: '#FFFFFF',
  }
}))(Select);


const columns = [
  {
    Header: observer(({ state: { workOrder, detail } }) => <Checkbox size="small" color="primary" checked={detail.isAllGroupSelected} onChange={workOrder.toggleAllSelectedGroup} />),
    accessor: 'checkbox',
    Cell: observer(({ row: { original, depth }, }) => depth === 0 && <Checkbox size="small" color="primary" checked={original.isSelected} onChange={original.toggleSelect} />)
  },
  {
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...getToggleAllRowsExpandedProps()}
      >
        <Box>CNTR#</Box>
        <Box>{isAllRowsExpanded ? <ArrowDropUpIcon style={{ fontSize: '18px' }} /> : <ArrowDropDownIcon style={{ fontSize: '18px' }} />}</Box>
      </Box>
    </>,
    accessor: 'ib_number',
    Cell: observer(({ row: { original, canExpand, isExpanded, getToggleRowExpandedProps, depth } }) => <Box px={0.5}>
      {depth === 0 && original && !original.is_new && <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...getToggleRowExpandedProps()}
      >
        <Box>{original.ib_number}</Box>
        {canExpand && <Box ml={1}>{isExpanded ? <ArrowDropUpIcon style={{ fontSize: '18px' }} /> : <ArrowDropDownIcon style={{ fontSize: '18px' }} />}</Box>}
      </Box>}
      {depth === 0 && original && original.is_new && <Box sx={{ m: 0.5 }}>
        <TaskAttributeInput margin="dense" name="ib_number" placeholder='CNTR#' value={original.ib_number} onChange={original.setValueByHtmlElememt} required />
        <TaskAttributeInput style={{ marginTop: '2px' }} margin="dense" name="receiver_shipment_id" placeholder="Shipment ID" value={original.receiver_shipment_id} onChange={original.setValueByHtmlElememt} />
      </Box>}
      {depth === 1 && original === null && 'Load#'}
      {depth === 1 && original && original.uid}
    </Box>),
    headerStyle: { width: '12%' },
  },
  {
    Header: 'WHS Marking#',
    accessor: 'marking',
    Cell: observer(({ row: { original, depth } }) => <Box px={0.5}>
      {depth === 0 && original && !original.is_new && original.marking}
      {depth === 0 && original && original.is_new && <Box >
        <TaskAttributeInput margin="dense" name="marking" placeholder="WHS Marking#" value={original.marking} onChange={original.setValueByHtmlElememt} required />
        <TaskAttributeInput style={{ marginTop: '2px' }} margin="dense" name="receiver_reference_id" placeholder="Reference ID" value={original.receiver_reference_id} onChange={original.setValueByHtmlElememt} />
      </Box>}
      {depth === 1 && original === null && 'Shipment ID'}
      {depth === 1 && original && original.receiver_shipment_id}
    </Box>),
    headerStyle: { width: '12%' },
  },
  {
    Header: 'WHS Group#',
    accessor: 'uid',
    Cell: observer(({ row: { original, depth } }) => <Box px={0.5}>
      {depth === 0 && original && !original.is_new && original.uid}
      {depth === 0 && original && original.is_new && <Badge>New</Badge>}
      {depth === 1 && original === null && 'Reference ID'}
      {depth === 1 && original && original.receiver_reference_id}
    </Box>),
    headerStyle: { width: '8%' },
    Footer: () => <strong>Total</strong>,
  },
  {
    Header: 'Load Type',
    accessor: 'loads.type',
    Cell: observer(({ row: { original, depth } }) => <Box px={0.5}>
      {depth === 0 && original && !original.is_new && <CollectionText taken={1} texts={uniq(original.loads.map(l => l.type_name))} emptyContent="-" />}
      {depth === 0 && original && original.is_new && <TaskAttributeSelect margin="dense" name="type" value={original.type} onChange={original.setValueByHtmlElememt} required>
        {Object.keys(LOAD_TYPE_MAP).map(k => <MenuItem key={k} value={k}>{LOAD_TYPE_MAP[k]}</MenuItem>)}
      </TaskAttributeSelect>}
      {depth === 1 && original === null && 'Load Type'}
      {depth === 1 && original && <TaskAttributeSelect margin="dense" name="type" value={original.type} onChange={original.setValueByHtmlElememt} required>
        {Object.keys(LOAD_TYPE_MAP).map(k => <MenuItem key={k} value={k}>{LOAD_TYPE_MAP[k]}</MenuItem>)}
      </TaskAttributeSelect>}
    </Box>),
    headerStyle: { width: '5%' },
  },
  {
    Header: 'Ready To Ship',
    accessor: 'is_ready_to_ship',
    Cell: observer(({ row: { original, depth } }) => <Box px={0.5}>
      {depth === 0 && <Switch color="primary" size="small" checked={!!original.is_ready_to_ship} name="is_ready_to_ship" onChange={original.setValueAndApplySameToLoadsByHtmlElement} />}
      {depth === 1 && original === null && 'Ready To Ship'}
      {depth === 1 && original && <Switch color="primary" size="small" checked={!!original.is_ready_to_ship} name="is_ready_to_ship" onChange={original.setValueByHtmlElememt} />}
    </Box>),
    headerStyle: { width: '5%' },
  },
  {
    Header: 'BOL',
    accessor: 'loads.bol',
    Cell: observer(({ row: { original, depth }, state: { loaction } }) => <Box px={0.5}>
      {depth === 0 && <CollectionText taken={1} texts={original.loads.filter(l => l.bol)} rowRender={load => <Link to={{
        pathname: `/workOrders/OBbol/${load.bol.id}`,
        state: { background: loaction }
      }}>{load.bol.uid}</Link>} emptyContent="-" />}
      {depth === 1 && original === null && 'BOL'}
      {depth === 1 && original && (original.bol ? <Link to={`/bols/${original.bol.id}`}>{original.bol.uid}</Link> : '-')}
    </Box>),
    headerStyle: { width: '6%' },
  },
  {
    Header: 'Current Qty',
    accessor: 'current_qty',
    Cell: observer(({ row: { original, depth } }) => <Box px={0.5}>
      {depth === 0 && original && !original.is_new && <Box display="flex" alignItems="center">
        <Box mr={1}>
          {original.current_qty}
        </Box>
        <Box>
          <QtyEdit
            addValue={original.add_current_qty}
            onAddChange={e => original.setAddCurrentQty(e.target.value)}
            removeValue={original.remove_current_qty}
            onRemoveChange={e => original.setRemoveCurrentQty(e.target.value)}
          />
        </Box>
      </Box>}
      {depth === 0 && original && original.is_new && <TaskAttributeInput type="number" min="0.01" margin="dense" name="current_qty" value={original.current_qty} onChange={original.setValueByHtmlElememt} required />}
      {depth === 1 && original === null && 'Current Qty'}
      {depth === 1 && original && <Box display="flex" alignItems="center">
        <Box mr={1}>
          {original.current_qty}
        </Box>
        <Box>
          <QtyEdit
            addValue={original.add_current_qty}
            onAddChange={e => original.setValueAndApplyToGroup('add_current_qty', e.target.value)}
            removeValue={original.remove_current_qty}
            onRemoveChange={e => original.setValueAndApplyToGroup('remove_current_qty', e.target.value)}
          />
        </Box>
      </Box>}
    </Box>),
    headerStyle: { width: '8%' },
    Footer: observer(({ state: { detail } }) => detail.group_total_current_qty),
  },
  {
    Header: 'Destroy Qty',
    accessor: 'destroy_qty',
    Cell: observer(({ row: { original, depth } }) => <Box px={0.5}>
      {depth === 0 && original && !original.is_new && <TaskAttributeInput type="number" min="0.01" margin="dense" name="destroy_qty" value={original.destroy_qty} onChange={original.setValueAndApplyToLoadsByHtmlElement} required={original.is_new} />}
      {depth === 0 && original && original.is_new && '-'}
      {depth === 1 && original === null && 'Destroy Qty'}
      {depth === 1 && original && <TaskAttributeInput type="number" min="0.01" margin="dense" name="destroy_qty" value={original.destroy_qty} onChange={original.setValueByHtmlElememt} />}
    </Box>),
    headerStyle: { width: '8%' },
    Footer: observer(({ state: { detail } }) => detail.group_total_destroy_qty),
  },
  {
    Header: 'New Qty',
    accessor: 'new_qty',
    Cell: observer(({ row: { original, depth } }) => <Box px={0.5}>
      {depth === 0 && original && !original.is_new && original.total_new_qty}
      {depth === 0 && original && original.is_new && original.new_qty}
      {depth === 1 && original === null && 'New Qty'}
      {depth === 1 && original && original.new_qty}
    </Box>),
    headerStyle: { width: '8%' },
    Footer: observer(({ state: { detail } }) => detail.group_total_new_qty),
  },
  {
    Header: 'Remaining PLT',
    accessor: 'whs_pallet_count',
    Cell: observer(({ row: { original, depth } }) => <Box px={0.5}>
      {depth === 0 && original && <TaskAttributeInput type="number" min="0.01" margin="dense" name="remaining_pallet_count" value={original.remaining_pallet_count} onChange={original.setValueAndApplyToLoadsByHtmlElement} required={original.is_new} />}
      {depth === 1 && original === null && 'WHS PLT'}
      {depth === 1 && original && <TaskAttributeInput type="number" min="0.01" margin="dense" name="remaining_pallet_count" value={original.remaining_pallet_count} onChange={original.setValueAndApplyToGroupByHtmlElement} />}
    </Box>),
    headerStyle: { width: '10%' },
    Footer: observer(({ state: { detail } }) => detail.group_total_remaining_pallet_count),
  },
  {
    Header: 'Weight LB',
    accessor: 'weight_lb',
    Cell: observer(({ row: { original, depth } }) => <Box px={0.5}>
      {depth === 0 && original && <TaskAttributeInput type="number" min="0.01" margin="dense" name="remaining_weight_lb" value={original.remaining_weight_lb} onChange={original.setValueAndApplyToLoadsByHtmlElement} required={original.is_new} />}
      {depth === 1 && original === null && 'Weight LB'}
      {depth === 1 && original && <TaskAttributeInput type="number" min="0.01" margin="dense" name="remaining_weight_lb" value={original.remaining_weight_lb} onChange={original.setValueAndApplyToGroupByHtmlElement} required />}
    </Box>),
    headerStyle: { width: '10%' },
    Footer: observer(({ state: { detail } }) => detail.group_total_remaining_weight_lb),
  },
  {
    Header: 'CBM',
    accessor: 'volume_cbm',
    Cell: observer(({ row: { original, depth } }) => <Box px={0.5}>
      {depth === 0 && original && <TaskAttributeInput type="number" min="0.01" margin="dense" name="remaining_volume_cbm" value={original.remaining_volume_cbm} onChange={original.setValueAndApplyToLoadsByHtmlElement} required={original.is_new} />}
      {depth === 1 && original === null && 'CBM'}
      {depth === 1 && original && <TaskAttributeInput type="number" min="0.01" margin="dense" name="remaining_volume_cbm" value={original.remaining_volume_cbm} onChange={original.setValueAndApplyToGroupByHtmlElement} />}
    </Box>),
    headerStyle: { width: '10%' },
    Footer: observer(({ state: { detail } }) => detail.group_total_remaining_volume_cbm),
  },
  {
    Header: 'Create From',
    accessor: 'create_from',
    Cell: observer(({ row: { original, depth }, state: { detail } }) => <Box px={0.5}>
      {original !== null && depth === 0 && original.is_new ? <CreateFrom detail={detail} group={original} /> : '-'}
    </Box>),
  },
  {
    Header: 'Action',
    accessor: 'remove',
    Cell: observer(({ row: { original, depth }, state: { detail, loading } }) => <Box px={0.5}>
      {depth === 0 && original && original.is_new ? <TrashIcon style={{ cursor: 'pointer', fontSize: '18px' }} onClick={original.remove} /> : ''}
      {depth === 0 && original && !original.is_new && original.deletable && !detail.completed_at ? <TrashIcon style={{ cursor: 'pointer', fontSize: '18px' }} disabled={loading} onClick={original.delete} /> : ''}
    </Box>),
  },
  {
    Header: 'History',
    accessor: 'history',
    Cell: observer(({ row: { original, depth }, state: { workOrder } }) => <Box px={0.5}>
      {original && !original.is_new ? <InventoryHistory workOrder={workOrder} row={original} type={depth === 0 ? 'group' : 'load'} /> : '-'}
    </Box>),
  },
];


const QtyEdit = observer(({ addValue, removeValue, onAddChange, onRemoveChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return <>
    <EditIcon onClick={handleClick} arrow style={{ fontSize: '18px', cursor: 'pointer' }} />
    {open && <ClickAwayListener onClickAway={handleClick}>
      <Popper open={open} anchorEl={anchorEl}>
        <Box display="flex" alignItems="center" p={2} style={{ fontSize: '12px', background: 'rgb(0,0,0, 0.8)', color: '#FFFFFF' }}>
          <Box>Add</Box>
          <Box ml={1}><TaskAttributeInput type="number" min="0.01" margin="dense" value={addValue} onChange={onAddChange} /></Box>
          <Box ml={2}>Remove</Box>
          <Box ml={1}><TaskAttributeInput type="number" min="0.01" margin="dense" value={removeValue} onChange={onRemoveChange} /></Box>
        </Box>
      </Popper>
    </ClickAwayListener>
    }
  </>
})


const useCreateFromStyles = makeStyles(theme => ({
  root: {
  },
  popper: {
    background: '#FFFFFF',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '12px',
    minWidth: '200px',
    border: '1px solid #000000',
    maxHeight: '300px',
    overflowY: 'auto',
  },
  selected: {
    background: '#E0E0E0',
  },
}));

const CreateFrom = observer(({ detail, group }) => {
  const classes = useCreateFromStyles();
  const { notNewGroups, } = detail;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const selectedText = truncate(group.createFromText, { length: 20 });

  const selecledLoadIds = group.create_from_load_ids.slice();

  return <>
    <Box className={classes.btn}>
      <TaskAttributeSelect open={false} margin="dense" displayEmpty variant="outlined" defaultValue="" onClick={handleClick} readOnly>
        <MenuItem value="">{selectedText}</MenuItem>
      </TaskAttributeSelect>
    </Box>
    {open && <ClickAwayListener onClickAway={handleClick}>
      <Popper open={open} anchorEl={anchorEl}>
        <Box className={classes.popper}>
          {notNewGroups.map((g, i) => <Box key={i}>
            <Box py={0.5} display="flex" alignItems="center" className={clsx({ [classes.selected]: group.hasGroupSomeLoadsSelectedForCreateFrom(g) })}>
              <Box ml={1}>
                <input type="checkbox" checked={group.hasGroupSomeLoadsSelectedForCreateFrom(g)} onChange={() => group.toggleCreateFromSelectedGroup(g)} />
              </Box>
              <Box ml={1}>
                {g.uid}
              </Box>
              <Box ml="auto" mr={1}>
                {g.isExpanedForCreateFrom ? <ExpandMoreIcon style={{ fontSize: '18px', cursor: 'pointer' }} onClick={g.toggleExpanedForCreateForm} /> : <ExpandLessIcon style={{ fontSize: '18px', cursor: 'pointer' }} onClick={g.toggleExpanedForCreateForm} />}
              </Box>
            </Box>
            {g.isExpanedForCreateFrom && g.loads.map(load => <Box key={load.id} py={0.5} display="flex" alignItems="center" className={clsx({ [classes.selected]: group.isSomeLoadIdsInCreateForm([load.id]) })}>
              <Box ml={3}>
                <input type="checkbox" checked={group.isSomeLoadIdsInCreateForm([load.id])} onChange={() => group.toggleLoadIdCreateFrom(load.id)} />
              </Box>
              <Box ml={1}>
                {load.uid}
              </Box>
            </Box>)}
          </Box>)}
        </Box>
      </Popper>
    </ClickAwayListener>
    }
  </>
})

const SelectedGroupsSummary = observer(({ workOrder, classes }) => {
  return <>
    {workOrder.selectedGroups.length > 0 && <Box className={classes.summary}>
      <Box className={classes.summaryItem}>
        <Box className={classes.summaryItemTitle}>Current Qty</Box>
        <Box className={classes.summaryItemValue}>{workOrder.selected_group_total_current_qty}</Box>
      </Box>
      <Box className={classes.summaryItem}>
        <Box className={classes.summaryItemTitle}>Add Qty</Box>
        <Box className={classes.summaryItemValue}>{workOrder.selected_group_total_add_current_qty}</Box>
      </Box>
      <Box className={classes.summaryItem}>
        <Box className={classes.summaryItemTitle}>Remove Qty</Box>
        <Box className={classes.summaryItemValue}>{workOrder.selected_group_total_remove_current_qty}</Box>
      </Box>
      <Box className={classes.summaryItem}>
        <Box className={classes.summaryItemTitle}>Destroy Qty</Box>
        <Box className={classes.summaryItemValue}>{workOrder.selected_group_total_destroy_qty}</Box>
      </Box>
      <Box className={classes.summaryItem}>
        <Box className={classes.summaryItemTitle}>New Qty</Box>
        <Box className={classes.summaryItemValue}>{workOrder.selected_group_total_new_qty}</Box>
      </Box>
      <Box className={classes.summaryItem}>
        <Box className={classes.summaryItemTitle}>Weight LB</Box>
        <Box className={classes.summaryItemValue}>{workOrder.selected_group_total_weight_lb}</Box>
      </Box>
      <Box className={classes.summaryItem}>
        <Box className={classes.summaryItemTitle}>WHS PLT</Box>
        <Box className={classes.summaryItemValue}>{workOrder.selected_group_total_whs_pallet_count}</Box>
      </Box>
      <Box className={classes.summaryItem}>
        <Box className={classes.summaryItemTitle}>CBM</Box>
        <Box className={classes.summaryItemValue}>{workOrder.selected_group_total_volume_cbm}</Box>
      </Box>
    </Box>}
  </>
});



const Inventory = observer(({ workOrder }) => {
  const classes = useStyles();
  const loaction = useLocation();
  const { detail, loading } = workOrder;

  const handleSubmit = (e) => {
    e.preventDefault();
    detail.updateInventory();
  }

  const handleMarkBolUrgent = () => {
    workOrder.markBolUrgent();
  }

  return <>
    <Box className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Box className={classes.header}>
          <Box className={classes.headerTitle}>
            WHS Inventory
          </Box>
          <Box ml="auto"><Button size="small" variant="outlined" color="primary" type="button" disabled={loading} onClick={() => handleMarkBolUrgent()}>Mark Bol Urgent</Button></Box>
          <Box ml="auto"><Button size="small" variant="outlined" color="primary" type="submit" disabled={loading || !detail.confirmed_at}>Save</Button></Box>
          <Box ml={2}><Button size="small" variant="outlined" color="primary" type="button" onClick={detail.opclose}>OP Close</Button></Box>

          <Box ml={2}><Button size="small" component={Link} variant="outlined" color="primary" type="button" to={{
            pathname: `/workOrders/OBbol/new`,
            state: { background: loaction }
          }}>Create OB BOL</Button></Box>
          {/* <Box ml={2}><Button size="small" variant="outlined" color="primary" onClick={detail.addGroup} type="button">Create New Group</Button></Box> */}
          <Box ml={2}><InventoryNewGroup detail={detail} /></Box>
        </Box>
        <hr />
        <Box mt={2}>
          <Table
            footerable
            className={classes.table}
            columns={columns}
            data={detail.groups.slice()}
            getSubRows={row => row && row.loads ? [null, ...row.loads.slice()] : []}
            getRowProps={({ original, depth }) => {
              return {
                className: clsx({
                  [classes.loads]: depth > 0,
                })
              }
            }}
            state={{
              detail,
              workOrder,
              loading,
              loaction
            }}
          />
        </Box>
      </form>
    </Box>
    <SelectedGroupsSummary workOrder={workOrder} classes={classes} />
  </>
})

export default Inventory;
