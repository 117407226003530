import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';

const P = ({ pagination, setPagination, fetchItems }) => {

  return <Box display="flex" alignItems="center">
    <TextField
      select
      variant="outlined"
      label="Per Page"
      value={pagination.perPage}
      onChange={e => setPagination({ ...pagination, perPage: e.target.value })}
      size="small"
      style={{ width: '100px' }}
    >
      <MenuItem value={50}>50</MenuItem>
      <MenuItem value={100}>100</MenuItem>
      <MenuItem value={200}>200</MenuItem>
      <MenuItem value={500}>500</MenuItem>
      <MenuItem value={1000}>1K</MenuItem>
      <MenuItem value={50000}>2K</MenuItem>
      <MenuItem value={100000}>10K</MenuItem>
    </TextField>
    <Box className="ml-1">
      <Pagination count={pagination.total} page={pagination.page} onChange={(e, page) => fetchItems({ page })} showFirstButton showLastButton />
    </Box>
  </Box>
}

export default P;
