import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '../../../ui/Table';
import { DuplicateIcon, TrashIcon } from '../../../ui/Icon';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { TASK_TYPE_MAP, TASK_UNITS } from '../../../../stores/workOrder/constants';

const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.5,
    color: '#000000',
  },
  table: {
    width: '100%',

    fontSize: '12px',
    lineHeight: 1.5,
    color: '#000000',
    borderCollapse: 'collapse',

    '& tr': {
      textAlign: 'left',

      '& td, th': {
        margin: '0px 10px',
        padding: '15px 0',
      },

      '& th': {
        border: 0,
        color: '#000000',
        background: '#E0E0E0',
        fontWeight: 'normal',
      },
    },

    '& tr:first-child': {
      '& td': {
        borderTop: 0,
      },
    },
  },
}));


const TaskAttributeInput = withStyles(theme => ({
  root: {
    background: '#FFFFFF',
  },
  input: {
    padding: '8px',
    fontSize: '12px',
  }
}))(OutlinedInput);

const TaskAttributeSelect = withStyles(theme => ({
  root: {
    background: '#FFFFFF',
  },
  select: {
    paddingTop: '5.5px',
    paddingBottom: '5.5px',
    fontSize: '12px',
  }
}))(Select);


const columns = [
  {
    Header: 'WHS Task',
    accessor: 'type',
    Cell: observer(({ row: { original } }) => <Box px={0.5}>
      <TaskAttributeSelect margin="dense" displayEmpty variant="outlined" name="type" value={original.type || ''} onChange={original.setValueByHtmlElememt} required>
        {Object.keys(TASK_TYPE_MAP).map(t => <MenuItem key={t} value={t}>{TASK_TYPE_MAP[t]}</MenuItem>)}
      </TaskAttributeSelect>
    </Box>),
    headerStyle: { width: '15%' },
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: observer(({ row: { original } }) => <Box px={0.5}>
      <TaskAttributeInput placeholder="Please write description for the task here" fullWidth margin="dense" name="description" value={original.description || ''} onChange={original.setValueByHtmlElememt} required />
    </Box>),
    headerStyle: { width: '35%' },
  },
  {
    Header: 'Rate',
    accessor: 'rate',
    Cell: observer(({ row: { original } }) => <Box px={0.5}>
      <TaskAttributeInput type="number" min="0.01" margin="dense" name="rate" value={original.rate} onChange={original.setValueByHtmlElememt} required />
    </Box>),
    headerStyle: { width: '8%' },
  },
  {
    Header: 'Unit',
    accessor: 'unit',
    Cell: observer(({ row: { original } }) => <Box px={0.5}>
      <TaskAttributeSelect margin="dense" displayEmpty variant="outlined" name="unit" value={original.unit || ''} onChange={original.setValueByHtmlElememt} required>
        {TASK_UNITS.map(t => <MenuItem key={t} value={t}>{t}</MenuItem>)}
      </TaskAttributeSelect>
    </Box>),
    headerStyle: { width: '12%' },
  },
  {
    Header: 'Qty',
    accessor: 'qty',
    Cell: observer(({ row: { original } }) => <Box px={0.5}>
      <TaskAttributeInput type="number" min="0.01" margin="dense" name="qty" value={original.qty} onChange={original.setValueByHtmlElememt} required />
    </Box>),
    headerStyle: { width: '8%' },
  },
  {
    Header: 'Sub Total',
    accessor: 'sub_total',
    Cell: observer(({ row: { original } }) => <Box px={0.5}>
      <TaskAttributeInput type="number" min="0.01" margin="dense" name="sub_total" value={original.sub_total} onChange={original.setValueByHtmlElememt} disabled />
    </Box>),
    headerStyle: { width: '10%' },
  },
  {
    Header: 'Action',
    accessor: 'duplicate',
    Cell: observer(({ row: { original } }) => <Box px={0.5}>
      <DuplicateIcon style={{ cursor: 'pointer', fontSize: '18px' }} onClick={original.duplicate} />
    </Box>),
  },
  {
    Header: '',
    accessor: 'remove',
    Cell: observer(({ row: { original } }) => <Box px={0.5}>
      <TrashIcon style={{ cursor: 'pointer', fontSize: '18px' }} onClick={original.remove} />
    </Box>),
  },
];



const Tasks = observer(({ workOrder }) => {
  const classes = useStyles();

  const { detail, loading } = workOrder;

  const handleSubmit = (e) => {
    e.preventDefault()

    detail.updateTasks();
  }

  return <form className={classes.root} onSubmit={handleSubmit}>
    <Box className={classes.header} >
      <Box className={classes.headerTitle}>
        WHS Task List
      </Box>
      <Box ml="auto"><Button type="submit" size="small" variant="outlined" color="primary" disabled={loading || !detail.confirmed_at}>Save</Button></Box>
      <Box ml={2}><Button type="button" size="small" variant="outlined" color="primary" onClick={detail.addTask}>Add New Task</Button></Box>
    </Box>
    <hr />
    <Box mt={2}>
      <Table
        className={classes.table}
        columns={columns}
        data={detail.tasks.slice()}
      />
    </Box>
  </form>
})

export default Tasks;
