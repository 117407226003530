import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDownFillIcon, SearchIcon } from './Icon';
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from 'clsx';
// import { debounce } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  title: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#000000',
    marginBottom: '5px',
  },
  selectedBox: {
    cursor: 'pointer',
    background: '#FFFFFF',
    border: '1px solid #BDBDBD',
    minWidth: '176px',
    minHeight: '36px',
    display: 'inline-flex',
    alignItems: 'center',
  },
  labelText: {
    fontSize: '12px',
    lineHeight: '14px',
    padding: '10px 15px',
  },
  arrowDropDownFill: {
    marginRight: '10px',
  },
  popover: {
    position: 'absolute',
    minWidth: '176px',
    zIndex: 10,
    background: '#FFFFFF',
    padding: '10px 0',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
    marginTop: '10px',
    maxHeight: '300px',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    color: '#243967',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '10px 15px',

    borderBottom: '1px solid #F3F5F9',
    cursor: 'pointer',

    '&:first-child': {
      borderTop: '1px solid #F3F5F9',
    },

    '&.active': {
      background: '#F3F5F9',
    },
  },
  optionText: {
    marginLeft: '5px',
  },
  searchBox: {
    position: 'relative',
  },
  search: {
    width: '100%',
    fontSize: '12px',
    lineHeight: '16px',
    border: 'none',
    color: '#2E52C5',
    opacity: 0.8,
    padding: '10px 16px',
    '&::placeholder': {
      paddingLeft: '20px',
      fontStyle: 'italic',
      color: '#2E52C5',
      opacity: 0.8,
    },
  },
  searchIcon: {
    position: 'absolute',
    color: theme.palette.primary.main,
    opacity: 0.8,
    top: '6px',
    left: '13px',
    fontSize: '20px',
  },
}))

const defaultRenderOption = o => o;
const defaultIsSelectedEqualToOption = (selected, option) => selected === option;
const defaultOnSearchFilter = (query, o) => o.match(new RegExp(query, 'i'));
const debounce = (func, delay = 200) => {
  let timeout = null
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(this, arguments)
    }, delay)
  }
}
const Select = ({ title,
  selected,
  options = [],
  onSelect,
  renderOption = defaultRenderOption,
  isSelectedEqualToOption = defaultIsSelectedEqualToOption,
  multiple = false,
  emptySelectedText = '',
  searchable = false,
  onSearchFilter = defaultOnSearchFilter,
  onSearchAsyncFilter
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [query, setQuery] = React.useState('');

  const expand = () => {
    setExpanded(true);
  }

  const handleClickOutside = () => {
    setTimeout(() => setExpanded(false), [200]);
  }

  const toggleExpand = () => {
    setExpanded(!expanded);
  }

  const handleSelect = (o) => {
    if (multiple) {
      const index = selected.findIndex(s => isSelectedEqualToOption(s, o));
      if (index === -1) {
        onSelect([...selected, o]);
      } else {
        onSelect(selected.filter(s => !isSelectedEqualToOption(s, o)));
      }
    } else {
      onSelect(o);
    }
  }

  const selectedText = React.useMemo(() => selected
    ? (
      multiple
        ? (selected.length > 0 ? selected.map(s => renderOption(s)).join(', ') : emptySelectedText)
        : renderOption(selected)
    )
    : emptySelectedText,
    [selected, multiple, renderOption]
  );

  const handleCheckOnchange = (e) => {
    console.log(e.target.checked)
  }
  const debouncedQueryHandler = React.useMemo(() => {
    if (onSearchAsyncFilter) {
      return debounce(onSearchAsyncFilter, 500)
    } else {
      return () => { }
    }
  }, [onSearchAsyncFilter])

  React.useEffect(() => {
    debouncedQueryHandler(query);
  }, [query])




  const finalOptions = React.useMemo(() => {
    return query ? options.filter((o) => onSearchFilter(query, o)) : options;
  }, [query, onSearchFilter, options])

  return <Box className={classes.root} >
    <Box className={classes.title}>{title}</Box>
    <Box>
      <Box className={classes.selectedBox} onClick={toggleExpand}>
        <Box className={classes.labelText}>{selectedText}</Box>
        <Box ml="auto"><ArrowDropDownFillIcon className={classes.arrowDropDownFill} /></Box>
      </Box>
      {expanded && <OutsideClickHandler onOutsideClick={handleClickOutside}>
        <Box className={classes.popover}>
          {searchable && <Box className={classes.searchBox}>
            <input autoFocus className={classes.search} placeholder="Search..." value={query} onChange={e => setQuery(e.target.value)} />
            {!query && <SearchIcon className={classes.searchIcon} />}
          </Box>}
          {finalOptions.map((o, i) => <Box key={i} className={clsx(classes.option, { active: isSelectedEqualToOption(selected, o) })} onClick={() => handleSelect(o)}>
            <Box>
              {multiple ? <input type="checkbox" checked={selected.findIndex(s => isSelectedEqualToOption(s, o)) !== -1} onChange={handleCheckOnchange} /> : <input type="radio" checked={isSelectedEqualToOption(selected, o)} onChange={handleCheckOnchange} />}
            </Box>
            <Box className={classes.optionText}>{renderOption(o)}</Box>
          </Box>)}
        </Box>
      </OutsideClickHandler>
      }
    </Box>
  </Box>
}


export default Select;
