import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Tasks from './Tasks';
import Header from './Header';
import Info from './Info';
import LoadBar from './LoadBar';
import { useParams } from "react-router-dom";

export default inject('root')(
  observer(({ root, loadId }) => {
    const { task } = root;
    loadId = loadId ? loadId : useParams().loadId;

    useEffect(() => {
      task.loadDataAndDocuments(loadId);
    }, [loadId])

    return <>
      <Header task={task} />
      <Info task={task} />
      <LoadBar task={task} />
      <Tasks task={task} />
    </>
  })
)
