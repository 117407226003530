import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 8px',
    cursor: 'pointer',
    background: '#F8F9FB',
    margin: '8px 0',
  },
  leftText: {
    fontSize: '14px',
    lineHeight: 1.5,
    color: '#001235',
    textDecorationLine: 'underline',
  },
  rightText: {
    fontSize: '12px',
    lineHeight: 1.5,
    color: '#80889A',
  },
}))

const OutboundCardItem = observer(({ outbound }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/outbounds`)
  }

  return <Box className={classes.root} display="flex" onClick={handleClick}>
    <Box className={classes.leftText}>
      {outbound.uid}
    </Box>
    <Box className={classes.rightText} ml="auto">
      {outbound.carrier_code}
    </Box>
  </Box>
})


export default OutboundCardItem;
