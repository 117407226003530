import axios from "axios";

const index = (params = { page: 1 }) => {
  return axios.get('products', { params });
}

const bulkUpdateQty = (data) => {
  return axios.patch('products/bulk/updateQty', data);
}

export const product = {
  index,
  bulkUpdateQty
}
