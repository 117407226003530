import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import GeneralInfo from './Genernalnfo';
import Tasks from './Tasks';
import Inventory from './Inventory';
import WhsDocuments from './WhsDocuments';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  backIconWrapper: {
    marginRight: '20px',
    lineHeight: '40px',
  },
  backIcon: {
    fontSize: '24px',
    color: '#00000',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  titleWrapper: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#000000',
  },
  title: {
    fontSize: '14px',
  },
  subTitle: {
    fontSize: '9px',
    opacity: 0.5,
  },
}));

const Detail = observer(({ workOrder }) => {
  const classes = useStyles();


  return <Box className={classes.root}>
    <Grid container spacing={3}>
      <Grid item md={8}>
        <Box>
          <Tasks workOrder={workOrder} />
        </Box>
        <Box mt={8}>
          <Inventory workOrder={workOrder} />
        </Box>
        <Box mt={8}>
          <WhsDocuments workOrder={workOrder} />
        </Box>
      </Grid>
      <Grid item md={4}>
        <GeneralInfo workOrder={workOrder} />
      </Grid>
    </Grid>
  </Box>
})

export default Detail;
