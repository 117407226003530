import { types, getParent, getRoot, getSnapshot, applySnapshot, flow } from 'mobx-state-tree';
import { AddonService } from '../addonService/models';
import DocumentsStore from '../document/DocumentsStore';

export const TypeCountItem = types
  .model({
    value: types.string,
    text: types.string,
    count: types.maybeNull(types.number),
  })
  .actions(self => ({
    isActive(type) {
      return self.value === type;
    },
    setCount(count) {
      self.count = count;
    },
  }));

export const BolRow = types
  .model({
    id: types.number,
    uid: types.string,
    status: types.maybeNull(types.string),
    delivery_code: types.maybeNull(types.string),
    total_qty: types.maybeNull(types.union(types.number, types.string)),
    shipping_mode: types.maybeNull(types.string),
    scheduled_pickup_time: types.maybeNull(types.string),
    actual_pickup_time: types.maybeNull(types.string),
    canceled_at: types.maybeNull(types.string),
    confirmed_at: types.maybeNull(types.string),
    changed_at: types.maybeNull(types.string),
    is_pod: types.maybe(types.boolean),
    container_number: types.array(types.string),
  })
  .actions(self => ({
    viewPDF() {
      getParent(self, 2).viewPDF(self);
    },
    printPDF() {
      getParent(self, 2).printPDF(self);
    },
  }))

export const BolDetailGroupLoad = types
  .model({
    id: types.number,
    uid: types.string,
    qty: types.number,
    actual_qty: types.maybeNull(types.number),
    current_qty: types.maybeNull(types.number),
    pickup_reference: types.string,
    inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    shipout_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    remaining_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    markup_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    weight_lb: types.maybeNull(types.union(types.number, types.string)),
    volume_cbm: types.maybeNull(types.union(types.number, types.string)),
    markings: types.array(types.string),
    pallet_overweight: types.maybeNull(types.number),
    pallet_oversize: types.maybeNull(types.number),
    dispatch_remark: types.maybeNull(types.string),
  })

export const BolDetailGroup = types
  .model({
    loads: types.array(BolDetailGroupLoad),
    qty: types.number,
    actual_qty: types.maybeNull(types.number),
    current_qty: types.maybeNull(types.number),
    container_number: types.string,
    inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    shipout_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    remaining_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    markup_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    pickup_reference: types.string,
    weight_lb: types.maybeNull(types.union(types.number, types.string)),
    volume_cbm: types.maybeNull(types.union(types.number, types.string)),
    markings: types.array(types.string),
    is_exist_pallet_overweight: types.maybeNull(types.boolean),
    is_exist_pallet_oversize: types.maybeNull(types.boolean),
  });

export const BolDetailProduct = types
  .model({
    id: types.number,
    sku: types.maybeNull(types.string),
    whs_markings: types.array(types.string),
    qty_on_hand: types.maybeNull(types.union(types.string, types.number)),
    qty_outbound: types.maybeNull(types.union(types.string, types.number)),
    weight_lb: types.maybeNull(types.union(types.string, types.number)),
    volume_cbm: types.maybeNull(types.union(types.string, types.number)),
  });

export const BolDetailProductPackageProduct = types
  .model({
    id: types.maybeNull(types.number),
    sku: types.maybeNull(types.string),
    total_qty: types.maybeNull(types.union(types.string, types.number)),
  })
  .views(self => ({
  }))
  .actions(self => ({
    selectProduct(productId) {
      if (getParent(self, 2).products.find(i => i.id == productId)) {
        return getRoot(self).ui.toast.error('Duplicate SKU in this package');
      }

      const products = getParent(self, 4).products
      const product = products.find(i => i.id == productId)
      applySnapshot(self, product ? getSnapshot(product) : {})
    },
    remove() {
      getParent(self, 2).removeProduct(self)
    },
    setValue(name, value) {
      self[name] = value;
    },
    setValueByHtmlElememt(e) {
      const { value, name, checked, type } = e.target;
      self.setValue(name, type === 'checkbox' ? checked : value);
    },
  }));

export const BolDetailProductPackage = types
  .model({
    id: types.maybeNull(types.number),
    carton_qty: types.maybeNull(types.union(types.string, types.number)),
    total_qty: types.maybeNull(types.union(types.string, types.number)),
    length_in: types.maybeNull(types.union(types.string, types.number)),
    width_in: types.maybeNull(types.union(types.string, types.number)),
    height_in: types.maybeNull(types.union(types.string, types.number)),
    weight_lb: types.maybeNull(types.union(types.string, types.number)),
    products: types.array(BolDetailProductPackageProduct),
    verified_at: types.maybeNull(types.string),
  })
  .views(self => ({
    get expanded() {
      return true
    },
    get root() {
      return getRoot(self)
    },
  }))
  .actions(self => ({
    setValue(name, value) {
      self[name] = value;
    },
    setValueByHtmlElememt(e) {
      const { value, name, checked, type } = e.target;

      self.setValue(name, type === 'checkbox' ? checked : value);
    },
    selectProduct(productId) {
      if (self.products.find(i => i.id == productId)) {
        return getRoot(self).ui.toast.error('Duplicate SKU in this package');
      }
      const products = getParent(self, 2).products
      const product = products.find(i => i.id == productId)

      if (self.products[0]) {
        self.products[0] = product ? getSnapshot(product) : {}
      }
    },
    addProduct() {
      self.products.push({})
    },
    removeProduct(product) {
      self.products.remove(product)
    },
    verify: flow(function* () {
      try {
        const response = yield self.root.api.productPackage.verify(self.id)
        applySnapshot(self, response.data.data)
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    unverify: flow(function* () {
      try {
        const response = yield self.root.api.productPackage.unverify(self.id)
        applySnapshot(self, response.data.data)
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    afterCreate() {
      if (self.products.length === 0) {
        self.products.push({})
      }
    },
  }));

const BolOtrCarrier = types
  .model({
    id: types.number,
    code: types.string,
    name: types.string,
    bl_address: types.maybeNull(types.string),
  })
  .actions(self => ({
    viewPDF() {
      getParent(self).viewPDF(self.id);
    },
  }))

const BolArrayOtrCarrier = types
  .model({
    id: types.number,
    name: types.string,
  })
  .actions(self => ({
    viewPDF() {
      getParent(self, 2).viewPDF(self.id)
    },
  }))

const ShipoutHistory = types
  .model({
    id: types.number,
    total_pallet_count: types.number,
    carrier_name: types.maybeNull(types.string),
    creator_name: types.maybeNull(types.string),
    created_at: types.string,
    shipout_at: types.maybeNull(types.string),
    delivered_at: types.maybeNull(types.string),
    podDocuments: types.optional(DocumentsStore, { target: 'shipout_pod' }),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    }
  }))
  .actions(self => ({
    loadPodDocuments() {
      self.podDocuments.load(self.id);
    },
    cancel() {
      getParent(self, 2).unshipout(self.id);
    },
    deliver(time) {
      return new Promise((resolve) => {
        getParent(self, 2).shipoutDeliver(self.id, time).then(response => resolve(response));
      });
    },
    undeliver() {
      return new Promise((resolve) => {
        getParent(self, 2).shipoutUnDeliver(self.id).then(response => resolve(response));
      });
    },
  }));

export const BolDetail = types
  .model({
    id: 0,
    uid: types.maybeNull(types.string),
    type: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
    carrier_name: types.maybeNull(types.string),
    delivery_code: types.maybeNull(types.string),
    total_qty: types.maybeNull(types.union(types.number, types.string)),
    total_actual_qty: types.maybeNull(types.union(types.number, types.string)),
    total_current_qty: types.maybeNull(types.union(types.number, types.string)),
    total_inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    total_whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    total_shipout_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    total_remaining_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    total_markup_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    item_packed_pallet_count: types.maybeNull(types.union(types.number, types.string)),
    shipping_mode: types.maybeNull(types.string),
    scheduled_pickup_time: types.maybeNull(types.string),
    actual_pickup_time: types.maybeNull(types.string),
    actual_pickup_by: types.maybeNull(types.string),
    canceled_at: types.maybeNull(types.string),
    complete_packing_at: types.maybeNull(types.string),
    remark: types.maybeNull(types.string),
    memo: types.maybeNull(types.string),
    groups: types.array(BolDetailGroup),
    confirmed_at: types.maybeNull(types.string),
    changed_at: types.maybeNull(types.string),
    otr_carrier: types.maybeNull(BolOtrCarrier),
    customer: types.maybeNull(BolOtrCarrier),
    otr_carriers: types.array(BolArrayOtrCarrier),
    shipout_histories: types.array(ShipoutHistory),
    products: types.array(BolDetailProduct),
    is_pod: types.maybe(types.boolean),
    addon_services: types.array(AddonService),
    product_packages: types.array(BolDetailProductPackage),
    is_ready_to_ship: types.maybeNull(types.boolean),
  })
  .views(self => ({
    get isOf() {
      return self.type == 9;
    },
  }))
  .actions(self => ({
    setValue(name, value) {
      self[name] = value;
    },
    setValueByHtmlElememt(e) {
      const { value, name, checked, type } = e.target;
      self.setValue(name, type === 'checkbox' ? checked : value);
    },
    addProductPackage() {
      self.product_packages.push({})
    },
    viewPDF(otrCarrierId = null) {
      getParent(self).viewPDF(self.id, otrCarrierId);
    },
    printPDF(otrCarrierId = null) {
      getParent(self).printPDF(self.id, otrCarrierId);
    },
    viewPickSlip() {
      getParent(self).viewPickSlip(self.id);
    },
    printPickSlip() {
      getParent(self).printPickSlip(self.id);
    },
    shipout(data) {
      return new Promise((resolve) => {
        getParent(self).shipout(self.id, data).then(response => resolve(response));
      });
    },
    unshipout(shipoutId) {
      getParent(self).unshipout(self.id, shipoutId);
    },
    shipoutDeliver(shipoutId, time) {
      return new Promise((resolve) => {
        getParent(self).shipoutDeliver(self.id, shipoutId, time).then(response => resolve(response));
      });
    },
    shipoutunDeliver(shipoutId) {
      return new Promise((resolve) => {
        getParent(self).shipoutunDeliver(self.id, shipoutId).then(response => resolve(response));
      });
    },
    pickup() {
      getParent(self).pickup(self.id);
    },
    unpickup() {
      getParent(self).unpickup(self.id);
    },
    updateMemo() {
      getParent(self).updateMemo(self.id, self.memo);
    },
    setMemo(memo) {
      self.memo = memo;
    },
    adjustWhsPalletCount(loadIds, palletCount, reason) {
      return new Promise((resolve) => {
        getParent(self).adjustWhsPalletCount(self.id, loadIds, palletCount, reason).then(response => resolve(response));
      });
    },
    saveProductPackages() {
      getParent(self).saveProductPackages(self.id, { product_packages: self.product_packages, item_packed_pallet_count: self.item_packed_pallet_count });
    },
    completePacking() {
      getParent(self).completePacking(self.id);
    },
  }))
