import axios from 'axios';
import FileSaver from 'file-saver';

export const report = {
  downloadUnloadingHistory(params) {
    return axios.get(`reports/downloadUnloadingHistory`, { responseType: 'blob', params, })
      .then((response) => {
        const file = new Blob(
          [response.data],
          {
            type: response.headers['content-type'],
          }
        );

        // Extract filename from header
        const filename = response.headers['content-disposition']
          .split(';')
          .find(n => n.includes('filename='))
          .replace('filename=', '')
          .trim()
          .replace(/^\"/, '')
          .replace(/\"$/, '')

        FileSaver.saveAs(file, filename);
      });
  }
}
