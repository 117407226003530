import { types, getRoot, getParent, flow, applySnapshot } from 'mobx-state-tree';
import DocumentsStore from '../document/DocumentsStore';

export const AddonService = types
  .model({
    id: types.number,
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    status: types.maybeNull(types.number),
    status_name: types.maybeNull(types.string),
    approved: types.maybeNull(types.number),
    rate: types.maybeNull(types.union(types.string, types.number)),
    qty: types.maybeNull(types.union(types.string, types.number)),
    amount: types.maybeNull(types.union(types.string, types.number)),
    documents: types.optional(DocumentsStore, { target: 'addon_service' }),
  })
  .volatile(self => ({
  }))
  .views(self => ({
    get isComplete() {
      return self.status == 1;
    },
    get root() {
      return getRoot(self);
    }
  }))
  .actions(self => ({
    updateRow: (e) => {
      const { name, value } = e.target;
      self[name] = value;
    },
    updateCode: (selected) => {
      if (selected) {
        self.code = selected.code;
        self.name = selected.name;
      }
    },
    complete: flow(function* () {
      try {
        const response = yield self.root.api.addonService.complete(self.id)
        applySnapshot(self, response.data.data)
        self.root.ui.toast.success('Success')
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    uncomplete: flow(function* () {
      try {
        const response = yield self.root.api.addonService.uncomplete(self.id)
        applySnapshot(self, response.data.data)
        self.root.ui.toast.success('Success')
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
  }))
