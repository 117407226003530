import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReleaseStatus from './ReleaseStatus';
import CollectionText from '../../../common/CollectionText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Columns from '../../../common/Columns';
import Trucker from './Trucker';
import SortBy from '../../../ui/SortBy';
import { GroupByIcon } from '../../../ui/Icon';

const StatusIcon = ({ detail, classes, theme }) => {
  if (detail.completed_at) {
    return <Tooltip title={'IB Completed'}><CheckCircleIcon style={{ fontSize: '21px' }} /></Tooltip>
  }

  if (detail.empty_at) {
    return <Tooltip title={'Confirm Empty'}><CheckCircleOutlineIcon style={{ fontSize: '21px' }} /></Tooltip>
  }

  if (detail.received_at) {
    return <Tooltip classes={{ tooltip: classes.successTooltip }} title={'Confirm Received'}><CheckCircleIcon style={{ color: theme.palette.success.main, fontSize: '21px' }} /></Tooltip>
  }

  return null;
}

const columns = [
  {
    Header: 'IB Shipment',
    accessor: 'ib_shipment_number',
    Cell: ({ row: { original }, state: { classes, theme } }) => <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box width={24} mr={1}>{!!original.is_outbound_pod && <Tooltip title="All Shipments POD Uploaded."><LocalShippingIcon style={{ fontSize: '21px' }} /></Tooltip>}</Box>
        <Box width={24} mr={1}><StatusIcon detail={original} classes={classes} theme={theme} /></Box>
        <Box><Link to={`/inbound/${original.id}`}><div onClick={original.newDisappear}>{original.ib_shipment_number}</div></Link></Box>
        <Box width={24} ml={1}>{original.isNew && <Link style={{ textDecoration: 'none' }} to={`/inbound/${original.id}`}><Chip variant="outlined" color="primary" size="small" label="New" onClick={original.newDisappear} /></Link>}</Box>
      </Box>
    </>,
    columnTitle: 'IB Shipment',
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>Ready Date</Box>
      {/* <Box><SortBy name="ready_to_pickup_at" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box> */}
    </Box>,
    accessor: 'ready_to_pickup_at',
    columnTitle: 'Ready Date',
  },
  {
    Header: 'Trucker',
    accessor: 'trucker',
    columnTitle: 'Trucker',
    Cell: ({ row: { original } }) => original.trucker ? <Trucker trucker={original.trucker} /> : '-',
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>Scheduled Delivery Date</Box>
      <Box><SortBy name="delivery_date" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'delivery_date',
    columnTitle: 'Scheduled Delivery Date',
  },
  {
    Header: 'Status',
    accessor: 'status',
    columnTitle: 'Status',
  },
  {
    Header: 'Number of Piece',
    accessor: 'total_qty',
    columnTitle: 'Number of Piece',
  },
  {
    Header: 'Truck Size',
    accessor: 'truck_size',
    columnTitle: 'Truck Size',
  },
  {
    Header: 'OP',
    accessor: 'operator_name',
    columnTitle: 'OP',
  },
  {
    Header: ({ state: { columns, selectedColumns, updateSelectColumns } }) => <Columns
      columns={columns}
      selected={selectedColumns}
      onSelect={updateSelectColumns}
    >
      <GroupByIcon style={{ fontSize: 16 }} />
    </Columns>,
    accessor: 'column',
    Cell: () => null,
  },
];


export default columns;
