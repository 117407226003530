import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { InboundDetail, InboundDetailGroup, InboundDetailItem } from './models';
import DocumentsStore from '../document/DocumentsStore';
import { INBOUND_GROUPBY_MARKING, INBOUND_GROUPBY_DESTINATION, INBOUND_GROUPBY_MAP } from './constants';
import { serialize } from 'object-to-formdata';
import { WhsArea } from '../inventory/models';

const InboundStore = types
  .model('InboundStore', {
    groupBy: INBOUND_GROUPBY_MARKING,
    detail: types.optional(InboundDetail, {}),
    groupSelected: types.array(InboundDetailGroup),
    whsAreas: types.optional(types.array(WhsArea), []),
    itemSelected: types.array(InboundDetailItem),
    whsDocuments: types.optional(DocumentsStore, { target: 'inbound_whs' }),
    adminDocuments: types.optional(DocumentsStore, { target: 'inbound_admin' }),
    driverReceiptDocuments: types.optional(DocumentsStore, { target: 'driver_receipt' }),
    unloadingReceiptDocuments: types.optional(DocumentsStore, { target: 'unloading_receipt' }),
    emptyReceiptDocuments: types.optional(DocumentsStore, { target: 'empty-Receipt' }),
    finishedReceiptDocuments: types.optional(DocumentsStore, { target: 'finished-Receipt' }),
    whsTroubleShootDocuments: types.optional(DocumentsStore, { target: 'none', defer: true }),
  })
  .volatile(self => ({
    loading: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
    get isActualQtyFinished() {
      return self.detail.groups.every(item => {
        return item.actual_qty !== null && !isNaN(item.actual_qty);
      });
    },
    get isInboundPalletCountFinished() {
      return self.detail.groups.every(item => {
        return item.inbound_pallet_count !== null && !isNaN(item.inbound_pallet_count);
      });
    },
    get isAllMarkupPalletCountFilled() {
      return self.detail.groups.every(item => {
        return item.markup_pallet_count !== null && !isNaN(item.markup_pallet_count);
      });
    },
    get isGroupByMarking() {
      return self.groupBy === INBOUND_GROUPBY_MARKING;
    },
    get isGroupByDestination() {
      return self.groupBy === INBOUND_GROUPBY_DESTINATION;
    },
    get groupByName() {
      return INBOUND_GROUPBY_MAP[self.groupBy];
    },
    get groupByMap() {
      return INBOUND_GROUPBY_MAP;
    },
    get areas() {
      return self.whsAreas;
    },
    get areaOptions() {
      return self.areas.map(o => ({
        text: o.name,
        value: `${o.id}`,
      }));
    },
  }))
  .actions(self => ({
    setDetail(detail) {
      applySnapshot(self.detail, detail);
      // self.root.inbounds.replaceCollection(detail);
    },
    setGroupSelected(groupSelected) {
      self.groupSelected = groupSelected;
    },
    setItemSelected(itemSelected) {
      self.itemSelected = itemSelected;
    },
    autoGroupSetSelectedPrintPalletCount() {
      self.groupSelected.forEach(s => s.autoSetPrintPalletCount());
    },
    loadData: flow(function* (id) {
      let response = null;

      try {
        self.loading = true;
        id != self.detail.id && self.setDetail({});
        response = yield self.root.api.inbound.show(id, { group_by: self.groupBy });
        self.setDetail(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;

      return response;
    }),
    loadWhsArea: flow(function* () {
      let response = null;
      try {
        response = yield self.root.api.whsArea.index();
        self.whsAreas = response.data.data;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadWhsDocuments() {
      self.whsDocuments.load(self.detail.id);
    },
    loadDriverReceiptDocuments() {
      self.driverReceiptDocuments.load(self.detail.id);
    },
    loadUnloadingReceiptDocuments() {
      self.unloadingReceiptDocuments.load(self.detail.id);
    },
    loadEmptyReceiptDocuments() {
      self.emptyReceiptDocuments.load(self.detail.id);
    },
    loadFinishedReceiptDocuments() {
      self.finishedReceiptDocuments.load(self.detail.id);
    },
    exportExcel: flow(function* (id) {
      self.loading = true;

      try {
        yield self.root.api.inbound.exportExcel(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;
    }),
    viewPDF: flow(function* (id) {
      self.loading = true;

      try {
        yield self.root.api.inbound.viewPDF(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;
    }),
    viewExcel: flow(function* (id, type) {
      self.loading = true;

      try {
        yield self.root.api.inbound.exportReceiptExcel(id, type);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;
    }),
    printPDF: flow(function* (id) {
      self.loading = true;

      try {
        yield self.root.api.inbound.printPDF(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;
    }),
    viewMultipleLabels: flow(function* (id, pageSize = 1) {
      self.loading = true;

      try {
        const data = self.groupSelected.map(s => ({
          load_ids: s.loads.map(l => l.id),
          qty: s.print_pallet_count,
        }))

        yield self.root.api.inbound.viewMultipleLabels(id, { data, pageSize });
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;
    }),
    printMultipleLabels: flow(function* (id, pageSize = 1) {
      self.loading = true;

      try {
        const data = self.groupSelected.map(s => ({
          load_ids: s.loads.map(l => l.id),
          qty: s.print_pallet_count,
        }))
        yield self.root.api.inbound.printMultipleLabels(id, { data, pageSize });
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;
    }),
    viewLabel: flow(function* (id, loadIds) {
      self.loading = true;

      try {
        yield self.root.api.inbound.viewLabel(id, loadIds);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;
    }),
    printLabel: flow(function* (id, loadIds) {
      self.loading = true;

      try {
        yield self.root.api.inbound.printLabel(id, loadIds);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;
    }),
    receive: flow(function* (id, confirmedDatetime) {
      try {
        self.loading = true;
        yield self.root.api.inbound.receive(id, confirmedDatetime);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    unreceive: flow(function* (id) {
      if (!window.confirm('Are you sure?')) {
        return;
      }

      
      try {
        self.loading = true;
        yield self.root.api.inbound.unreceive(id);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    empty: flow(function* (id, data) {
      try {
        self.loading = true;
        yield self.root.api.inbound.empty(id, data);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    unempty: flow(function* (id) {
      if (!window.confirm('Are you sure?')) {
        return;
      }

      try {
        self.loading = true;
        yield self.root.api.inbound.unempty(id);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    complete: flow(function* (id, completedAt) {
      try {
        self.loading = true;
        yield self.root.api.inbound.complete(id, completedAt);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    uncomplete: flow(function* (id) {
      if (!window.confirm('Are you sure?')) {
        return;
      }

      try {
        self.loading = true;
        yield self.root.api.inbound.uncomplete(id);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateGroupActualQty: flow(function* (id, markings, deliveryCodes, totalAcutalQty) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateGroupActualQty(id, self.groupBy, self.toGroupByValue(self.groupBy, markings, deliveryCodes), totalAcutalQty);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    syncGroupQtys: flow(function* (id, loadIds, type) {
      try {
        self.loading = true;
        yield self.root.api.inbound.syncGroupQtys(id, loadIds, type);
        self.loadData(id);
        self.root.ui.toast.success('Synced');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateGroupInboundPalletCount: flow(function* (id, markings, deliveryCodes, totalInboundPalletCount) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateGroupInboundPalletCount(id, self.groupBy, self.toGroupByValue(self.groupBy, markings, deliveryCodes), totalInboundPalletCount);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateGroupWhsArea: flow(function* (id, markings, deliveryCodes, whsArea) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateGroupWhsArea(id, self.groupBy, self.toGroupByValue(self.groupBy, markings, deliveryCodes), whsArea);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateGroupMarkupPalletCount: flow(function* (id, markings, deliveryCodes, totalMarkupPalletCount) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateGroupMarkupPalletCount(id, self.groupBy, self.toGroupByValue(self.groupBy, markings, deliveryCodes), totalMarkupPalletCount);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateGroupMemo: flow(function* (id, markings, deliveryCodes, memo) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateGroupMemo(id, self.groupBy, self.toGroupByValue(self.groupBy, markings, deliveryCodes), memo);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateLoadMemo: flow(function* (id, loadId, memo) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateLoadMemo(id, loadId, memo);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    toGroupByValue(groupBy, markings, deliveryCodes) {
      if (groupBy == INBOUND_GROUPBY_MARKING) {
        return markings[0];
      }
      if (groupBy == INBOUND_GROUPBY_DESTINATION) {
        return deliveryCodes[0];
      }
    },
    updateItemItemActualQty: flow(function* (id, itemId, totalAcutalQty) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateItemItemActualQty(id, itemId, totalAcutalQty);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateItemCartonActualQty: flow(function* (id, itemId, totalAcutalQty) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateItemCartonActualQty(id, itemId, totalAcutalQty);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateItemInboundPalletCount: flow(function* (id, itemId, totalInboundPalletCount) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateItemInboundPalletCount(id, itemId, totalInboundPalletCount);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateItemMemo: flow(function* (id, itemId, memo) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateItemMemo(id, itemId, memo);
        self.loadData(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateFeedback: flow(function* (id, feedback) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateFeedback(id, feedback);
        self.loadData(id).then(response => {
          self.root.ui.toast.success("saved");
        });

      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    setGroupBy(g) {
      self.groupBy = g;
      self.loadData(self.detail.id);
    },
    createTroubleShoot: flow(function* (data) {

      const formData = serialize({ ...data, documents: [...self.whsTroubleShootDocuments.toOriginalFiles()] }, { indices: true, nullsAsUndefineds: true })
      let response = false;
      try {
        self.loading = true;
        response = yield self.root.api.troubleShoot.store(formData);
        self.detail.whs_trouble_shoot = response.data.data;
        self.detail.whs_trouble_shoot.isOpened = true;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;

      return response;
    }),
    updateReadyToShip: flow(function* (id, loadIds, checked) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateReadyToShip(id, loadIds, checked);
        self.loadData(id).then(response => {
          self.root.ui.toast.success('saved');
        });

      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateUnloadingTeams: flow(function* (id, unloading_team) {
      try {
        self.loading = true;
        yield self.root.api.inbound.updateUnloadingTeams(id, unloading_team);
        self.loadData(id).then(response => {
          self.root.ui.toast.success('saved');
        });

      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
  }))


export default InboundStore;
