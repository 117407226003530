export const CONTAINER_SIZE_TYPES = [
  '20FR',
  '20GP',
  '20OT',
  '20RF',
  '20TK',
  '20HC',
  '20HQ',
  '27GP',
  '40FR',
  '40GP',
  '40HC',
  '40HQ',
  '40HR',
  '40OT',
  '40RD',
  '40RF',
  '40RH',
  '40TK',
  '45HC',
  '45HQ',
  '45RH',
  '46P3',
  '53FT',
  '53HC',
  '53HQ',
  'LCL',
];
