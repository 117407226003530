import React from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Divider } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import CommonDocuments from "../../../common/Documents";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "12px 16px",
  },
  selectContainer: {
    // padding: '12px 16px',
    display: "flext",
    alignItems: "center",
  },
  checkboxContainer: {},
}));

const Print = observer(({ outbound }) => {
  const TABS = [
    "OB PDF",
    "OB excel",
    "OB excel v2",
    "Admin Document" +
    (outbound.adminDocuments.hasDocuments
      ? `(${outbound.adminDocuments.collection.length})`
      : ""),
  ];

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tab, setTab] = React.useState(TABS[0]);

  const [selectedBols, setSelectedBols] = React.useState([]);
  const [isSelectedSummary, setIsSelectedSummary] = React.useState(false);

  const isSelectedAll = React.useMemo(() => {
    return selectedBols.length === outbound.bols.length && isSelectedSummary;
  }, [selectedBols, outbound.bols.length, isSelectedSummary]);

  const toggleSelectAll = () => {
    if (isSelectedAll) {
      setSelectedBols([]);
      setIsSelectedSummary(false);
    } else {
      setSelectedBols(outbound.bols.slice());
      setIsSelectedSummary(true);
    }
  };

  const isBolSelected = (bol) => {
    return selectedBols.includes(bol);
  };

  const toggleBolSelected = (bol) => {
    if (isBolSelected(bol)) {
      setSelectedBols(selectedBols.filter((b) => b.id != bol.id));
    } else {
      setSelectedBols([...selectedBols, bol]);
    }
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);

    setSelectedBols(outbound.bols.slice());
    setIsSelectedSummary(true);
    outbound.adminDocuments.load(outbound.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrint = () => {
    outbound.viewPDF(
      isSelectedSummary,
      selectedBols.map((s) => s.id)
    );
  };


  const handlePrintExcelVersion = () => {
    outbound.printEFBolsExcel(
      isSelectedSummary,
      selectedBols.map((s) => s.id)
    );
  };

  const handlePrintExcel = () => {
    outbound.printOBExcel(outbound.bols.map((s) => s.id));
  };

  const handlePrintExcelV2 = () => {
    outbound.printOBExcel(outbound.bols.map((s) => s.id), "V2");
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={handleOpen}
      >
        Print OB
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <AppBar position="static">
          <Tabs value={tab} onChange={(e, value) => setTab(value)}>
            {TABS.map((t) => (
              <Tab key={t} value={t} label={t} />
            ))}
          </Tabs>
        </AppBar>
        {tab === TABS[0] && (
          <Box className={classes.container}>
            <Box>
              <Box className={classes.selectContainer}>
                <Box className={classes.checkboxContainer}>
                  <FormControlLabel
                    label="Select All"
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={isSelectedAll}
                        onChange={toggleSelectAll}
                        label="Select All"
                      />
                    }
                  />
                </Box>
                <Box className={classes.checkboxContainer}>
                  <FormControlLabel
                    label={`${outbound.uid} Overview`}
                    control={
                      <Checkbox
                        color="primary"
                        size="small"
                        checked={isSelectedSummary}
                        onChange={(e) => setIsSelectedSummary(e.target.checked)}
                      />
                    }
                  />
                </Box>
                {outbound.bols.map((bol) => (
                  <Box className={classes.checkboxContainer} key={bol.id}>
                    <FormControlLabel
                      label={`OB ${bol.uid}`}
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={isBolSelected(bol)}
                          onChange={() => toggleBolSelected(bol)}
                        />
                      }
                    />
                  </Box>
                ))}
              </Box>
              <Divider />
              <Box className={classes.selectContainer} display="flex" mt={1}>
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handlePrint}
                    disabled={outbound.loading}
                  >
                    Print
                  </Button>

                </Box>
                <Box ml={1}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handlePrintExcelVersion}
                    disabled={outbound.loading}
                  >
                    Download Excel Version
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box ml={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={outbound.hide_carrier_in_bol}
                        onChange={(e) =>
                          outbound.setHideCarrerInBOL(e.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Hide Carrier in BOL"
                  />
                </Box>
              </Box>
              {outbound.loading && <LinearProgress />}
            </Box>
          </Box>
        )}
        {tab === TABS[1] && (
          <Box className={classes.container}>
            <Box>
              <Box className={classes.selectContainer} display="flex" mt={1}>
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handlePrintExcel}
                    disabled={outbound.loading}
                  >
                    Print
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
              {outbound.loading && <LinearProgress />}
            </Box>
          </Box>
        )}
        {tab === TABS[2] && (
          <Box className={classes.container}>
            <Box>
              <Box className={classes.selectContainer} display="flex" mt={1}>
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handlePrintExcelV2}
                    disabled={outbound.loading}
                  >
                    Print
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
              {outbound.loading && <LinearProgress />}
            </Box>
          </Box>
        )}
        {tab === TABS[3] && (
          <Box className={classes.container}>
            <CommonDocuments
              documents={outbound.adminDocuments}
              uploadable={false}
              deletable={false}
            />
          </Box>
        )}
      </Popover>
    </div>
  );
});

export default Print;
