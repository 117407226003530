import React from 'react';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: '18px',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
  },
  inputWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    border: '1px dashed #80889A',
    marginTop: '15px',
    padding: '11px 15px',
  },
  input: {
    flex: 1,
    width: '100%',
  },
}));

const Memo = observer(({ bol }) => {
  const classes = useStyles();

  const { detail: { memo, setMemo, updateMemo } } = bol;

  return <Box className={classes.root}>
    <Box className={classes.title}>Memo:</Box>
    <Box className={classes.inputWrapper}>
      <InputBase value={memo || ''} className={classes.input} onChange={e => setMemo(e.target.value)} multiline rows="6" onBlur={updateMemo} />
    </Box>
  </Box>
})

export default Memo;
