import React from 'react';

export const parseDataIntoExcelTableData = (items = [], columns) => {
    const columnsByAttributeMap = columns.reduce((acc, column, index) => {
        const attribute = (column.attribute || '').replace('*', '');
        return {
            ...acc,
            [attribute]: index,
        }
    }, {});

    const tableData = items.map(item => {
        const array = new Array(columns.length);
        Object.keys(item).forEach(key => {
            if (columnsByAttributeMap[key] !== undefined) {
                array[columnsByAttributeMap[key]] = item[key];
            }
        });

        return array;
    })

    return tableData;
}

export const toExcelTableData = (rowData, columns) => {
    return rowData.filter(values => values.filter(i => i).length > 0)
        .map(values => {
            return columns.reduce((acc, column, index) => {
                const attribute = (column.attribute || '').replace('*', '');
                const value = values[index] || null
                return {
                    ...acc,
                    [attribute]: value,
                };
            }, {});
        });
}
