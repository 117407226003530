export const TASK_LIST_TYPE_ALL = 'all';
export const TASK_LIST_TYPE_TODAY = 'today';
export const TASK_LIST_TYPE_UPCOMING = 'upcoming';
export const TASK_LIST_TYPE_OVER_DUE = 'overdue';
export const TASK_LIST_TYPE_COMPLETED = 'completed';
export const TASK_LIST_TYPE_CANCELED = 'canceled';

export const TASK_LIST_TYPES_MAP = {
  [TASK_LIST_TYPE_ALL]: 'Total',
  [TASK_LIST_TYPE_TODAY]: 'Today\'s WHS Task',
  [TASK_LIST_TYPE_UPCOMING]: 'Upcoming WHS Task',
  [TASK_LIST_TYPE_OVER_DUE]: 'Overdue WHS Task',
  [TASK_LIST_TYPE_COMPLETED]: 'Completed WHS Task',
  [TASK_LIST_TYPE_CANCELED]: 'Canceled WHS Task',
};
