import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box, Button, Input } from '@material-ui/core';
import Table from '../../ui/Table';
import { toast } from '../../../services';
import Memo from './Memo';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    margin: '0 auto',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    width: '100%',
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ChangeInvertory({ products, handleSubmit }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [changes, setChanges] = React.useState(products.map((product) => {
    return {
      product_id: product.id,
      sku: product.sku,
      qty_before: product.qty_on_hand,
      qty_after: '',
      memo: product.memo,
    }
  }));

  useEffect(() => {
    setChanges(products.map((product) => {
      return {
        product_id: product.id,
        sku: product.sku,
        qty_before: product.qty_on_hand,
        qty_after: '',
        memo: product.memo,
      }
    }));
  }, [products])

  const setValueTo = (id, value) => {
    const copy = [...changes];
    let change = copy.find((product) => product.product_id === id);
    change.qty_after = value;
    setChanges(copy);
    setFoucs(id)
  }

  const setMemoTo = (id, value) => {
    const copy = [...changes];
    let change = copy.find((product) => product.product_id === id);
    change.memo = value;
    setChanges(copy);
  }

  const [foucs, setFoucs] = React.useState(0);

  const columns = [{
    Header: 'Sku#',
    Cell: ({ row: { original } }) => {
      return <>{original.sku}</>
    },
    accessor: 'sku',
    id: 'sku',
    name: 'Sku#',
  }, {
    Header: 'Customer#',
    accessor: 'customer',
    Cell: ({ row: { original } }) => {
      return <div>
        {original.customer?.name || '-'}
      </div>
    },
    id: 'customer',
    name: 'Customer#',
  }, {
    Header: 'Current Std Qty',
    accessor: 'qty_before',
    id: 'qty_before',
    name: 'Current Std Qty',
  }, {
    Header: 'Std QoH (Set to) Qty',
    Cell: ({ row: { original }, state: { setValueTo } }) => {
      return <div>
        <Input type="number" autoFocus={original.product_id === foucs} key={`${original.product_id}_qty_after`} value={original.qty_after} className='border-0' onChange={(e) => { setValueTo(original.product_id, e.target.value) }} />
      </div>
    },
    accessor: 'product_qty',
    id: 'product_qty',
    name: 'Std QoH (Set to) Qty',
  }, {
    Header: 'Memo',
    accessor: 'memo',
    Cell: ({ row: { original } }) => {
      return <Memo value={original.memo} change_id={original.product_id} setValue={setMemoTo} />
    }
  }]

  const handleOpen = () => {
    if (products.length == 0) {
      toast.error('Please select at least one product');
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    handleSubmit({ changes })
      .then(() => handleClose())
  }

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" color="primary">Inventory Change</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" style={{
              textAlign: 'center',
            }}>Inventory Change</h2>
            <Table
              stickyHeader
              columns={columns}
              data={changes}
              state={{ setValueTo }}
            />
            <Box mt={3} display={'flex'} justifyContent={'end'}>
              <Box ml={1}>
                <Button onClick={handleClose} variant="outlined" color="primary">Close</Button>
              </Box>
              <Box ml={1}>
                <Button onClick={onSubmit} variant="contained" color="primary">Confirm</Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div >
  );
}
