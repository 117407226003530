import React from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { format } from 'date-fns-tz'
import AttachFileIcon from '@material-ui/icons/AttachFile';

const formatDate = (date) => {
  return format(new Date(date), 'HH:mm a MM/dd/yyyy');
}

const ChatMessage = ({ sender, message, senderId, viewFile }) => {

  return <>
    {!message.file_id && <div>{!sender ? <div style={{
      display: 'flex',
      marginBottom: '1px',
      alignItems: 'center',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        color: '#2E52C5',
        fontSize: '12px',
      }}><PersonOutlineOutlinedIcon /> {message.sender_name}:</div>
      <div style={{
        fontSize: '10px',
        color: '#80889A',
        height: '15px',
        marginLeft: '10px',
      }}>
        {formatDate(message.created_at)}
      </div>
    </div> :
      <div style={{
        fontSize: '10px',
        color: '#80889A',
        width: '60%',
        marginLeft: 'auto',
        textAlign: 'right',
        marginBottom: '3px',
      }}>{formatDate(message.created_at)}</div>}
    </div>}
    <div style={{
      marginLeft: !sender ? '0' : 'auto',
      marginTop: message.file_id ? '0px' : '3px',
      marginBottom: '2px',
      textAlign: sender ? 'right' : 'left',
    }}>
      <div style={{
        padding: '10px',
        border: !sender ? '1px solid #2E52C5' : 'none',
        background: !sender ? 'white' : '#C9D6FF',
        maxWidth: '60%',
        borderRadius: '3px',
        fontSize: '12px',
        wordBreak: 'break-word',
        display: 'inline-block',

      }}>
        {message.file_id ? <div style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }} onClick={() => viewFile(message.file_id)}>
          <AttachFileIcon style={{ fontSize: '16px' }} /> {message.message}
        </div> : message.message}
      </div>
    </div>
  </>
}

export default ChatMessage;
