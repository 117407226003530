import FileSaver from 'file-saver';
import { flatten, sumBy } from 'lodash';
import { types, getParent, applySnapshot, flow, getSnapshot } from 'mobx-state-tree';
import DocumentsStore from '../document/DocumentsStore';
import { WorkOrderDetail } from './models';

const WorkOrderStore = types
  .model('WorkOrderStore', {
    detail: types.optional(WorkOrderDetail, {}),
    selectedGroupUniqueIds: types.array(types.string),
    whsDocuments: types.optional(DocumentsStore, { target: 'whs_work_order' }),
  })
  .volatile(self => ({
    loading: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
    get selectedGroups() {
      return self.detail.groups.filter(g => self.selectedGroupUniqueIds.includes(g.uniqId));
    },
    get selected_group_total_qty() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_qty || 0)).toFixed(2);
    },
    get selected_group_total_actual_qty() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_actual_qty || 0)).toFixed(2);
    },
    get selected_group_total_current_qty() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_current_qty || 0)).toFixed(2);
    },
    get selected_group_total_destroy_qty() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_destroy_qty || 0)).toFixed(2);
    },
    get selected_group_total_add_current_qty() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_add_current_qty || 0)).toFixed(2);
    },
    get selected_group_total_remove_current_qty() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_remove_current_qty || 0)).toFixed(2);
    },
    get selected_group_total_new_qty() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_new_qty || 0)).toFixed(2);
    },
    get selected_group_total_volume_cbm() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_volume_cbm || 0)).toFixed(2);
    },
    get selected_group_total_weight_kg() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_weight_kg || 0)).toFixed(2);
    },
    get selected_group_total_weight_lb() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_weight_lb || 0)).toFixed(2);
    },
    get selected_group_total_whs_pallet_count() {
      return sumBy(self.selectedGroups, group => parseFloat(group.total_whs_pallet_count || 0)).toFixed(2);
    },
  }))
  .actions(self => ({
    setDetail(detail) {
      applySnapshot(self.detail, detail);
    },
    loadData: flow(function* (id) {
      let response = null;

      id = id || self.detail.id;

      try {
        self.loading = true;
        self.setDetail({});
        response = yield self.root.api.workOrder.show(id);
        self.setDetail(response.data.data);
        self.detail.groups.forEach((group) => {
          group.loadDocuments();
        });
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;

      return response;
    }),
    confirm: flow(function* (id) {
      if (!window.confirm('Are you sure to confirm?')) {
        return;
      }
      try {
        self.loading = true;
        const response = yield self.root.api.workOrder.confirm(id);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Confirmed');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    unconfirm: flow(function* (id) {
      if (!window.confirm('Are you sure to unconfirm?')) {
        return;
      }
      try {
        self.loading = true;
        const response = yield self.root.api.workOrder.unconfirm(id);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('UnConfirmed');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    cancel: flow(function* (id) {
      if (!window.confirm('Are you sure to cancel?')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.workOrder.cancel(id);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Canceled');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    reject: flow(function* (id) {
      if (!window.confirm('Are you sure to reject?')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.workOrder.reject(id);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Rejected');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    complete: flow(function* (id) {
      if (!window.confirm('Are you sure to complete?')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.workOrder.complete(id);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Completed');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    uncomplete: flow(function* (id) {
      if (!window.confirm('Are you sure to cancel complete?')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.workOrder.uncomplete(id);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Cancel Completed');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateTasks: flow(function* (id, tasks) {
      try {
        self.loading = true;
        const response = yield self.root.api.workOrder.updateTasks(id, tasks);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Saved');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateInventory: flow(function* (id, groups) {
      try {
        self.loading = true;
        const response = yield self.root.api.workOrder.updateInventory(id, groups);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Saved');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    opclose: flow(function* (id) {
      if (!window.confirm('All the loads will be op closed and removed it from BOL. Are you sure to opclose it? ')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.workOrder.opclose(id, flatten(self.selectedGroups.map(g => g.loads)).map(l => l.id));
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Saved');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    deleteGroup: flow(function* (id, groupId) {
      if (!window.confirm('Are you sure to delete it?')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.workOrder.deleteGroup(id, groupId);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Deleted');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    markBolUrgent: flow(function* () {
      if (!window.confirm('Are you sure to confirm?')) {
        return;
      }
      try {
        self.loading = true;
        const ids = self.selectedGroups.map((g) => g.loads.map(load => load.bol.id)).flat();
        const response = yield self.root.api.bol.markUrgent(ids);
        // self.setDetail(response.data.data);
        self.root.ui.toast.success('Succeed');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    downloadPDF: flow(function* (id) {
      const response = yield self.root.api.workOrder.downloadPDF(id);
      const file = new Blob(
        [response.data],
        {
          type: response.headers['content-type'],
        }
      );
      FileSaver.saveAs(file, `3PL-workOrder#${id}`);
    }),
    clearSelectedGroup() {
      self.selectedGroupUniqueIds.clear();
    },
    isSelectedGroup(group) {
      return !!self.selectedGroupUniqueIds.find(uniqId => uniqId === group.uniqId);
    },
    toggleSelectedGroup(group) {
      if (self.isSelectedGroup(group)) {
        self.selectedGroupUniqueIds = self.selectedGroupUniqueIds.filter(uniqId => uniqId !== group.uniqId);
      } else {
        self.selectedGroupUniqueIds.push(group.uniqId);
      }
    },
    toggleAllSelectedGroup() {
      if (self.detail.isAllGroupSelected) {
        self.selectedGroupUniqueIds.clear();
      } else {
        self.selectedGroupUniqueIds = self.detail.groups.map(g => g.uniqId);
      }
    },
  }))


export default WorkOrderStore;
