import React from 'react';
import Badge from '../../../common/Badge';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {

  },
  badge: {
    cursor: 'pointer',
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: '#fff',
  },
  released: {
    backgroundColor: theme.palette.success.main,
  },
  hold: {
    backgroundColor: theme.palette.danger.main,
  },
}));

const ReleaseStatus = ({ freightReleasedAt, customsReleasedAt, terminalReleasedAt }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isAllReleased = freightReleasedAt && customsReleasedAt && terminalReleasedAt;

  const open = Boolean(anchorEl);
  return <>
    <Badge className={classes.badge} onClick={handleClick} pill color={isAllReleased ? 'success' : 'danger'}>{isAllReleased ? 'Released' : 'Hold'}</Badge>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <Box className={clsx(classes.item, { [classes.released]: freightReleasedAt, [classes.hold]: !freightReleasedAt })}>Freight Released</Box>
      <Divider />
      <Box className={clsx(classes.item, { [classes.released]: customsReleasedAt, [classes.hold]: !customsReleasedAt })}>Customs Released</Box>
      <Divider />
      <Box className={clsx(classes.item, { [classes.released]: terminalReleasedAt, [classes.hold]: !terminalReleasedAt })}>Terminal Released</Box>
    </Popover>
  </>
}

export default ReleaseStatus;
