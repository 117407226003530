import React, { useState } from 'react';
import CommonDocuments from '../../../common/Documents';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { isEmpty, sumBy, values } from 'lodash';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { format } from 'date-fns';
import { TableFooter } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    padding: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  table: {

  },
}));

const ConfirmShipout = observer(({ bol }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [shipoutAt, setShipoutAt] = useState(new Date());
  const [items, setItems] = useState({});

  const { detail, shipoutDocuments, loading } = bol;

  const handleOpen = () => {
    setSelected(detail.otr_carrier);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    detail.shipout({
      otr_carrier_id: selected.id,
      items,
      shipout_at: format(shipoutAt, 'yyyy-MM-dd HH:mm:ss')
    }).then(response => {
      if (response) {
        setOpen(false);
        setItems({});
      }
    });
  }

  const canSubmit = () => {
    return !loading && shipoutDocuments.hasDocuments && !isEmpty(items) && selected && shipoutAt && !isNaN(shipoutAt.getTime());
  }

  return <>
    <Button variant="contained" color="primary" onClick={handleOpen}>CONFIRM SHIPOUT</Button>
    {open && <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box className={classes.content}>
            <Box className={classes.subtitle}>1. Please input shipout date and time *</Box>
            <Box mt={1}>
              <FormControl required>
                <KeyboardDateTimePicker variant="inline" value={shipoutAt} onChange={setShipoutAt} format="yyyy-MM-dd HH:mm" />
              </FormControl>
            </Box>
            <Box mt={3} className={classes.subtitle}>2. Please select OTR carrier for this Shipout *</Box>
            <Box mt={1}>
              {detail.otr_carrier && <OtrCarrierCheckbox carrier={detail.otr_carrier} selected={selected} onChange={setSelected} />}
              {detail.otr_carriers && detail.otr_carriers.map((carrier, index) => <Box key={index}><OtrCarrierCheckbox carrier={carrier} selected={selected} onChange={setSelected} /></Box>)}
            </Box>
            <Box mt={3} className={classes.subtitle}>3. Please fill in the Shipout Pallet Count *</Box>
            <Box mt={1}>
              <Table className={classes.table} size="small" >
                <TableHead>
                  <TableRow>
                    <TableCell>Packed Pallet Count</TableCell>
                    <TableCell>Shipout Pallet Count</TableCell>
                    <TableCell>Remaining Pallet Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{detail.item_packed_pallet_count}</TableCell>
                    <TableCell><OutlinedInput type="number" size="small" margin="dense" value={items['']} onChange={e => setItems({ ...items, '': e.target.value })} /></TableCell>
                    <TableCell>{(parseFloat(detail.item_packed_pallet_count || 0) - parseFloat(items[''] || 0)).toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box mt={3} className={classes.subtitle}>4. Upload Pickup Receipt *</Box>
            <Box mt={1}><CommonDocuments documents={shipoutDocuments}
              onUploaded={shipoutDocuments.load}
              onDeleted={shipoutDocuments.load}
            /></Box>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!canSubmit()}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>}
  </>
});

const OtrCarrierCheckbox = observer(({ carrier, selected, onChange }) => {
  return <FormControlLabel
    control={
      <Checkbox
        checked={selected == carrier}
        onChange={() => onChange(carrier)}
        color="primary"
      />
    }
    label={carrier.name}
  />
})

export default ConfirmShipout;

