import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { PersonSearch } from '../../ui/Icon';
import { Box, Button } from '@material-ui/core';
import Table from '../../ui/Table';
import Memo from './Memo';

const DEFAULT_COUNT = 3
export default function History({ history = [] }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [count, setCount] = React.useState(DEFAULT_COUNT);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowAll = () => {
    if (count === history.length) {
      setCount(3);
    } else { setCount(history.length); }
  }
  const columns = [
    {
      Header: 'Create Time',
      accessor: 'created_at',
      id: 'created_at',
      name: 'Create Time',
    },
    {
      Header: 'Before Qty',
      accessor: 'qty_before',
      id: 'qty_before',
      name: 'Before Qty',
    },
    {
      Header: ' Qty Change',
      accessor: 'qty_change',
      id: 'qty_change',
      name: 'Qty Change',
    },
    {
      Header: 'After Qty',
      accessor: 'qty_after',
      id: 'qty_after',
      name: 'After Qty',
    },
    {
      Header: 'Memo',
      accessor: 'memo',
      Cell: ({ row: { original } }) => {
        return <Memo value={original.memo} />
      }
    },
    {
      Header: 'Operator',
      accessor: 'operator',
      Cell: ({ row: { original } }) => {
        return <div className="d-flex align-items-center">
          <div className="mr-1">{original.operator}</div>
        </div>
      }
    }];

  const open = Boolean(anchorEl);

  return (
    <div >
      <Button type="button" onClick={handleClick}>
        <PersonSearch></PersonSearch>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          style: { minWidth: '800px' },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Box p={2}>
          <Table
            stickyHeader
            columns={columns}
            data={history.slice(0, count)} />
        </Box>
        {history.length > 3 && <Box mb={2} display={'flex'} justifyContent={'center'}>
          <Button variant='outlined' onClick={handleShowAll}>
            {count === DEFAULT_COUNT ? 'Show All' : 'Hide'}</Button>
        </Box>}
      </Popover>
    </div>
  );
}
