import { makeStyles } from '@material-ui/core';
import React, { useRef, useState, useCallback, useEffect } from 'react';


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  btn: props => ({
    border: props.border ? props.border : `1.5px solid #2E52C5`,
    margin: '0px 2px',
    minWidth: `${props.width || 80}px`,
    height: `${props.height || 37}px`,
    background: props.variant ? theme.palette[props.variant].main : '#2E52C5',
    color: 'white',
    // border: '1px solid white',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  }),
  block: {
    padding: '0px 30px',
    textAlign: 'center',
    fontWeight: '500',
    flexGrow: '1',
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    color: '#2E52C5',
    height: '100%',
    // width: '18px'
  },
  dropDownMenu: {
    color: '#243967',
    position: 'absolute',
    right: '0',
    zIndex: 100,
    marginTop: '5px',
    background: 'white',
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
  },
  divider: {
    marginTop: '5px',
    borderTop: '1px solid #F3F5F9',
    width: '100%',
    height: '1px'
  },
  dropDownItem: {
    width: '100%',
    minWidth: '176px',
    padding: '10px 8px',
    display: 'flex',
    alignItems: 'center'
  },
  radio: {
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center'
  },
  text: {
    marginLeft: '7px',
    fontSize: '14px',
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
    whiteSpace: 'pre',
  },
  children: {
    cursor: 'pointer',
  },
}));

const RadioSelect = (props) => {
  const {
    children,
    options = [],
    onSelect,
    selected,
    onClick,
    hideOnSelect,
    icon,
    btnText } = props;

  const [drop, setDrop] = useState(false)

  const classes = useStyles(props);
  const dropdown = useRef();

  const handleClickOutside = useCallback((ev) => {
    if (dropdown.current.contains(ev.target) === false) {
      setDrop(false);
    }
  }, [])

  const handleOnClick = () => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  }
  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };

  }, [handleClickOutside])

  const handleOnSelected = (option) => {
    onSelect(option);
    if (hideOnSelect)
      setDrop(false);
  }

  return <>
    <div
      className={classes.root}
      ref={dropdown}>
      <div onClick={() => setDrop(true)}>
        {children ? children : <div className={classes.btn}>
          <div className={classes.block}>{btnText ? btnText : selected.value}</div>
          {icon && <div className={classes.arrow}>
            {icon}
          </div>}
        </div>}
      </div>
      {drop && <div className={classes.dropDownMenu}>
        <div className={classes.divider}></div>
        {options.map((option, index) => <div
          className={classes.dropDownItem}
          style={{ backgroundColor: !!selected.find((i)=>i.key === option.key) ? '#F3F5F9' : 'white', }}
          key={index + option.key + option.value}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginRight: '4px'
            }}
            onClick={() => handleOnSelected(option)}>
            <div className={classes.radio}>
              <input type="radio"
                style={{ margin: '0' }}
                checked={!!selected.find((i)=>i.key === option.key)}
                onChange={() => handleOnClick()}
              />
            </div>
            <div className={classes.text}>
              {option.value}
            </div>
          </div>
          <div>
            <span>{!!selected.find((i)=>i.key === option.key) && !!option.append && option.append}</span>
          </div>
        </div>)}
        <div className={classes.divider}></div>
      </div>}
    </div>
  </>
}

export default RadioSelect;
