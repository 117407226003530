import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { BolDetail, BolDetailGroup, BolDetailGroupLoad } from './models';
import DocumentsStore from '../document/DocumentsStore';
import { serialize } from 'object-to-formdata';
import { flatten, uniqBy } from 'lodash';
import { toJS } from 'mobx';

const BolStore = types
  .model('BolStore', {
    detail: types.optional(BolDetail, {}),
    selected: types.array(types.union(BolDetailGroup, BolDetailGroupLoad)),
    whsDocuments: types.optional(DocumentsStore, { target: 'outbound_whs' }),
    adminDocuments: types.optional(DocumentsStore, { target: 'outbound_panda' }),
    shipoutDocuments: types.optional(DocumentsStore, { target: 'outbound_shipout' }),
  })
  .volatile(self => ({
    loading: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
    get selectedLoads() {
      return uniqBy(flatten(self.selected.map(s => s.loads ? s.loads : s).slice()), 'id');
    },
  }))
  .actions(self => ({
    setSelected(selected) {
      self.selected = selected;
    },
    setDetail(detail) {
      applySnapshot(self.detail, detail);
      self.root.bols.replaceCollection(detail);
    },
    reload() {
      self.loadData(self.detail.id);
    },
    loadData: flow(function* (id) {
      try {
        self.loading = true;
        self.setDetail({});
        const response = yield self.root.api.bol.show(id);
        self.setDetail(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),

    viewPDF: flow(function* (id, otrCarrierId = null) {
      try {
        yield self.root.api.bol.viewPDF(id, otrCarrierId);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    printPDF: flow(function* (id, otrCarrierId = null) {
      try {
        yield self.root.api.bol.printPDF(id, otrCarrierId);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    viewPickSlip: flow(function* (id) {
      try {
        yield self.root.api.bol.viewPickSlip(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    printPickSlip: flow(function* (id) {
      try {
        yield self.root.api.bol.printPickSlip(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    shipout: flow(function* (bolId, data) {

      let message = 'Are you sure to Confirm Ship Out?';

      if (self.detail.is_ready_to_ship) {
        message = 'This BOL is On Hold, ' + message;
      }

      if (!window.confirm(message)) {
        return;
      }

      const formData = serialize({ ...data, documents: [...self.shipoutDocuments.toOriginalFiles()] }, { indices: true, nullsAsUndefineds: true })

      let response = false;
      try {
        self.loading = true;
        response = yield self.root.api.bol.shipout(bolId, formData);
        self.setDetail(response.data.data);
        self.whsDocuments.load();
        self.root.ui.toast.success('Success');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;

      return response;
    }),
    shipoutDeliver: flow(function* (bolId, shipoutId, time) {
      if (!window.confirm('Are you sure to Confirm POD')) {
        return;
      }

      let response = false;
      try {
        self.loading = true;
        response = yield self.root.api.bol.shipoutDeliver(bolId, shipoutId, time);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Success');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;

      return response;
    }),
    shipoutUnDeliver: flow(function* (bolId, shipoutId) {
      if (!window.confirm('Are you sure to Confirm POD')) {
        return;
      }

      let response = false;
      try {
        self.loading = true;
        response = yield self.root.api.bol.shipoutDeliver(bolId, shipoutId);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Success');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;

      return response;
    }),
    unshipout: flow(function* (bolId, shipoutId) {
      if (!window.confirm('Are you sure to UnConfirm Ship Out')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.bol.unshipout(bolId, shipoutId);
        self.setDetail(response.data.data);
        self.whsDocuments.load();
        self.root.ui.toast.success('Success');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    pickup: flow(function* (id) {
      if (!window.confirm('Are you sure to Confirm Ship Out')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.bol.pickup(id);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Success');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    unpickup: flow(function* (id) {
      if (!window.confirm('Are you sure to UnConfirm Ship Out')) {
        return;
      }

      try {
        self.loading = true;
        const response = yield self.root.api.bol.unpickup(id);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Success');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    updateMemo: flow(function* (id, memo) {
      try {
        self.loading = true;
        const response = yield self.root.api.bol.updateMemo(id, memo);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Success');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    cancelChanged: flow(function* (id) {
      try {
        yield self.root.api.bol.cancelChanged(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    confirm: flow(function* (id, data) {
      let response = null;
      try {
        self.loading = true;
        response = yield self.root.api.bol.confirm(id, data);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Success');
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;

      return response
    }),
    saveProductPackages: flow(function* (id, data) {
      let response = null;
      try {
        self.loading = true;
        response = yield self.root.api.bol.updateProductPackages(id, data);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Success');
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;

      return response
    }),
    completePacking: flow(function* (id) {
      let response = null;
      try {
        self.loading = true;
        response = yield self.root.api.bol.completePacking(id);
        self.setDetail(response.data.data);
        self.root.ui.toast.success('Success');
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.loading = false;

      return response
    }),
  }))


export default BolStore;
