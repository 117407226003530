import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { green, red, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: props => ({
    padding: '3px',
    color: '#fff',
    backgroundColor: props.color,
    borderRadius: '3px',
    display: 'inline',
    fontSize: '75%',
    fontWeight: '500',

    ...(props.pill ? {
      paddingLeft: '5px',
      paddingRight: '5px',
      borderRadius: '10em',
    } : {})
  }),
}));

const colorMap = theme => ({
  'primary': theme.palette.primary.main,
  'secondary': theme.palette.secondary.main,
  'success': theme.palette.success.main,
  'danger': theme.palette.danger.main,
  'warning': theme.palette.warning.main,
  'green': green[500],
  'red': red[500],
  'yellow': yellow[500],
})

const Badge = ({ children, color = 'primary', pill = false, className, ...props }) => {
  const theme = useTheme();
  const colors = colorMap(theme);

  const classes = useStyles({
    pill,
    color: colors[color] || color,
  });


  return <Box {...props} className={`${classes.root} ${className}`}>
    {children}
  </Box>
}


export default Badge;
