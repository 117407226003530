import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    padding: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const AdjustPalletCount = observer(({ inventorys, variant = 'contained', size = 'small', selected, onAdjustFinished, }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [palletCount, setPalletCount] = useState('');
  const [reason, setReason] = useState('');

  const { loading } = inventorys;

  const handleOpen = () => {
    if (selected.length > 0) {
      setPalletCount('')
      setReason('')
      setOpen(true)
    } else {
      inventorys.root.ui.toast.error('Please select at least one item.')
    }
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    inventorys.adjustWhsPalletCount(
      selected.map(l => l.id),
      palletCount,
      reason,
    ).then(response => {
      if (response) {
        setOpen(false);
        onAdjustFinished && onAdjustFinished();
      }
    });
  }

  const canSubmit = () => {
    return !loading && palletCount;
  }

  return <>
    <Button size={size} variant={variant} color="primary" onClick={handleOpen}>Adjust WHS PLT Count</Button>
    {open && <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box className={classes.content}>
            <Box className={classes.subtitle}>Please input pallet count *</Box>
            <Box mt={1}>
              <FormControl required fullWidth size="small">
                <OutlinedInput type="number" value={palletCount} onChange={e => setPalletCount(e.target.value)} />
              </FormControl>
            </Box>
            <Box mt={3} className={classes.subtitle}>Please input the reason *</Box>
            <Box mt={1}>
              <FormControl required fullWidth size="small">
                <OutlinedInput multiline rows={4} type="number" value={reason} onChange={e => setReason(e.target.value)} />
              </FormControl>
            </Box>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!canSubmit()}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>}
  </>
});



export default AdjustPalletCount;

