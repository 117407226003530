import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AdjustPalletCount from './AdjustPalletCount';
import Move from './Move';
import Columns from '../../../common/Columns';
import { observer } from 'mobx-react';
import columns from './columns';
import RadioSelect from '../../../common/RadioSelect';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TransferToWMS from './TransferToWMS';
import WhsArea from './WhsArea';



const Actions = observer(({ inventorys }) => {
  const { column } = inventorys;
  const [selected, setSelected] = React.useState([]);


  const handleOnSelect = (option) => {
    setSelected([option]);
    let blob = inventorys.exportInventories(option.key === 2);
    if (blob) {
      let filename = 'data.csv';
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0)
      }
    }
  }


  return <Box>
    <Box display="flex" mt={3} justifyContent="flex-end">
      <Box>
        <Button variant="outlined" color="primary" onClick={() => window.location.reload()}>Refresh</Button>
      </Box>

      <Box ml={1}>
        <Move size="medium" inventorys={inventorys} />
      </Box>
      <Box ml={1}>
        <RadioSelect
          btnText="Export"
          variant="primary"
          options={[
            {
              key: 1,
              value: 'Current Page All Rows',
            },
            {
              key: 2,
              value: 'Selected Row Only',
            }
          ]}
          icon={<ExpandMore ></ExpandMore>}
          selected={selected}
          hideOnSelect
          onSelect={(selected) => { handleOnSelect(selected) }}
        />
      </Box>
    </Box>
    <Box display="flex" mt={3} justifyContent="flex-end">
      <Box ml={1}>
        <WhsArea size="small" inventorys={inventorys} selected={inventorys.selected} onDone={inventorys.loadInventorys} />
      </Box>
      <Box ml={1}>
        <TransferToWMS size="small" inventorys={inventorys} selected={inventorys.selected} onDone={inventorys.loadInventorys} />
      </Box>
      <Box ml={1}>
        <AdjustPalletCount size="small" inventorys={inventorys} selected={inventorys.selectedLoads} onAdjustFinished={inventorys.loadInventorys} />
      </Box>
      <Box ml={1}>
        <Columns
          columns={columns}
          selected={column.selected}
          onSelect={column.updateSelected}
        >
          <Button size="small" variant="outlined" color="primary">Manage Column</Button>
        </Columns>
      </Box>
    </Box>
  </Box>
})


export default Actions;
