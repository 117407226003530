import request from "./request";
import FileSaver from "file-saver";

export const outbound = {
  counts() {
    return request.get("outbounds/counts");
  },
  index(params) {
    return request.get("outbounds", { params });
  },
  show(id) {
    return request.get(`outbounds/${id}`);
  },
  viewPDF(id, withSummary, bolIds = [], hideCarrerInBOL = false) {
    return this.exportPDF(false, id, withSummary, bolIds, hideCarrerInBOL);
  },
  printPDF(id, withSummary, bolIds = [], hideCarrerInBOL = false) {
    return this.exportPDF(true, id, withSummary, bolIds, hideCarrerInBOL);
  },
  printEFBolsExcel(id, withSummary, bolIds = [], hideCarrerInBOL = false, filename = "bols") {
    return request
      .get(`outbounds/${id}/efBolsExcel`, {
        params: { bol_ids: bolIds, withSummary, hideCarrerInBOL },
        responseType: "blob",

      })
      .then((response) => {
        const file = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        FileSaver.saveAs(file, filename);
      });
  },
  printOBExcel(id, bolIds = [], filename = "bols", v = '') {
    return request
      .get(`outbounds/${id}/obExcel${v}`, {
        params: { bol_ids: bolIds },
        responseType: "blob",
      })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Extract filename from header
        // const filename = response.headers['content-disposition']
        //   .split(';')
        //   .find(n => n.includes('filename='))
        //   .replace('filename=', '')
        //   .trim()
        //   .replace(/^\"/, '')
        //   .replace(/\"$/, '')

        FileSaver.saveAs(file, filename);
      });
  },
  exportExcel(id) {
    return request
      .get(`outbounds/${id}/excel`, { responseType: "blob" })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Extract filename from header
        const filename = response.headers["content-disposition"]
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim()
          .replace(/^\"/, "")
          .replace(/\"$/, "");

        FileSaver.saveAs(file, filename);
      });
  },
  exportPDF(print, id, withSummary, bolIds = [], hideCarrerInBOL = false) {
    return request
      .get(`outbounds/${id}/pdf`, {
        params: {
          with_summary: withSummary,
          bol_ids: bolIds,
          hide_carrier_in_bol: hideCarrerInBOL,
        },
        responseType: "blob",
      })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const w = window.open(fileURL, "_blank");
        if (print) {
          w.print();
        }
      });
  },
  shipout(outboundId, data) {
    return request.post(`outbounds/${outboundId}/shipout`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  unshipout(outboundId) {
    return request.put(`outbounds/${outboundId}/unshipout`);
  },
  cargoLoaded(outboundId) {
    return request.put(`outbounds/${outboundId}/cargoLoaded`);
  },
  cancelLoaded(outboundId) {
    return request.put(`outbounds/${outboundId}/cancelCargoLoaded`);
  },
  updateRemark(id, remark) {
    return request.put(`outbounds/${id}/updateRemark`, { remark });
  },
};
