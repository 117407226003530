import request from './request';

export const column = {
  index() {
    return request.get('columns');
  },
  show(table) {
    return request.get(`columns/${table}`);
  },
  update(table, columns) {
    return request.put('columns', { table: table, columns: columns });
  },
}
