import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Table from '../../../ui/Table';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Box, DialogActions, InputAdornment, OutlinedInput } from '@material-ui/core';
import TradePartySelect from '../../../common/TradePartySelect';
import { BOL_PAYMENT_TYPES, BOL_TYPE_MAP } from '../../../../stores/bol/constants';
import { bol, shippingMode, toast, workOrder as workOrderService } from '../../../../services';
import { useParams } from 'react-router-dom';
import { toJS } from 'mobx';

const useStyles = makeStyles((theme) => ({
  root: { padding: '10px 50px' },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    color: theme.palette.primary.main
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    width: '100%',
    padding: '0.25rem 0.5rem',
    fontSize: '0.875rem',
    lineHeight: '1.3',
    borderRadius: '0.2rem',
    color: '#495057',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
  },
  margin: {
    // margin: theme.spacing(1),
  },
  withoutLabel: {
    // marginTop: theme.spacing(3),
  },
  textField: {
    fontSize: '0.675rem',
  },
  table: {

    textAlign: 'center',
    fontSize: '12px',
    lineHeight: 1.5,
    color: '#000000',
    borderCollapse: 'collapse',
    '& tr': {
      textAlign: 'left',
      '& td, th': {
        margin: '0px 10px',
        padding: '15px 0',
      },
      '& th': {
        border: 0,
        color: '#000000',
        background: '#E0E0E0',
        fontWeight: 'normal',
      },
    },
    '& tr:first-child': {
      '& td': {
        borderTop: 0,
      },
    },
  },
  inputAdornment: {

  },
  input: {
    padding: '10px',
    border: '1px solid #BBBFC8',
    borderRadius: '5px',
    flex: 1,
    width: '100%',
  },
  whiteSpacePre: {
    whiteSpace: 'pre',
  },
}));




const OBbolForm = observer(({ root, goBack }) => {
  const classes = useStyles();
  const { auth, workOrder } = root;
  const defaultValue = () => {

    const groups = workOrder.selectedGroups.map(item => ({
      ...item.toJSON(),
      loads: item.toJSON().loads?.map(item => ({ ...item })),
    }))

    const loads = [].concat(groups.map(g => {
      return (g.loads.map(l => ({
        id: l.id,
        luid: l.uid,
        uid: g.uid,
        marking: g.marking,
        ib_number: g.ib_number,
        customer_reference: l.customer_reference,
        receiver_shipment_id: l.receiver_shipment_id,
        receiver_reference_id: l.receiver_reference_id,
        current_qty: l.current_qty,
        weight_kg: l.weight_kg,
        weight_lb: l.weight_lb,
        dispatch_remark: l.dispatch_remark,
      })))
    }).flat())

    return {
      obbol: '',
      type: '',
      payment_type: '',
      shipping_mode_id: '',

      pickup: Object.assign({}, auth.user.whs),
      delivery: {},
      loads: loads || [],
    }
  }

  // const [open, setOpen] = React.useState(false);
  const [shippingModes, setShippingModes] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [form, setForm] = React.useState(defaultValue())
  const { id } = useParams();

  const isNew = (id === 'new')

  React.useEffect(() => {
    if (!workOrder.selectedGroups.length && id === 'new') {
      toast.error('No selected group')
      goBack();
    }

    if (id !== 'new') {
      loadBol();
    }
    shippingMode.index()
      .then(response => {
        setShippingModes(response.data.data)
      }).catch(e => toast.error(e))
  }, [])

  const loadBol = () => {
    bol.show(id).then(repsonse => {
      let _bol = repsonse.data.data;
      let groups = _bol.groups
      const loads = [].concat(groups.map(g => {
        return (g.loads.map(l => ({
          id: l.id,
          luid: l.uid,
          uid: g.uid || '',
          marking: g.markings[0] || '',
          ib_number: g.container_number || '',
          customer_reference: l.customer_reference,
          receiver_shipment_id: l.receiver_shipment_id,
          receiver_reference_id: l.receiver_reference_id,
          current_qty: l.current_qty,
          weight_kg: l.weight_kg,
          weight_lb: l.weight_lb,
          dispatch_remark: l.dispatch_remark,
        })))
      }).flat())

      setForm({
        uid: _bol.uid,
        type: _bol.type,
        payment_type: _bol.payment_type,
        shipping_mode_id: _bol.shipping_mode_id,

        pickup: _bol.pickup,
        delivery: _bol.delivery,
        loads: loads || [],
        urgent_level: _bol.urgent_level
      })
    })
  }

  const columns = React.useMemo(() => [
    {
      Header: ({ getToggleAllRowsExpandedProps }) => <>
        <Box
          display="flex"
          alignItems="left"
          px={1}
          {...getToggleAllRowsExpandedProps()}
        >
          <Box>Load ID</Box>
        </Box>
      </>,
      accessor: 'load',
      Cell: ({ row: { original } }) => <Box px={1}>
        {original.luid}
      </Box>,
    },
    {
      Header: ({ getToggleAllRowsExpandedProps }) => <>
        <Box
          display="flex"
          alignItems="left"
          px={1}
          {...getToggleAllRowsExpandedProps()}
        >
          <Box>Group ID</Box>
        </Box>
      </>,
      accessor: 'group',
      Cell: ({ row: { original } }) => <Box px={1}>
        {original.uid}
      </Box>,
    },
    {
      Header: 'WHS Marking#',
      accessor: 'marking',
      Cell: observer(({ row: { original } }) => <Box px={0.5}>
        {original.marking}
      </Box>),
    },
    {
      Header: 'CNTR#',
      accessor: 'ib_number',
      Cell: observer(({ row: { original } }) => <Box px={0.5}>
        {original.ib_number}
      </Box>),
    },
    {
      Header: 'Customer Ref#',
      accessor: 'customer_reference',
      Cell: observer(({ row: { original }, state: { handleUpdateLoad, focus, classes } }) => <Box px={0.5}>
        <FormControl size="small" className={clsx(classes.margin, classes.textField)} variant="outlined">
          <OutlinedInput
            multiline
            id={`${original.id}_load.customer_reference`}
            className={classes.textField}
            value={original.customer_reference || ""}
            labelWidth={0}
            onChange={(e) => { handleUpdateLoad(e, original.id) }}
            name="customer_reference"
            autoFocus={`${original.id}_load.customer_reference` === focus}
            inputProps={{ style: { whiteSpace: 'pre' } }}
          />
        </FormControl>
      </Box>),
    },

    {
      Header: 'Receiver Shipment ID',
      accessor: 'receiver_shipment_id',
      Cell: observer(({ row: { original }, state: { handleUpdateLoad, focus, classes } }) => <Box px={0.5}>
        <FormControl size="small" className={clsx(classes.margin, classes.textField)} variant="outlined">
          <OutlinedInput
            multiline
            id={`${original.id}_load.receiver_shipment_id`}
            className={classes.textField}
            value={original.receiver_shipment_id || ""}
            labelWidth={0}
            onChange={(e) => { handleUpdateLoad(e, original.id) }}
            name="receiver_shipment_id"
            autoFocus={`${original.id}_load.receiver_shipment_id` === focus}
            inputProps={{ style: { whiteSpace: 'pre' } }}
          />
        </FormControl>
      </Box>),
    },
    {
      Header: 'Receiver Ref ID',
      accessor: 'receiver_reference_id',
      Cell: observer(({ row: { original }, state: { handleUpdateLoad, focus, classes } }) => <Box px={0.5}>
        <FormControl size="small" className={clsx(classes.margin, classes.textField)} variant="outlined">
          <OutlinedInput
            multiline
            id={`${original.id}_receiver_reference_id`}
            className={classes.textField}
            value={original.receiver_reference_id || ""}
            onChange={(e) => { handleUpdateLoad(e, original.id) }}
            name="receiver_reference_id"
            labelWidth={0}
            autoFocus={`${original.id}_receiver_reference_id` === focus}
            inputProps={{ style: { whiteSpace: 'pre' } }}
          />
        </FormControl>
      </Box>),
    },
    {
      Header: 'Dispatch Remark',
      accessor: 'dispatch_remark',
      Cell: observer(({ row: { original }, state: { handleUpdateLoad, focus, classes } }) => {
        return <Box px={0.5}>
          <FormControl size="small" className={clsx(classes.margin, classes.textField)} variant="outlined">
            <OutlinedInput
              multiline
              id={`${original.id}_load.dispatch_remark`}
              className={classes.textField}
              value={original.dispatch_remark || ""}
              labelWidth={0}
              onChange={(e) => { handleUpdateLoad(e, original.id) }}
              name="dispatch_remark"
              autoFocus={`${original.id}_load.dispatch_remark` === focus}
              inputProps={{ style: { whiteSpace: 'pre' } }}
            />
          </FormControl>
        </Box>
      }),
    },
    {
      Header: 'Current Qty',
      accessor: 'current_qty',
      Cell: observer(({ row: { original } }) => <Box px={0.5}>
        {original.current_qty}
      </Box>),
    },
    {
      Header: 'Display Weight',
      accessor: 'weight_kg',
      Cell: observer(({ row: { original }, state: { handleUpdateLoad, focus, classes } }) => <Box px={0.5}>
        <FormControl size="small" className={clsx(classes.margin, classes.textField)} variant="outlined">
          <OutlinedInput
            id={`${original.id}_weight_kg`}
            value={original.weight_kg || ""}
            className={classes.textField}
            name="weight_kg"
            onChange={(e) => { handleUpdateLoad(e, original.id) }}
            endAdornment={<InputAdornment variant="filled" position="end">Kg</InputAdornment>}
            labelWidth={0}
            autoFocus={`${original.id}_weight_kg` === focus}
          />
        </FormControl>
      </Box>),
    },
    {
      Header: '',
      accessor: 'weight_lb',
      Cell: observer(({ row: { original }, state: { handleUpdateLoad, focus, classes } }) => <Box px={0.5}>
        <FormControl size="small" className={clsx(classes.margin, classes.textField)} variant="outlined">
          <OutlinedInput
            id={`${original.id}_weight_lb`}
            className={classes.textField}
            value={original.weight_lb || ""}
            name="weight_lb"
            onChange={(e) => { handleUpdateLoad(e, original.id) }}
            endAdornment={<InputAdornment position="end">LBS</InputAdornment>}
            labelWidth={0}
            autoFocus={`${original.id}_weight_lb` === focus}
          />

        </FormControl>
      </Box>),
    },
  ], []);

  const [focus, setFocus] = React.useState(null);

  // const handleClickOpen = () => {
  //   if (workOrder.selectedGroups.length === 0) {
  //     toast.error('No selected Groups')
  //     return;
  //   }
  //   // setOpen(true);
  //   setFocus(null)
  //   setForm(defaultValue())
  // };


  const handlePropertyChage = (e) => {
    const copy = { ...form };
    copy[e.target.name] = e.target.value;
    setForm(copy);
    setFocus(null)
  }

  const handleSelectChange = (name, list) => {
    const copy = { ...form };
    copy[name] = list;
    setForm(copy);
    setFocus(null)
  }

  const handleAddressChange = (name, e) => {
    const copy = { ...form };
    copy[name].bl_address = e.target.value;
    setForm(copy);
    setFocus(null)
  }

  const handleUpdateLoad = (e, id) => {
    const { name, value, checked, type } = e.target;
    const copy = { ...form };
    const load = copy.loads.find(l => l.id === id);

    const v = type === 'checkbox' ? checked : value;

    if (!load) {
      return;
    }

    load[name] = v

    setForm(copy)
    setFocus(e.target.id)
  }

  const handleCreateBol = () => {
    const params = { ...form };
    params.delivery_location_type = 'App\\Models\\TradeParty';
    params.delivery_location_id = form.delivery.id;
    params.delivery_address = form.delivery.bl_address;
    delete params.delivery;

    setSubmitting(true);

    workOrderService.createBol(params)
      .then(response => {
        setFocus(null);
        toast.success(`Bol ${response.data.data.id} created`);
        workOrder.loadData && workOrder.loadData();
        goBack()
      }).catch(e => toast.error(e))
      .then(() => setSubmitting(false));
  }


  const handleUpdateBol = () => {
    const params = { ...form };
    params.delivery_location_type = 'App\\Models\\TradeParty';
    params.delivery_location_id = form.delivery.id;
    params.delivery_address = form.delivery.bl_address;
    delete params.delivery;

    setSubmitting(true);

    workOrderService.updateBol(id, params)
      .then(response => {
        setFocus(null);
        toast.success(`Bol ${response.data.data.id} updated`);
        workOrder.loadData && workOrder.loadData();
        goBack()
      }).catch(e => toast.error(e))
      .then(() => setSubmitting(false));
  }

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={1}>
          <Grid item xs={2} sm={2}>
            <label>OB BOL#</label>
            <input className={classes.formControl} value={form.uid || "Auto Generate"} defaultValue="Auto Generate" disabled />
          </Grid>
          <Grid item xs={2} sm={2}>
            <label>Type*</label>
            <select name="type" value={form.type} onChange={handlePropertyChage} className={classes.formControl} required >
              <option value="">BOL TYPE</option>
              {Object.entries(BOL_TYPE_MAP).map(([key, value]) => <option key={'BOL_TYPE_MAP' + key} value={key}>
                {value}
              </option>)}
            </select>
          </Grid>
          <Grid item xs={3} sm={3}>
            <label>Payment Type*</label>
            <select name="payment_type" value={form.payment_type} onChange={handlePropertyChage} className={classes.formControl} required >
              <option value="">BOL PAYMENT TYPE</option>
              {BOL_PAYMENT_TYPES.map((item, index) => <option key={'BOL_PAYMENT_TYPES' + index} value={item}>
                {item}
              </option>)}
            </select>
          </Grid>
          <Grid item xs={3} sm={3}>
            <label>Shipping Mode*</label>
            <select name="shipping_mode_id" value={form.shipping_mode_id} onChange={handlePropertyChage} className={classes.formControl} required >
              <option value="">SHIPPING MODE</option>
              {shippingModes.map((item, index) => <option key={'shipping_mode_id' + index} value={item.id}>
                {item.name}
              </option>)}
            </select>
          </Grid>
          <Grid item xs={2} sm={2}>
            <label>Urgent*</label>
            <select name="urgent_level" value={form.urgent_level} onChange={handlePropertyChage} className={classes.formControl} required >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </Grid>

        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <Box mt={2}>
              <label>Pickup Location*</label>
              <TradePartySelect
                size="sm"
                disabled
                selected={form.pickup}
                onChange={(list) => { }}></TradePartySelect>
              <InputBase disabled mt={2} value={form.pickup.bl_address} className={classes.input} multiline rows="6" onChange={() => { }} placeholder="Address" />
            </Box>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box mt={2}>
              <label>Delivery Location*</label>
              <TradePartySelect
                size="sm"
                selected={form.delivery}
                onChange={(list) => { handleSelectChange('delivery', list) }}></TradePartySelect>
              <InputBase mt={2} value={form.delivery?.bl_address} className={classes.input} multiline rows="6" onChange={(e) => { handleAddressChange('delivery', e) }} placeholder="Address" />
            </Box>
          </Grid>
        </Grid>
        <Box mt={2}>
          <Table
            className={classes.table}
            columns={columns}
            data={form.loads}
            getSubRows={row => row && row.loads ? [null, ...row.loads.slice()] : []}
            state={{ handleUpdateLoad, focus, classes }}
          />
        </Box>
      </form>

      <DialogActions style={{ padding: '8px 25px' }}>
        <Button onClick={goBack} variant="outlined" color="primary" type="button">
          Cancel
        </Button>

        {isNew ? <Button onClick={handleCreateBol} variant="contained" color="primary" type="button" disabled={submitting}>
          Create
        </Button> :
          <Button onClick={handleUpdateBol} variant="contained" color="primary" type="button" disabled={submitting}>
            Update
          </Button>
        }

      </DialogActions>

    </React.Fragment >
  );
})


export default inject('root')(OBbolForm);
