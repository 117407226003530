import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { MemoActiveIcon, MemoInActiveIcon } from '../../../ui/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  button: {
    padding: 0,
    fontSize: '15px',
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: '15px',
  },
  inputWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    border: '1px dashed #80889A',
    padding: '11px 15px',
  },
  input: {
    flex: 1,
    width: '100%',
  },
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '11px 22px',
  },
}));

const Remark = observer(({ value, onChange }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [remark, setRemark] = useState(value || '');

  const handleOpen = () => {
    setRemark(value);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSave = () => {
    onChange(remark);
    handleClose();
  }

  return <>
    <IconButton color="primary" size="small" className={classes.button} onClick={handleOpen}>
      {value ? <MemoActiveIcon className={classes.icon} /> : <MemoInActiveIcon className={classes.icon} />}
    </IconButton>
    {open && <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogTitle}>Remark:</DialogTitle>
      <DialogContent>
        <Box className={classes.inputWrapper}>
          <InputBase value={remark} className={classes.input} multiline rows="9" onChange={e => setRemark(e.target.value)} />
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button fullWidth size="small" onClick={handleClose} variant="outlined" color="primary">
              Discard
          </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth size="small" onClick={handleSave} variant="contained" color="primary">
              Save
          </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>}

  </>
});

export default Remark;
