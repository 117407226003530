import { types, getRoot, getParent } from 'mobx-state-tree';

export const Document = types
  .model({
    id: types.number,
    name: types.maybeNull(types.string),
    file_name: types.maybeNull(types.string),
    mime_type: types.maybeNull(types.string),
    creator_name: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    created_at: types.maybeNull(types.string),
    collection: types.maybeNull(types.string),
  })
  .volatile(self => ({
    original: null,
  }))
  .views(self => ({
    get root() {
      return getRoot(self);
    }
  }))
  .actions(self => ({
    download() {
      getParent(self, 2).download(self);
    },
    view() {
      getParent(self, 2).view(self);
    },
    delete() {
      return new Promise((resolve) => {
        getParent(self, 2).delete(self.id).then(response => resolve(response ? self : response));
      })
    },
    setOriginal(original) {
      self.original = original;
    }
  }))
