import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import LoadBox from './LoadBox';
import { chunk } from 'lodash';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '50px',

  },
  loadbar: {
    width: '100%',
    display: 'flex',
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.2)',
    marginTop: theme.spacing(2),
  }
}));

const LoadBar = observer(({ task }) => {
  const classes = useStyles();

  const { detail } = task;
  const chunkedLoads = chunk(detail.loadBarWrappers, 10);

  return <>
    <Box className={classes.root}>
      {chunkedLoads.map((loads, index) => <Box className={classes.loadbar} key={index}>
        {loads.map(loadWrapper => <LoadBox key={loadWrapper.load.id} load={loadWrapper.load} role={loadWrapper.role} active={loadWrapper.active} />)}
      </Box>)}
    </Box>
  </>
})

export default LoadBar;
