import React from 'react'
import Table from '../../ui/Table'
import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Documents from '../../common/Documents'

export const Doc = observer(({ model }) => {
  const [open, setOpen] = React.useState(false)
  React.useEffect(() => {
    open && model.documents.load(model.id)
  }, [open])
  return <>
    <Button variant="outlined" size="small" color="primary" onClick={() => setOpen(true)}>View</Button>
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Documents</DialogTitle>
      <DialogContent>
        <Documents documents={model.documents} uploadable={false} deletable={false} />
      </DialogContent>
    </Dialog>
  </>
})

const AddonServicesTable = observer(({ data }) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Billing Code',
      accessor: 'code',
    },
    {
      Header: 'Billing Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      Cell: observer(({ row: { original } }) => original.status_name),
    },
    {
      Header: 'Rate',
      accessor: 'rate',
    },
    {
      Header: 'Qty',
      accessor: 'qty',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Related Doc',
      Cell: ({ row: { original } }) => <Doc model={original} />,
    },
    {
      Header: 'Action',
      Cell: observer(({ row: { original } }) => <>
        {original.status == 0 && <Button variant="contained" color="primary" size="small" onClick={original.complete}>Complete</Button>}
        {original.status == 1 && <Button variant="outlined" color="primary" size="small" onClick={original.uncomplete}>Incomplete</Button>}
      </>),
    },
  ], [])

  return <Table
    columns={columns}
    data={data}
  />
})

export default AddonServicesTable
