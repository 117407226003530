import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TradePartySelect from '../../../common/TradePartySelect';
import { EditIcon } from '../../../ui/Icon';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    minHeight: '100px',
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const UnloadingTeamModal = observer(({ inbound }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { detail, updateUnloadingTeams } = inbound;
  const [selected, setSelected] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  }

  useEffect(() => {
    setSelected(detail.unloading_team)
  }, [detail.unloading_team])

  const handelConfirm = async () => {
    await updateUnloadingTeams(inbound.detail.id, selected.id)
    handleClose();
  }


  const handleClose = () => {
    setOpen(false);
  }

  return <>
    <a href={() => false} onClick={handleOpen}>{detail.unloading_team?.name || <EditIcon fontSize="inherit" />}</a>
    {open && <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Box display={"flex"}>
          <Box padding={2} flex={1} className={classes.content}>
            <Box mb={2} className={classes.subtitle}>
              Select Unloading Team
            </Box>
            <Box className={classes.content}>
              <TradePartySelect selected={selected || null} onChange={(unloadingTeam) => setSelected(unloadingTeam)} />
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handelConfirm}
                disabled={!selected || inbound.loading}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>}
  </>
});

export default UnloadingTeamModal;
