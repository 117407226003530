import request from './request';
const index = (params = { page: 1 }) => {
  return request.get('tradePartys', { params });
}



export const tradePartyService = {
  index,

}
