import request from './request';

export const workOrder = {
  counts() {
    return request.get('workOrders/counts');
  },
  index(params = {}) {
    return request.get('workOrders', { params });
  },
  confirm(workOrderId) {
    return request.put(`workOrders/${workOrderId}/confirm`);
  },
  unconfirm(workOrderId) {
    return request.put(`workOrders/${workOrderId}/unconfirm`);
  },
  reject(workOrderId) {
    return request.put(`workOrders/${workOrderId}/reject`);
  },
  cancel(workOrderId) {
    return request.put(`workOrders/${workOrderId}/cancel`);
  },
  complete(workOrderId) {
    return request.put(`workOrders/${workOrderId}/complete`);
  },
  uncomplete(workOrderId) {
    return request.put(`workOrders/${workOrderId}/uncomplete`);
  },
  updateTasks(workOrderId, tasks) {
    return request.put(`workOrders/${workOrderId}/updateTasks`, { tasks });
  },
  updateInventory(workOrderId, groups) {
    return request.put(`workOrders/${workOrderId}/updateInventory`, { groups });
  },
  show(id) {
    return request.get(`workOrders/${id}`);
  },
  inventoryHistoryByLoad(workOrderId, loadId) {
    return request.get(`workOrders/${workOrderId}/inventoryHistoryByLoad/${loadId}`);
  },
  inventoryHistoryByGroup(workOrderId, groupId) {
    return request.get(`workOrders/${workOrderId}/inventoryHistoryByGroup/${groupId}`);
  },
  createBol(params) {
    return request.post(`workOrders/createBol`, params);
  },
  updateBol(id, params) {
    return request.put(`workOrders/updateBol/${id}`, params);
  },
  deleteGroup(workOrderId, groupId) {
    return request.delete(`workOrders/${workOrderId}/groups/${groupId}`);
  },
  downloadPDF(id) {
    return request.get(`workOrders/${id}/pdf`, { responseType: 'blob' })
  },
  opclose(id, loadIds) {
    return request.put(`workOrders/${id}/opclose`, { load_ids: loadIds })
  },
  downloadExcel() {
    return request.get(`workOrders/export`, { responseType: 'blob' });
  }
}
