import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import Documents from './Documents';
import ChatRoom from '../../../common/ChatRoom';
import Badge from '../../../common/Badge';
import WhsGroupBadge from '../../../common/WhsGroupBadge';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    border: '0.5px solid #000000',
    padding: '24px',
    background: '#E5E5E5',
  },
  header: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.5,
    color: '#000000;',
    paddingBottom: '10px',
    borderBottom: '1px solid #000000',
  },
  group: {
    marginTop: '10px',
  },
  title: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#000000',
    marginBottom: '5px',
  },
  value: {
    border: '1px solid #000000',
    background: '#80889A',
    padding: '4px 10px',
    minHeight: '26px',
    fontSize: '12px',
    lineHeight: 1.5,
    color: '#FFFFFF',
  },
  description: {
    minHeight: '200px',
    overflowY: 'auto',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  divider: {
    color: 'E0E0E0',
  },
  statusBadge: {
    float: 'right',
    fontSize: '10px',
    lineHeight: '10px',
  },
}));

const GeneralInfo = observer(({ workOrder }) => {
  const classes = useStyles();

  const { detail } = workOrder;

  return <Box className={classes.root}>
    <Box className={classes.header}>General Information</Box>
    <Box className={classes.group}>
      <Box className={classes.title}>Work Order Title</Box>
      <Box className={classes.value}>{detail.title}</Box>
    </Box>


    <Grid container spacing={2}>
      <Grid item md={6}>
        <Box className={classes.group}>
          <Box className={classes.title}>Order Type</Box>
          <Box className={classes.value}>{detail.type_name}</Box>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box className={classes.group}>
          <Box className={classes.title}>Urgent Level</Box>
          <Box className={classes.value}>{detail.urgent_name}</Box>
        </Box>
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item md={6}>
        <Box className={classes.group}>
          <Box className={classes.title}>Ship Type</Box>
          <Box className={classes.value}>{detail.ship_type_name}</Box>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box className={classes.group}>
          <Box className={classes.title}>Status</Box>
          <Box className={classes.value}>{detail.status_name}</Box>
        </Box>
      </Grid>
    </Grid>

    <Box mt={3}><hr className={classes.divider} /></Box>


    {detail.notNewGroups.map(group => <Box key={group.pivot_id}>
      <Grid container spacing={1}>
        <Grid item md={4}>
          <Box className={classes.group}>
            <Box className={classes.title}>CNTR#</Box>
            <Box className={classes.value}>{group.ib_number}</Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box className={classes.group}>
            <Box className={classes.title}>Marking</Box>
            <Box className={classes.value}>{group.marking}</Box>
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box className={classes.group}>
            <Box className={classes.title}>
              <span>WHS Group#</span>
              <WhsGroupBadge group={group} />
            </Box>
            <Box className={classes.value}>
              {group.uid}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Box className={classes.group}>
          <Box className={classes.title}>Related Documents:</Box>
          <Documents documents={group.documents} />
        </Box>
      </Box>
    </Box>)}

    <Box mt={3}><hr className={classes.divider} /></Box>

    <Box mt={2} className={classes.header}>Customer Demand</Box>
    <Box className={classes.group}>
      <Box className={clsx(classes.value, classes.description)}>{detail.description}</Box>
    </Box>

    <Box mt={2} mb={2} className={classes.header}>Chat Room</Box>
    <Box>
      {detail.id && <ChatRoom
        senderId={workOrder.root.auth.user.id}
        targetId={detail.id}
        target="whs_work_order"
        sendMessage
      />}
    </Box>
  </Box >
});

export default GeneralInfo;

