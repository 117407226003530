export const TROUBLE_SHOOT_LIST_TYPE_ALL = 'all';
export const TROUBLE_SHOOT_LIST_TYPE_IN_PROGRESS = 'inprogress';
export const TROUBLE_SHOOT_LIST_TYPE_SOLVED = 'solved';
export const TROUBLE_SHOOT_LIST_TYPE_CANCELED = 'canceled';

export const TROUBLE_SHOOT_LIST_TYPES_MAP = {
  [TROUBLE_SHOOT_LIST_TYPE_ALL]: 'Total',
  [TROUBLE_SHOOT_LIST_TYPE_IN_PROGRESS]: 'In Progress TB',
  [TROUBLE_SHOOT_LIST_TYPE_SOLVED]: 'Solved TB',
  [TROUBLE_SHOOT_LIST_TYPE_CANCELED]: 'Canceled TB',
};

