import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { observer, PropTypes } from 'mobx-react';
import ReactPropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import { take } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';


const StyledBadge = withStyles((theme) => ({
  badge: {
    right: '-8px',
    backgroundColor: 'transparent',
    color: '#959597',
    fontSize: '12px',
    cursor: 'pointer',

    '&::before': {
      content: '"+"',
    },
  },
}))(Badge);

const defaultRowRender = row => row;

const toRenderText = (t, delimiter, rowRender) => {
  return t
    .map((row, index) => <React.Fragment key={index}>{rowRender(row)}</React.Fragment>)
    .reduce((prev, curr) => [prev, delimiter, curr]);
}

const CollectionText = observer(({ texts, taken = 2, classes = {}, rowRender = defaultRowRender, delimiter = ',', emptyContent = null, tooltip = false }) => {
  const t = take(texts, taken);

  const length = texts.length;
  const remainingLength = length - t.length;

  const renderedText = t.length === 0
    ? emptyContent
    : toRenderText(t, delimiter, rowRender)

  const content = <StyledBadge color="secondary" badgeContent={remainingLength} max={99} >
    <span className={classes.root}>{renderedText}</span>
  </StyledBadge >;

  if (tooltip) {
    const remainingTexts = texts.slice(taken);

    if (remainingTexts.length > 0) {

      const remainingText = toRenderText(remainingTexts, delimiter, rowRender);

      return <Tooltip title={remainingText}>
        {content}
      </Tooltip>
    } else {
      return content;
    }


  } else {
    return content;
  }
})

CollectionText.propTypes = {
  texts: PropTypes.arrayOrObservableArray.isRequired,
  taken: ReactPropTypes.number,
}

export default CollectionText;
