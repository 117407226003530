import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { InBoundIcon, OutBoundIcon, WorkOrderIcon, ChatIcon } from '../../../ui/Icon';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import NotificationList from './NotificationList';
import CardContainer from './CardContainer';
import Card from './Card';
import Title from './Title';
import InboundCardItem from './InboundCardItem';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import FlightIcon from '@material-ui/icons/Flight';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import OutboundCardItem from './OutboundCardItem';
import WorkOrderCardItem from './WorkOrderCardItem';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
  },
  container: {
    marginTop: '16px',
  },
  welcome: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '150%',
    letterSpacing: '0.085em',
    color: '#000000',
  },
  todayDate: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    color: '#000000',
    opacity: '0.7',
  },

  geCenterPaper: {
    marginTop: '24px',
    borderRadius: '10px',
  },
  messageCenter: {
    height: '710px',
  },
  messageCenterTitle: {
    padding: '24px 27px 0px 27px',
    display: 'inline-block',
    width: '100%',
  },
  messageCenterTitleIcon: {
    verticalAlign: 'middle',
  },
  messageCenterTitleCharacter: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    color: "#2242A1",
    verticalAlign: 'middle',
  },
  messageCenterTitlebtn: {
    float: 'right',
  },
  messageCenterSeparator: {
    margin: '14px 19.5px 0px 19px',
    opacity: 0.1,
    border: '0.5px solid #000000',
  },
}));

export default inject('root')(
  observer(({ root }) => {

    useEffect(() => {
      dashboard.loadData();
    }, []);

    const { dashboard } = root;
    const { dashboardData } = dashboard;
    const classes = useStyles();
    const history = useHistory();

    const setTypeAndRedirect = (type, redirectPath, setType) => {
      setType(type);
      history.push(redirectPath)
    };

    return <>
      <div className={classes.root}>
        <div className={classes.welcome}>
          Good morning, {root.auth.user.name}
        </div>
        <div className={classes.todayDate}>
          {format(new Date(), "'Today is' eeee', 'MM/dd/yyyy'.'")}
        </div>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={3} className={classes.container}>
          <Grid item md={12} lg={8}>
            <Box>
              <Title title="Inbound" icon={<InBoundIcon />} />
              <Paper elevation={0}>
                <CardContainer
                  cards={[
                    <Card
                      title="Today"
                      count={dashboardData.todayInboundTotal}
                      rows={[
                        <InboundCardItem
                          key="1"
                          text="Ocean Shipment"
                          Icon={DirectionsBoatIcon}
                          count={dashboardData.todayInboundTotalOcean}
                          onClick={() => setTypeAndRedirect('today', '/inbound/ocean', root.oceanInbounds.setType)}
                        />,
                        <InboundCardItem
                          key="2"
                          text="Ground Shipment"
                          Icon={LocalShippingIcon}
                          count={dashboardData.todayInboundTotalGround}
                          onClick={() => setTypeAndRedirect('today', '/inbound/ground', root.groundInbounds.setType)}
                        />,
                        <InboundCardItem
                          key="3"
                          text="Air Shipment"
                          Icon={FlightIcon}
                          count={dashboardData.todayInboundTotalAir}
                          onClick={() => setTypeAndRedirect('today', '/inbound/air', root.airInbounds.setType)}
                        />,
                      ]}
                    />,
                    <Card
                      title="Upcoming"
                      count={dashboardData.upcomingInboundTotal}
                      rows={[
                        <InboundCardItem
                          key="1"
                          text="Ocean Shipment"
                          Icon={DirectionsBoatIcon}
                          count={dashboardData.upcomingInboundTotalOcean}
                          onClick={() => setTypeAndRedirect('upcoming', '/inbound/ocean', root.oceanInbounds.setType)}
                        />,
                        <InboundCardItem
                          key="2"
                          text="Ground Shipment"
                          Icon={LocalShippingIcon}
                          count={dashboardData.upcomingInboundTotalGround}
                          onClick={() => setTypeAndRedirect('upcoming', '/inbound/ground', root.groundInbounds.setType)}
                        />,
                        <InboundCardItem
                          key="3"
                          text="Air Shipment"
                          Icon={FlightIcon}
                          count={dashboardData.upcomingInboundTotalAir}
                          onClick={() => setTypeAndRedirect('upcoming', '/inbound/air', root.airInbounds.setType)}
                        />,
                      ]}
                    />,
                    <Card
                      title="Overdue"
                      count={dashboardData.overdueInboundTotal}
                      rows={[
                        <InboundCardItem
                          key="1"
                          text="Ocean Shipment"
                          Icon={DirectionsBoatIcon}
                          count={dashboardData.overdueInboundTotalOcean}
                          onClick={() => setTypeAndRedirect('overdue', '/inbound/ocean', root.oceanInbounds.setType)}
                        />,
                        <InboundCardItem
                          key="2"
                          text="Ground Shipment"
                          Icon={LocalShippingIcon}
                          count={dashboardData.overdueInboundTotalGround}
                          onClick={() => setTypeAndRedirect('overdue', '/inbound/ground', root.groundInbounds.setType)}
                        />,
                        <InboundCardItem
                          key="3"
                          text="Air Shipment"
                          Icon={FlightIcon}
                          count={dashboardData.overdueInboundTotalAir}
                          onClick={() => setTypeAndRedirect('overdue', '/inbound/air', root.airInbounds.setType)}
                        />,
                      ]}
                    />,
                  ]}
                />
              </Paper>
            </Box>
            <Box mt={3}>
              <Title title="Outbound" icon={<OutBoundIcon />} />
              <Paper elevation={0}>
                <CardContainer
                  cards={[
                    <Card
                      title="Today"
                      count={dashboardData.todayOutbounds.length}
                      rows={dashboardData.todayOutbounds}
                      renderRow={(outbound) => <OutboundCardItem key={outbound.id} outbound={outbound} />}
                      onClickViewDetail={() => setTypeAndRedirect('today', '/inbound/ground', root.groundInbounds.setType)}
                    />,
                    <Card
                      title="Upcoming"
                      count={dashboardData.upcomingOutbounds.length}
                      rows={dashboardData.upcomingOutbounds}
                      renderRow={(outbound) => <OutboundCardItem key={outbound.id} outbound={outbound} />}
                      onClickViewDetail={() => setTypeAndRedirect('upcoming', '/inbound/ground', root.groundInbounds.setType)}
                    />,
                    <Card
                      title="Overdue"
                      count={dashboardData.overdueOutbounds.length}
                      rows={dashboardData.overdueOutbounds}
                      renderRow={(outbound) => <OutboundCardItem key={outbound.id} outbound={outbound} />}
                      onClickViewDetail={() => setTypeAndRedirect('overdue', '/inbound/ground', root.groundInbounds.setType)}
                    />,
                  ]}
                />
              </Paper>
            </Box>
            <Box mt={3}>
              <Title title="Work Order" icon={<WorkOrderIcon />} />
              <Paper elevation={0}>
                <CardContainer
                  cards={[
                    <Card
                      title="New"
                      count={dashboardData.newWorkOrders.length}
                      rows={dashboardData.newWorkOrders}
                      renderRow={(workOrder) => <WorkOrderCardItem key={workOrder.id} workOrder={workOrder} />}
                      onClickViewDetail={() => setTypeAndRedirect('new', '/workOrders', root.workOrders.setType)}
                    />,
                    <Card
                      title="In Progress"
                      count={dashboardData.inProgressWorkOrders.length}
                      rows={dashboardData.inProgressWorkOrders}
                      renderRow={(workOrder) => <WorkOrderCardItem key={workOrder.id} workOrder={workOrder} />}
                      onClickViewDetail={() => setTypeAndRedirect('inProgress', '/workOrders', root.workOrders.setType)}
                    />,
                    <Card
                      title="Completed"
                      count={dashboardData.completedWorkOrders.length}
                      rows={dashboardData.completedWorkOrders}
                      renderRow={(workOrder) => <WorkOrderCardItem key={workOrder.id} workOrder={workOrder} />}
                      onClickViewDetail={() => setTypeAndRedirect('completed', '/workOrders', root.workOrders.setType)}
                    />,
                  ]}
                />
              </Paper>
            </Box>
          </Grid>
          <Grid item md={8} lg={4}>
            <Paper className={classes.messageCenterPaper} elevation={0}>
              <div className={classes.messageCenter}>
                <div className={classes.messageCenterTitle}>
                  <ChatIcon className={classes.messageCenterTitleIcon} />
                  <span className={classes.messageCenterTitleCharacter}>&nbsp;Notifications</span>
                  <Button className={classes.messageCenterTitlebtn} size="small" variant="outlined" onClick={() => root.chatRoomNotification.markAllAsRead()} color="primary">Mark All as Read</Button>
                </div>

                <hr className={classes.messageCenterSeparator} />
                <NotificationList></NotificationList>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  })
);
