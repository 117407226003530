import { types, getParent } from 'mobx-state-tree';

const AppStore = types
  .model('AppStore', {
    appLoaded: false,
    timezone: 'America/Los_Angeles',
    isNavabrOpen: false,
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    toggleNavbarOpen() {
      self.isNavabrOpen = !self.isNavabrOpen;
      self.isNavabrOpen ? window.localStorage.setItem('is_navbar_open', 'ok') : window.localStorage.removeItem('is_navbar_open');
    },
    readIsNavbarOpenStorage() {
      self.isNavabrOpen = !!window.localStorage.getItem('is_navbar_open');
    },
    setApploaded(loaded) {
      self.appLoaded = loaded;
    },
    afterAttach() {
      self.readIsNavbarOpenStorage();
      self.root.start();
    },
    confirm(msg) {
      return window.confirm(msg);
    },
  }));

export default AppStore;
