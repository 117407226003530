import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import { Box, TextField } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function Memo({ change_id, value = '', setValue }) {
  const classes = useStyles();
  const [memo, setMemo] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (setValue) {
      setValue(change_id, memo);
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} onClick={handleClick}>
        <DescriptionIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={3}>
          <h3>Memo</h3>
          <TextField
            disabled={!setValue}
            value={memo}
            id="outlined-multiline-static"
            onChange={(e) => { setMemo(e.target.value) }}
            multiline
            rows={4}
            variant="outlined"
          />
        </Box>
      </Popover>
    </div>
  );
}
