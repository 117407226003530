import { types, getParent, flow, applySnapshot } from 'mobx-state-tree';
import { TASK_LIST_TYPES_MAP, TASK_LIST_TYPE_TODAY } from './constants';
import { TypeCountItem, TaskRow } from './models';
import { resolvePaginationStore } from '../PaginationStore';



const TasksStore = types
  .model('TasksStore', {
    type: types.optional(types.enumeration(Object.keys(TASK_LIST_TYPES_MAP)), TASK_LIST_TYPE_TODAY),
    typeCounts: types.frozen(Object.keys(TASK_LIST_TYPES_MAP).map(t => TypeCountItem.create({ value: t, text: TASK_LIST_TYPES_MAP[t] }))),
    collection: types.array(TaskRow),
    pagination: types.optional(
      resolvePaginationStore({
        onLoadMore: (self) => getParent(self).loadMore(),
        onChangePerPage: (self) => getParent(self).loadCollection(),
      }),
      {
        perPage: 20
      },
    ),
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    replaceCollection(data) {
      for (let row of self.collection) {
        if (row.id == data.id) {
          applySnapshot(row, data);
        }
      }
    },
    setType(type) {
      self.type = type;
    },
    loadCounts: flow(function* () {
      try {
        const { data } = yield self.root.api.task.counts()
        self.typeCounts.forEach(item => {
          item.setCount(data.data[item.value]);
        })
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadCollection: flow(function* () {
      try {
        const { data } = yield self.root.api.task.index({ type: self.type, page: self.pagination.page, per_page: self.pagination.perPage });
        self.collection = data.data
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadMore: flow(function* () {
      try {
        const { data } = yield self.root.api.task.index({ type: self.type, page: self.pagination.page, per_page: self.pagination.perPage });
        self.collection.push(...data.data);
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    setTypeAndLoad(type) {
      self.pagination.reset();
      self.setType(type);
      self.loadCollection();
    },
  }));

export default TasksStore;
