import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { observer, PropTypes } from 'mobx-react';
import DocumentBox from './DocumentBox';
import { Document } from '../../../stores/document/models';
import ReactPropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
  },
}));

const DocumentList = observer(({ documents, downloadable = true, deletable = true, onDeleted, ...props }) => {
  const classes = useStyles();
  return <Box className={classes.root}>
    {documents.map(document => <DocumentBox {...props} key={document.id} document={document} downloadable={downloadable} deletable={deletable} onDeleted={onDeleted} />)}
  </Box>
})

DocumentList.prototype = {
  documents: PropTypes.arrayOrObservableArrayOf(Document),
  downloadable: ReactPropTypes.bool,
  deletable: ReactPropTypes.bool,
}

export default DocumentList;
