import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Badge from '../../../common/Badge';
import Table from '../../../ui/Table';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import CollectionText from '../../../common/CollectionText';
import Memo from './Memo';
import { WHSTaskIcon } from '../../../ui/Icon';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const WhsWorkOrdersCollectionText = withStyles(theme => ({
  root: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.5,
    textDecorationLine: 'underline',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))(CollectionText);

const BolNumbersCollectionText = withStyles(theme => ({
  root: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.5,
    textDecorationLine: 'underline',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))(CollectionText);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '36px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
}));

const columns = [
  {
    Header: 'WHS Marking',
    accessor: 'whs_markings', // accessor is the "key" in the data
    Cell: ({ row: { original, } }) => <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box mr={1}>
        {original.is_oversize && <Badge pill color="warning">OS</Badge>}
        {original.is_overweight && <Badge pill color="warning">OW</Badge>}
      </Box>
      <Box mr={1}>{original.has_whs_work_order && <WHSTaskIcon color="primary" style={{ fontSize: '21px' }} />}</Box>
      <Box><CollectionText texts={original.whs_markings} /></Box>
    </Box>,
    Footer: 'Total',
  },
  {
    Header: ({ state: { inbound, classes } }) => <Box className={clsx({ [classes.groupedBy]: inbound.isGroupByDestination })}>Destination</Box>,
    accessor: 'delivery_codes',
    Cell: ({ row: { original, } }) => <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box mr={1}>
        {original.is_oversize && <Badge pill color="warning">OS</Badge>}
        {original.is_overweight && <Badge pill color="warning">OW</Badge>}
      </Box>
      <Box><CollectionText texts={original.delivery_codes} /></Box>
    </Box>,
  },
  {
    Header: 'Weight LB',
    accessor: (original) => original.weight_lb || '-',
  },
  {
    Header: 'CBM',
    accessor: (original) => original.volume_cbm || '-',
  },
  {
    Header: 'Current Qty',
    accessor: (original) => original.current_qty || '-',
  },
  {
    Header: 'WHS PLT',
    accessor: (original) => original.whs_pallet_count === null ? '-' : original.whs_pallet_count,
  },
  {
    Header: 'EST PLT',
    accessor: 'estimate_pallet_count',
    Cell: observer(({ row: { original } }) => original.estimate_pallet_count ? original.estimate_pallet_count.toFixed(2) : '-'),
  },
  {
    Header: 'BOL',
    accessor: 'bols',
    Cell: ({ row: { original }, state: { theme } }) => <>
      {original.bols.length > 0 ? <BolNumbersCollectionText texts={original.bols} taken={1} rowRender={bol => <span>
        <Link to={`/bols/${bol.id}`}>{bol.uid}</Link>
        {bol.actual_pickup_time && !bol.is_pod && <Tooltip title="Fully Shipout"><CheckCircleIcon style={{ fontSize: 18, color: theme.palette.success.main, verticalAlign: 'middle' }} /></Tooltip>}
        {bol.actual_pickup_time && bol.is_pod && <Tooltip title="All POD Uploaded"><CheckCircleIcon style={{ fontSize: 18, color: theme.palette.secondary.main, verticalAlign: 'middle' }} /></Tooltip>}
      </span>
      } /> : '-'}
    </>,
  },
  {
    Header: 'WO',
    accessor: 'work_orders',
    Cell: ({ row: { original }, state: { theme } }) => <>
      {original.has_whs_work_order && original.whs_work_orders.length > 0 ?
        <WhsWorkOrdersCollectionText
          texts={original.whs_work_orders}
          rowRender={
            wo => <Link to={`/workOrders/${wo.id}`}>
              {wo.uid}
              {wo.completed_at && <CheckCircleIcon style={{ fontSize: 18, color: theme.palette.success.main, verticalAlign: 'middle' }} />}
            </Link>
          }
        />
        : '-'}
    </>,
  },
  {
    Header: 'Ready To Ship',
    accessor: 'is_ready_to_ship',
    Cell: ({ row: { original } }) => original.is_ready_to_ship ? 'Yes' : 'HOLD',
  },
  {
    Header: 'Memo',
    accessor: 'memo',
    Cell: ({ row: { original } }) => <>
      <Memo value={original.memo} onChange={original.updateLoadMemo} />
    </>,
  },
];


const WhsLoads = observer(({ inbound }) => {
  const classes = useStyles();
  const { detail } = inbound;
  const theme = useTheme();


  if (!detail.whs_loads.length) {
    return null;
  }

  return <Box tabIndex="0" className={classes.root}>
    <Box className={classes.title}>ADDTINAL GROUP CREATED AFTER INBOUND</Box>
    <Table
      columns={columns}
      data={detail.whs_loads.slice()}
      state={{ detail, classes, inbound, theme }}
    />
  </Box>
})

export default WhsLoads;
