import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Chip from '@material-ui/core/Chip';

const ChatRoomNotification = inject('root')
  (observer(({ root }) => {
    React.useEffect(() => {
      root.chatRoomNotification.loadCollection();
    }, []);

    return <>
      <List>
        {root.chatRoomNotification.unreadCollection.map((row) => (
          <ListItem key={row.chat_room_id}>
            <Link to={row.route ? row.route.toJSON() : null}>
              <ListItemText primary={row.subject_uid} />
            </Link>
            <ListItemSecondaryAction>
              <Chip label={row.count} color="secondary" />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  }))

export default ChatRoomNotification;
