import React from 'react';
import { observer } from 'mobx-react';
import Editable from '../../../common/Editable';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    fontSize: '14px',
    padding: 0,
  },
}));

const ItemEditable = observer(({ value, onChange, disabled = false, resigster = false }) => {
  const classes = useStyles();

  return <Editable
    resigster={resigster}
    value={value}
    onHideEditChange={onChange}
    buttonClassName={classes.button}
  >
    {({ onChange, value }) => <TextField size="small"  inputRef={input => input && input.focus()} variant="outlined" value={value} onChange={e => onChange(e.target.value)} disabled={disabled} />}
  </Editable>
})

export default ItemEditable;
