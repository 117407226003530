import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Select from '../../../ui/Select';
import { observer } from 'mobx-react';


const Filter = observer(({ filter }) => {
  const { released, container_size, releasedOptions, containerSizeOptions, truckers, truckerOptions, is_outbound_fully_pod, outboundFullyPodOptions, renderSelectOption, handleSelect, isSelectedEqualToOption, reset, onSelectSearchFilter } = filter;
  return <Box display="flex" alignItems="flex-end">
    <Box>
      <Select
        title="Release Status"
        options={releasedOptions}
        selected={released.toJSON()}
        renderOption={renderSelectOption}
        onSelect={handleSelect('released')}
        isSelectedEqualToOption={isSelectedEqualToOption}
      />
    </Box>
    <Box ml={1}>
      <Select
        title="CNTR Size"
        options={containerSizeOptions}
        selected={container_size.toJSON()}
        renderOption={renderSelectOption}
        onSelect={handleSelect('container_size')}
        isSelectedEqualToOption={isSelectedEqualToOption}
      />
    </Box>
    <Box ml={1}>
      <Select
        multiple
        searchable
        onSearchFilter={onSelectSearchFilter}
        emptySelectedText="All"
        title="Trucker"
        options={truckerOptions}
        selected={truckers.toJSON()}
        renderOption={renderSelectOption}
        onSelect={handleSelect('truckers')}
        isSelectedEqualToOption={isSelectedEqualToOption}
      />
    </Box>
    <Box ml={1}>
      <Select
        title="Outbound Fully POD"
        options={outboundFullyPodOptions}
        selected={is_outbound_fully_pod.toJSON()}
        renderOption={renderSelectOption}
        onSelect={handleSelect('is_outbound_fully_pod')}
        isSelectedEqualToOption={isSelectedEqualToOption}
      />
    </Box>
    <Box ml={1}>
      <Button variant="outlined" color="primary" onClick={reset}>Reset Filter</Button>
    </Box>
  </Box>
})

export default Filter;
