import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const DashboardIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <rect x="4" y="5" width="6.92308" height="15.2308" rx="0.5" strokeWidth="0.642857" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="13.6923" y="5" width="8.30769" height="5.53846" rx="0.5" strokeWidth="0.642857" strokeLinecap="round" strokeLinejoin="round" />
      <rect x="13.6923" y="13.3077" width="8.30769" height="6.92308" rx="0.5" strokeWidth="0.642857" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export const InBoundIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1877 8.70312L12.8302 12.4302C12.8302 12.4302 13.0476 12.7264 13.2745 12.4605C13.5873 12.0956 15.8363 8.61199 15.8363 8.61199C15.8363 8.61199 16.2368 8.14923 15.7066 8.14923H14.3304V7.46984V4H20.564C20.9888 4 21.3332 4.3198 21.3332 4.71429V15.7857C21.3332 16.1802 20.9888 16.5 20.564 16.5H5.43577C5.01094 16.5 4.66654 16.1802 4.66654 15.7857V4.71429C4.66654 4.3198 5.01094 4 5.43577 4H11.7106V7.50345V8.26016H10.2207C9.78597 8.26016 10.1877 8.70312 10.1877 8.70312ZM3.46154 18.2852H22.5385C22.7934 18.2852 23 18.4771 23 18.7138V22.1424C23 22.3791 22.7934 22.5709 22.5385 22.5709H20.3846C20.1297 22.5709 19.9231 22.3791 19.9231 22.1424V21.5709C19.9231 21.3343 19.7164 21.1424 19.4615 21.1424H6.79487C6.53997 21.1424 6.33333 21.3343 6.33333 21.5709V22.1424C6.33333 22.3791 6.1267 22.5709 5.8718 22.5709H3.46154C3.20664 22.5709 3 22.3791 3 22.1424V18.7138C3 18.4771 3.20664 18.2852 3.46154 18.2852Z" />
    </SvgIcon>
  );
}

export const OutBoundIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.4047 4.52239L15.6301 1.12697C15.6301 1.12697 15.4019 0.860408 15.1635 1.09974C14.8351 1.42816 12.4737 4.52239 12.4737 4.52239C12.4737 4.52239 12.0532 4.97989 12.6098 4.97989H14.0549V5.59134V6.27144H10.4231C10.1469 6.27144 9.92307 6.4953 9.92307 6.77144V15.4638C9.92307 15.7399 10.1469 15.9638 10.4231 15.9638H20.5C20.7761 15.9638 21 15.7399 21 15.4638V6.77144C21 6.4953 20.7761 6.27144 20.5 6.27144H16.8056V5.56109V4.97989H18.3812C18.8377 4.97989 18.4047 4.52239 18.4047 4.52239ZM16.8056 6.27144H14.0549V9.80057C14.0549 9.80057 14.0485 9.99991 14.3057 9.99991L16.5064 9.99991C16.8671 9.99991 16.8056 9.76225 16.8056 9.76225V6.27144ZM3.69231 6.27144C3.30996 6.27144 3 6.5814 3 6.96375V8.34837C3 8.73072 3.30996 9.04067 3.69231 9.04067H5.76923L5.76923 17.3484V19.4253C5.76923 19.8076 6.07919 20.1176 6.46154 20.1176H20.3077C20.69 20.1176 21 19.8076 21 19.4253V18.0407C21 17.6583 20.69 17.3484 20.3077 17.3484H8.53846V9.04067V6.96375C8.53846 6.5814 8.2285 6.27144 7.84615 6.27144H3.69231ZM9.92307 22.8868C9.92307 24.0339 8.9932 24.9638 7.84614 24.9638C6.69909 24.9638 5.76922 24.0339 5.76922 22.8868C5.76922 21.7398 6.69909 20.8099 7.84614 20.8099C8.9932 20.8099 9.92307 21.7398 9.92307 22.8868ZM18.9231 24.9638C20.0701 24.9638 21 24.0339 21 22.8868C21 21.7398 20.0701 20.8099 18.9231 20.8099C17.776 20.8099 16.8461 21.7398 16.8461 22.8868C16.8461 24.0339 17.776 24.9638 18.9231 24.9638Z" />
    </SvgIcon>
  );
}

export const WHSTaskIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <rect x="3" y="5" width="18.9768" height="14.2326" rx="1" />
      <circle cx="19.0571" cy="16.6782" r="5.83903" fill="white" />
      <path d="M21.7409 18.6536C21.5456 18.4584 21.229 18.4584 21.0338 18.6536C20.8385 18.8489 20.8385 19.1655 21.0338 19.3607L21.7409 18.6536ZM22.5821 20.909C22.7774 21.1043 23.0939 21.1043 23.2892 20.909C23.4845 20.7138 23.4845 20.3972 23.2892 20.2019L22.5821 20.909ZM21.0338 19.3607L22.5821 20.909L23.2892 20.2019L21.7409 18.6536L21.0338 19.3607ZM20.5177 14.7158C21.4626 15.6607 21.4626 17.1926 20.5177 18.1375L21.2248 18.8446C22.5602 17.5092 22.5602 15.3441 21.2248 14.0087L20.5177 14.7158ZM20.5177 18.1375C19.5728 19.0824 18.0408 19.0824 17.096 18.1375L16.3889 18.8446C17.7243 20.18 19.8894 20.18 21.2248 18.8446L20.5177 18.1375ZM17.096 18.1375C16.1511 17.1926 16.1511 15.6607 17.096 14.7158L16.3889 14.0087C15.0535 15.3441 15.0535 17.5092 16.3889 18.8446L17.096 18.1375ZM17.096 14.7158C18.0408 13.7709 19.5728 13.7709 20.5177 14.7158L21.2248 14.0087C19.8894 12.6733 17.7243 12.6733 16.3889 14.0087L17.096 14.7158Z" />
      <path d="M8.83914 8.64929H16.1379" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export const InventoryIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <rect x="9.14294" y="15.5718" width="7.71429" height="5.14286" rx="0.5" />
      <path d="M4.41876 10.1295L12.4188 4.41517C12.7665 4.16681 13.2335 4.16682 13.5812 4.41517L21.5812 10.1295C21.844 10.3172 22 10.6202 22 10.9432V20.2143C22 20.4904 21.7761 20.7143 21.5 20.7143H18.6429C18.3667 20.7143 18.1429 20.4904 18.1429 20.2143V15.0824C18.1429 14.8063 17.919 14.5824 17.6429 14.5824H8.35714C8.081 14.5824 7.85714 14.8063 7.85714 15.0824V20.2143C7.85714 20.4904 7.63328 20.7143 7.35714 20.7143H4.5C4.22386 20.7143 4 20.4904 4 20.2143V10.9432C4 10.6202 4.15597 10.3172 4.41876 10.1295Z" />
    </SvgIcon>
  );
}

export const ProfileIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path d="M13 1C11.8924 1 10.8097 1.32843 9.88881 1.94377C8.96789 2.55911 8.25013 3.43371 7.82628 4.45697C7.40243 5.48024 7.29153 6.60621 7.5076 7.6925C7.72368 8.7788 8.25703 9.77662 9.0402 10.5598C9.82338 11.343 10.8212 11.8763 11.9075 12.0924C12.9938 12.3085 14.1198 12.1976 15.143 11.7737C16.1663 11.3499 17.0409 10.6321 17.6562 9.71119C18.2716 8.79028 18.6 7.70757 18.6 6.6C18.6 5.11479 18.01 3.69041 16.9598 2.6402C15.9096 1.59 14.4852 1 13 1Z" />
      <path d="M21 23.4V19.4C21 17.9148 20.41 16.4904 19.3598 15.4402C18.3096 14.39 16.8852 13.8 15.4 13.8H10.6C9.11479 13.8 7.69041 14.39 6.6402 15.4402C5.59 16.4904 5 17.9148 5 19.4V23.4H21Z" />
    </SvgIcon>
  );
}

export const ChevronRightIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 8 15" {...props}>
      <path d="M7.72761 8.29663L2.46362 14.6696C2.09979 15.1101 1.51146 15.1101 1.1515 14.6696L0.276746 13.6106C-0.0870878 13.1701 -0.0870879 12.4578 0.276746 12.022L4.00411 7.5L0.272874 2.98266C-0.0909597 2.54218 -0.0909597 1.8299 0.272874 1.3941L1.14762 0.330367C1.51146 -0.11012 2.09979 -0.11012 2.45975 0.330367L7.72373 6.70337C8.09144 7.14386 8.09144 7.85614 7.72761 8.29663Z" />
    </SvgIcon>
  )
}

export const ChevronLeftIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 8 15" {...props}>
      <path d="M0.272394 6.70337L5.53638 0.330366C5.90021 -0.110122 6.48854 -0.110122 6.8485 0.330366L7.72325 1.38941C8.08709 1.8299 8.08709 2.54217 7.72325 2.97798L3.99589 7.5L7.72712 12.0173C8.09096 12.4578 8.09096 13.1701 7.72712 13.6059L6.85237 14.6696C6.48854 15.1101 5.90021 15.1101 5.54025 14.6696L0.276264 8.29663C-0.0914405 7.85614 -0.0914404 7.14386 0.272394 6.70337Z" />
    </SvgIcon>
  )
}

export const LeftArrowIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" />
    </SvgIcon>
  )
}

export const UploadIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path d="M12.6292 11.4132C12.6139 11.3936 12.5943 11.3777 12.5719 11.3668C12.5495 11.3559 12.5249 11.3503 12.5 11.3503C12.4751 11.3503 12.4506 11.3559 12.4282 11.3668C12.4058 11.3777 12.3862 11.3936 12.3708 11.4132L10.074 14.3192C10.055 14.3434 10.0433 14.3724 10.0401 14.4029C10.0368 14.4335 10.0423 14.4643 10.0558 14.4919C10.0692 14.5195 10.0902 14.5427 10.1163 14.559C10.1423 14.5752 10.1724 14.5838 10.2032 14.5837H11.7187V19.5548C11.7187 19.645 11.7925 19.7189 11.8827 19.7189H13.1132C13.2035 19.7189 13.2773 19.645 13.2773 19.5548V14.5858H14.7969C14.9343 14.5858 15.0102 14.4279 14.9261 14.3212L12.6292 11.4132Z" />
      <path d="M18.64 9.52022C17.7008 7.04287 15.3075 5.28125 12.5041 5.28125C9.70068 5.28125 7.30742 7.04082 6.36816 9.51816C4.61064 9.97959 3.3125 11.5812 3.3125 13.4844C3.3125 15.7505 5.14795 17.5859 7.41201 17.5859H8.23438C8.32461 17.5859 8.39844 17.5121 8.39844 17.4219V16.1914C8.39844 16.1012 8.32461 16.0273 8.23438 16.0273H7.41201C6.7209 16.0273 6.0708 15.7525 5.58682 15.2542C5.10488 14.7579 4.84854 14.0894 4.87109 13.3962C4.88955 12.8548 5.07412 12.3462 5.4084 11.9176C5.75088 11.4808 6.23076 11.1629 6.76396 11.0214L7.54121 10.8184L7.82627 10.0678C8.00264 9.6002 8.24873 9.16338 8.5584 8.76758C8.86411 8.37528 9.22624 8.03042 9.63301 7.74424C10.4759 7.15156 11.4685 6.83779 12.5041 6.83779C13.5397 6.83779 14.5323 7.15156 15.3752 7.74424C15.7833 8.03135 16.1442 8.37588 16.4498 8.76758C16.7595 9.16338 17.0056 9.60225 17.1819 10.0678L17.4649 10.8163L18.2401 11.0214C19.3517 11.3208 20.1289 12.3318 20.1289 13.4844C20.1289 14.1632 19.8644 14.803 19.3845 15.2829C19.1491 15.5196 18.8692 15.7073 18.5608 15.8351C18.2524 15.9629 17.9218 16.0282 17.588 16.0273H16.7656C16.6754 16.0273 16.6016 16.1012 16.6016 16.1914V17.4219C16.6016 17.5121 16.6754 17.5859 16.7656 17.5859H17.588C19.8521 17.5859 21.6875 15.7505 21.6875 13.4844C21.6875 11.5833 20.3935 9.98369 18.64 9.52022Z" />
    </SvgIcon>
  )
}

export const DocumentsIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <g clipPath="url(#documentclip0)">
        <path d="M18.0711 10.8025H11.7178L11.2548 7.64902C11.1473 6.9169 10.4539 6.31788 9.71396 6.31788H4.16416C3.42419 6.31788 2.9067 6.9169 3.0142 7.64902L4.7808 19.6822H21.262L19.6903 12.1198C19.5397 11.3953 18.8111 10.8025 18.0711 10.8025Z" />
        <path d="M21.8517 7.66328H12.6169L12.8802 9.45712H18.0712C18.7374 9.45712 19.405 9.70171 19.9509 10.1458C20.4968 10.59 20.8721 11.1938 21.0076 11.846L21.862 15.9566L22.9832 8.99161C23.1009 8.26105 22.5917 7.66328 21.8517 7.66328Z" />
      </g>
      <defs>
        <clipPath id="documentclip0">
          <rect width="20" height="20" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export const DocumentIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path d="M20.3605 8.20254C20.4895 8.33145 20.5625 8.50547 20.5625 8.68809V21.9375C20.5625 22.3178 20.2553 22.625 19.875 22.625H6.125C5.74473 22.625 5.4375 22.3178 5.4375 21.9375V4.0625C5.4375 3.68223 5.74473 3.375 6.125 3.375H15.2494C15.432 3.375 15.6082 3.44805 15.7371 3.57695L20.3605 8.20254ZM18.977 9.00391L14.9336 4.96055V9.00391H18.977Z" />
    </SvgIcon>
  )
}

export const DeleteIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path d="M11 11H12V17H11V11Z" />
      <path d="M14 11H15V17H14V11Z" />
      <path d="M7.5 8C7.22386 8 7 8.22386 7 8.5C7 8.77614 7.22386 9 7.5 9C7.77614 9 8 9.22386 8 9.5V19C8 19.2652 8.10536 19.5196 8.29289 19.7071C8.48043 19.8946 8.73478 20 9 20H17C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19V9.5C18 9.22386 18.2239 9 18.5 9C18.7761 9 19 8.77614 19 8.5C19 8.22386 18.7761 8 18.5 8H7.5ZM9.5 19C9.22386 19 9 18.7761 9 18.5V9.5C9 9.22386 9.22386 9 9.5 9H16.5C16.7761 9 17 9.22386 17 9.5V18.5C17 18.7761 16.7761 19 16.5 19H9.5Z" />
      <path d="M11 6.5C11 6.22386 11.2239 6 11.5 6H14.5C14.7761 6 15 6.22386 15 6.5C15 6.77614 14.7761 7 14.5 7H11.5C11.2239 7 11 6.77614 11 6.5Z" />
    </SvgIcon>
  )
}

export const DownloadIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.33334 17.6667H17.6667C17.8435 17.6667 18.0131 17.7369 18.1381 17.8619C18.2631 17.987 18.3333 18.1565 18.3333 18.3333C18.3333 18.5101 18.2631 18.6797 18.1381 18.8047C18.0131 18.9298 17.8435 19 17.6667 19H8.33334C8.15653 19 7.98696 18.9298 7.86193 18.8047C7.73691 18.6797 7.66667 18.5101 7.66667 18.3333C7.66667 18.1565 7.73691 17.987 7.86193 17.8619C7.98696 17.7369 8.15653 17.6667 8.33334 17.6667ZM13.6667 12.5765C13.6667 13.0219 14.2052 13.245 14.5202 12.9301L15.4751 11.9754C15.6704 11.7802 15.9869 11.7803 16.1822 11.9755L16.4178 12.2111C16.613 12.4064 16.613 12.723 16.4178 12.9182L13.3536 15.9824C13.1583 16.1777 12.8417 16.1777 12.6465 15.9824L9.58223 12.9182C9.38696 12.723 9.38696 12.4064 9.58222 12.2111L9.81784 11.9755C10.0131 11.7803 10.3296 11.7802 10.5249 11.9754L11.4798 12.9301C11.7948 13.245 12.3333 13.0219 12.3333 12.5765V6.83333C12.3333 6.55719 12.5572 6.33333 12.8333 6.33333H13.1667C13.4428 6.33333 13.6667 6.55719 13.6667 6.83333V12.5765Z" />
    </SvgIcon>
  )
}

export const FlagIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path d="M11 8.5V19" stroke="#2E52C5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 19L12.75 19" stroke="#2E52C5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.0172 10.5461C17.4044 10.7249 17.4044 11.2751 17.0172 11.4539L11.2097 14.1363C10.8783 14.2894 10.5 14.0474 10.5 13.6824L10.5 8.31759C10.5 7.95264 10.8783 7.71064 11.2097 7.86367L17.0172 10.5461Z" />
    </SvgIcon>
  )
}

export const MemoActiveIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path d="M0 12.987H10V10.8225H0V12.987ZM0 0V2.1645H15V0H0ZM0 7.57576H15V5.41126H0V7.57576Z" fill="#2E52C5" />
    </SvgIcon>
  )
}

export const MemoInActiveIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path d="M0 12.987H10V10.8225H0V12.987ZM0 0V2.1645H15V0H0ZM0 7.57576H15V5.41126H0V7.57576Z" fill="#80889A" />
    </SvgIcon>
  )
}

export const EditIcon = React.forwardRef((props, ref) => {
  return (
    <SvgIcon viewBox="0 0 15 15" ref={ref} {...props}>
      <g clipPath="url(#editiconclip0)">
        <path d="M14.3596 0.735048L14.3416 0.719121C13.9963 0.414096 13.5488 0.246094 13.0814 0.246094C12.5574 0.246094 12.0544 0.46063 11.7011 0.834644L5.02188 7.90731C4.961 7.97177 4.91483 8.04782 4.88628 8.13062L4.1009 10.4079C4.0101 10.6712 4.05567 10.9623 4.22279 11.1867C4.39124 11.4129 4.66262 11.5479 4.94879 11.5479C5.07257 11.5479 5.1936 11.5233 5.30836 11.4747L7.58233 10.5125C7.66501 10.4775 7.73918 10.4262 7.80001 10.3617L14.4793 3.28911C15.1743 2.55318 15.1207 1.40752 14.3596 0.735048ZM5.60566 9.98722L6.06652 8.65093L6.10539 8.60976L6.97886 9.38141L6.93999 9.42258L5.60566 9.98722ZM13.527 2.44772L7.84877 8.46035L6.97529 7.6887L12.6535 1.67603C12.7645 1.55843 12.9165 1.49364 13.0814 1.49364C13.2261 1.49364 13.3647 1.5457 13.4719 1.64043L13.4899 1.65636C13.7257 1.86466 13.7423 2.21967 13.527 2.44772Z" />
        <path d="M13.0638 6.00096C12.7077 6.00096 12.4189 6.28024 12.4189 6.62473V11.9204C12.4189 12.7951 11.6832 13.5068 10.7788 13.5068H2.96247C2.05809 13.5068 1.32237 12.7951 1.32237 11.9204V4.42177C1.32237 3.54708 2.05813 2.83544 2.96247 2.83544H8.61998C8.97615 2.83544 9.2649 2.55615 9.2649 2.21166C9.2649 1.86717 8.97615 1.58789 8.61998 1.58789H2.96247C1.34687 1.58789 0.0325317 2.85918 0.0325317 4.42177V11.9204C0.0325317 13.483 1.34691 14.7543 2.96247 14.7543H10.7788C12.3943 14.7543 13.7087 13.483 13.7087 11.9204V6.62473C13.7088 6.28024 13.42 6.00096 13.0638 6.00096Z" />
      </g>
      <defs>
        <clipPath id="editiconclip0">
          <rect width="15" height="14.5082" fill="white" transform="translate(0 0.246094)" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
})

export const SearchIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path d="M20.2583 19.075L17.425 16.25C18.3392 15.0854 18.8352 13.6472 18.8333 12.1667C18.8333 10.8481 18.4423 9.5592 17.7098 8.46287C16.9773 7.36654 15.9361 6.51206 14.7179 6.00747C13.4997 5.50289 12.1593 5.37087 10.8661 5.6281C9.57286 5.88534 8.38497 6.52027 7.45262 7.45262C6.52027 8.38497 5.88534 9.57286 5.6281 10.8661C5.37087 12.1593 5.50289 13.4997 6.00747 14.7179C6.51206 15.9361 7.36654 16.9773 8.46287 17.7098C9.5592 18.4423 10.8481 18.8333 12.1667 18.8333C13.6472 18.8352 15.0854 18.3392 16.25 17.425L19.075 20.2583C19.1525 20.3364 19.2446 20.3984 19.3462 20.4407C19.4477 20.4831 19.5567 20.5048 19.6667 20.5048C19.7767 20.5048 19.8856 20.4831 19.9872 20.4407C20.0887 20.3984 20.1809 20.3364 20.2583 20.2583C20.3364 20.1809 20.3984 20.0887 20.4407 19.9872C20.4831 19.8856 20.5048 19.7767 20.5048 19.6667C20.5048 19.5567 20.4831 19.4477 20.4407 19.3462C20.3984 19.2446 20.3364 19.1525 20.2583 19.075ZM7.16667 12.1667C7.16667 11.1778 7.45991 10.2111 8.00932 9.38882C8.55873 8.56657 9.33962 7.92571 10.2533 7.54727C11.1669 7.16883 12.1722 7.06982 13.1421 7.26274C14.112 7.45567 15.0029 7.93187 15.7022 8.63114C16.4015 9.3304 16.8777 10.2213 17.0706 11.1912C17.2635 12.1611 17.1645 13.1665 16.7861 14.0801C16.4076 14.9937 15.7668 15.7746 14.9445 16.324C14.1223 16.8734 13.1556 17.1667 12.1667 17.1667C10.8406 17.1667 9.56882 16.6399 8.63114 15.7022C7.69345 14.7645 7.16667 13.4928 7.16667 12.1667Z" />
    </SvgIcon>
  )
}

export const BangIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M7.25 10.25H8.75V11.75H7.25V10.25ZM7.25 4.24997H8.75V8.74997H7.25V4.24997ZM7.9925 0.499969C3.8525 0.499969 0.5 3.85997 0.5 7.99997C0.5 12.14 3.8525 15.5 7.9925 15.5C12.14 15.5 15.5 12.14 15.5 7.99997C15.5 3.85997 12.14 0.499969 7.9925 0.499969ZM8 14C4.685 14 2 11.315 2 7.99997C2 4.68497 4.685 1.99997 8 1.99997C11.315 1.99997 14 4.68497 14 7.99997C14 11.315 11.315 14 8 14Z" fill="#2242A1" />
    </SvgIcon>
  )
}

export const ChatIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 31 31" {...props}>
      <path d="M29.7934 9.49377C29.0155 7.64113 27.8838 5.9572 26.4614 4.53585C25.0433 3.11203 23.3588 1.98 21.5034 1.20405C19.6028 0.405286 17.5607 -0.00415991 15.4984 3.18626e-05H15.429C13.3314 0.0104113 11.3032 0.425589 9.39276 1.24211C7.55335 2.02601 5.88462 3.16005 4.47984 4.58083C3.07125 5.99899 1.95255 7.67702 1.18607 9.52145C0.390411 11.4394 -0.0127497 13.4969 0.000307297 15.5727C0.0107087 17.9738 0.586252 20.3576 1.66106 22.4888V27.7478C1.66106 28.6266 2.37529 29.3393 3.25248 29.3393H8.51558C10.6613 30.4197 13.0291 30.9882 15.4325 31H15.5053C17.5787 31 19.5861 30.5987 21.4792 29.8133C23.3251 29.0466 25.0034 27.9278 26.4198 26.5195C27.8483 25.1045 28.9716 23.4507 29.7587 21.6066C30.5735 19.6968 30.9895 17.6659 30.9999 15.5692C31.0068 13.4622 30.5977 11.4174 29.7934 9.49377ZM8.57799 17.1607C7.66266 17.1607 6.91723 16.4169 6.91723 15.5C6.91723 14.5832 7.66266 13.8393 8.57799 13.8393C9.49331 13.8393 10.2387 14.5832 10.2387 15.5C10.2387 16.4169 9.49678 17.1607 8.57799 17.1607ZM15.4984 17.1607C14.5831 17.1607 13.8376 16.4169 13.8376 15.5C13.8376 14.5832 14.5831 13.8393 15.4984 13.8393C16.4137 13.8393 17.1591 14.5832 17.1591 15.5C17.1591 16.4169 16.4137 17.1607 15.4984 17.1607ZM22.4188 17.1607C21.5034 17.1607 20.758 16.4169 20.758 15.5C20.758 14.5832 21.5034 13.8393 22.4188 13.8393C23.3341 13.8393 24.0795 14.5832 24.0795 15.5C24.0795 16.4169 23.3341 17.1607 22.4188 17.1607Z" fill="#2242A1" />
    </SvgIcon>
  )
}

export const FolderIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 18 15" {...props}>
      <path d="M1 12.4859V2.22C1 1.54621 1.54621 1 2.22 1H5.67113C5.99469 1 6.30501 1.12854 6.5338 1.35733L8.17208 2.99561C8.40088 3.22441 8.71119 3.35294 9.03475 3.35294H15.78C16.4538 3.35294 17 3.89915 17 4.57294V12.4859C17 13.1597 16.4538 13.7059 15.78 13.7059H2.22C1.54621 13.7059 1 13.1597 1 12.4859Z" fill="#F8F9FB" stroke="#2E52C5" strokeWidth="1.2" />
    </SvgIcon>
  )
}

export const SignOutIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path d="M6.22224 5.33332H20.4445V14.0444H22.2222V5.33332C22.2222 4.86182 22.0349 4.40964 21.7015 4.07624C21.3681 3.74284 20.916 3.55554 20.4445 3.55554H6.22224C5.75074 3.55554 5.29855 3.74284 4.96516 4.07624C4.63176 4.40964 4.44446 4.86182 4.44446 5.33332V26.6667C4.44446 27.1381 4.63176 27.5903 4.96516 27.9237C5.29855 28.2571 5.75074 28.4444 6.22224 28.4444H20.4445C20.916 28.4444 21.3681 28.2571 21.7015 27.9237C22.0349 27.5903 22.2222 27.1381 22.2222 26.6667H6.22224V5.33332Z" />
      <path d="M25.0311 15.36C24.8611 15.2144 24.6423 15.1383 24.4186 15.1469C24.1949 15.1555 23.9827 15.2483 23.8244 15.4066C23.6661 15.5649 23.5734 15.7771 23.5647 16.0008C23.5561 16.2245 23.6322 16.4433 23.7778 16.6133L26.7822 19.5555H13.8933C13.6576 19.5555 13.4315 19.6492 13.2648 19.8159C13.0981 19.9826 13.0045 20.2087 13.0045 20.4444C13.0045 20.6802 13.0981 20.9063 13.2648 21.073C13.4315 21.2397 13.6576 21.3333 13.8933 21.3333H26.7822L23.7778 24.4089C23.6847 24.4886 23.6092 24.5866 23.5558 24.6969C23.5025 24.8072 23.4725 24.9273 23.4677 25.0497C23.463 25.1721 23.4836 25.2942 23.5283 25.4083C23.573 25.5223 23.6408 25.6259 23.7274 25.7126C23.8141 25.7992 23.9177 25.867 24.0317 25.9117C24.1458 25.9563 24.2679 25.977 24.3903 25.9722C24.5127 25.9675 24.6328 25.9375 24.7431 25.8842C24.8534 25.8308 24.9514 25.7553 25.0311 25.6622L30.2222 20.5066L25.0311 15.36Z" />
    </SvgIcon>
  )
}

export const GroupByIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 14 15" {...props}>
      <path d="M12.75 0H0.75C0.551088 0 0.360322 0.0790176 0.21967 0.21967C0.0790178 0.360322 0 0.551088 0 0.75V2.6925C0 3.08475 0.15975 3.47025 0.43725 3.74775L4.5 7.8105V13.5C4.50015 13.6278 4.53291 13.7534 4.59517 13.865C4.65744 13.9766 4.74716 14.0704 4.85583 14.1376C4.9645 14.2048 5.08853 14.2432 5.21618 14.2491C5.34382 14.255 5.47085 14.2282 5.58525 14.1713L8.58525 12.6713C8.8395 12.5438 9 12.2843 9 12V7.8105L13.0627 3.74775C13.3403 3.47025 13.5 3.08475 13.5 2.6925V0.75C13.5 0.551088 13.421 0.360322 13.2803 0.21967C13.1397 0.0790176 12.9489 0 12.75 0ZM7.71975 6.96975C7.64997 7.03928 7.59463 7.12192 7.55691 7.21293C7.5192 7.30393 7.49986 7.40149 7.5 7.5V11.5365L6 12.2865V7.5C6.00014 7.40149 5.9808 7.30393 5.94309 7.21293C5.90537 7.12192 5.85003 7.03928 5.78025 6.96975L1.5 2.6925V1.5H12.0007L12.0023 2.68725L7.71975 6.96975Z" />
    </SvgIcon>
  )
}

export const ArrowDropDownFillIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M12 10L16 14L8 14L12 10Z" />
    </SvgIcon>
  )
}

export const InformationIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 12 12" {...props}>
      <path d="M6 0.375C2.8935 0.375 0.375 2.8935 0.375 6C0.375 9.1065 2.8935 11.625 6 11.625C9.1065 11.625 11.625 9.1065 11.625 6C11.625 2.8935 9.1065 0.375 6 0.375ZM6.9375 9.70312H5.0625V5.20312H6.9375V9.70312ZM6 4.17188C5.75136 4.17188 5.5129 4.0731 5.33709 3.89729C5.16127 3.72147 5.0625 3.48302 5.0625 3.23438C5.0625 2.98573 5.16127 2.74728 5.33709 2.57146C5.5129 2.39565 5.75136 2.29688 6 2.29688C6.24864 2.29688 6.4871 2.39565 6.66291 2.57146C6.83873 2.74728 6.9375 2.98573 6.9375 3.23438C6.9375 3.48302 6.83873 3.72147 6.66291 3.89729C6.4871 4.0731 6.24864 4.17188 6 4.17188Z" />
    </SvgIcon>
  )
}

export const DuplicateIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M13.9999 22H3.99995C3.46402 22.0186 2.94442 21.8139 2.56524 21.4347C2.18605 21.0555 1.98132 20.5359 1.99995 20V10C1.98132 9.46408 2.18605 8.94448 2.56524 8.5653C2.94442 8.18611 3.46402 7.98138 3.99995 8.00001H7.99995V4.00001C7.98132 3.46408 8.18605 2.94448 8.56524 2.5653C8.94442 2.18611 9.46402 1.98138 9.99995 2.00001H19.9999C20.5359 1.98138 21.0555 2.18611 21.4347 2.5653C21.8138 2.94448 22.0186 3.46408 21.9999 4.00001V14C22.0183 14.5358 21.8134 15.0553 21.4343 15.4344C21.0552 15.8135 20.5358 16.0183 19.9999 16H15.9999V20C16.0183 20.5358 15.8134 21.0553 15.4343 21.4344C15.0552 21.8135 14.5358 22.0183 13.9999 22ZM3.99995 10V20H13.9999V16H9.99995C9.46411 16.0183 8.94468 15.8135 8.56557 15.4344C8.18645 15.0553 7.98162 14.5358 7.99995 14V10H3.99995ZM9.99995 4.00001V14H19.9999V4.00001H9.99995Z" />
    </SvgIcon>
  )
}

export const TrashIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M17 22H7C5.89543 22 5 21.1046 5 20V7H3V5H7V4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V5H21V7H19V20C19 21.1046 18.1046 22 17 22ZM7 7V20H17V7H7ZM9 4V5H15V4H9ZM15 18H13V9H15V18ZM11 18H9V9H11V18Z" />
    </SvgIcon>
  )
}

export const WorkOrderIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M6 4V8H18V4H20.007C20.555 4 21 4.445 21 4.993V21.007C21 21.555 20.555 22 20.007 22H3.993C3.445 22 3 21.555 3 21.007V4.993C3 4.445 3.445 4 3.993 4H6ZM9 17H7V19H9V17ZM9 14H7V16H9V14ZM9 11H7V13H9V11ZM16 2V6H8V2H16Z" />
    </SvgIcon>
  )
}


export const PersonSearch = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M10.7546 13.9764V15.665C9.41104 15.665 8.12249 16.1988 7.17244 17.1488C6.22239 18.0989 5.68865 19.3874 5.68865 20.731H4C4 18.9396 4.71165 17.2215 5.97838 15.9548C7.24512 14.688 8.96318 13.9764 10.7546 13.9764ZM10.7546 13.132C7.95567 13.132 5.68865 10.865 5.68865 8.06608C5.68865 5.26714 7.95567 3.00012 10.7546 3.00012C13.5536 3.00012 15.8206 5.26714 15.8206 8.06608C15.8206 10.865 13.5536 13.132 10.7546 13.132ZM10.7546 11.4434C12.6206 11.4434 14.1319 9.93205 14.1319 8.06608C14.1319 6.20012 12.6206 4.68878 10.7546 4.68878C8.88865 4.68878 7.37731 6.20012 7.37731 8.06608C7.37731 9.93205 8.88865 11.4434 10.7546 11.4434ZM18.7301 19.0694L20 20.3392L18.8061 21.5331L17.5363 20.2632C16.8282 20.6807 15.9921 20.8257 15.1848 20.6708C14.3775 20.516 13.6543 20.0721 13.1509 19.4222C12.6475 18.7724 12.3984 17.9612 12.4503 17.1408C12.5022 16.3204 12.8515 15.5471 13.4328 14.9659C14.014 14.3846 14.7873 14.0353 15.6077 13.9834C16.4281 13.9315 17.2392 14.1806 17.8891 14.684C18.539 15.1874 18.9829 15.9106 19.1377 16.7179C19.2926 17.5252 19.1476 18.3613 18.7301 19.0694ZM15.8206 19.0423C16.2684 19.0423 16.698 18.8644 17.0146 18.5477C17.3313 18.2311 17.5092 17.8015 17.5092 17.3537C17.5092 16.9058 17.3313 16.4763 17.0146 16.1596C16.698 15.8429 16.2684 15.665 15.8206 15.665C15.3727 15.665 14.9432 15.8429 14.6265 16.1596C14.3098 16.4763 14.1319 16.9058 14.1319 17.3537C14.1319 17.8015 14.3098 18.2311 14.6265 18.5477C14.9432 18.8644 15.3727 19.0423 15.8206 19.0423Z" fill="#80889A" />
    </SvgIcon>
  )
}




