import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Documents from './Documents';
import Remark from './Remark';
import { observer } from 'mobx-react';

const Footer = observer(({ inbound }) => {
  const { detail, whsDocuments, adminDocuments } = inbound;
  return <Box mt={5}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Documents whsDocuments={whsDocuments} adminDocuments={adminDocuments} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Remark detail={detail} />
      </Grid>
    </Grid>
  </Box>
})

export default Footer;
