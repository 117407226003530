import { createMuiTheme, } from '@material-ui/core/styles';
import { green, yellow, red } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';


const LIGHT_GRAY_COLOR = '#FAFAFA';
const BLUE_GRAY_COLOR = '#F8F9FB';
const PRIMARY_COLOR = '#2E52C5';
const SECONDARY_COLOR = '#80889A';
const DARK_COLOR = '#001235';
const RED_COLOR = '#EB5757';

export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    dark: {
      main: DARK_COLOR,
    },
    blueGray: {
      main: BLUE_GRAY_COLOR,
    },
    lightGray: {
      main: LIGHT_GRAY_COLOR,
    },
    success: {
      main: green[500],
    },
    danger: {
      main: red[500],
    },
    warning: {
      main: yellow[700],
    },
    red: {
      main: RED_COLOR,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          color: PRIMARY_COLOR,
        },
        '*::-webkit-scrollbar': {
          width: '5px',
        },
        '*::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#888',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
        '*': {
          '-ms-overflow-style': 'none', /* IE and Edge */
          'scrollbar-width': '5px',  /* Firefox */
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'center',
        borderRadius: '3px',
        padding: '7px 30px 7px 30px',
        boxShadow: 'none',
        textTransform: 'none',
        borderWidth: '1.5px',
      },
      sizeSmall: {
        fontSize: '12px',
        lineHeight: '18px',
        borderRadius: '3px',
        padding: '4px 27px 4px 27px',
      },
      contained: {
        boxShadow: 'none',

        '&:focus': {
          boxShadow: 'none',
        },
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        border: '1.5px solid ' + PRIMARY_COLOR,
      },
      containedDanger: {
        border: '1.5px solid ' + RED_COLOR,
      },
      containedSecondary: {
        border: '1.5px solid ' + SECONDARY_COLOR,
      },
      containedSizeSmall: {
        padding: '4px 27px 4px 27px',

        '&:focus': {
          padding: '4px 27px 4px 27px',
          boxShadow: 'none',
        },
        '&:hover': {
          padding: '4px 27px 4px 27px',
          boxShadow: 'none',
        },
      },
      outlined: {
        padding: '7px 30px 7px 30px',
        borderWidth: '1.5px',

        '&:focus': {
          padding: '7px 30px 7px 30px',
          boxShadow: 'none',
        },
        '&:hover': {
          padding: '7px 30px 7px 30px',
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        border: '1.5px solid ' + PRIMARY_COLOR,

        '&:focus': {
          border: '1.5px solid ' + PRIMARY_COLOR,
        },
        '&:hover': {
          border: '1.5px solid ' + PRIMARY_COLOR,
        },
      },
      outlinedDanger: {
        border: '1.5px solid ' + RED_COLOR,

        '&:focus': {
          border: '1.5px solid ' + RED_COLOR,
        },
        '&:hover': {
          border: '1.5px solid ' + RED_COLOR,
        },
      },
      outlinedSecondary: {
        border: '1.5px solid ' + SECONDARY_COLOR,

        '&:focus': {
          border: '1.5px solid ' + SECONDARY_COLOR,
        },
        '&:hover': {
          border: '1.5px solid ' + SECONDARY_COLOR,
        },
      },
      outlinedSizeSmall: {
        padding: '4px 27px 4px 27px',

        '&:focus': {
          padding: '4px 27px 4px 27px',
          boxShadow: 'none',
        },
        '&:hover': {
          padding: '4px 27px 4px 27px',
          boxShadow: 'none',
        },
      },
    },
  },
});


