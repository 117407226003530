import React, { useEffect } from 'react';
import clsx from 'clsx';
import Table from '../../../ui/Table';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import ShowMore from './ShowMore';
import Tooltip from '@material-ui/core/Tooltip';
import CollectionText from '../../../common/CollectionText';
import Print from './Print';
import ConfirmShipout from './ConfirmShipout';
import Remark from './Remark';
import SortBy from '../../../ui/SortBy';
import ChatRoom from '../../../common/ChatRoom';
import { OUTBOUND_LIST_TYPE_ALL } from '../../../../stores/outbound/constants';
import InfoIcon from '@material-ui/icons/Info';
import AdjustPalletCount from '../../inventory/InventoryList/AdjustPalletCount';
import { getSnapshot } from 'mobx-state-tree';
import Filter from './Filter';
import Button from '@material-ui/core/Button';
import Badge from '../../../common/Badge';
import { ALL_EMPTY, GREEN, NON_EMPTY, PARTIALLY_EMPTY, RED, ROW_COLOR_MAP, YELLOW } from './data';
import { Menu, MenuItem } from '@material-ui/core';
import ConfirmLoaded from './ConfirmLoaded';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  title: {

  },
  titleItem: {
    paddingBottom: '15px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#262626',
    opacity: 0.7,
    position: 'relative',
    cursor: 'pointer',
  },
  titleActiveItem: {
    opacity: 1,
    color: theme.palette.primary.main,

    '&::after': {
      content: '""',
      margin: 'auto',
      background: theme.palette.primary.main,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '2.5px',
      width: '50%',
      maxWidth: '150px',
    },
  },
  showMore: {
    textAlign: 'center',
  },
  completedRow: {
    opacity: 0.5,
  },
  canceledRow: {
    opacity: 0.5,
  },
}));


const columns = [
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>OB#</Box>
      <Box><SortBy name="id" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'uid',
    Cell: ({ row: { original } }) => {
      return <div style={{
        display: 'flex'
      }}>
        <span>{original.uid}</span> {original.bols.some(bol => !bol.is_ready_to_ship) && <Badge className="ml-1" color='danger' pill>Hold</Badge>}
      </div>
    }
  },
  {
    Header: 'OB Status',
    accessor: 'status_name',
  },
  {
    Header: 'Carrier Code',
    accessor: 'carrier_code',
  },
  {
    Header: 'Truck Type',
    accessor: 'truck_type_name',
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>Scheduled PU Time</Box>
      <Box><SortBy name="scheduled_pickup_time" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'scheduled_pickup_time',
  },
  {
    Header: 'Actual PU Time',
    accessor: 'shipout_at',
  },
  {
    Header: 'OB Type',
    accessor: 'type_name',
  },
  {
    Header: 'Booked PLT',
    accessor: 'booked_qty',
  },
  {
    Header: 'Weight',
    accessor: (original) => `${original.total_weight_lb}LB`,
  },
  {
    Header: 'Bols',
    accessor: 'bol',
    Cell: ({ row: { original, canExpand, isExpanded, getToggleRowExpandedProps } }) => <Box  {...getToggleRowExpandedProps()} >
      <a>View</a>
    </Box >,
  },
  {
    Header: 'Chat Room',
    accessor: 'chatroom',
    Cell: ({ row: { original, canExpand, isExpanded, getToggleRowExpandedProps } }) => <Box  {...getToggleRowExpandedProps()}>
      <a>View</a>
    </Box>,
  },
  {
    Header: 'Remark',
    accessor: 'remark',
    Cell: ({ row: { original } }) => <Remark value={original.remark} onChange={original.updateRemark} />,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    Cell: observer(({ row: { original }, state: { inventorys } }) => {
      const [anchorEl, setAnchorEl] = React.useState(null);

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };
      return <div>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          Actions
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem ><Button size={"small"} variant="outlined" color="primary" onClick={original.export}>Export</Button></MenuItem>
          <MenuItem ><AdjustPalletCount variant="outlined" selected={original.selectedLoads} onAdjustFinished={original.reload} inventorys={inventorys} /></MenuItem>
          <MenuItem ><ConfirmShipout outbound={original} /></MenuItem>
          <MenuItem ><Print outbound={original} /></MenuItem>
          <MenuItem ><ConfirmLoaded outbound={original} /></MenuItem>
        </Menu>
      </div>
      // <Box display="flex" justifyContent="center" alignItems="center">
      //   <Box><Button size={"small"} variant="outlined" color="primary" onClick={original.export}>Export</Button> </Box>
      //   <Box ml={1}><AdjustPalletCount variant="outlined" selected={original.selectedLoads} onAdjustFinished={original.reload} inventorys={inventorys} /></Box>
      //   <Box ml={1}><ConfirmShipout outbound={original} /></Box>
      //   <Box ml={1}><Print outbound={original} /></Box>
      // </Box>
    }),
  },
];

const List = inject('root')(
  observer(({ root }) => {
    const history = useHistory();
    const location = useLocation();

    const classes = useStyles();
    const theme = useTheme();

    // const [isLoadOnlyOne, setIsLoadOnlyOne] = React.useState(false);

    const { type, setType, setTypeAndLoad, typeCounts, loadCounts, loadCollection, collection, isTypeCompleted, isTypeCanceled, filter, sortBy, sortByName, sortByValue } = root.outbounds;

    useEffect(() => {
      if (location.state && location.state.id) {
        setType(OUTBOUND_LIST_TYPE_ALL);
        filter.setValueAndSubmit('id', location.state.id);

        history.replace({
          ...location,
          state: { ...location.state, id: null, },
        });
      } else {
        loadCollection()
      }

      loadCounts();
    }, []);

    const renderRowSubComponent = React.useCallback(({ row }) => {

      return <Box>
        <Box>
          <Bols row={row} />
        </Box>
        <Box>
          <ChatRoom
            targetId={row.original.id}
            target="outbound"
            sendMessage
            header
          />
        </Box>
      </Box>
    }, []);


    return <Box className={classes.root}>
      <Box mb={3}>
        <Filter filter={filter} />
      </Box>
      <Grid className={classes.title} container alignContent="center" alignItems="center">
        {typeCounts.map(t => <Grid key={t.value} item xs onClick={() => setTypeAndLoad(t.value)} className={clsx(classes.titleItem, { [classes.titleActiveItem]: t.isActive(type) })}>
          {t.text} {t.count}
        </Grid>)}
      </Grid>
      <Table
        columns={columns}
        data={collection.slice()}
        getRowProps={({ original }) => {
          let bgColor = '';
          if (original.bols.every(bol => +bol.whs_pallet_count != 0)) {
            bgColor = NON_EMPTY
          } else if (original.bols.every(bol => +bol.whs_pallet_count == 0)) {
            bgColor = ALL_EMPTY
          } else if (original.bols.some(bol => +bol.whs_pallet_count == 0)) {
            bgColor = PARTIALLY_EMPTY
          }
          return {
            className: clsx({
              [classes.completedRow]: original.shipout_at && !isTypeCompleted,
              [classes.canceledRow]: original.canceled_at && !isTypeCanceled,
            }),
            style: {
              backgroundColor: ROW_COLOR_MAP[bgColor]
            }
          }
        }}
        state={{
          theme,
          sortBy,
          sortByName,
          sortByValue,
          inventorys: root.inventorys,
        }}
        renderRowSubComponent={renderRowSubComponent}
      // expanded={isLoadOnlyOne ? { '0': true } : {}}
      />
      <Box mt={3} className={classes.showMore}>
        <ShowMore outbounds={root.outbounds} />
      </Box>
    </Box>
  })
)

const bolsColumns = [
  {
    Header: 'OB BOL#',
    accessor: 'uid',
    Cell: ({ row: { original } }) => <Box display="flex" justifyContent="center" alignItems="center">
      <Box><Link to={`/bols/${original.id}`}>{original.uid}</Link></Box>
      <Box ml={1}>
        {original.internal_remark && <Tooltip title={original.internal_remark}>
          <InfoIcon style={{ width: '18px' }} />
        </Tooltip>}
      </Box>
    </Box >
  },
  {
    Header: 'IB Shipment#',
    accessor: 'ib_shipment_numbers',
    Cell: ({ row: { original } }) => <CollectionText texts={original.ib_shipment_numbers} />
  },
  {
    Header: 'WHS Marking',
    accessor: 'whs_markings',
    Cell: ({ row: { original } }) => <CollectionText texts={original.whs_markings} />
  },
  {
    Header: 'Delivery Location',
    accessor: 'delivery_address',
    Cell: ({ row: { original } }) => <Box display="flex" justifyContent="center" alignItems="center">
      <Box>{original.delivery_code}</Box>
      <Box ml={1}>
        <Tooltip title={original.delivery_address}>
          <InfoIcon style={{ width: '18px' }} />
        </Tooltip>
      </Box>
      {!original.is_ready_to_ship && <Box ml={1}><Badge className="ml-1" color='danger' pill>Hold</Badge></Box>}
    </Box>,
    Footer: () => <strong>Total</strong>,
  },
  {
    Header: 'Delivery Apt Time',
    accessor: 'delivery_appointment_time',
  },
  {
    Header: '# of Piece',
    accessor: 'qty',
    Footer: (table) => <strong>{table.state.outbound.total_qty}</strong>,
  },
  {
    Header: 'WHS PLT ',
    accessor: 'whs_pallet_count',
    Footer: (table) => <strong>{table.state.outbound.total_whs_pallet_count}</strong>,
  },
  {
    Header: 'Shipout PLT ',
    accessor: 'shipout_pallet_count',
    Footer: (table) => <strong>{table.state.outbound.total_shipout_pallet_count}</strong>,
  },
  {
    Header: 'Remaining PLT ',
    accessor: 'remaining_pallet_count',
    Footer: (table) => <strong>{table.state.outbound.total_remaining_pallet_count}</strong>,
  },
  {
    Header: 'Weight',
    accessor: (original) => `${original.weight_lb}LB`,
    Footer: (table) => <strong>{table.state.outbound.total_weight_lb}LB</strong>,
  },
];

const Bols = observer(({ row: { original } }) => {
  const setSelected = React.useCallback((selected) => {
    original.setSelected(selected.map(s => getSnapshot(s)));
  }, [original]);

  return <Table
    footerable
    selection
    onSelectedChange={setSelected}
    data={original.bols.slice()}
    columns={bolsColumns}
    state={{
      outbound: original,
    }}
  />;
})

export default List;
