import { types, getParent, flow } from 'mobx-state-tree';
import { UnreadRow } from './models';

const ChatRoomNotificationStore = types
  .model('ChatRoomNotificationStore', {
    unreadCount: 0,
    unreadCollection: types.array(UnreadRow),
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    loadCount: flow(function*() {
      try {
        const response = yield self.root.api.chatRoomNotification.unreadCount();
        self.unreadCount = response.data.data;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadCollection: flow(function*() {
      try {
        const response = yield self.root.api.chatRoomNotification.unread();
        self.unreadCollection = response.data.data;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    markAsRead: flow(function*(chatRoomId) {
      try {
        yield self.root.api.chatRoomNotification.markAsRead(chatRoomId);
        self.loadCollection()
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    markAllAsRead: flow(function*() {
      try {
        yield self.root.api.chatRoomNotification.markAllAsRead();
        self.loadCollection()
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
  }));

export default ChatRoomNotificationStore;
