import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 0',
    // display: 'flex',
    // flex: '1 1 0px',

    '& > .card:not(:first-child)': {
      borderLeft: '1px solid #F3F3F3',
    },
  },
}))

const CardContainer = observer(({ cards = [] }) => {
  const classes = useStyles();

  return <Grid container spacing={0} className={classes.root}>
    {cards.map((c, i) => <Grid key={i} sm={12} md={12 / cards.length} item className="card">
      {c}
    </Grid>)}
  </Grid>
})

export default CardContainer;
