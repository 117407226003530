import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import POD from './POD';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: '18px',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
  },
  inputWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    border: '1px dashed #80889A',
    marginTop: '15px',
    padding: '11px 15px',
    minHeight: '150px',
  },
  noHistory: {
    color: theme.palette.secondary.main,
    fontStyle: 'italic'
  },
  shipoutTitle: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '150%',
    color: '#4F4F4F',
  },
  shipoutTime: {
    fontSize: '12px',
    lineHeight: '150%',
    fontStyle: 'italic',
    color: '#828282',
  },
  shipoutPallet: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '150%',
    color: '#001235',
  },
  shipoutCarrier: {
    fontSize: '12px',
    lineHeight: '150%',
    fontStyle: 'italic',
    color: '#001235',
  },
}));

const ShipoutHistories = observer(({ bol }) => {
  const classes = useStyles();

  const { detail } = bol;

  return <Box className={classes.root}>
    <Box className={classes.title}>ShipOut History:</Box>
    <Box className={classes.inputWrapper}>
      {detail.shipout_histories.length > 0 ? <>
        {detail.shipout_histories.map((shipout, i) => <Box key={shipout.id}>
          <Box display="flex" alignItems="center" mt={1}>
            <Box>
              <Box className={classes.shipoutTitle}>Shipout #{i + 1}</Box>
              <Box className={classes.shipoutTime}>{shipout.shipout_at}</Box>
            </Box>
            <Box ml={2}>
              <Box className={classes.shipoutPallet}>PLT {shipout.total_pallet_count}</Box>
              <Box className={classes.shipoutCarrier}>{shipout.carrier_name}</Box>
            </Box>
            <Box ml="auto"></Box>
            <Box><POD shipout={shipout} index={i + 1} /></Box>
            <Box ml={1}><Button type="button" size="small" variant="outlined" color="primary" onClick={shipout.cancel}>Cancel</Button></Box>
          </Box>
          <Box mt={1}>
            <Divider />
          </Box>
        </Box>)}
      </> : <Box className={classes.noHistory}>There’s no history here yet.</Box>}
    </Box>
  </Box>
})

export default ShipoutHistories;
