import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Select from '../../../ui/Select';
import { observer } from 'mobx-react';
import Input from '../../../ui/Input';


const Filter = observer(({ filter }) => {
  const {
    container_number,
    setValueByHtmlElement,
    reset,
    submit,
  } = filter;

  const handleSubmit = (e) => {
    e.preventDefault()
    submit();
  }

  return <form onSubmit={handleSubmit}>
    <Box display="flex" alignItems="flex-end">
      <Box ml={1}>
        <Input
          title="CNTR#"
          name="container_number"
          value={container_number}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1}>
        <Box ml={1} display="flex">
          <Box><Button variant="outlined" color="primary" type="submit">Apply</Button></Box>
          <Box ml={1}><Button variant="outlined" color="primary" onClick={reset}>Reset Filter</Button></Box>
        </Box>
      </Box>
    </Box>
  </form>
})

export default Filter;
