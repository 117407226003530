import { types, getParent, flow } from 'mobx-state-tree';
import { SearchedItem } from './models';
import { debounce } from 'lodash';

const SearchStore = types
  .model('  SearchStore', {
    query: '',
    loading: false,
    searchedItems: types.array(SearchedItem),
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setQuery(query) {
      self.query = query;
    },
    setLoading(loading) {
      self.loading = loading;
    },
    loadSearchedItems: flow(function* () {
      try {
        self.setLoading(true);
        const { data } = yield self.root.api.search.index({ query: self.query });
        self.searchedItems = data.data;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.setLoading(false);
    }),
    debounceLoadSearchedItems: debounce(() => {
      self.loadSearchedItems()
    }, 500),
    setQueryAndLoad(query) {
      self.setLoading(true);
      self.setQuery(query);
      self.debounceLoadSearchedItems();
    },
  }));

export default SearchStore;
