import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { LeftArrowIcon } from '../../../ui/Icon';
import { useHistory } from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  backIconWrapper: {
    marginRight: '20px',
    lineHeight: '40px',
  },
  backIcon: {
    fontSize: '24px',
    color: '#00000',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  titleWrapper: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#000000',
  },
  title: {
    fontSize: '14px',
  },
  subTitle: {
    fontSize: '9px',
    opacity: 0.5,
  },
  completedBox: {
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    color: '#FFFFFF',
    backgroundColor: '#3CA755',
    borderRadius: '3px',
    padding: '3px 15px',
    marginLeft: '16px',
  },
  completedIcon: {
    marginRight: '5px',
    fontSize: '21px',
  },
  divider: {
    marginTop: '22px',
  },
}));

const Header = observer(({ task }) => {
  const classes = useStyles();
  const history = useHistory();
  const [goBackCount, setGoBackCount] = useState(-1);

  useEffect(() => {
    const historyListener = history.listen(() => {
      setGoBackCount(count => count - 1);
    });

    return () => historyListener();
  }, []);

  const goBack = useCallback((e) => {
    e.preventDefault();

    goBackCount < -1 ? history.go(goBackCount) : history.push('/tasks');
  }, [goBackCount, history]);

  const { detail } = task;

  return <>
    <Box className={classes.root}>
      <Box className={classes.backIconWrapper}>
        <LeftArrowIcon className={classes.backIcon} onClick={goBack} />
      </Box>
      <Box className={classes.titleWrapper}>
        <Box className={classes.title}>
          {detail.container_number}
        </Box>
        <Box className={classes.subTitle}>
          # Container
      </Box>
      </Box>
      <Box display="flex" alignItems="flex-end">
        {!!detail.areAllCompleted && <Box className={classes.completedBox} display="flex" alignItems="center" justifyContent="center">
          <Box><DoneIcon className={classes.completedIcon} /></Box>
          <Box>All Current Tasks Completed</Box>
        </Box>}
      </Box>
      <Box display="flex" ml="auto">
        <Box ml={2}>
          <Button variant="outlined" color="primary" onClick={detail.viewInboundPDF}>View Inbound Receipt</Button>
        </Box>
      </Box>
    </Box>
    <Divider className={classes.divider} />
  </>
})

export default Header;
