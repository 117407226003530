import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CollectionText from '../../../common/CollectionText';
import Memo from './Memo';
import Documents from './Documents';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Badge from '../../../common/Badge';
import uniq from 'lodash.uniq';

const WhsTasksCollectionText = withStyles(theme => ({
  root: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    textAlign: 'center',
    cursor: 'pointer',
  },
}))(CollectionText);

const WhsWorkOrdersCollectionText = withStyles(theme => ({
  root: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    textAlign: 'center',
    cursor: 'pointer',
  },
}))(CollectionText);

const columns = [
  // {
  //   Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, state: { isGroupedByGroup }, }) => <>
  //     <Box
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="center"
  //       {...getToggleAllRowsExpandedProps()}
  //     >
  //       <Box>Container Number</Box>
  //       {!!isGroupedByGroup && <Box>{isAllRowsExpanded ? <ArrowDropUpIcon style={{ fontSize: '21px' }} /> : <ArrowDropDownIcon style={{ fontSize: '21px' }} />}</Box>}
  //     </Box>
  //   </>,
  //   accessor: 'container_number',
  //   Cell: ({ row: { original, canExpand, isExpanded, getToggleRowExpandedProps, depth } }) => <>
  //     {canExpand ? <Box
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="center"
  //       {...getToggleRowExpandedProps()}
  //     >
  //       <Box>{original.container_number}</Box>
  //       <Box width={24} ml={1}>{isExpanded ? <ArrowDropUpIcon style={{ fontSize: '21px' }} /> : <ArrowDropDownIcon style={{ fontSize: '21px' }} />}</Box>
  //     </Box> : <>
  //       {depth > 0 ? null : original.container_number}
  //     </>}
  //   </>,
  // },
  {
    Header: 'Group#',
    accessor: 'uid',
    columnTitle: 'Group#',
    defaultColumn: true,
  },
  {
    Header: 'BOL',
    accessor: 'bol_id',
    Cell: ({ row: { original, canExpand, depth } }) => <CollectionText texts={original.bols} emptyContent="-" rowRender={bol => bol.id ? <Link to={`/bols/${bol.id}`}>{bol.uid}</Link> : bol.uid} />,
    defaultColumn: true,
    columnTitle: 'BOL',
  },
  {
    Header: 'CNTR#',
    accessor: 'ib_number',
    defaultColumn: true,
    columnTitle: 'CNTR#',
  },
  {
    Header: 'WHS Marking',
    accessor: 'marking',
    defaultColumn: true,
    columnTitle: 'WHS Marking',
  },
  {
    Header: 'Group Status',
    accessor: 'status_name',
    defaultColumn: true,
    columnTitle: 'Group Status',
  },

  {
    Header: 'Actual Qty',
    accessor: 'total_actual_qty',
    Footer: (table) => <strong>{table.state.totalValues.total_actual_qty}</strong>,
    defaultColumn: true,
    columnTitle: 'Actual Qty',
  },
  {
    Header: 'Booked Qty',
    accessor: 'qty',
    Cell: ({ row: { original } }) => original.total_qty || '-',
    Footer: (table) => <strong>{table.state.totalValues.total_qty}</strong>,
    columnTitle: 'Booked Qty',
  },
  {
    Header: 'Current Qty',
    accessor: 'current_qty',
    Cell: ({ row: { original } }) => original.total_current_qty || '-',
    Footer: (table) => <strong>{Number(table.state.totalValues.total_current_qty) === 0 ? 'not in WHS' : table.state.totalValues.total_current_qty}</strong>,
    columnTitle: 'Current Qty',
  },
  {
    Header: 'Aging(Day)',
    accessor: 'aging',
    Cell: ({ row: { original } }) => original.aging || '-',
    columnTitle: 'Aging',
  },
  {
    Header: 'IB PLT',
    accessor: 'total_inbound_pallet_count',
    Cell: ({ row: { original } }) => original.total_inbound_pallet_count || '-',
    Footer: (table) => <strong>{table.state.totalValues.total_inbound_pallet_count}</strong>,
    columnTitle: 'IB PLT',
  },
  {
    Header: 'WHS PLT',
    accessor: 'total_whs_pallet_count',
    Cell: ({ row: { original } }) => original.total_whs_pallet_count || '-',
    Footer: (table) => <strong>{table.state.totalValues.total_whs_pallet_count}</strong>,
    defaultColumn: true,
    columnTitle: 'WHS PLT',
  },
  {
    Header: 'Shipout PLT',
    accessor: 'total_shipout_pallet_count',
    Cell: ({ row: { original } }) => original.total_shipout_pallet_count || '-',
    Footer: (table) => <strong>{table.state.totalValues.total_shipout_pallet_count}</strong>,
    columnTitle: 'Shipout PLT',
  },
  {
    Header: 'Remain PLT',
    accessor: 'total_remaining_pallet_count',
    Cell: ({ row: { original } }) => original.total_remaining_pallet_count || '-',
    Footer: (table) => <strong>{table.state.totalValues.total_remaining_pallet_count}</strong>,
    defaultColumn: true,
    columnTitle: 'Remain PLT',
  },
  {
    Header: 'DEL Code',
    accessor: 'delivery_code',
    Cell: ({ row: { original } }) => <CollectionText texts={original.delivery_codes} emptyContent="-" />,
    defaultColumn: true,
    columnTitle: 'DEL Code',
  },
  {
    Header: 'IB Date',
    accessor: 'internal_warehouse_received_at',
    Cell: ({ row: { original, canExpand, depth } }) => <CollectionText texts={original.inbounds} emptyContent="-" rowRender={inbound => inbound.id ? <Link to={`/inbound/${inbound.id}`}>{inbound.received_at}</Link> : inbound.received_at} />,
    defaultColumn: true,
    columnTitle: 'IB Date',
  },
  {
    Header: 'OB Date',
    accessor: 'bol_delivered_at',
    Cell: ({ row: { original, canExpand, depth } }) => <CollectionText texts={original.delivered_bols} emptyContent="-" rowRender={o => o.delivered_at} />,
    defaultColumn: true,
    columnTitle: 'OB Date',
  },
  {
    Header: 'WO',
    accessor: 'whs_work_orders',
    Cell: ({ row: { original } }) => <WhsWorkOrdersCollectionText emptyContent="-" texts={original.whs_work_orders} rowRender={wo => <Link to={`/workOrders/${wo.id}`}>{wo.uid}</Link>} />,
    defaultColumn: true,
    columnTitle: 'WO',
  },
  {
    Header: 'Ready To Ship',
    accessor: 'is_ready_to_ship',
    Cell: ({ row: { original } }) => original.is_ready_to_ship ? 'Yes' : <Badge color="warning" pill>Hold</Badge>,
    defaultColumn: true,
    columnTitle: 'Ready To Ship',
  },
  {
    Header: 'Load Type',
    accessor: 'load_type',
    Cell: ({ row: { original } }) => <CollectionText texts={uniq(original.loads.map(l => l.type_name))} emptyContent="-" />,
    defaultColumn: true,
    columnTitle: 'Load Type',
  },
  {
    Header: 'WHS Area',
    accessor: 'group_area',
    Cell: ({ row: { original } }) => <>{original.area?.join(',') || '-'}</>,
    defaultColumn: true,
    columnTitle: 'WHS Area',
  },
  {
    Header: 'EST PLT',
    accessor: 'total_estimate_pallet_count',
    Footer: (table) => <strong>{table.state.totalValues.total_estimate_pallet_count}</strong>,
    defaultColumn: true,
    columnTitle: 'EST PLT',
  },
  {
    Header: 'Shipment ID',
    accessor: 'receiver_shipment_id',
    Cell: ({ row: { original } }) => <CollectionText texts={original.receiver_shipment_ids} emptyContent="-" />,
    columnTitle: 'Shipment ID',
  },
  {
    Header: 'Remark',
    accessor: 'memo',
    Cell: ({ row: { original, canExpand, depth } }) => <>
      {canExpand && depth == 0 ? '' : <Memo value={original.memo} onChange={original.updateGroupMemo} />}
    </>,
    defaultColumn: true,
    columnTitle: 'Remark',
  },
  {
    Header: 'LBS',
    accessor: 'total_weight_lb',
    Footer: (table) => <strong>{table.state.totalValues.total_weight_lb}</strong>,
    columnTitle: 'LBS',
  },
  {
    Header: 'CBM',
    accessor: 'total_volume_cbm',
    Footer: (table) => <strong>{table.state.totalValues.total_volume_cbm}</strong>,
    columnTitle: 'CBM',
  },
  {
    Header: 'Document',
    accessor: 'documents',
    Cell: ({ row: { original, canExpand, depth } }) => <>
      {canExpand && depth == 0 ? '' : <Documents inventory={original} />}
    </>,
    columnTitle: 'Documents',
  },
];

export default columns;
