
import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { GroupByIcon } from '../../../ui/Icon';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {

  },
  groupByBtn: {
    background: '#FFFFFF',
    border: '1px solid #C6C7C9',
    boxSizing: 'border-box',
    borderRadius: '3px',
    padding: '5px 20px',
    cursor: 'pointer',
    color: '#959597',
  },
  groupByItem: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupByItemContent: {
    cursor: 'pointer',
  },
}));



const GroupBy = observer(({ inbound }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'inbound-detail-groupby' : undefined;

  return <Box className={classes.root}>
    <button className={classes.groupByBtn} onClick={handleClick}><GroupByIcon style={{ fontSize: '10px' }} /> {inbound.groupByName}</button>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper>
        {Object.keys(inbound.groupByMap).map(k => <Box className={classes.groupByItem} key={k}>
          <Box className={classes.groupByItemContent} onClick={e => inbound.setGroupBy(k)}>
            <Checkbox
              color="primary"
              size="small"
              checked={inbound.groupBy == k}
            />
            {inbound.groupByMap[k]}
          </Box>
          <Divider />
        </Box>)}
      </Paper>
    </Popover>
  </Box>
})

export default GroupBy;
