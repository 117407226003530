import React from 'react';
import { observer, inject } from 'mobx-react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Detail from './Detail';
import Box from '@material-ui/core/Box';

export default inject('root')(
  observer(({ root, workOrderId }) => {
    const { workOrder } = root;
    workOrderId = workOrderId ? workOrderId : useParams().workOrderId;

    React.useEffect(() => {
      workOrder.clearSelectedGroup();
      workOrder.loadData(workOrderId);
      workOrder.whsDocuments.load(workOrderId);
    }, [workOrderId]);

    return <>
      <Header workOrder={workOrder} />
      <Box mt={1}>
        <Detail workOrder={workOrder} />
      </Box>
    </>
  })
)

