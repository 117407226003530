import request from './request';

export const troubleShoot = {
  counts() {
    return request.get('troubleShoots/counts');
  },
  index(params = {}) {
    return request.get('troubleShoots', { params });
  },
  store(params = {}) {
    return request.post(`troubleShoots`, params);
  },
  update(id, params = {}) {
    return request.patch(`troubleShoots/${id}`, params);
  },
  show(id) {
    return request.get(`troubleShoots/${id}`);
  },
  sendMessage(id, params) {
    return request.post(`troubleShoots/messages/${id}`, params);
  }
}
