import React from 'react';
import { DashboardIcon, InBoundIcon } from '../ui/Icon';
import { Button, Typography } from '@material-ui/core';
import Logo from '../ui/Logo';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../ui/Table';
import Select from '../ui/Select';

const useStyles = makeStyles(theme => ({
  root: {
  },
  iconPrimary: {
    color: theme.palette.primary.main,
  }
}));

const data = [
  {
    container_number: 'CSNU6655793',
    scheduled_delivery_date: '01/15/2021',
    status: 'Today’s Inbound',
    qty: '1505',
  },
  {
    container_number: 'CSNU6655793',
    scheduled_delivery_date: '01/15/2021',
    status: 'Today’s Inbound',
    qty: '1505',
  },
  {
    container_number: 'CSNU6655793',
    scheduled_delivery_date: '01/15/2021',
    status: 'Today’s Inbound',
    qty: '1505',
  },
];

const columns = [
  {
    Header: 'Container Number',
    accessor: 'container_number', // accessor is the "key" in the data
  },
  {
    Header: 'Scheduled Delivery Date',
    accessor: 'scheduled_delivery_date',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Number of Piece',
    accessor: 'qty',
  },
];

const Summary = () => {
  const classes = useStyles();

  const [selected, setSelected] = React.useState('A');
  const [multipleSelected, setMultipleSelected] = React.useState([]);

  return <>
    <Typography variant="h5" gutterBottom>
      Summary
    </Typography>
    <div>Select: <Select options={['A', 'B', 'C']} selected={selected} onSelect={setSelected} /></div>
    <div>Multiple Select: <Select options={['A', 'B', 'C']} multiple selected={multipleSelected} onSelect={setMultipleSelected} searchable /></div>
    <div>Icons: <DashboardIcon className={classes.iconPrimary} /> {'  '} <InBoundIcon /></div>

    <hr />

    <div>
      Buttons:
      <Button variant="contained" color="primary" endIcon={<DashboardIcon />}>Button</Button>
      {'  '}
      <Button variant="outlined" color="primary">Button</Button>
      {'  '}
      <Button variant="outlined" color="primary" endIcon={<DashboardIcon />}>Button</Button>
      {'  '}
      <Button size="small" variant="contained" color="primary" startIcon={<DashboardIcon />}>Button</Button>
      {'  '}
      <Button size="small" variant="outlined" color="primary">Button</Button>
      {'  '}
      <Button size="small" variant="outlined" color="primary" startIcon={<DashboardIcon />}>Button</Button>
    </div>

    <hr />

    <div>
      Logo:
      <Logo />
    </div>

    <hr />

    <div>
      Table:
      <Table columns={columns} data={data} />
    </div>


  </>
}





export default Summary;
