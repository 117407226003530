import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Detail from './Detail';
import Footer from './Footer';
import Header from './Header';
import { useParams } from "react-router-dom";

export default inject('root')(
  observer(({ root, bolId }) => {
    const { bol, inventorys } = root;
    bolId = bolId ? bolId : useParams().bolId;

    useEffect(() => {
      // bol.loadData(bolId);
      // bol.cancelChanged(bolId);
      // bol.whsDocuments.load(bolId);
      // bol.shipoutDocuments.clear();
      // bol.adminDocuments.load(bolId);
    }, [bolId])

    return <>
      <Header bol={bol} inventorys={inventorys} />
      <Detail bol={bol} />
      <Footer bol={bol} />
    </>
  })
)
