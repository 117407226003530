import React, { useState } from 'react';
import CommonDocuments from '../../../common/Documents';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { FolderIcon } from '../../../ui/Icon';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { UploadIcon } from '../../../ui/Icon';

const useStyles = makeStyles(theme => ({
  button: {
    padding: 0,
    fontSize: '15px',
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: '15px',
  },
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    minHeight: '300px',
  },
  subtitle: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#001235',
  },
  subtitleIcon: {
    marginRight: '5px',
    fontSize: '18px',
    lineHeight: '18px',
  },
}));

const Documents = observer(({ inventory }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    inventory.loadDocuments()
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return <>
    <IconButton color="primary" size="small" className={classes.button} onClick={handleOpen}>
      <FolderIcon className={classes.icon} />
    </IconButton>
    {open && <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogTitle}>Upload</DialogTitle>
      <DialogContent>
        <Box className={classes.content}>
          <Box mb={2} className={classes.subtitle}>
            <Box><UploadIcon className={classes.subtitleIcon} /></Box>
            <Box>Upload WHS File</Box>
          </Box>
          <CommonDocuments documentListProps={{ locationable: true, actionPosition: 'end' }} dropzonePosition="top" documents={inventory.whsDocuments} />
        </Box>
      </DialogContent>
    </Dialog>}
  </>
});

export default Documents;

