import { types, getParent, getRoot } from 'mobx-state-tree';
import { every, first } from 'lodash';
import DocumentStore from '../document/DocumentsStore';

export const TypeCountItem = types
  .model({
    value: types.string,
    text: types.string,
    count: types.maybeNull(types.number),
  })
  .actions(self => ({
    isActive(type) {
      return self.value === type;
    },
    setCount(count) {
      self.count = count;
    },
  }));

export const TaskRowLoad = types
  .model({
    id: types.number,
    uid: types.string,
    container_number: types.maybeNull(types.string),
    markings: types.array(types.string),
    marking_histories: types.array(types.string),
    shipment_ids: types.array(types.string),
    task_types: types.array(types.string),
    task_units: types.array(types.string),
    task_due_dates: types.array(types.string),
    qty: types.maybeNull(types.number),
    pallet_count: types.maybeNull(types.number),
    whs_task_completed_at: types.maybeNull(types.string),
    whs_task_canceled_at: types.maybeNull(types.string),
  })
  .views(self => ({
    get isCompleted() {
      return !!self.whs_task_completed_at;
    },
    get isCanceled() {
      return !!self.whs_task_canceled_at;
    },
    get firstMarkingHistory() {
      return first(self.marking_histories);
    },
    get marking() {
      return first(self.markings);
    },
  }));

export const TaskRow = types
  .model({
    container_number: types.maybeNull(types.string),
    markings: types.array(types.string),
    shipment_ids: types.array(types.string),
    task_types: types.array(types.string),
    task_units: types.array(types.string),
    task_due_dates: types.array(types.string),
    qty: types.maybeNull(types.number),
    pallet_count: types.maybeNull(types.number),
    loads: types.array(TaskRowLoad),
  })
  .views(self => ({
    get isCompleted() {
      return every(self.loads, 'isCompleted');
    },
    get isCanceled() {
      return every(self.loads, 'isCanceled');
    },
  }));


export const TaskDetailItem = types
  .model({
    id: types.number,
    load_id: types.number,
    type_name: types.maybeNull(types.string),
    unit: types.maybeNull(types.string),
    qty: types.maybeNull(types.number),
    description: types.maybeNull(types.string),
    due_at: types.maybeNull(types.string),
    completed_at: types.maybeNull(types.string),
    canceled_at: types.maybeNull(types.string),
    released_at: types.maybeNull(types.string),
    whsDocuments: types.optional(DocumentStore, { target: 'task_whs' }),
    adminDocuments: types.optional(DocumentStore, { target: 'task_panda' }),
  })
  .actions(self => ({
    loadDocuments() {
      self.whsDocuments.load(self.id);
      self.adminDocuments.load(self.id);
    },
    complete() {
      getParent(self, 2).complete(self.id);
    },
    uncomplete() {
      getParent(self, 2).uncomplete(self.id);
    },
  }));

export const TaskDetailLoad = types
  .model({
    id: types.number,
    uid: types.string,
    master_id: types.maybeNull(types.number),
    has_subs: types.maybeNull(types.boolean),
    marking: types.maybeNull(types.string),
    marking_histories: types.array(types.string),
    whs_task_completed_at: types.maybeNull(types.string),
    whs_task_canceled_at: types.maybeNull(types.string),
  })
  .views(self => ({
    get isCompleted() {
      return !!self.whs_task_completed_at;
    },
    get isCanceled() {
      return !!self.whs_task_canceled_at;
    },
  }));

export const TaskDetail = types
  .model({
    id: 0,
    uid: types.maybeNull(types.string),
    master_id: types.maybeNull(types.number),
    has_subs: types.maybeNull(types.boolean),
    warehouse_alert_id: types.maybeNull(types.number),
    container_number: types.maybeNull(types.string),
    marking: types.maybeNull(types.string),
    received_date: types.maybeNull(types.string),
    whs_task_completed_at: types.maybeNull(types.string),
    tasks: types.array(TaskDetailItem),
    loads: types.array(TaskDetailLoad),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get areAllCompleted() {
      return !!self.whs_task_completed_at && self.loads.length > 0 && every(self.loads, 'whs_task_completed_at');
    },
    get loadBarWrappers() {
      const loadWrappers = [self, ...self.loads].sort((a, b) => a.id - b.id).map((load) => {
        return {
          load: load,
          subs: load.master_id == 0 ? [] : null,
          role: null,
          active: false,
        }
      });
      let masterLoadWrappers = loadWrappers.filter((loadWrapper) => loadWrapper.load.master_id == 0);
      let subLoadWrappers = loadWrappers.filter((loadWrapper) => loadWrapper.load.master_id != 0);
      subLoadWrappers.map((subLoadWrapper) => {
        masterLoadWrappers.some((masterLoadWrapper) => {
          if (subLoadWrapper.load.master_id == masterLoadWrapper.load.id) {
            masterLoadWrapper.subs = [subLoadWrapper, ...masterLoadWrapper.subs];
            return true;
          }
          return false;
        })
      });
      let loadBarWrappers = [];
      let masterNumber = 1;
      masterLoadWrappers.map((masterLoadWrapper) => {
        if (masterLoadWrapper.subs.length > 0) {
          masterLoadWrapper.role = `Master ${masterNumber}`;
        } else {
          masterLoadWrapper.role = null;
          --masterNumber;
        }
        loadBarWrappers.push(masterLoadWrapper);
        let subNumber = 1;
        masterLoadWrapper.subs.map((subLoadWrapper) => {
          subLoadWrapper.role = `Sub ${masterNumber}-${subNumber}`;
          ++subNumber;
          loadBarWrappers.push(subLoadWrapper);
        });
        ++masterNumber;
      });
      loadBarWrappers.map((loadBarWrapper) => {
        loadBarWrapper.load.id == self.id ? loadBarWrapper.active = true : loadBarWrapper.active = false;
      });
      return loadBarWrappers;
    },
  }))
  .actions(self => ({
    complete(taskId) {
      getParent(self).complete(self.id, taskId);
    },
    uncomplete(taskId) {
      getParent(self).uncomplete(self.id, taskId);
    },
    viewInboundPDF() {
      self.root.inbound.viewPDF(self.warehouse_alert_id);
    },
  }));
