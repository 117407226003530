import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useParams } from "react-router-dom";
import DetailOrderFulfillment from './DetailOrderFulfillment';
import DetailRegular from './DetailRegular';

export default inject('root')(
  observer(({ root, bolId }) => {
    const { bol } = root;
    bolId = bolId ? bolId : useParams().bolId;

    useEffect(() => {
      bol.loadData(bolId);
      bol.cancelChanged(bolId);
      bol.whsDocuments.load(bolId);
      bol.shipoutDocuments.clear();
      // bol.adminDocuments.load(bolId);
    }, [bolId])

    return <>
      {!!bol.detail.id && <>
        {bol.detail.isOf ? <DetailOrderFulfillment /> : <DetailRegular />}
      </>}
    </>
  })
)
