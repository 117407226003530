import React from 'react';
import { observer, inject } from 'mobx-react';
import Routes from '../routes';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, CssBaseline } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { defaultTheme } from './theme';
import Layout from './layouts/Layout';
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from './layouts/ToastProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));


export default inject('root')(
  observer(({ root }) => {
    const { auth, app } = root;

    const classes = useStyles();

    return <>
      <ThemeProvider theme={defaultTheme}>
        <ToastProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <CssBaseline />
            {app.appLoaded ? (
              <BrowserRouter>
                <Layout auth={auth} app={app} isAuthenticated={auth.isAuthenticated}>
                  <Routes isAuthenticated={auth.isAuthenticated} />
                </Layout>
              </BrowserRouter>
            ) : (
                <Box className={classes.root}>
                  <CircularProgress />
                </Box>
              )}
          </MuiPickersUtilsProvider>
        </ToastProvider>
      </ThemeProvider>
    </>
  })
)
