import React from 'react';
import { observer } from 'mobx-react';
import HistoryIcon from '@material-ui/icons/History';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { get } from 'lodash';

const InventoryHistory = observer(({ workOrder, row, type }) => {
  const [open, setOpen] = React.useState(false);
  const [collection, setCollection] = React.useState([]);

  const handleOpen = () => {
    let http;

    if (type === 'group') {
      http = row.root.api.workOrder.inventoryHistoryByGroup(workOrder.detail.id, row.id)
    } else if (type === 'load') {
      http = row.root.api.workOrder.inventoryHistoryByLoad(workOrder.detail.id, row.id)
    }

    http.then(response => setCollection(response.data.data));

    setOpen(true);
  }

  return <>
    <HistoryIcon onClick={handleOpen} size="small" style={{ cursor: 'pointer', fontSize: '18px' }} />
    {open && <Dialog onClose={() => setOpen(false)} open={open}>
      <DialogTitle>Inventory History</DialogTitle>
      <DialogContent>
        <Box pb={3}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Operator</TableCell>
                <TableCell>Load</TableCell>
                <TableCell>Time</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collection.map(c => <TableRow key={c.id}>
                <TableCell>{c.causer_name}</TableCell>
                <TableCell>{c.load_uid}</TableCell>
                <TableCell>{c.created_at}</TableCell>
                <TableCell>
                  <DiffDetail row={c} />
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
    </Dialog>
    }
  </>
});


const DiffDetail = ({ row }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return <>
    <Button variant="outlined" color="primary" onClick={handleClick} size="small">
      Detail
    </Button>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{
        style: { minWidth: '400px' },
      }}
    >
      <Diff row={row} />
    </Popover>
  </>
}

const Diff = ({ row }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Column</TableCell>
          <TableCell>Before</TableCell>
          <TableCell>After</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(get(row, 'to') || {}).map(column => (
          <TableRow key={column}>
            <TableCell>{column}</TableCell>
            <TableCell><DiffText value={get(row, `from.${column}`, '')} /></TableCell>
            <TableCell><DiffText value={get(row, `to.${column}`, '')} /></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const DiffText = ({ value }) => {
  return value + '';
}

export default InventoryHistory;
