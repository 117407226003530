import React, { useEffect } from 'react';
import clsx from 'clsx';
import Table from '../../../ui/Table';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import ShowMore from './ShowMore';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Filter from './Filter';
import CollectionText from '../../../common/CollectionText';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  title: {

  },
  titleItem: {
    paddingBottom: '15px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#262626',
    opacity: 0.7,
    position: 'relative',
    cursor: 'pointer',
  },
  titleActiveItem: {
    opacity: 1,
    color: theme.palette.primary.main,

    '&::after': {
      content: '""',
      margin: 'auto',
      background: theme.palette.primary.main,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '2.5px',
      width: '50%',
      maxWidth: '150px',
    },
  },
  showMore: {
    textAlign: 'center',
  },
  completedRow: {
    opacity: 0.5,
  },
  canceledRow: {
    opacity: 0.5,
  },
}));


const columns = [
  {
    Header: 'BOL Number',
    accessor: 'uid',
    Cell: ({ row: { original }, state: { theme } }) => <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box width={24} mr={1}>
          {original.actual_pickup_time && !original.is_pod && <Tooltip title="Fully Shipout"><CheckCircleIcon style={{ fontSize: 21, color: theme.palette.success.main, verticalAlign: 'middle' }} /></Tooltip>}
          {original.actual_pickup_time && original.is_pod && <Tooltip title="All POD Uploaded"><CheckCircleIcon style={{ fontSize: 21, color: theme.palette.secondary.main, verticalAlign: 'middle' }} /></Tooltip>}
        </Box>
        <Box><Link to={`/bols/${original.id}`}>{original.uid}</Link></Box>
        <Box>
          <Box width={24} ml={1}>{!!original.confirmed_at && <Tooltip title={original.confirmed_at}><span><Chip variant="outlined" color="primary" size="small" label="Confirmed" /></span></Tooltip>}</Box>
          <Box width={24} ml={1}>{!!original.changed_at && <Tooltip title={original.changed_at}><span><Chip variant="outlined" color="primary" size="small" label="Update" /></span></Tooltip>}</Box>
        </Box>
      </Box>
    </>
  },
  {
    Header: 'CNTR#',
    accessor: 'container_number',
    Cell: ({ row: { original } }) => <>
      <Box><CollectionText texts={original.container_number} /></Box>
    </>,
  },
  {
    Header: 'Delivery Code',
    accessor: 'delivery_code',
  },
  {
    Header: 'Scheduled Pick up Date',
    accessor: 'scheduled_pickup_time',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Number of Piece',
    accessor: 'total_qty',
  },
  {
    Header: 'Transportation Type',
    accessor: 'shipping_mode',
  },
];

const List = inject('root')(
  observer(({ root }) => {
    const classes = useStyles();
    const theme = useTheme();

    const { type, setTypeAndLoad, typeCounts, loadCounts, loadCollection, collection, isTypeCompleted, isTypeCanceled, filter } = root.bols;

    useEffect(() => {
      loadCounts();
      loadCollection();
    }, []);


    return <Box className={classes.root}>
      <Box mb={3}>
        <Filter filter={filter} />
      </Box>
      <Grid className={classes.title} container alignContent="center" alignItems="center">
        {typeCounts.map(t => <Grid key={t.value} item xs onClick={() => setTypeAndLoad(t.value)} className={clsx(classes.titleItem, { [classes.titleActiveItem]: t.isActive(type) })}>
          {t.text} {t.count}
        </Grid>)}
      </Grid>
      <Table
        columns={columns}
        data={collection.slice()}
        getRowProps={({ original }) => {
          return {
            className: clsx({
              [classes.completedRow]: original.actual_pickup_time && !isTypeCompleted,
              [classes.canceledRow]: original.canceled_at && !isTypeCanceled,
            })
          }
        }}
        state={{ theme }}
      />
      <Box mt={3} className={classes.showMore}>
        <ShowMore bols={root.bols} />
      </Box>
    </Box>
  })
)

export default List;
