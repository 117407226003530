import { types, getParent, flow } from 'mobx-state-tree';

const UIStore = types
  .model('UIStore', {
    loading: false,
    logoUrl: types.maybeNull(types.string),
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
    get toast() {
      return self.root.api.toast;
    }
  }))
  .actions(self => ({
    setLoading(loading) {
      self.loading = loading;
    },
    loadLogo: flow(function* () {
      try {
        const response = yield self.root.api.auth.logo();
        let imgUrl = 'data:image/png;base64,' + btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        self.logoUrl = imgUrl;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
  }));

export default UIStore;
