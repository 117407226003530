import React from "react";
import History from "./History";

const columns = [
  {
    Header: 'SKU#',
    accessor: 'sku',
    columnTitle: 'SKU#',
    defaultColumn: true,
  },
  {
    Header: 'Item Name',
    accessor: 'name',
    columnTitle: 'Item Name',
    defaultColumn: true,
  },
  {
    Header: 'Customer',
    accessor: 'customer',
    Cell: ({ row: { original } }) => original.customer?.name || '-',
    columnTitle: 'Customer',
    defaultColumn: true,
  },
  {
    Header: 'Create Time',
    accessor: 'created_at',
    columnTitle: 'Create Time',
    defaultColumn: true,
  },
  {
    Header: 'Qty on Hand',
    accessor: 'qty_on_hand',
    columnTitle: 'Qty on Hand',
    defaultColumn: true,
  },
  {
    Header: 'Qty Inbound',
    accessor: 'qty_inbound',
    columnTitle: 'Qty Inbound',
    defaultColumn: true,
  },
  {
    Header: 'Qty Outbound',
    accessor: 'qty_outbound',
    columnTitle: 'Qty Outbound',
    defaultColumn: true,
  },
  {
    Header: 'History',
    accessor: 'history',
    Cell: ({ row: { original } }) => {
      return <History history={original.product_qty_changes}></History>
    },
    columnTitle: 'History',
    defaultColumn: true,
  },
];

export default columns;
