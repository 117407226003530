import { types, getParent, } from 'mobx-state-tree';
import columns from '../components/pages/stockInventory/columns';
import ColumnStore from './column/ColumnStore';


const DEFAULT_COLUMNS = columns.filter(c => c.defaultColumn).map(c => c.accessor);
const COLUMN_NAME = 'products';

const ProductStore = types
  .model('ProductStore', {
    column: types.optional(ColumnStore, { table: COLUMN_NAME, selected: DEFAULT_COLUMNS, }),
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({


  }))


export default ProductStore;
