import { types, getParent } from 'mobx-state-tree';

const Outbound = types.model({
  id: types.number,
  uid: types.maybeNull(types.string),
  carrier_code: types.maybeNull(types.string),
})

const WorkOrder = types.model({
  id: types.number,
  uid: types.maybeNull(types.string),
  urgent_name: types.maybeNull(types.string),
})

export const DashboardData = types
  .model({
    todayInboundTotal: types.maybeNull(types.number),
    todayInboundTotalOcean: types.maybeNull(types.number),
    todayInboundTotalAir: types.maybeNull(types.number),
    todayInboundTotalGround: types.maybeNull(types.number),
    upcomingInboundTotal: types.maybeNull(types.number),
    upcomingInboundTotalOcean: types.maybeNull(types.number),
    upcomingInboundTotalAir: types.maybeNull(types.number),
    upcomingInboundTotalGround: types.maybeNull(types.number),
    overdueInboundTotal: types.maybeNull(types.number),
    overdueInboundTotalOcean: types.maybeNull(types.number),
    overdueInboundTotalAir: types.maybeNull(types.number),
    overdueInboundTotalGround: types.maybeNull(types.number),
    todayOutbounds: types.array(Outbound),
    upcomingOutbounds: types.array(Outbound),
    overdueOutbounds: types.array(Outbound),
    newWorkOrders: types.array(WorkOrder),
    inProgressWorkOrders: types.array(WorkOrder),
    completedWorkOrders: types.array(WorkOrder),
  })
  .actions(self => ({
    index() {
      getParent(self).index();
    },
  }))
