import { types, getParent } from 'mobx-state-tree';
import { isArray, debounce } from 'lodash';

const CONFIRMED_OPTIONS = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Yes',
    value: 'true',
  },
  {
    text: 'No',
    value: 'false',
  },
];

const Option = types
  .model({
    text: types.string,
    value: types.string,
  })

export const FilterStore = types
  .model({
    confirmed: types.optional(Option, { ...CONFIRMED_OPTIONS[0] }),
    weight_lb: '',
    delivery: '',
    delivery_code: '',
    whs_pallet_count: '',
    qty: '',
  })
  .volatile(self => ({

  }))
  .views(self => ({
    get confirmedOptions() {
      return CONFIRMED_OPTIONS;
    },
  }))
  .actions(self => ({
    renderSelectOption(o) {
      return o.text
    },
    handleSelect(name) {
      return (option) => {
        self.setValue(name, option);
      }
    },
    isSelectedEqualToOption(selected, option) {
      return selected && selected.value === option.value;
    },
    toValues() {
      const values = { ...self.toJSON() };

      for (const k in values) {
        if (isArray(values[k])) {
          values[k] = values[k].map(o => o['value']);
        } else if (values[k] && values[k].value !== undefined) {
          values[k] = values[k].value;
        }
      }

      return values;
    },
    setValue(name, value) {
      self[name] = value;
    },
    setValueAndSubmit(name, value) {
      self.setValue(name, value);
      self.submit();
    },
    setValueByHtmlElement(e) {
      const { value, name, type, checked } = e.target;
      self.setValue(name, type === 'checkbox' ? checked : value);
    },
    submit() {
      getParent(self, 1).loadCollection();
    },
    reset() {
      self.confirmed = CONFIRMED_OPTIONS[0];
      self.weight_lb = '';
      self.delivery = '';
      self.delivery_code = '';
      self.whs_pallet_count = '';
      self.qty = '';
    },
  }));
