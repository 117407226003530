export const BOL_LIST_TYPE_ALL = 'all';
export const BOL_LIST_TYPE_TODAY = 'today';
export const BOL_LIST_TYPE_UPCOMING = 'upcoming';
export const BOL_LIST_TYPE_OVER_DUE = 'overdue';
export const BOL_LIST_TYPE_COMPLETED = 'completed';
export const BOL_LIST_TYPE_CANCELED = 'canceled';

export const BOL_LIST_TYPES_MAP = {
  [BOL_LIST_TYPE_ALL]: 'Total',
  [BOL_LIST_TYPE_TODAY]: 'Today\'s BOL',
  [BOL_LIST_TYPE_UPCOMING]: 'Upcoming BOL',
  [BOL_LIST_TYPE_OVER_DUE]: 'Overdue BOL',
  [BOL_LIST_TYPE_COMPLETED]: 'Completed BOL',
  [BOL_LIST_TYPE_CANCELED]: 'Canceled BOL',
};


export const BOL_TYPE_FBM = 0;
export const BOL_TYPE_FBA = 1;
export const BOL_TYPE_UPS = 2;
export const BOL_TYPE_FEDEX = 3;
export const BOL_TYPE_FBA_DIRECT = 4;
export const BOL_TYPE_FBM_DIRECT = 5;
export const BOL_TYPE_WORK_ORDER = 6;
export const BOL_TYPE_OTHER = 8;
export const BOL_TYPE_ORDER_FULFILLMENT = 9;
export const BOL_TYPE_TEMPORARY_STORAGE = 10;
export const BOL_TYPE_SELF_PICKUP = 11;


export const BOL_TYPE_MAP = {
  [BOL_TYPE_FBM]: 'FBM',
  [BOL_TYPE_FBA]: 'FBA',
  [BOL_TYPE_UPS]: 'UPS',
  [BOL_TYPE_FEDEX]: 'FedEx',
  [BOL_TYPE_WORK_ORDER]: 'Work Order',
  [BOL_TYPE_ORDER_FULFILLMENT]: 'Order Fulfilment',
  [BOL_TYPE_TEMPORARY_STORAGE]: 'Temporary Storage',
  [BOL_TYPE_SELF_PICKUP]: 'Self Pickup',
  // [BOL_TYPE_FBA_DIRECT]: 'FBA Direct',
  // [BOL_TYPE_FBM_DIRECT]: 'FBM Direct',
  // [BOL_TYPE_OTHER]: 'Other',
}


export const BOL_PAYMENT_TYPE_PREPAID = 'PREPAID';
export const BOL_PAYMENT_TYPE_COLLECT = 'COLLECT';
export const BOL_PAYMENT_TYPE_THIRTY_PAIRTY = 'THIRTY PAIRTY';


export const BOL_PAYMENT_TYPES = [
  BOL_PAYMENT_TYPE_PREPAID,
  BOL_PAYMENT_TYPE_COLLECT,
  BOL_PAYMENT_TYPE_THIRTY_PAIRTY,
];
