import request from './request';

export const inventory = {
  index(params = {}) {
    return request.get('inventorys', { params });
  },
  adjustWhsPalletCount(loadIds, palletCount, reason) {
    return request.put(`inventorys/adjustWhsPalletCount`, { load_ids: loadIds, pallet_count: palletCount, reason: reason });
  },
  transferToWms(groupIds, time) {
    return request.put(`inventorys/group/transferToWms`, { group_ids: groupIds, time: time });
  },
  area(groupIds) {
    return request.get(`inventorys/group/area`, { params: { group_ids: groupIds } });
  },
  move(groupIds, areaId, time = null) {
    return request.put(`inventorys/group/move`, { area_id: areaId, time: time, group_ids: groupIds });
  },
  selectFilter(params) {
    return request.get(`inventorys/selectFilter`, { params });
  },
}
