import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Divider, InputLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react';
import CommonDocuments from '../../../common/Documents';
import { toast } from '../../../../services';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(theme => ({
  button: {
    padding: 0,
    fontSize: '15px',
    color: theme.palette.primary.main,
  },
  inputWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',

    padding: '11px 15px',
  },
  inputLabel: {
    marginBottom: '.5rem',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.palette.primary.main,
  },
  input: {
    padding: '5px',
    border: '1px solid #BBBFC8',
    borderRadius: '5px',
    flex: 1,
    width: '100%',
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogCloseBtn:
  {
    cursor: 'pointer',
    float: 'right',
    marginTop: '0px',
    width: '20px',
    color: theme.palette.primary.main,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '11px 22px',
  },
}));

const TroubleShootForm = observer(({ inbound }) => {
  const { detail, whsTroubleShootDocuments, createTroubleShoot } = inbound;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [description, setDescription] = useState();
  const [hasRevise, setHasRevise] = useState('0');

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleCreate = () => {
    setSubmitting(true)
    createTroubleShoot({
      warehouse_alert_id: detail.id,
      description,
      has_revise: hasRevise,
    }).then((response) => {
      if (response) {
        setOpen(false);
        setHasRevise(false);
        setDescription('');
        whsTroubleShootDocuments.clear();
      }

      setSubmitting(false)
    })
  }

  return <>
    <Button style={{ width: '100%' }} color="primary" onClick={handleOpen}>REPORT TROUBLE SHOOT</Button>
    {open && <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        Create New Trouble Shoot Case
        <CloseIcon onClick={handleClose} className={classes.dialogCloseBtn} />
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Box className={classes.inputWrapper}>
          <InputLabel className={classes.inputLabel}>1. Please Write the Description for the Trouble Shoot Case (Required*)</InputLabel>
          <InputBase mt={2} value={description} className={classes.input} multiline rows="6" onChange={e => setDescription(e.target.value)} placeholder="Write description for the Trouble Shoot here..." />
        </Box>
        <Box className={classes.inputWrapper}>
          <InputLabel className={classes.inputLabel}>2. Please upload the related Document (Optional)</InputLabel>
          <CommonDocuments documents={whsTroubleShootDocuments} dropzonePosition="top"></CommonDocuments>
        </Box>
        <Box className={classes.inputWrapper}>
          <InputLabel className={classes.inputLabel}>3. Do you need to revise the inbound shipment info? (Optional)</InputLabel>
          <RadioGroup row value={hasRevise} onChange={e => setHasRevise(e.target.value)} size="small">
            <FormControlLabel value="1" control={<Radio size="small" color="primary" />} label="Yes" />
            <FormControlLabel value="0" control={<Radio size="small" color="primary" />} label="No" />
          </RadioGroup>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container spacing={2}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Button fullWidth size="small" onClick={handleClose} variant="outlined" color="primary">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button disabled={submitting} fullWidth size="small" onClick={handleCreate} variant="contained" color="primary">
              {submitting ? 'Creating...' : 'Create'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>}
  </>
});

export default TroubleShootForm;
