import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Tooltip, Chip } from '@material-ui/core';
import { FlagIcon, LeftArrowIcon } from '../../../ui/Icon';
import { useHistory } from 'react-router-dom';
import PDF from './PDF';
import ConfirmShipout from './ConfirmShipout';
import AdjustPalletCount from '../../inventory/InventoryList/AdjustPalletCount';
import ConfirmBol from './ConfirmBol';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  backIconWrapper: {
    marginRight: '20px',
    lineHeight: '40px',
  },
  backIcon: {
    fontSize: '24px',
    color: '#00000',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  titleWrapper: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#000000',
  },
  title: {
    fontSize: '14px',
  },
  subTitle: {
    fontSize: '9px',
    opacity: 0.5,
  },
  confirmed: {
    marginLeft: theme.spacing(1),
  },
}));

const Header = observer(({ bol, inventorys }) => {
  const classes = useStyles();
  const history = useHistory();

  const { detail } = bol;

  return <Box className={classes.root}>
    <Box className={classes.backIconWrapper}>
      <LeftArrowIcon className={classes.backIcon} onClick={() => history.goBack()} />
    </Box>
    <Box className={classes.titleWrapper}>
      <Box className={classes.title}>
        {detail.uid} / {detail.delivery_code} / {detail.total_qty}KGS / {detail.carrier_name}
        {!!detail.confirmed_at && <Tooltip title={detail.confirmed_at}><span className={classes.confirmed}><Chip variant="outlined" color="primary" size="small" label="Confirmed" /></span></Tooltip>}
        {!!detail.changed_at && <Tooltip title={detail.changed_at}><span className={classes.changed}><Chip variant="outlined" color="primary" size="small" label="Update" /></span></Tooltip>}
      </Box>
      <Box className={classes.subTitle}>
        # BOL / Destination Code / # Pieces / Carrier
      </Box>
    </Box>
    <Box display="flex" ml="auto" mr={2}>
      {!detail.actual_pickup_time && <AdjustPalletCount selected={bol.selectedLoads} onAdjustFinished={bol.reload} inventorys={inventorys} />}
    </Box>
    <Box display="flex">
      {!!detail.actual_pickup_time && <Tooltip placement="top" title={`At ${detail.actual_pickup_time}`}>
        <Button onClick={() => detail.shipout_histories.length === 0 && detail.unpickup()} className={classes.confirmed} size="small" variant="outlined" color="primary" startIcon={<FlagIcon />}>CONFIRMED SHIPOUT</Button>
      </Tooltip>
      }
    </Box>
    {!!bol.root.auth.user.whs.whs_confirm_bol && <Box display="flex" mr={2}>
      <ConfirmBol bol={bol} />
    </Box>}
    <Box display="flex">
      {detail.confirmed_at ? <>
        {!detail.actual_pickup_time && <Box>
          <ConfirmShipout bol={bol} />
        </Box>}
      </> : <>
        <Box display="flex" alignItems="center">
          <Chip
            size="small"
            label="Please ask Operator Confirm this BOL first"
          />
        </Box>
        <Box ml={1}>
          <Button variant="contained" disabled>CONFIRM SIHPOUT</Button>
        </Box>
      </>}

      <Box ml={2}>
        <PDF detail={detail} />
      </Box>
    </Box>
  </Box >
})

export default Header;
