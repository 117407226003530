import { types, getParent, getRoot, flow, applySnapshot } from 'mobx-state-tree';
import { isArray, flatten } from 'lodash';
import { LOAD_STATUSES_MAP, LOAD_STATUS_RECEIVED, GROUP_STATUSES_MAP, GROUP_STATUS_IN_WHS, AREA_MAP, LOAD_TYPE_MAP } from './constants';
import { uniq } from 'lodash';
const Option = types
  .model({
    text: types.string,
    value: types.string,
  })

const ALL_OPTION = {
  text: 'All',
  value: '',
}

const LOAD_STATUS_OPTIONS = Object.keys(LOAD_STATUSES_MAP).map(i => ({ text: LOAD_STATUSES_MAP[i], value: i }));
const GROUP_STATUS_OPTIONS = Object.keys(GROUP_STATUSES_MAP).map(i => ({ text: GROUP_STATUSES_MAP[i], value: i }));
const AREA_OPTIONS = Object.keys(AREA_MAP).map(i => ({ text: AREA_MAP[i], value: i }));
GROUP_STATUS_OPTIONS.unshift({ ...ALL_OPTION });
AREA_OPTIONS.unshift({ ...ALL_OPTION });
const LOAD_TYPE_OPTIONS = Object.keys(LOAD_TYPE_MAP).map(i => ({ text: LOAD_TYPE_MAP[i], value: i }));

const IS_READY_TO_SHIP_OPTIONS = [ALL_OPTION, { text: 'Ready To Ship', value: 'true' }, { text: 'Hold', value: 'false' }];

export const FilterStore = types
  .model({
    containers: types.array(Option),
    markings: types.array(Option),
    delCodes: types.array(Option),
    bols: types.array(Option),
    // loadStatuses: types.optional(types.array(Option), [LOAD_STATUS_OPTIONS.find(s => s.value == LOAD_STATUS_RECEIVED)]),
    groupStatus: types.optional(Option, { ...GROUP_STATUS_OPTIONS.find(s => s.value == GROUP_STATUS_IN_WHS) }),
    area: types.optional(Option, { ...AREA_OPTIONS.find(s => s.value == '') }),
    isReadyToShip: types.optional(Option, { ...IS_READY_TO_SHIP_OPTIONS.find(s => s.value == '') }),
    loadTypes: types.array(Option),
  })
  .volatile(self => ({
    containerStringArray: [],
    markingStringArray: [],
    delCodeStringArray: [],
    bolStringArray: [],
    areaOptionsArray: [],
  }))
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get activeFilters() {
      const activeValues = [];

      self.containers.forEach(option => {
        if (option.value) {
          activeValues.push({
            field: 'containers',
            option,
          });
        }
      })

      self.markings.forEach(option => {
        if (option.value) {
          activeValues.push({
            field: 'markings',
            option,
          });
        }
      })

      self.bols.forEach(option => {
        if (option.value) {
          activeValues.push({
            field: 'bols',
            option,
          });
        }
      })

      self.delCodes.forEach(option => {
        if (option.value) {
          activeValues.push({
            field: 'delCodes',
            option,
          });
        }
      })


      if (self.groupStatus.value) {
        activeValues.push({
          field: 'groupStatus',
          option: self.groupStatus,
        });
      }

      if (self.area.value) {
        activeValues.push({
          field: 'area',
          option: self.area,
        });
      }

      if (self.isReadyToShip.value) {
        activeValues.push({
          field: 'isReadyToShip',
          option: self.isReadyToShip,
        });
      }

      self.loadTypes.forEach(option => {
        if (option.value) {
          activeValues.push({
            field: 'Load Type',
            option,
          });
        }
      })
      return activeValues;
    },
    get containerOptions() {
      return self.containerStringArray.map(d => ({
        text: d,
        value: d,
      }));
    },
    get markingOptions() {
      return self.markingStringArray.map(d => ({
        text: d,
        value: d,
      }));
    },
    get delCodeOptions() {
      return self.delCodeStringArray.map(d => ({
        text: d,
        value: d,
      }));
    },
    get bolsOptions() {
      return self.bolStringArray.map(d => ({
        text: d,
        value: d,
      }));
    },
    get loadStatusOptions() {
      return LOAD_STATUS_OPTIONS;
    },
    get groupStatusOptions() {
      return GROUP_STATUS_OPTIONS;
    },
    get areaOptions() {
      return [ALL_OPTION, ...getParent(self).areas.map(o => ({
        text: o.name,
        value: `${o.id}`,
      }))];
    },
    get isReadyToShipOptions() {
      return IS_READY_TO_SHIP_OPTIONS;
    },
    get loadTypeOptions() {
      return LOAD_TYPE_OPTIONS
    },
  }))
  .actions(self => ({
    setContainerStringArray(containerStringArray) {
      self.containerStringArray = containerStringArray;
    },
    setMarkingStringArray(markingStringArray) {
      self.markingStringArray = markingStringArray;
    },
    setDelCodeStringArray(delCodeStringArray) {
      self.delCodeStringArray = delCodeStringArray;
    },
    setBolStringArray(bolStringArray) {
      self.bolStringArray = bolStringArray;
    },
    setAreaOptionsArray(areaOptions) {
      self.areaOptionsArray = areaOptions;
    },
    renderSelectOption(o) {
      return o.text
    },
    onSelectSearchFilter(query, o) {
      return self.renderSelectOption(o).match(new RegExp(query, 'i'));
    },
    onSelectSearchCntrFilter: flow(function* (query) {
      if (query == '') {
        self.setContainerStringArray(uniq(getParent(self).inventorys.map(i => i.ib_number).filter(i => i)));
      } else {
        const { data } = yield self.root.api.inventory.selectFilter({ type: 'ib_number', query });
        self.containerStringArray = data;
      }
    }),
    onSelectSearchMarkingFilter: flow(function* (query) {
      if (query == '') {
        self.setMarkingStringArray(uniq(getParent(self).inventorys.map(i => i.marking).filter(i => i)));
      } else {
        const { data } = yield self.root.api.inventory.selectFilter({ type: 'marking', query });
        self.markingStringArray = data;
      }
    }),
    onSelectSearchBolFilter: flow(function* (query) {
      if (query == '') {
        self.setBolStringArray(uniq([].concat.apply([], getParent(self).inventorys.map(i => [].concat.apply([], i.bols.map(b => b.uid))).filter(i => i))));
      } else {
        const { data } = yield self.root.api.inventory.selectFilter({ type: 'bol', query });
        self.setBolStringArray(data);
      }
    }),
    handleSelect(name) {
      return (option) => {
        self.setValue(name, option);
      }
    },
    isSelectedEqualToOption(selected, option) {
      return selected && selected.value === option.value;
    },
    toValues() {
      const values = { ...self.toJSON() };

      for (const k in values) {
        if (isArray(values[k])) {
          values[k] = values[k].map(o => o['value']);
        } else {
          values[k] = values[k]['value'];
        }
      }

      return values;
    },
    setValue(name, value) {
      self[name] = value;
      getParent(self, 1).loadInventorys({ page: 1 });
    },
    reset() {
      // self.containers = [];
      // self.marking = [];
      // self.delCodes = [];
      // self.bols = [];
      applySnapshot(self, {})
      getParent(self, 1).loadInventorys({ page: 1 });
    },
    clear() {
      const values = self.toJSON();

      const vs = {};

      for (const [key, value] of Object.entries(values)) {
        if (isArray(value)) {
          vs[key] = [];
        } else {
          vs[key] = { ...ALL_OPTION };
        }
      }

      applySnapshot(self, vs);

      getParent(self, 1).loadInventorys({ page: 1 });
    },
    removeFilter(field, option) {
      if (isArray(self[field])) {
        self[field].remove(option);
      } else {
        self[field] = { ...ALL_OPTION };
      }

      getParent(self, 1).loadInventorys({ page: 1 });
    },
    checkItemIsActive(item) {
      if (isArray(item) && item.map(i => i.value).filter(i => i).length > 0) {
        return true;
      } else if (!isArray(item) && item && item.value) {
        return true;
      }

      return false;
    },
  }));
