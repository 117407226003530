import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { observer } from "mobx-react";
import { FixedSizeList as List } from "react-window";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 35px",
  },
  title: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "150%",
    color: theme.palette.primary.main,
  },
  count: {
    fontSize: "18px",
  },
  rows: {
    height: "150px",
    overflowY: "auto",
  },
}));

const Card = observer(
  ({ title, count, onClickViewDetail, rows = [], renderRow }) => {
    const classes = useStyles();

    return (
      <Box className={classes.root}>
        <Box display="flex" className={classes.title} mb={1}>
          <Box>{title}</Box>
          <Box ml="auto" className={classes.count}>
            {count}
          </Box>
        </Box>
        {/* <Box >{rows}</Box> */}
        <Box className={classes.rows}>
          <List height={150} itemCount={rows.length} itemSize={35} width="100%">
            {({ index, style }) => (
              <Box style={style} key={index}>
                {renderRow ? renderRow(rows[index]) : rows[index]}
              </Box>
            )}
          </List>
        </Box>
        <Box display="flex" mt={2}>
          <Box ml="auto">
            {onClickViewDetail && (
              <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={onClickViewDetail}
              >
                View Detail
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);

export default Card;
