import { types, getParent, flow, applySnapshot } from 'mobx-state-tree';
import { LIST_TYPES_MAP, LIST_TYPE_NEW } from './constants';
import { TypeCountItem, WorkOrderRow } from './models';
import { resolvePaginationStore } from '../PaginationStore';
import { FilterStore } from './FilterStore';
import FileSaver from 'file-saver';



const WorkOrdersStore = types
  .model('WorkOrdersStore', {
    type: types.optional(types.enumeration(Object.keys(LIST_TYPES_MAP)), LIST_TYPE_NEW),
    typeCounts: types.frozen(Object.keys(LIST_TYPES_MAP).map(t => TypeCountItem.create({ value: t, text: LIST_TYPES_MAP[t] }))),
    collection: types.array(WorkOrderRow),
    pagination: types.optional(
      resolvePaginationStore({
        onLoadMore: (self) => getParent(self).loadMore(),
        onChangePerPage: (self) => getParent(self).loadCollection(),
      }),
      {
        perPage: 20
      },
    ),
    filter: types.optional(FilterStore, {}),
    sortByName: 'whs_work_orders.id',
    sortByValue: 'desc',
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    replaceCollection(data) {
      for (let row of self.collection) {
        if (row.id == data.id) {
          applySnapshot(row, data);
        }
      }
    },
    setType(type) {
      self.type = type;
    },
    loadCounts: flow(function* () {
      try {
        const { data } = yield self.root.api.workOrder.counts()
        self.typeCounts.forEach(item => {
          item.setCount(data.data[item.value]);
        })
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadCollection: flow(function* () {

      try {
        const { data } = yield self.root.api.workOrder.index({
          type: self.type,
          page: self.pagination.page,
          per_page: self.pagination.perPage,
          sort_by: self.sortByName,
          sort_value: self.sortByValue,
          ...self.filter.toValues(),
        });

        self.collection = data.data
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadMore: flow(function* () {
      try {
        const { data } = yield self.root.api.workOrder.index({
          type: self.type,
          page: self.pagination.page,
          per_page: self.pagination.perPage,
          sort_by: self.sortByName,
          sort_value: self.sortByValue,
          ...self.filter.toValues(),
        });
        self.collection.push(...data.data);
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    setTypeAndLoad(type) {
      self.pagination.reset();
      self.setType(type);
      self.loadCollection();
    },
    sortBy(name, value) {
      self.sortByName = name;
      self.sortByValue = value;
      self.loadCollection();
    },
    downloadExcel: flow(function* () {
      const response = yield self.root.api.workOrder.downloadExcel();
      const file = new Blob(
        [response.data],
        {
          type: response.headers['content-type'],
        }
      );
      FileSaver.saveAs(file, 'workOrders.xlsx');
    }),
  }));

export default WorkOrdersStore;
