import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CommonDocuments from '../../../common/Documents';

const useStyles = makeStyles(theme => ({
  root: {
  },
  header: {
    display: 'flex',
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.5,
    color: '#000000',
  },
}));

const WhsDocuments = observer(({ workOrder }) => {
  const classes = useStyles();

  return <Box className={classes.root}>
    <Box className={classes.header}>
      <Box className={classes.headerTitle}>
        Whs Documents
      </Box>
    </Box>
    <hr />
    <Box mt={2}>
      <CommonDocuments documents={workOrder.whsDocuments} dropzonePosition="top" />
    </Box>
  </Box>
});

export default WhsDocuments;
