import { types, applySnapshot, getRoot } from 'mobx-state-tree';

export const resolvePaginationStore = ({ onChangePage, onChangePerPage, onLoadMore }) => {
  return types
    .model('SimplePaginationStore', {
      page: 1,
      lastPage: 1,
      total: 0,
      perPage: 20,
    })
    .views(self => ({
      get root() {
        return getRoot(self);
      },
      get totalPage() {
        return self.lastPage;
      },
    }))
    .actions(self => ({
      setFromMeta(meta) {
        applySnapshot(self, {
          page: parseInt(meta.current_page),
          lastPage: parseInt(meta.last_page),
          total: parseInt(meta.total),
          perPage: parseInt(meta.per_page),
        });
      },
      setPage(page) {
        self.page = page;
        onChangePage && onChangePage(self, page);
      },
      setPerPage(perPage) {
        self.perPage = perPage;
        onChangePerPage && onChangePerPage(self, perPage);
      },
      loadMore() {
        self.page += 1;
        onLoadMore && onLoadMore(self, self.page);
      },
      reset() {
        applySnapshot(self, {
          page: 1,
          total: 0,
          perPage: self.perPage,
        });
      },
    }))
}
