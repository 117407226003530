import React, { useState } from 'react';
import CommonDocuments from '../../../common/Documents';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import FormControl from '@material-ui/core/FormControl';
import { format, isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    minHeight: '300px',
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const POD = observer(({ shipout, index }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(null);

  const { podDocuments, loadPodDocuments } = shipout;

  const handleOpen = () => {
    loadPodDocuments();
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = () => {
    shipout.deliver(format(time, 'yyyy-MM-dd HH:mm:ss')).then(response => response && handleClose());
  }

  React.useEffect(() => {
    if (shipout.delivered_at) {
      setTime(utcToZonedTime(shipout.delivered_at, shipout.root.app.timezone));
    }
  }, [shipout.delivered_at, shipout.root.app.timezone]);

  const submitable = time && isValid(time) && podDocuments.hasDocuments;


  return <>
    <Button variant={shipout.delivered_at ? 'outlined' : 'contained'} color="primary" size="small" onClick={handleOpen}>{shipout.delivered_at ? 'View POD' : 'Upload POD'}</Button>
    {open && <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="md"
    >
      <DialogContent>
        <Box className={classes.content}>
          <Box mb={2}>
            <Box mb={2} className={classes.subtitle}>Shipout Info</Box>
            <Box><strong>Shipout {index}</strong></Box>
            <Box>PLT {shipout.total_pallet_count}</Box>
            <Box>{shipout.carrier_name}</Box>
            <Box>{shipout.shipout_at}</Box>
          </Box>
          <Box mb={2} className={classes.subtitle}>1. Please enter the delivery time for this Shipout *</Box>
          <Box mb={2}>
            <FormControl required>
              <KeyboardDateTimePicker
                variant="inline"
                value={time}
                onChange={setTime}
                format="yyyy-MM-dd HH:mm"
              />
            </FormControl>
          </Box>
          <Box mb={2} className={classes.subtitle}>2. Upload POD *</Box>
          <CommonDocuments pastable documents={podDocuments} onUploaded={loadPodDocuments} onDeleted={loadPodDocuments} />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                disabled={!submitable}
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>}
  </>
});

export default POD;

