import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.primary.main,
    alignItems: 'center',
    marginBottom: '16px',
  },
}))

const Title = observer(({ title, icon }) => {
  const classes = useStyles();

  return <Box className={classes.root}>
    <Box>{icon}</Box>
    <Box ml={1}>{title}</Box>
  </Box>
})

export default Title;
