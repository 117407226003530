export const INBOUND_LIST_TYPE_ALL = 'all';
export const INBOUND_LIST_TYPE_TODAY = 'today';
export const INBOUND_LIST_TYPE_UPCOMING = 'upcoming';
export const INBOUND_LIST_TYPE_OVER_DUE = 'overdue';
export const INBOUND_LIST_TYPE_COMPLETED = 'completed';
export const INBOUND_LIST_TYPE_CANCELED = 'canceled';

export const INBOUND_LIST_TYPES_MAP = {
  [INBOUND_LIST_TYPE_ALL]: 'Total',
  [INBOUND_LIST_TYPE_UPCOMING]: 'Upcoming Inbound',
  [INBOUND_LIST_TYPE_TODAY]: 'Today\'s Inbound',
  [INBOUND_LIST_TYPE_OVER_DUE]: 'Overdue Inbound',
  [INBOUND_LIST_TYPE_COMPLETED]: 'Completed Inbound',
  [INBOUND_LIST_TYPE_CANCELED]: 'Canceled Inbound',
};

export const INBOUND_GROUPBY_MARKING = 'marking';
export const INBOUND_GROUPBY_DESTINATION = 'deliveryLocation.code';

export const INBOUND_GROUPBY_MAP = {
  [INBOUND_GROUPBY_MARKING]: 'Carton Marking',
  [INBOUND_GROUPBY_DESTINATION]: 'Destination',
}


export const INBOUND_DEFAULT_CBM_PER_PALLET_RATIO = 2.0;
export const INBOUND_DEFAULT_WEIGHT_LBS_LIMIT_PER_PALLET_RATIO = 1000;

export const WAREHOUSE_TASK_TYPE_LABEL = 1;
export const WAREHOUSE_TASK_TYPE_REMOVE_LABEL = 2;
export const WAREHOUSE_TASK_TYPE_SORT = 3;
export const WAREHOUSE_TASK_TYPE_REPACK = 4;
export const WAREHOUSE_TASK_TYPE_REPALLETIZE = 5;
export const WAREHOUSE_TASK_TYPE_ISSUE_DOCUMENT = 6;
export const WAREHOUSE_TASK_TYPE_INSPECTION = 7;
export const WAREHOUSE_TASK_TYPE_HOLD = 8;
export const WAREHOUSE_TASK_TYPE_OTHER = 15;

export const WAREHOUSE_TASK_TYPE_MAP = {
  [WAREHOUSE_TASK_TYPE_LABEL]: 'LABEL',
  [WAREHOUSE_TASK_TYPE_REMOVE_LABEL]: 'REMOVE LABEL',
  [WAREHOUSE_TASK_TYPE_SORT]: 'SORT',
  [WAREHOUSE_TASK_TYPE_REPACK]: 'REPACK',
  [WAREHOUSE_TASK_TYPE_REPALLETIZE]: 'REPALLETIZE',
  [WAREHOUSE_TASK_TYPE_ISSUE_DOCUMENT]: 'ISSUE DOCUMENT',
  [WAREHOUSE_TASK_TYPE_INSPECTION]: 'INSPECTION',
  [WAREHOUSE_TASK_TYPE_HOLD]: 'HOLD',
  [WAREHOUSE_TASK_TYPE_OTHER]: 'OTHER ',
}

export const WAREHOUSE_TASK_NAME_HOLD_RLS = 'HOLD RLS';

export const OCEAN_INBOUND_TABLE_NAME = 'ocean_inbounds';
export const GROUND_INBOUND_TABLE_NAME = 'ground_inbounds';
export const AIR_INBOUND_TABLE_NAME = 'air_inbounds';


export const INBOUND_SHIPMENT_TYPE_OCEAN = 0;
export const INBOUND_SHIPMENT_TYPE_GROUND = 1;
export const INBOUND_SHIPMENT_TYPE_AIR = 2;

export const INBOUND_SHIPMENT_TYPE_MAP = {
  [INBOUND_SHIPMENT_TYPE_OCEAN]: 'Ocean Shipment',
  [INBOUND_SHIPMENT_TYPE_GROUND]: 'Ground Shipment',
  [INBOUND_SHIPMENT_TYPE_AIR]: 'Air Shipment',
};
