import React from 'react';
import Box from '@material-ui/core/Box';
import SortBy from '../../ui/SortBy';
import { LOAD_TYPE_MAP } from '../../../stores/inventory/constants';

const columns = [
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>DEL</Box>
      <Box><SortBy name="del" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'del',
    Footer: <strong>Total</strong>
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>type</Box>
      <Box><SortBy name="type" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'type',
    Cell: ({ row: { original } }) => {
      return <Box>{LOAD_TYPE_MAP[original.type]}</Box>
    }

  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>In WHS</Box>
      <Box><SortBy name="in_whs" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'in_whs',
    Footer: (table) => {
      return <Box><strong>{table.data.reduce((acc, row) => acc + Number(row.in_whs.replace(/,/g, "")), 0)}</strong></Box>
    },
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>Outbound Scheduled (Cargo loaded)</Box>
      <Box><SortBy name="cargo_loaded" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'cargo_loaded',
    Footer: (table) => {
      return <Box><strong>{table.data.reduce((acc, row) => acc + Number(row.cargo_loaded.replace(/,/g, "")), 0)}</strong></Box>
    }
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>Outbound Scheduled (Cargo not yet loaded)</Box>
      <Box><SortBy name="carge_not_loaded" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'carge_not_loaded',
    Footer: (table) => {
      return <Box><strong>{table.data.reduce((acc, row) => acc + Number(row.carge_not_loaded.replace(/,/g, "")), 0)}</strong></Box>
    },
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>Pending outbound</Box>
      <Box><SortBy name="pending_outbound" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'pending_outbound',
    Footer: (table) => {
      return <Box><strong>{table.data.reduce((acc, row) => acc + Number(row.pending_outbound.replace(/,/g, "")), 0)}</strong></Box>
    }
  },

];

export default columns;
