export const LIST_TYPE_ALL = 'all';
export const LIST_TYPE_NEW = 'new';
export const LIST_TYPE_IN_PROGRESS = 'inprogress';
export const LIST_TYPE_COMPLETED = 'completed';
export const LIST_TYPE_REJECTED = 'rejected';
export const LIST_TYPE_CANCELED = 'canceled';

export const LIST_TYPES_MAP = {
  [LIST_TYPE_ALL]: 'Total',
  [LIST_TYPE_NEW]: 'New WO',
  [LIST_TYPE_IN_PROGRESS]: 'In Progress WO',
  [LIST_TYPE_COMPLETED]: 'Completed WO',
  [LIST_TYPE_REJECTED]: 'Rejected WO',
  [LIST_TYPE_CANCELED]: 'Cancelled WO',
};

export const TASK_TYPE_LABEL = 1;
export const TASK_TYPE_REMOVE_LABEL = 2;
export const TASK_TYPE_SORT = 3;
export const TASK_TYPE_REPACK = 4;
export const TASK_TYPE_REPALLETIZE = 5;
export const TASK_TYPE_ISSUE_DOCUMENT = 6;
export const TASK_TYPE_INSPECTION = 7;
export const TASK_TYPE_HOLD = 8;
export const TASK_TYPE_OTHER = 15;

export const TASK_TYPE_MAP = {
  [TASK_TYPE_LABEL]: 'LABEL',
  [TASK_TYPE_REMOVE_LABEL]: 'REMOVE LABEL',
  [TASK_TYPE_SORT]: 'SORT',
  [TASK_TYPE_REPACK]: 'REPACK',
  [TASK_TYPE_REPALLETIZE]: 'REPALLETIZE',
  [TASK_TYPE_ISSUE_DOCUMENT]: 'ISSUE DOCUMENT',
  [TASK_TYPE_INSPECTION]: 'INSPECTION',
  [TASK_TYPE_HOLD]: 'HOLD',
  [TASK_TYPE_OTHER]: 'OTHER',
};

export const TASK_UNITS = [
  'Bin',
  'Box',
  'Carton',
  'Package',
  'Pallet',
  'Piece',
  'Roll',
];
