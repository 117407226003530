import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TradePartySelect from '../../../common/TradePartySelect';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    padding: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  table: {

  },
  form: {
    minHeight: '300px',
  },
}));

const ConfirmBol = observer(({ bol }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [otrCarrier, setOtrCarrier] = useState(null);

  const { detail, loading } = bol;

  const handleOpen = () => {
    setOpen(true);
    setOtrCarrier(detail.otr_carrier || bol.root.auth.user.whs)
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    bol.confirm(detail.id, {
      otr_carrier_id: otrCarrier.id,
    })
      .then(response => {
        if (response) {
          bol.root.ui.toast.success('Saved');
          setOpen(false)
        }
      })
  }

  const canSubmit = () => {
    return !loading;
  }

  return <>
    <Button variant="outlined" color="primary" onClick={handleOpen}>CONFIRM BOL</Button>
    {open && <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <form onSubmit={handleSubmit} className={classes.form}>
          <Box className={classes.content}>
            <Box className={classes.subtitle}>Please Select OTR Carrier *</Box>
            <Box mt={1}>
              <TradePartySelect
                types={['OTR Carrier', 'Drayage Trucker', 'Warehouse', 'Internal Warehouse']}
                size="sm"
                selected={otrCarrier || {}}
                onChange={setOtrCarrier}
              />
            </Box>
            <Box mt={16} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!canSubmit()}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>}
  </>
});


export default ConfirmBol;

