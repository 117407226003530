import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import { QuestionAnswer } from '@material-ui/icons';
import { format } from 'date-fns'
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background: '#F8F9FB',
    margin: '10px 28px',
    // padding: '22px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
  },
  time: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#4F4F4F',
  },
  content: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    margin: '8px 0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // width:'300px'
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'end',
  },
  btn: {
    marginRight: '10px'
  },
  routeLink:{
    textDecoration:'none',
  },
  flex:{
    display:'flex'
  }

}))

const NotificationList = inject('root')
  (observer(({ root }) => {
    const classes = useStyles();
    React.useEffect(() => {
      root.chatRoomNotification.loadCollection();
    }, []);
    return <>
      {root.chatRoomNotification.unreadCollection.map((row) => <Card className={classes.root} sx={{ minWidth: 275 }}>
        <CardContent>
          <div className={classes.header}>
            <div className={classes.link}><QuestionAnswer style={{ fontSize: '14px', marginRight: '3px' }} /><Link to={row.route ? row.route.toJSON() : null}>{row.subject_uid}</Link> </div>
            <div className={classes.time}>{row.latest_time}</div>
          </div>
          <div className={classes.content}>{row.sender}: {row.latest_message}</div>
          <div className={classes.buttonGroup}>
            <Button className={classes.btn} size="small" onClick={() => root.chatRoomNotification.markAsRead(row.chat_room_id)} variant="outlined" color="primary">
              Mark as Read
            </Button>
            <Link className={classes.routeLink} to={row.route ? row.route.toJSON() : null}>
              <Button size="small" variant="outlined" color="primary">
                View Details
              </Button>
            </Link>
          </div>
        </CardContent>
      </Card>)}
    </>
  }))


export default NotificationList
