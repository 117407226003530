import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { DashboardData } from './models';

const DashboardStore = types
  .model('DashboardStore', {
    dashboardData: types.optional(DashboardData, {}),
  })
  .volatile(self => ({
    loading: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    loadData: flow(function* () {
      try {
        self.loading = true;
        const response = yield self.root.api.dashboard.index();
        self.dashboardData = response.data.data;
        self.loading = false;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
  }))


export default DashboardStore;
