import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: '18px',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
  },
  tabs: {
    display: 'inline-block',
    fontSize: '18px',
    lineHeight: 1.5,
    textAlign: 'center',
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#80889A',
    backgroundColor: '#F1F3F9',
  },
  tab: {
    display: 'inline-block',
    padding: '15px 25px',
    cursor: 'pointer',
    borderRadius: '3px 3px 0px 0px',
  },
  tabActive: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    backgroundColor: '#FFFFFF',
  },
  tabsContent: {
    padding: '15px',
    minHeight: '169px',
  },
  inputWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    border: '1px dashed #80889A',
    marginTop: '15px',
    padding: '11px 15px',
  },
  input: {
    flex: 1,
    width: '100%',
  },
  feedbackSaveButton: {
    marginTop: '10px',
  },
}));

const tabsData = [
  {
    value: 0,
    text: 'Remark',
  },
  {
    value: 1,
    text: 'Feedback',
  },
];

const Remark = observer(({ detail }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  const handleFeedbackSave = () => {
    detail.updateFeedback(detail.feedback);
  }

  return <Box className={classes.root}>
    <Paper elevation={0} className={classes.tabs}>
      {tabsData.map(data => <Box key={data.value} className={clsx(classes.tab, { [classes.tabActive]: tab === data.value })} onClick={() => setTab(data.value)}>
        <span>{data.text}</span>
      </Box>)}
    </Paper>
    <Paper elevation={0} className={classes.tabsContent}>
      {tab === 0 && <Box className={classes.inputWrapper}>
        <InputBase value={detail.remark || ''} className={classes.input} multiline rows="8" disabled />
      </Box>}
      {tab === 1 && <Box>
        <Box className={classes.inputWrapper}>
          <InputBase value={detail.feedback} className={classes.input} multiline rows="8" onChange={e => detail.setFeedback(e.target.value)} />
        </Box>
        <Button className={classes.feedbackSaveButton} size="small" onClick={handleFeedbackSave} variant="contained" color="primary">Save</Button>
      </Box>}
    </Paper>
  </Box>
})

export default Remark;
