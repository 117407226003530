import React from 'react';
import { observer } from 'mobx-react';
import Popover from '../../../ui/Popover';
import { InformationIcon } from '../../../ui/Icon';
import Box from '@material-ui/core/Box';
import { truncate } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  popoverBody: {
    color: '#FFFFFF',
    textAlign: 'left',
  },
  name: {
    marginTop: '20px',
  },
  contact: {
  },
  contactRow: {
    display: 'flex',
    marginTop: '15px',
  },
  contactRowItem: {
    minWidth: '110px',
  },
  title: {
    fontSize: '10px',
    lineHeight: '12px',
  },
  value: {
    fontSize: '14px',
    lineHeight: '24px',
  },
}))

const Trucker = observer(({ trucker }) => {
  const classes = useStyles();

  return <Box display="flex" alignItems="center" justifyContent="center">
    <Box>
      {truncate(trucker.name, { length: 20})}
    </Box>
    <Box>
      <Popover title="Trucker Information" toggle={<InformationIcon style={{ fontSize: '14px' }} />}>
        <Box className={classes.popoverBody}>
          <Box className={classes.name}>
            <Box className={classes.title}>Name</Box>
            <Box className={classes.value}>{trucker.name}</Box>
          </Box>
          <Box className={classes.contact}>
            {trucker.contacts.map((c, i) => <Box key={i} className={classes.contactRow}>
              <Box className={classes.contactRowItem}>
                <Box className={classes.title}>Type</Box>
                <Box className={classes.value}>{c.type}</Box>
              </Box>
              <Box className={classes.contactRowItem}>
                <Box className={classes.title}>Phone Num</Box>
                <Box className={classes.value}>{c.phone}</Box>
              </Box>
              <Box className={classes.contactRowItem}>
                <Box className={classes.title}>Email</Box>
                <Box className={classes.value}>{c.email}</Box>
              </Box>
            </Box>)}
          </Box>
        </Box>
      </Popover>
    </Box>
  </Box>
})

export default Trucker;
