import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';



const StatusIcon = ({ detail }) => {
  if (detail.solved_at) {
    return <Tooltip title={'Solved'}><CheckCircleIcon style={{ fontSize: '21px' }} /></Tooltip>
  }

  if (detail.canceled_at) {
    return <Tooltip title={'Canceled'}><CancelIcon style={{ fontSize: '21px' }} /></Tooltip>
  }

  return null;
}

const columns = [
  {
    Header: 'TB#',
    accessor: 'id',
    Cell: ({ row: { original } }) => <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box width={24} mr={1}><StatusIcon detail={original} /></Box>
        <Box><Link to={{ pathname: `/inbound/${original.warehouse_alert_id}`, hash: 'troubleshoot' }}>{original.uid}</Link></Box>
        <Box width={24} ml={1}></Box>
      </Box>
    </>,
  },
  {
    Header: 'CNTR#',
    accessor: 'container_number',
  },
  {
    Header: 'TB Status',
    accessor: 'status',
    Cell: ({ row: { original } }) => <Box className="text-center">{original.status_name}</Box>,
  },

  {
    Header: 'TB Description',
    accessor: 'description',
    Cell: ({ row: { original } }) => <Box style={{ textAlign: 'left' }}>
      {original.description}
    </Box>,
  },
  {
    Header: 'Create Date',
    accessor: 'created_at',
  },
  {
    Header: 'Attachments',
    accessor: 'doc',
    Cell: ({ row: { original, canExpand, isExpanded, getToggleRowExpandedProps } }) => <Box  {...getToggleRowExpandedProps()}>
      <a href="javascript:void(0);">View</a>
    </Box>,
  },
  {
    Header: 'Chat Room',
    accessor: 'expand',
    Cell: ({ row: { original, canExpand, isExpanded, getToggleRowExpandedProps } }) => <Box  {...getToggleRowExpandedProps()}>
      <a href="javascript:void(0);">View</a>
    </Box>,
  },
];


export default columns;
