import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import FunctionsIcon from '@material-ui/icons/Functions';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  root: {
  },
  icon: {
    cursor: 'pointer',
  },
  input: {
    minWidth: '200px',
  },
}));

const EstimatePalletCount = observer(({ detail }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return <>
    <FunctionsIcon className={classes.icon} onClick={handleClick} />
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <Box p={2}>
        <Box>
          <TextField className={classes.input} type="number" required label="CBM Per Pallet" value={detail.cbmPerPalletRatio} onChange={e => detail.setCbmPerPalletRatio(e.target.value)} />
        </Box>
        <Box mt={3}>
          <TextField className={classes.input} type="number" required label="Weight Limit Per Pallet" value={detail.weightLbsLimitPerPallet} onChange={e => detail.setWeightLbsLimitPerPallet(e.target.value)} />
        </Box>
      </Box>
    </Popover>
  </>
});


export default EstimatePalletCount;
