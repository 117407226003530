import React from 'react';
import { NavLink } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import ScrollTop from './ScrollTop';
import Logo from '../ui/Logo';
import { DashboardIcon, InBoundIcon, OutBoundIcon, WHSTaskIcon, InventoryIcon, ProfileIcon, SignOutIcon } from '../ui/Icon';
import SearchBar from './SearchBar';
import Avatar from 'react-avatar';
import { observer } from 'mobx-react';
import AssessmentIcon from '@material-ui/icons/Assessment';
import NotificationBell from './NotificationBell';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const openDrawerWidth = 240;
const closeDrawerWidth = 130;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: openDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',

    '& > .MuiDrawer-paperAnchorDockedLeft': {
      borderRight: 'none',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    }
  },
  drawerOpen: {
    width: openDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: closeDrawerWidth,
  },
  list: {
    margin: '28px 27px 0 26px',
  },
  listItemLink: {
    textDecoration: 'none',
    '&.active > .MuiListItem-root': {
      background: '#F1F3F9',
      borderRadius: '3px',

      '& > .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },

      '& > .MuiListItemText-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  listItem: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '27px',
    letterSpacing: '0.05em',
    color: theme.palette.secondary.main,
    padding: '12px 20px',
    margin: '8px 0',
  },
  listSubItem: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: 1.5,
    letterSpacing: '0.05em',
    color: theme.palette.secondary.main,
    padding: '10px 20px',
    margin: '4px 0',
  },
  listItemActive: {
    background: '#F1F3F9',
    borderRadius: '3px',

    '& > .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },

    '& > .MuiListItemText-root': {
      color: theme.palette.primary.main,
    },
  },
  listItemIcon: {
    color: theme.palette.dark.main,
    height: '18px',
    minWidth: '28px',

    '& > svg.MuiSvgIcon-root': {
      fontSize: '18px',
    },
  },
  listItemExpanIcon: {
    fontSize: '18px',
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
    '& > .MuiTypography-body1': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '27px',
      letterSpacing: '0.05em',
    },
  },
  listSubItemText: {
    marginTop: 0,
    marginBottom: 0,
    '& > .MuiTypography-body1': {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: 1.5,
      letterSpacing: '0.05em',
    },
  },
  content: {
    marginLeft: '35px',
    flexGrow: 1,
    padding: theme.spacing(3),
    background: theme.palette.blueGray.main,
  },
  chevronPositionWrapper: {
    position: 'fixed',
    top: 0,
    height: '100%',
    background: theme.palette.blueGray.main,
  },
  chevronOpenWrapper: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    left: openDrawerWidth,
  },
  chevronCloseWrapper: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    left: closeDrawerWidth,
  },
  chevronBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '10px',
    height: '100%',
  },
  chevronBtn: {
    width: '25px',
    height: '82px',
    background: '#FFFFFF',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    border: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    }
  },
  logo: {
    height: '87px',
    width: '140px',
    marginTop: '44px',
  },
  logoOpen: {
    marginLeft: '45px',
    transition: theme.transitions.create('marginLeft', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoClose: {
    transition: theme.transitions.create('marginLeft', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '5px',
  },
  signout: {
    marginTop: 'auto',
    marginLeft: '36px',
    marginBottom: '16px',
  },
  signoutContent: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '150%',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    opacity: 0.5,
  },
  signoutContentOpen: {
    transition: theme.transitions.create('display', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'block',
  },
  signoutContentClose: {
    transition: theme.transitions.create('display', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'none',
  },
}));

const routes = [
  {
    text: 'Dashboard',
    icon: <DashboardIcon />,
    to: '/',
    exact: true,
  },
  {
    text: 'InBound',
    icon: <InBoundIcon />,
    to: '/inbound',
    childrens: [
      {
        text: 'Ocean Inbound',
        to: '/inbound/ocean',
      },
      {
        text: 'Ground Inbound',
        to: '/inbound/ground',
      },
      {
        text: 'Air Inbound',
        to: '/inbound/air',
      },
      {
        text: 'InBound Trouble Shoot',
        to: '/troubleshoots',
      },
    ]
  },
  {
    text: 'Outbound',
    icon: <OutBoundIcon />,
    to: '/outbounds',
    childrens: [
      {
        text: 'Outbound List',
        to: '/outbounds',
      },
      {
        text: 'OB BOL List',
        to: '/bols',
      },
    ]
  },
  {
    text: 'Work Order',
    icon: <WHSTaskIcon />,
    to: '/workOrders',
  },
  {
    text: 'WHS Inventory',
    icon: <InventoryIcon />,
    to: '/inventory',
  },
  {
    text: 'Stock Inventory',
    icon: <InventoryIcon />,
    to: '/stockinventory',
  },
  {
    text: 'Reports',
    icon: <AssessmentIcon />,
    to: '/report',
  },
  {
    text: 'Whs Pallet Report',
    icon: <AssessmentIcon />,
    to: '/whsPalletReport',
  }
];

const Layout = observer(({ auth, app, children, isAuthenticated }) => {
  const classes = useStyles();

  const open = app.isNavabrOpen;

  const toggleDrawer = () => {
    app.toggleNavbarOpen();
  }

  return isAuthenticated ? <>
    <div className={classes.root} >
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={clsx(classes.logo, {
          [classes.logoOpen]: open,
          [classes.logoClose]: !open,
        })}>
          <Logo />
        </div>
        <List className={classes.list}>
          {routes.map((route) => (
            <NavItem key={route.text} open={open} route={route} classes={classes} />
          ))}
        </List>
        <div className={classes.signout}>
          <Button onClick={auth.logout}>
            <SignOutIcon />
            <span className={clsx(classes.signoutContent, {
              [classes.signoutContentOpen]: open,
              [classes.signoutContentClose]: !open,
            })}>
              &nbsp;Sign Out
            </span>
          </Button>
        </div>
      </Drawer>
      <div className={clsx(classes.chevronPositionWrapper, {
        [classes.chevronOpenWrapper]: open,
        [classes.chevronCloseWrapper]: !open,
      })}>
        <div className={classes.chevronBtnWrapper}>
          <button className={classes.chevronBtn} onClick={toggleDrawer}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </button>
        </div>

      </div>
      <main className={classes.content}>
        <Box display="flex" mb={4}>
          <Box flexGrow="1">
            <SearchBar />
          </Box>
          <Box ml={3} display="flex" alignItems="center">
            <NotificationBell />
          </Box>
          <Box ml={3}>
            <Avatar name={auth.user.name} size="40" round={true} />
          </Box>
        </Box>
        <Box>
          {children}
        </Box>
      </main>
      <ScrollTop />
    </div >
  </> : children;
})

const NavItem = ({ route, classes, open }) => {
  const [isExpanded, setIsExpanaded] = React.useState(true);

  const listItemContent = <ListItem button className={classes.listItem} onClick={() => setIsExpanaded(!isExpanded)}>
    <ListItemIcon className={classes.listItemIcon}>{route.icon}</ListItemIcon>
    {open && <ListItemText className={classes.listItemText} primary={route.text} />}
    {route.childrens && <>
      {isExpanded && <ExpandMoreIcon className={classes.listItemExpanIcon} />}
      {!isExpanded && <ExpandLessIcon className={classes.listItemExpanIcon} />}
    </>}
  </ListItem>

  return <>
    {!route.childrens ? <NavLink to={route.to} exact={!!route.exact} className={classes.listItemLink}>
      {listItemContent}
    </NavLink> : listItemContent
    }
    {isExpanded && route.childrens && route.childrens.map(r => <NavLink key={r.text} to={r.to} exact={!!r.exact} className={classes.listItemLink}>
      <ListItem button className={classes.listSubItem}>
        <ListItemIcon className={classes.listItemIcon}>{r.icon}</ListItemIcon>
        {open && <ListItemText className={classes.listSubItemText} primary={r.text} />}
      </ListItem>
    </NavLink>)}
  </>
}

const ChevronLeftIcon = () => {
  return <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.272394 6.70337L5.53638 0.330366C5.90021 -0.110122 6.48854 -0.110122 6.8485 0.330366L7.72325 1.38941C8.08709 1.8299 8.08709 2.54217 7.72325 2.97798L3.99589 7.5L7.72712 12.0173C8.09096 12.4578 8.09096 13.1701 7.72712 13.6059L6.85237 14.6696C6.48854 15.1101 5.90021 15.1101 5.54025 14.6696L0.276264 8.29663C-0.0914405 7.85614 -0.0914404 7.14386 0.272394 6.70337Z" fill="#001235" />
  </svg>
}

const ChevronRightIcon = () => {
  return <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.72761 8.29663L2.46362 14.6696C2.09979 15.1101 1.51146 15.1101 1.1515 14.6696L0.276746 13.6106C-0.0870878 13.1701 -0.0870879 12.4578 0.276746 12.022L4.00411 7.5L0.272874 2.98266C-0.0909597 2.54218 -0.0909597 1.8299 0.272874 1.3941L1.14762 0.330367C1.51146 -0.11012 2.09979 -0.11012 2.45975 0.330367L7.72373 6.70337C8.09144 7.14386 8.09144 7.85614 7.72761 8.29663Z" fill="#001235" />
  </svg>
}


export default Layout;
