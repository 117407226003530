import request from './request';

export const chatRoom = {

  pull(target, targetId) {
    return request.get(`chatroom/messages/${target}/${targetId}`);
  },
  sendMessage(target, targetId, params) {
    return request.post(`chatroom/messages/${target}/${targetId}`, params);
  },
  uploadDocuments(target, targetId, values) {
    return request.post(`chatroom/messages/${target}/${targetId}/documents`, values, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
}
