import { types, getEnv } from 'mobx-state-tree';
import AuthStore from './AuthStore';
import UIStore from './UIStore';
import AppStore from './AppStore';
import InputStore from './InputStore';
import OceanInboundsStore from './inbound/OceanInboundsStore';
import GroundInboundsStore from './inbound/GroundInboundsStore';
import AirInboundsStore from './inbound/AirInboundsStore';
import InboundStore from './inbound/InboundStore';
import DashboardStore from './dashboard/DashboardStore';
import InventorysStore from './inventory/InventorysStore';
import BolsStore from './bol/BolsStore';
import BolStore from './bol/BolStore';
import TasksStore from './task/TasksStore';
import TaskStore from './task/TaskStore';
import SearchStore from './search/SearchStore';
import ReportStore from './report/ReportStore';
import TroubleShoot from './troubleShoot/TroubleShootsStore';
import WorkOrdersStore from './workOrder/WorkOrdersStore';
import WorkOrderStore from './workOrder/WorkOrderStore';
import ChatRoomNotificationStore from './notification/ChatRoomNotificationStore';
import OutboundsStore from './outbound/OutboundsStore';
import ProductStore from './ProductStore';
import WhsPalletReport from './whsPalletReport/WhsPalletReport';


const RootStore = types
  .model('RootStore', {
    auth: types.optional(AuthStore, {}),
    ui: types.optional(UIStore, {}),
    app: types.optional(AppStore, {}),
    oceanInbounds: types.optional(OceanInboundsStore, {}),
    groundInbounds: types.optional(GroundInboundsStore, {}),
    airInbounds: types.optional(AirInboundsStore, {}),
    inbound: types.optional(InboundStore, {}),
    troubleShoots: types.optional(TroubleShoot, {}),
    dashboard: types.optional(DashboardStore, {}),
    inventorys: types.optional(InventorysStore, {}),
    bols: types.optional(BolsStore, {}),
    bol: types.optional(BolStore, {}),
    tasks: types.optional(TasksStore, {}),
    task: types.optional(TaskStore, {}),
    search: types.optional(SearchStore, {}),
    inputManager: types.optional(InputStore, {}),
    report: types.optional(ReportStore, {}),
    workOrders: types.optional(WorkOrdersStore, {}),
    workOrder: types.optional(WorkOrderStore, {}),
    chatRoomNotification: types.optional(ChatRoomNotificationStore, {}),
    outbounds: types.optional(OutboundsStore, {}),
    products: types.optional(ProductStore, {}),
    whsPalletReport: types.optional(WhsPalletReport, {}),
  })
  .views(self => ({
    get api() {
      return getEnv(self).api;
    },
  }))
  .actions(self => ({
    start() {
      !!self.auth.token && self.ui.loadLogo();
      self.auth.loadUser().then(() => self.app.setApploaded(true));
    },
  }))

export default RootStore;
