import { types, getParent, flow, applySnapshot } from 'mobx-state-tree';
import { INBOUND_LIST_TYPES_MAP, INBOUND_LIST_TYPE_TODAY, INBOUND_LIST_TYPE_COMPLETED, INBOUND_LIST_TYPE_CANCELED, INBOUND_SHIPMENT_TYPE_AIR } from './constants';
import { TypeCountItem, InboundRow } from './models';
import ColumnStore from '../column/ColumnStore';
import { resolvePaginationStore } from '../PaginationStore';
import { AIR_INBOUND_TABLE_NAME } from './constants';
import { FilterStore } from './FilterStore';
import columns from '../../components/pages/inbound/AirList/columns';

const DEFAULT_COLUMNS = columns.map(c => c.accessor);

const AirInboundsStore = types
  .model('AirInboundsStore', {
    type: types.optional(types.enumeration(Object.keys(INBOUND_LIST_TYPES_MAP)), INBOUND_LIST_TYPE_TODAY),
    typeCounts: types.frozen(Object.keys(INBOUND_LIST_TYPES_MAP).map(t => TypeCountItem.create({ value: t, text: INBOUND_LIST_TYPES_MAP[t] }))),
    collection: types.array(InboundRow),
    column: types.optional(ColumnStore, { table: AIR_INBOUND_TABLE_NAME, selected: DEFAULT_COLUMNS, }),
    pagination: types.optional(
      resolvePaginationStore({
        onLoadMore: (self) => getParent(self).loadMore(),
        onChangePerPage: (self) => getParent(self).loadCollection(),
      }),
      {
        perPage: 20
      },
    ),
    filter: types.optional(FilterStore, {}),
    sortByName: '',
    sortByValue: '',
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
    get isTypeCompleted() {
      return self.type == INBOUND_LIST_TYPE_COMPLETED;
    },
    get isTypeCanceled() {
      return self.type == INBOUND_LIST_TYPE_CANCELED;
    },
  }))
  .actions(self => ({
    replaceCollection(data) {
      for (let row of self.collection) {
        if (row.id == data.id) {
          applySnapshot(row, data);
        }
      }
    },
    setType(type) {
      self.type = type;
    },
    loadCounts: flow(function* () {
      try {
        const { data } = yield self.root.api.inbound.counts({
          shipment_type: INBOUND_SHIPMENT_TYPE_AIR,
        })
        self.typeCounts.forEach(item => {
          item.setCount(data.data[item.value]);
        })
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadCollection: flow(function* ({ page = 1 } = {}) {
      try {
        const { data } = yield self.root.api.inbound.index({
          type: self.type,
          page: page,
          per_page: self.pagination.perPage,
          sort_by: self.sortByName,
          sort_value: self.sortByValue,
          shipment_type: INBOUND_SHIPMENT_TYPE_AIR,
          ...self.filter.toValues(),
        });
        self.collection = data.data
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    loadMore: flow(function* () {
      try {
        const { data } = yield self.root.api.inbound.index({
          type: self.type,
          page: self.pagination.page,
          per_page: self.pagination.perPage,
          sort_by: self.sortByName,
          sort_value: self.sortByValue,
          shipment_type: INBOUND_SHIPMENT_TYPE_AIR,
          ...self.filter.toValues(),
        });
        self.collection.push(...data.data);
        self.pagination.setFromMeta(data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    setTypeAndLoad(type) {
      self.pagination.reset();
      self.setType(type);
      self.loadCollection();
    },
    newDisappear: flow(function* (id) {
      try {
        self.root.api.inbound.newDisappear(id);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    sortBy(name, value) {
      self.sortByName = name;
      self.sortByValue = value;
      self.loadCollection();
    },
  }));

export default AirInboundsStore;
