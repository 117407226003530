import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import CollectionText from '../../../common/CollectionText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WhsGroupBadge from '../../../common/WhsGroupBadge';
import SortBy from '../../../ui/SortBy';
import { truncate } from 'lodash';

const columns = [
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>WO#</Box>
      <Box><SortBy name="whs_work_orders.id" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'id',
    Cell: ({ row: { original } }) => <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box mr={1}>{!!original.completed_at && <Tooltip title={`Completed at ${original.completed_at}`}><CheckCircleIcon style={{ fontSize: '21px' }} /></Tooltip>}</Box>
        <Box><Link to={`/workOrders/${original.id}`}>{original.uid}</Link></Box>
      </Box>
    </>,
  },
  {
    Header: 'Work Order Title',
    accessor: 'title',
    Cell: ({ row: { original } }) => <>
      {original.title?.length > 20 ?
        <Tooltip title={original.title} placement="buttom">
          <span>{truncate(original.title, { length: 20 })}...</span>
        </Tooltip> : original.title
      }
    </>
  },
  {
    Header: 'Order Status',
    accessor: 'status_name',
  },
  {
    Header: 'Order Type',
    accessor: 'type_name',
  },
  {
    Header: 'Urgent Level',
    accessor: 'urgent_name',
  },
  {
    Header: 'IB#',
    accessor: 'container_number',
    Cell: ({ row: { original } }) => <>
      <CollectionText texts={original.shipmentGroups} taken={1} rowRender={group => group.warehouse_alert_id ? <Link to={`/inbound/${group.warehouse_alert_id}`}>{group.ib_number}</Link> : group.ib_number} emptyContent="-" tooltip />
    </>
  },
  {
    Header: 'WHS Marking',
    accessor: 'marking',
    Cell: ({ row: { original } }) => <>
      <CollectionText texts={original.markings} taken={1} emptyContent="-" tooltip />
    </>
  },
  {
    Header: ({ state }) => <Box display="flex" alignItems="center" justifyContent="center">
      <Box>Group Status</Box>
      <Box><SortBy name="load_whs_groups.status" by={state.sortByName} value={state.sortByValue} onSort={state.sortBy} /></Box>
    </Box>,
    accessor: 'group_status',
    Cell: ({ row: { original } }) => <Box display="flex" justifyContent="center">
      <Box><CollectionText texts={original.groups} taken={1} emptyContent="-" rowRender={group => group.uid} tooltip /></Box>
      <Box ml={1}><CollectionText texts={original.groups} taken={1} emptyContent="-" rowRender={group => <WhsGroupBadge group={group} />} tooltip /></Box>
    </Box>
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
  },
];

export default columns;
