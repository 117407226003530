import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Documents from './Documents';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '25px',
    fontWeight: 500,
  },
  paper: {
    padding: '17px 27px',
  },
  typeName: {
    fontSize: '18px',
  },
  releasedBox: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginLeft: '23px',
  },
  releasedText: {
    fontSize: '9px',
    border: '1px solid ' + theme.palette.primary.main,
    padding: '3px 5px',
    borderRadius: '3px',
  },
  releasedTime: {
    fontSize: '12px',
    marginLeft: '8px',
  },
  completedBox: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginLeft: '23px',
  },
  completedText: {
    fontSize: '9px',
    border: '1px solid ' + theme.palette.primary.main,
    padding: '3px 5px',
    borderRadius: '3px',
  },
  completedTime: {
    fontSize: '12px',
    marginLeft: '8px',
  },
  canceledBox: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    marginLeft: '23px',
  },
  canceledText: {
    fontSize: '9px',
    border: '1px solid ' + theme.palette.primary.main,
    padding: '3px 5px',
    borderRadius: '3px',
  },
  canceledTime: {
    fontSize: '12px',
    marginLeft: '8px',
  },
  divider: {
    marginTop: '20px',
  },
  infoBox: {
    marginTop: '20px',
  },
  infoAttributeWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginLeft: '120px',
    },
    '& > div:first-child': {
      marginLeft: 0,
    },
  },
  infoAttributeBox: {
    fontSize: '14px',
  },
  infoAttributeTitle: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  infoAttributeContent: {
    fontWeight: 'normal',
    marginTop: '8px',
    minHeight: '20px',
  },
  descriptionBox: {
    marginTop: '20px',
  },
  descriptionContent: {
    backgroundColor: theme.palette.lightGray.main,
    border: '1px dashed ' + theme.palette.secondary.main,
    borderRadius: '5px',
    padding: '11px 15px',
    marginTop: '8px',
    minHeight: '150px',
  },
  documentsBox: {
    marginTop: '40px',
  },
}));

const TaskBox = observer(({ task }) => {
  const classes = useStyles();

  return <>
    <Box className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Box
          display="flex"
          alignItems="center"
        >
          <Box className={classes.typeName}>{task.type_name}</Box>
          {task.type_name == 'HOLD' && !!task.released_at && <Box className={classes.releasedBox}>
            <Box className={classes.releasedText}>Hold Released</Box>
            <Box className={classes.releasedTime}>{task.released_at}</Box>
          </Box>}
          {!!task.completed_at && <Box className={classes.completedBox}>
            <Box className={classes.completedText}>Completed</Box>
            <Box className={classes.completedTime}>{task.completed_at}</Box>
          </Box>}
          {!!task.canceled_at && <Box className={classes.canceledBox}>
            <Box className={classes.canceledText}>Canceled</Box>
            <Box className={classes.canceledTime}>{task.canceled_at}</Box>
          </Box>}
          <Box ml="auto">
            {task.completed_at
              ? <Button variant="outlined" color="primary" onClick={task.uncomplete}>Cancel Complete</Button>
              : <Button variant="contained" color="primary" onClick={task.complete}>Complete</Button>}
          </Box>
        </Box>

        <Divider className={classes.divider} />

        <Grid container spacing={2} className={classes.infoBox}>
          <Grid item md={6} sm={12}>
            <Box className={classes.infoAttributeWrapper}>
              <Box className={classes.infoAttributeBox}>
                <Box className={classes.infoAttributeTitle}>
                  Task Unit:
                </Box>
                <Box className={classes.infoAttributeContent}>
                  {task.unit}
                </Box>
              </Box>
              <Box className={classes.infoAttributeBox}>
                <Box className={classes.infoAttributeTitle}>
                  Qty:
                </Box>
                <Box className={classes.infoAttributeContent}>
                  {task.qty}
                </Box>
              </Box>
              <Box className={classes.infoAttributeBox}>
                <Box className={classes.infoAttributeTitle}>
                  Task Due Date:
                </Box>
                <Box className={classes.infoAttributeContent}>
                  {task.due_at}
                </Box>
              </Box>
            </Box>

            <Box className={classes.descriptionBox}>
              <Box className={classes.infoAttributeTitle}>
                Description:
              </Box>
              <Box className={classes.descriptionContent}>
                {task.description}
              </Box>
            </Box>
          </Grid>

          <Grid item md={6} sm={12}>
            <Box className={classes.documentsBox}>
              <Documents whsDocuments={task.whsDocuments} adminDocuments={task.adminDocuments} />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  </>
});

export default TaskBox;
