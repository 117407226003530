import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Documents from './Documents';
import ChatRoom from './../../../common/ChatRoom';
import { observer } from 'mobx-react';
import Memo from './Memo';
import ShipoutHistories from './ShipoutHistories';

const Footer = observer(({ bol }) => {
  const { detail, whsDocuments, adminDocuments } = bol;
  return <Box mt={5}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Memo bol={bol} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ShipoutHistories bol={bol} />
      </Grid>
    </Grid>
    <Box mt={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Documents whsDocuments={whsDocuments} adminDocuments={adminDocuments} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChatRoom
            targetId={detail.id}
            target="bol_whs"
            sendMessage
            header
          />
        </Grid>
      </Grid>
    </Box>
  </Box>
})

export default Footer;
