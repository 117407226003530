import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Detail from './Detail';
import Footer from './Footer';
import Header from './Header';
import { useParams, useLocation } from "react-router-dom";
import TroubleShoot from './TroubleShoot';

export default inject('root')(
  observer(({ root, warehouseAlertId }) => {
    const location = useLocation();
    const { inbound } = root;
    warehouseAlertId = warehouseAlertId ? warehouseAlertId : useParams().warehouseAlertId;

    useEffect(() => {
      inbound.loadData(warehouseAlertId).then(response => {
        if (response && location.hash === '#troubleshoot' && inbound.detail.whs_trouble_shoot && inbound.detail.whs_trouble_shoot.id) {
          inbound.detail.whs_trouble_shoot.open();
        }
      });
      inbound.whsDocuments.load(warehouseAlertId);
      inbound.adminDocuments.load(warehouseAlertId);
    }, [warehouseAlertId]);

    return <>
      <Header inbound={inbound} />
      <Detail inbound={inbound} />
      <Footer inbound={inbound} />
      {inbound.detail.whs_trouble_shoot && inbound.detail.whs_trouble_shoot.id && <TroubleShoot header whs_trouble_shoot={inbound.detail.whs_trouble_shoot} />}
    </>
  })
)
