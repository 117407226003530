import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';


const MarkingLink = observer(({ marking, loadId }) => {
  return <Link to={`tasks/${loadId}`}>
    {marking}
  </Link>
})

export default MarkingLink;
