export const GREEN = '#72eb725e';
export const RED = '#f68e8e4d';
export const YELLOW = '#f3f38269';

export const ALL_EMPTY = 0
export const PARTIALLY_EMPTY = 1
export const NON_EMPTY = 2

export const ROW_COLOR_MAP = {
  [ALL_EMPTY]: RED,
  [PARTIALLY_EMPTY]: YELLOW,
  [NON_EMPTY]: GREEN,
}

export const ROW_COLOR_NAME_MAP = {
  [ALL_EMPTY]: 'All Empty',
  [PARTIALLY_EMPTY]: 'Partially Empty',
  [NON_EMPTY]: 'Non Empty',
}



