import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../ui/Table';
import Box from '@material-ui/core/Box';
import { pick, flatten } from 'lodash';
import Filter from './Filter';
import columns from './columns';
import Actions from './Actions';
import Pagination from './Pagination';

const useStyles = makeStyles((theme) => ({
  root: {

  },
}));



const InventoryList = inject('root')(
  observer(({ root }) => {
    const classes = useStyles();

    const {
      loadInventorys,
      loadWhsArea,
      inventorys,
      filter,
      column,
    } = root.inventorys;

    useEffect(() => {
      column.load();
      loadInventorys({ page: 1 });
      loadWhsArea();
    }, []);

    const totalAttributes = [
      'total_weight_lb',
      'total_volume_cbm',
      'total_estimate_pallet_count',
      'total_inbound_pallet_count',
      'total_whs_pallet_count',
      'total_shipout_pallet_count',
      'total_remaining_pallet_count',
      'total_qty',
      'total_actual_qty',
    ];

    const totalValues = pick(root.inventorys, totalAttributes);

    const setSelected = React.useCallback((selected) => {
      root.inventorys.setSelected(selected.map(s => getSnapshot(s)));
    }, []);


    return <>
      <Box className={classes.root}>
        <Box mb={1} display="flex">
          <Box>
            <Filter filter={filter} />
          </Box>
          <Box ml="auto">
            <Actions inventorys={root.inventorys} />
          </Box>
        </Box>
        <Box>
          <Table
            stickyHeader
            footerable
            selection
            onSelectedChange={setSelected}
            columns={React.useMemo(() => columns.filter(c => column.selected.indexOf(c.accessor) !== -1), [column.selected.length])}
            data={inventorys.slice()}
            state={{ totalValues }}
          />
        </Box>
        <Box mt={2} >
          <Pagination inventorys={root.inventorys} />
        </Box>
      </Box>
    </>
  })
);

export default InventoryList;
