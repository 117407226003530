import request from './request';

export const addonService = {
  complete(id) {
    return request.post(`addonServices/${id}/complete`);
  },
  uncomplete(id) {
    return request.post(`addonServices/${id}/uncomplete`);
  },
}
