import React from 'react';
import { observer } from 'mobx-react';
import Editable from '../../../common/Editable';
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../../ui/Select';


const useStyles = makeStyles(() => ({
  button: {
    fontSize: '14px',
    padding: 0,
  },
}));

const ItemSelectable = observer(({ value, onChange, options = [], disabled = false, resigster = false, multiple }) => {
  const classes = useStyles();

  return <Editable
    resigster={resigster}
    value={value}
    onHideEditChange={onChange}
    buttonClassName={classes.button}
    renderValue={v => v.map(v => v.text).join(', ')}
    multiple={multiple}
  >
    {({ onChange, value, multiple }) => <Select
      emptySelectedText="All"
      options={options}
      selected={value}
      renderOption={(option) => option.text}
      onSelect={onChange}
      isSelectedEqualToOption={(selected, option) => option.value === selected?.value}
      multiple={multiple}
    />}
  </Editable>
})

export default ItemSelectable;
