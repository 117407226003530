import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Select from '../../../ui/Select';
import Input from '../../../ui/Input';
import { observer } from 'mobx-react';
import { IconButton, Popover } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ROW_COLOR_MAP, ROW_COLOR_NAME_MAP } from './data';
import { ErrorOutline } from '@material-ui/icons';
// import _ from 'lodash';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const Filter = observer(({ filter }) => {
  const {
    id,
    bol_id,
    carrier,
    search,
    renderSelectOption,
    handleSelect,
    isSelectedEqualToOption,
    setValueByHtmlElement,
    reset,
    submit,
  } = filter;


  const handleSubmit = (e) => {
    e.preventDefault()
    submit();
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;
  const classes = useStyles();

  return <form onSubmit={handleSubmit}>
    <Box display="flex" alignItems="flex-end">
      <Box ml={1}>
        <Input
          title="OB#"
          name="id"
          value={id}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1}>
        <Input
          title="BOL#"
          name="bol_id"
          value={bol_id}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1}>
        <Input
          title="Carrier"
          name="carrier"
          value={carrier}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1}>
        <Input
          title="ISF/REF#"
          name="search"
          value={search}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1} display="flex">

        <Box ml={1}><Button variant="outlined" color="primary" type="submit">Apply</Button></Box>
        <Box ml={1}><Button variant="outlined" color="primary" onClick={reset}>Reset Filter</Button></Box>
        <Box>
          <div>
            <IconButton aria-describedby={id} variant="outlined" color="primary" onClick={handleClick}>
              {/* <ErrorOutlineIcon /> */}
              <ErrorOutline />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography className={classes.typography}>
                <> {Object.keys(ROW_COLOR_MAP).map((key) => {
                  return <div key={`color-${key}`} >
                    <div style={{
                      width: '200px',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }} >
                      <div>{ROW_COLOR_NAME_MAP[key]}</div>
                      <div style={{
                        background: ROW_COLOR_MAP[key],
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%'
                      }}> </div>

                    </div>
                  </div>
                })}</>
              </Typography>
            </Popover>
          </div>
        </Box>
      </Box>
    </Box>
  </form>
})

export default Filter;
