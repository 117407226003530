import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from 'react-router-dom';
import ChatRoomNotification from './ChatRoomNotification';


const ChatRoomNotificationModal = () => {
  const history = useHistory();

  return <>
    <Dialog onClose={() => history.goBack()} open={true}>
      <DialogTitle id="simple-dialog-title">Chat Room Unread Message</DialogTitle>
      <ChatRoomNotification />
    </Dialog>
  </>
}

export default ChatRoomNotificationModal;
