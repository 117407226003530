import request from './request';

export const chatRoomNotification = {
  index() {
    return request.get('chatroom/notifications')
  },
  unread() {
    return request.get('chatroom/notifications/unread');
  },
  unreadCount() {
    return request.get('chatroom/notifications/unreadCount');
  },
  markAllAsRead() {
    return request.post('chatroom/notifications/markAllAsRead');
  },
  markAsRead(chatRoomId) {
    return request.post('chatroom/notifications/markAsRead/' + chatRoomId);
  },
}
