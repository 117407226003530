import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    marginTop: '25px',
  },
  infoBox: {
    fontSize: '14px',
  },
  infoTitle: {
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  infoContent: {
  },
}));

const Info = observer(({ task }) => {
  const classes = useStyles();

  const { detail } = task;

  return <>
    <Box className={classes.root}>
      <Box className={classes.infoBox}>
        <Box className={classes.infoTitle}>Received Date:</Box>
        <Box className={classes.infoContent}>{detail.received_date}</Box>
      </Box>
    </Box>
  </>
})

export default Info;
