import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  triggerClick: {
    cursor: 'pointer',
  },
  popupWrapper: {
    position: 'absolute',
    zIndex: 10,
    top: '26px',
    left: '-23px',
  },
  popup: {
    position: 'relative',
  },
  popupArrow: {
    position: 'absolute',
    border: '4px solid transparent',
    borderBottom: '10px solid #2E52C5',
    top: '-14px',
    left: '26px',
  },
  popupContent: {
    textAlign: 'left',
    color: '#FFFFFF',
    background: '#2E52C5',
    padding: '25px 35px',
    whiteSpace: 'nowrap',
  },
  title: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    borderBottom: '0.5px solid #F8F9FB',
    paddingBottom: '10px',
  },
}))

const Popover = ({ title, children, trigger = 'click', toggle, className }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleTrigger = () => {
    setOpen(!open);
  }

  const handleClickOutside = () => {
    setTimeout(() => setOpen(false), [200]);
  }

  const triggerProps = {}
  if (trigger === 'click') {
    triggerProps.onClick = handleTrigger;
    triggerProps.className = classes.triggerClick;
  }

  return <Box className={clsx(classes.root, className)}>
    <Box {...triggerProps}>{toggle}</Box>
    {open && <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Box className={classes.popupWrapper}>
        <Box className={classes.popup}>
          <Box className={classes.popupArrow}></Box>
          <Box className={classes.popupContent}>
            {title && <Box className={classes.title}>{title}</Box>}
            <Box>{children}</Box>
          </Box>
        </Box>
      </Box>
    </OutsideClickHandler>}
  </Box>
}

export default Popover;
