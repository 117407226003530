import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    textDecorationLine: 'none',
    marginRight: '50px',
    color: ({ active }) => active ? theme.palette.primary.main : theme.palette.secondary.main,
  },
  doneIcon: {
    fontSize: '21px',
    color: ({ active }) => active ? theme.palette.primary.main : theme.palette.secondary.main,
  },
  marking: {
    fontSize: '14px',
    fontWeight: 500,
    paddingBottom: '10px',
    borderBottom: ({ active }) => active ? '2.5px solid ' + theme.palette.primary.main : 'none',
  },
}));

const LoadBox = observer(({ load, role, active }) => {
  const classes = useStyles({ active });

  return <>
    <Link to={`/tasks/${load.id}`} className={classes.root}>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          {role && <Chip variant="outlined" color={active ? 'primary' : 'secondary'} size="small" label={role} />}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          {!!load.whs_task_completed_at && <Box mr={1}><CheckCircleIcon className={classes.doneIcon} /></Box>}
          <Box className={classes.marking}>{load.marking}</Box>
        </Box>
      </Box>
    </Link>
  </>
})

export default LoadBox;
