import React from 'react';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import { Divider } from '@material-ui/core';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { SearchIcon } from '../ui/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getSnapshot } from 'mobx-state-tree';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '40px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E4E6EA',
    borderRadius: '5px',
    padding: '10px 14px',
    display: 'flex',
  },
  input: {
    height: '100%',
    fontSize: '14px',
    lineHeight: 1.5,
    fontWeight: 'normal',
    fontStyle: 'normal',
    color: '#00000',
    flexGrow: 1,

    '&::placeholder': {
      color: '#B3C0CC',
    },
  },
  searchIconWrapper: {
    lineHeight: '18px',
    marginRight: '12px',
  },
  searchIcon: {
    fontSize: '18px',
    color: '#B3C0CC',
  },
  searchIconActive: {
    color: '#000000',
  },
  listboxContainer: {
    position: 'relative',
  },
  listbox: {
    margin: '0px',
    padding: '0px',
    zIndex: 10,
    boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    width: '100%',
    maxHeight: '300px',
    '& div:first-child:hover': {
      backgroundColor: '#F5F5FA',
      cursor: 'pointer',
    },
  },
  listItem: {
    padding: '5px 10px 5px 17px',
  },
  optionText: {
    color: theme.palette.primary.main,
    textDecoration: 'underline'
  },
  divider: {

  },
}));

const SearchBar = inject('root')(
  observer(({ root }) => {
    const classes = useStyles();
    const history = useHistory();
    const { search } = root;
    const { query, loading, setQuery, searchedItems, setQueryAndLoad, } = search;

    const {
      getRootProps,
      getInputLabelProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
      focused,
    } = useAutocomplete({
      clearOnBlur: true,
      inputValue: query,
      options: searchedItems.slice(),
      getOptionLabel: (option) => option.text.toString(),
      onInputChange: (event, value) => setQueryAndLoad(value),
    });


    const redirect = (url) => {
      setQuery('');
      history.push(getSnapshot(url));
    }

    return <>
      <Box className={classes.root} id="back-to-top-anchor" {...getRootProps()}>
        <Box className={classes.searchIconWrapper}><SearchIcon className={clsx(classes.searchIcon, { [classes.searchIconActive]: !!query })} /> </Box>
        <InputBase {...getInputProps()} className={classes.input} />
        <Box>{loading && <CircularProgress size={20} />}</Box>
      </Box>
      {groupedOptions.length > 0 && !!query ? (
        <Box className={classes.listboxContainer}>
          <ul className={classes.listbox} {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li key={index}>
                <div className={classes.listItem} onClick={() => redirect(option.url)}>
                  <div><strong>{option.title}</strong></div>
                  <div><small>{option.text}</small></div>
                  <div>{'# ' + option.topic}</div>
                  <div>&gt;&nbsp;<span className={classes.optionText}>{option.uid}</span></div>
                </div>
                <Divider className={classes.divider} />
              </li>
            ))}
          </ul>
        </Box>
      ) : null}
      {groupedOptions.length === 0 && !!query && !loading && focused ?
        <Box boxShadow={1} className={classes.listboxContainer}>
          <ul className={classes.listbox}>
            <li onMouseDown={(event) => { event.preventDefault(); }}>
              <div>We didn't find any result for '{query}'. Sorry!</div>
            </li>
          </ul>
        </Box>
        : null}
    </>
  })
);

export default SearchBar;
