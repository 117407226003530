import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Select from '../../../ui/Select';
import Input from '../../../ui/Input';
import { observer } from 'mobx-react';


const Filter = observer(({ filter }) => {
  const {
    confirmed,
    confirmedOptions,
    weight_lb,
    delivery,
    delivery_code,
    whs_pallet_count,
    qty,
    renderSelectOption,
    handleSelect,
    isSelectedEqualToOption,
    setValueByHtmlElement,
    reset,
    submit,
  } = filter;


  const handleSubmit = (e) => {
    e.preventDefault()
    submit();
  }

  return <form onSubmit={handleSubmit}>
    <Box display="flex" alignItems="flex-end">
      <Box>
        <Select
          title="BOL Confirm"
          options={confirmedOptions}
          selected={confirmed.toJSON()}
          renderOption={renderSelectOption}
          onSelect={handleSelect('confirmed')}
          isSelectedEqualToOption={isSelectedEqualToOption}
        />
      </Box>
      <Box ml={1}>
        <Input
          title="Weight LB"
          name="weight_lb"
          value={weight_lb}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1}>
        <Input
          title="Delivery Location"
          name="delivery"
          value={delivery}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1}>
        <Input
          title="Delivery Code"
          name="delivery_code"
          value={delivery_code}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1}>
        <Input
          title="WHS PLT Count"
          name="whs_pallet_count"
          value={whs_pallet_count}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1}>
        <Input
          title="Current Qty"
          name="qty"
          value={qty}
          onChange={setValueByHtmlElement}
        />
      </Box>
      <Box ml={1} display="flex">
        <Box><Button variant="outlined" color="primary" type="submit">Apply</Button></Box>
        <Box ml={1}><Button variant="outlined" color="primary" onClick={reset}>Reset Filter</Button></Box>
      </Box>
    </Box>
  </form>
})

export default Filter;
