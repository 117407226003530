import { types, getRoot, flow } from 'mobx-state-tree';
import { INBOUND_SHIPMENT_TYPE_MAP } from '../inbound/constants'

const ReportStore = types
  .model('ReportStore', {
    from: types.maybeNull(types.Date),
    to: types.maybeNull(types.Date),
    shipment_type: types.optional(types.enumeration(Object.keys(INBOUND_SHIPMENT_TYPE_MAP)), Object.keys(INBOUND_SHIPMENT_TYPE_MAP)[0]),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    },
  }))
  .actions(self => ({

    downloadUnloadingHistory: flow(function* () {
      try {
        yield self.root.api
          .report.downloadUnloadingHistory(
            {
              from: self.getDate(self.from),
              to: self.getDate(self.to),
              shipment_type: self.shipment_type,
            }
          )
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    setRange(key, value) {
      self[key] = value;
    },
    getDate(value) {
      const dd = String(value.getDate()).padStart(2, '0');
      const mm = String(value.getMonth() + 1).padStart(2, '0');
      const yyyy = value.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    },
    setShipmentType(s) {
      self.shipment_type = s;
    },

  }))


export default ReportStore;
