import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Select from '../../../ui/Select';
import { observer } from 'mobx-react';
import FilterBadge from './FilterBadge';

const Filter = observer(({ filter }) => {
  const {
    containerOptions,
    containers,
    bols,
    onSelectSearchCntrFilter,
    onSelectSearchMarkingFilter,
    onSelectSearchBolFilter,
    bolsOptions,
    markingOptions,
    markings,
    delCodeOptions,
    delCodes,
    loadStatusOptions,
    loadStatuses,
    groupStatusOptions,
    groupStatus,
    areaOptions,
    area,
    isReadyToShipOptions,
    isReadyToShip,
    loadTypes,
    loadTypeOptions,
    onSelectSearchFilter,
    renderSelectOption,
    handleSelect,
    isSelectedEqualToOption,
  } = filter;


  return <Box>
    <Box display="flex" alignItems="flex-end">
      <Box ml={1}>
        <Select
          multiple
          searchable
          onSearchFilter={onSelectSearchFilter}
          onSearchAsyncFilter={onSelectSearchCntrFilter}
          emptySelectedText="All"
          title="CNTR#"
          options={containerOptions}
          selected={containers.toJSON()}
          renderOption={renderSelectOption}
          onSelect={handleSelect('containers')}
          isSelectedEqualToOption={isSelectedEqualToOption}
        />
      </Box>
      <Box ml={1}>
        <Select
          multiple
          searchable
          onSearchAsyncFilter={onSelectSearchMarkingFilter}
          onSearchFilter={onSelectSearchFilter}
          emptySelectedText="All"
          title="Marking"
          options={markingOptions}
          selected={markings.toJSON()}
          renderOption={renderSelectOption}
          onSelect={handleSelect('markings')}
          isSelectedEqualToOption={isSelectedEqualToOption}
        />
      </Box>
      <Box ml={1}>
        <Select
          multiple
          searchable
          onSearchFilter={onSelectSearchFilter}
          emptySelectedText="All"
          title="DEL Code"
          options={delCodeOptions}
          selected={delCodes.toJSON()}
          renderOption={renderSelectOption}
          onSelect={handleSelect('delCodes')}
          isSelectedEqualToOption={isSelectedEqualToOption}
        />
      </Box>
      <Box ml={1}>
        <Select
          onSearchFilter={onSelectSearchFilter}
          emptySelectedText="All"
          title="Group Status"
          options={groupStatusOptions}
          selected={groupStatus.toJSON()}
          renderOption={renderSelectOption}
          onSelect={handleSelect('groupStatus')}
          isSelectedEqualToOption={isSelectedEqualToOption}
        />
      </Box>
      <Box ml={1}>
        <Select
          onSearchFilter={onSelectSearchFilter}
          emptySelectedText="All"
          title="WHS Area"
          options={areaOptions}
          selected={area.toJSON()}
          renderOption={renderSelectOption}
          onSelect={handleSelect('area')}
          isSelectedEqualToOption={isSelectedEqualToOption}
        />
      </Box>
      <Box ml={1}>
        <Select
          searchable
          onSearchFilter={onSelectSearchFilter}
          emptySelectedText="All"
          title="Is Ready To Ship"
          options={isReadyToShipOptions}
          selected={isReadyToShip.toJSON()}
          renderOption={renderSelectOption}
          onSelect={handleSelect('isReadyToShip')}
          isSelectedEqualToOption={isSelectedEqualToOption}
        />
      </Box>
      <Box ml={1}>
        <Select
          multiple
          searchable
          onSearchAsyncFilter={onSelectSearchBolFilter}
          onSearchFilter={onSelectSearchFilter}
          emptySelectedText="All"
          title="Bols"
          options={bolsOptions}
          selected={bols.toJSON()}
          renderOption={renderSelectOption}
          onSelect={handleSelect('bols')}
          isSelectedEqualToOption={isSelectedEqualToOption}
        />
      </Box>
      <Box ml={1}>
        <Select
          multiple
          onSearchFilter={onSelectSearchFilter}
          emptySelectedText="All"
          title="Load Type"
          options={loadTypeOptions}
          selected={loadTypes.toJSON()}
          renderOption={renderSelectOption}
          onSelect={handleSelect('loadTypes')}
          isSelectedEqualToOption={isSelectedEqualToOption}
        />
      </Box>
    </Box>
    <Box mt={3}>
      <FilterBadge filter={filter} />
    </Box>
  </Box>
})

export default Filter;
