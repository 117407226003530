import { types, getParent } from 'mobx-state-tree';

const Route = types.model({
  pathname: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  hash: types.maybeNull(types.string),
  state: types.maybeNull(types.map(types.union(types.string, types.number, types.null))),
});

export const UnreadRow = types.model({
  chat_room_id: types.maybeNull(types.number),
  count: types.maybeNull(types.number),
  subject_uid: types.maybeNull(types.string),
  route: types.maybeNull(Route),
  latest_message: types.maybeNull(types.string),
  latest_time: types.maybeNull(types.string),
  sender: types.maybeNull(types.string),
});

