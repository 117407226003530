import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { isArray } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  },
  header: {
    fontWeight: 500,
    color: '#3651BE',
  },
  item: {
    padding: '5px 15px',
    color: '#273240',
    background: '#DCE0F0',
    borderRadius: '3px',
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  closeIcon: {
    fontSize: '14px',
    color: '#3651BE',
    marginLeft: '8px',
    cursor: 'pointer',
  },
}));

const FilterBadge = observer(({ filter }) => {
  const classes = useStyles();

  const { activeFilters } = filter;

  return <Box className={classes.root}>
    <Box className={classes.header} mr={2}>Active Filters:</Box>
    {activeFilters.map(item => <React.Fragment key={item.field}>
      {isArray(item.option)
        ? item.option.map(o => <Badge filter={filter} classes={classes} option={o} field={item.field} />)
        : <Badge filter={filter} classes={classes} option={item.option} field={item.field} />}
    </React.Fragment>)}
    <Box ml={1}>
      <Button variant="outlined" color="primary" size="small" onClick={filter.clear}>Clear All</Button>
    </Box>
  </Box>
});

const Badge = observer(({ classes, option, field, filter }) => {
  return <Box className={classes.item}>
    {option.text}
    <CancelIcon className={classes.closeIcon} onClick={() => filter.removeFilter(field, option)} />
  </Box>
});

export default FilterBadge;
