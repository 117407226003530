import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
}));


const Logo = inject('root')(
  observer(({ root, ...props }) => {
    const { className, width = 140, ...rest } = props;
    const classes = useStyles();

    const { logoUrl } = root.ui;

    return <>
      {logoUrl ? <img {...rest} width={width} src={logoUrl} /> : null}
    </>
  })
);

export default Logo;
