import { every, sumBy, uniqueId } from 'lodash';
import { getParent, types, getSnapshot, getRoot } from 'mobx-state-tree';
import DocumentStore from '../document/DocumentsStore';
import { uniq, intersection, uniqBy } from 'lodash';
import { autorun } from 'mobx';
import { unit } from 'mathjs';

export const TypeCountItem = types
  .model({
    value: types.string,
    text: types.string,
    count: types.maybeNull(types.number),
  })
  .actions(self => ({
    isActive(type) {
      return self.value === type;
    },
    setCount(count) {
      self.count = count;
    },
  }));

export const WorkOrderDetailTask = types
  .model({
    id: 0,
    type: types.maybeNull(types.union(types.number, types.string)),
    description: types.maybeNull(types.string),
    rate: types.maybeNull(types.union(types.number, types.string)),
    unit: types.maybeNull(types.string),
    qty: types.maybeNull(types.union(types.number, types.string)),
    // sub_total: types.maybeNull(types.union(types.number, types.string)),
  })
  .views(self => ({
    get sub_total() {
      if (self.rate && self.qty) {
        return (parseFloat(self.rate) * parseFloat(self.qty)).toFixed(2);
      } else {
        return '';
      }
    },
  }))
  .actions(self => ({
    remove() {
      getParent(self, 2).removeTask(self);
    },
    setValue(name, value) {
      self[name] = value;
    },
    setValueByHtmlElememt(e) {
      const { value, name, checked, type } = e.target;
      self.setValue(name, type === 'checkbox' ? checked : value);
    },
    duplicate() {
      getParent(self, 2).duplicateTask(self);
    },
  }));

const WorkOrderDetailGroupLoadBol = types.model({
  id: 0,
  uid: types.maybeNull(types.string),
})

export const WorkOrderDetailGroupLoad = types
  .model({
    id: 0,
    uid: types.maybeNull(types.string),
    type: types.maybeNull(types.union(types.number, types.string)),
    type_name: types.maybeNull(types.union(types.number, types.string)),
    whs_marking: types.maybeNull(types.string),
    receiver_shipment_id: types.maybeNull(types.string),
    receiver_reference_id: types.maybeNull(types.string),
    customer_reference: types.maybeNull(types.string),
    qty: types.maybeNull(types.union(types.string, types.number)),
    actual_qty: types.maybeNull(types.union(types.string, types.number)),
    current_qty: types.maybeNull(types.union(types.string, types.number)),
    destroy_qty: types.maybeNull(types.union(types.string, types.number)),
    new_qty: types.maybeNull(types.union(types.string, types.number)),
    volume_cbm: types.maybeNull(types.union(types.string, types.number)),
    weight_kg: types.maybeNull(types.union(types.string, types.number)),
    display_weight_kg: types.maybeNull(types.union(types.string, types.number)),
    weight_lb: types.maybeNull(types.union(types.string, types.number)),
    whs_pallet_count: types.maybeNull(types.union(types.string, types.number)),
    remaining_volume_cbm: types.maybeNull(types.union(types.string, types.number)),
    remaining_weight_lb: types.maybeNull(types.union(types.string, types.number)),
    remaining_pallet_count: types.maybeNull(types.union(types.string, types.number)),
    shipout_pallet_count: types.maybeNull(types.number),
    is_ready_to_ship: types.boolean,
    bol: types.maybeNull(WorkOrderDetailGroupLoadBol),
    created_by_whs_id: types.maybeNull(types.number),
    dispatch_remark: types.maybeNull(types.string),
  })
  .volatile(self => ({
    add_current_qty: '',
    remove_current_qty: '',
    autorunDisposer1: null,
    autorunDisposer2: null,
  }))
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get group() {
      return getParent(self, 2);
    },
    // get new_qty() {
    //   return parseFloat(self.current_qty || 0) + parseFloat(self.add_current_qty || 0) - parseFloat(self.remove_current_qty || 0) - parseFloat(self.destroy_qty || 0);
    // },
  }))
  .actions(self => ({
    setValue(name, value) {
      self[name] = value;
    },
    setValueByHtmlElememt(e) {
      const { value, name, checked, type } = e.target;
      self.setValue(name, type === 'checkbox' ? checked : value);
    },
    setValueAndApplyToGroupByHtmlElement(e) {
      const { value, name, checked, type } = e.target;
      self.setValueAndApplyToGroup(name, type === 'checkbox' ? checked : value);
    },
    setValueAndApplyToGroup(name, value) {
      self.setValue(name, value);
      // if (name == 'remaining_pallet_count') {
      //   const ratio = (+value) / (parseFloat(self.shipout_pallet_count || 0) + (+value));
      //   self.setValue('remaining_weight_lb', (+self.weight_lb * ratio).toFixed(2));
      //   self.setValue('remaining_volume_cbm', (+self.volume_cbm * ratio).toFixed(2));
      // }
      self.group.recalculateFromLoads(name);
    },
    afterAttach() {
      self.autorunDisposer1 = autorun(() => {
        self.setValue('new_qty', (parseFloat(self.current_qty || 0) + parseFloat(self.add_current_qty || 0) - parseFloat(self.remove_current_qty || 0) - parseFloat(self.destroy_qty || 0)).toFixed(2));
      });
      self.autorunDisposer2 = autorun(() => {
        self.setValue('weight_kg', unit(self.weight_lb || 0, 'lb').toNumber('kg').toFixed(2));
      });
    },
    beforeDetach() {
      self.autorunDisposer1 && self.autorunDisposer1();
      self.autorunDisposer2 && self.autorunDisposer2();
    },
  }))


export const WorkOrderDetailGroup = types
  .model({
    id: 0,
    pivot_id: types.maybeNull(types.number),
    uid: types.maybeNull(types.string),
    ib_number: types.maybeNull(types.string),
    status: types.maybeNull(types.number),
    status_name: types.maybeNull(types.string),
    marking: types.maybeNull(types.string),
    type: types.maybeNull(types.union(types.string, types.number)),
    receiver_reference_id: types.maybeNull(types.union(types.string)),
    receiver_shipment_id: types.maybeNull(types.union(types.string)),
    current_qty: types.maybeNull(types.union(types.string, types.number)),
    destroy_qty: types.maybeNull(types.union(types.string, types.number)),
    new_qty: types.maybeNull(types.union(types.string, types.number)),
    volume_cbm: types.maybeNull(types.union(types.string, types.number)),
    weight_kg: types.maybeNull(types.union(types.string, types.number)),
    weight_lb: types.maybeNull(types.union(types.string, types.number)),
    remaining_volume_cbm: types.maybeNull(types.union(types.string, types.number)),
    remaining_weight_lb: types.maybeNull(types.union(types.string, types.number)),
    remaining_pallet_count: types.maybeNull(types.union(types.string, types.number)),
    whs_pallet_count: types.maybeNull(types.union(types.string, types.number)),
    loads: types.array(WorkOrderDetailGroupLoad),
    is_ready_to_ship: true,
    documents: types.optional(DocumentStore, { target: 'whs_work_order_group' }),
    create_from_load_ids: types.array(types.number),
    created_by_whs_id: types.maybeNull(types.number),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get deletable() {
      return self.root.auth.user.id == self.created_by_whs_id;
    },
    get isSelected() {
      const workOrder = getParent(self, 3);
      return !!workOrder.selectedGroups.find(s => s.uniqId == self.uniqId);
    },
    get is_new() {
      return !self.id;
    },
    get total_qty() {
      return self.is_new ? self.qty : sumBy(self.loads, load => parseFloat(load.qty || 0)).toFixed(2);
    },
    get total_actual_qty() {
      return self.is_new ? (self.actual_qty || 0) : sumBy(self.loads, load => parseFloat(load.actual_qty || 0)).toFixed(2);
    },
    get total_current_qty() {
      return self.is_new ? (self.current_qty || 0) : sumBy(self.loads, load => parseFloat(load.current_qty || 0)).toFixed(2);
    },
    get total_add_current_qty() {
      return self.is_new ? 0 : sumBy(self.loads, load => parseFloat(load.add_current_qty || 0)).toFixed(2);
    },
    get total_remove_current_qty() {
      return self.is_new ? 0 : sumBy(self.loads, load => parseFloat(load.remove_current_qty || 0)).toFixed(2);
    },
    get total_destroy_qty() {
      return self.is_new ? (self.destroy_qty || 0) : sumBy(self.loads, load => parseFloat(load.destroy_qty || 0)).toFixed(2);
    },
    get total_new_qty() {
      return self.is_new ? (self.new_qty || 0) : sumBy(self.loads, load => parseFloat(load.new_qty || 0)).toFixed(2);
    },
    get total_volume_cbm() {
      return self.is_new ? (self.volume_cbm || 0) : sumBy(self.loads, load => parseFloat(load.volume_cbm || 0)).toFixed(2);
    },
    get total_weight_kg() {
      return self.is_new ? (self.weight_kg || 0) : sumBy(self.loads, load => parseFloat(load.weight_kg || 0)).toFixed(2);
    },
    get total_weight_lb() {
      return self.is_new ? (self.weight_lb || 0) : sumBy(self.loads, load => parseFloat(load.weight_lb || 0)).toFixed(2);
    },
    get total_whs_pallet_count() {
      return self.is_new ? (self.whs_pallet_count || 0) : sumBy(self.loads, load => parseFloat(load.whs_pallet_count || 0)).toFixed(2);
    },
    get total_remaining_pallet_count() {
      return self.is_new ? (self.remaining_pallet_count || 0) : sumBy(self.loads, load => parseFloat(load.remaining_pallet_count || 0)).toFixed(2);
    },
    get total_remaining_volume_cbm() {
      return self.is_new ? (self.remaining_volume_cbm || 0) : sumBy(self.loads, load => parseFloat(load.remaining_volume_cbm || 0)).toFixed(2);
    },
    get total_remaining_weight_lb() {
      return self.is_new ? (self.remaining_weight_lb || 0) : sumBy(self.loads, load => parseFloat(load.remaining_weight_lb || 0)).toFixed(2);
    },
    get isCreateFromSelected() {
      return self.create_from_load_ids.length > 0;
    },
    get createFromText() {
      if (self.isCreateFromSelected) {
        const detail = getParent(self, 2);
        return detail.createFromTextByLoadIds(self.create_from_load_ids, self.create_from_load_ids.length);
      } else {
        return '';
      }
    },
    get createFromSelectedLoads() {
      const detail = getParent(self, 2);
      return detail.toLoadsByIds(self.create_from_load_ids, self.create_from_load_ids.length);
    },

    // get new_qty() {
    //   return parseFloat(self.current_qty || 0) - parseFloat(self.destroy_qty || 0);
    // },
  }))
  .volatile(self => ({
    add_current_qty: '',
    remove_current_qty: '',
    uniqId: '',
    isExpanedForCreateFrom: false,
    autorunDisposer1: null,
    autorunDisposer2: null,
    autorunDisposer3: null,
    autorunDisposer4: null,
  }))
  .actions(self => ({
    delete() {
      getParent(self, 2).deleteGroup(self.id);
    },
    loadDocuments() {
      self.documents.load(self.pivot_id);
    },
    setIbNumberByCreateFrom(loadIds) {
      const groups = getParent(self, 2).toSelectedGroupsByLoadIds(loadIds)
      if (groups && groups.length > 0) {
        self.ib_number = groups[0].ib_number;
      }
    },
    addLoadIdsToCreateFrom(ids) {
      self.create_from_load_ids = uniq([...self.create_from_load_ids, ...ids])
    },
    removeLoadIdsToCreateFrom(ids) {
      self.create_from_load_ids = self.create_from_load_ids.filter(i => ids.indexOf(i) === -1);
    },
    toggleLoadIdCreateFrom(id) {
      if (self.create_from_load_ids.indexOf(id) === -1) {
        self.create_from_load_ids.push(id);
      } else {
        self.create_from_load_ids = self.create_from_load_ids.filter(i => i != id);
      }
    },
    isAllLoadIdsInCreateForm(loadIds) {
      for (let id of loadIds) {
        if (self.create_from_load_ids.indexOf(id) === -1) {
          return false
        }
      }

      return true;
    },
    isSomeLoadIdsInCreateForm(loadIds) {
      return intersection(self.create_from_load_ids, loadIds).length > 0;
    },
    hasGroupSomeLoadsSelectedForCreateFrom(group) {
      const loadIds = group.loads.map(load => load.id);
      return self.isSomeLoadIdsInCreateForm(loadIds);
    },
    hasGroupAllLoadsSelectedForCreateFrom(group) {
      const loadIds = group.loads.map(load => load.id);
      return self.isAllLoadIdsInCreateForm(loadIds);
    },
    toggleCreateFromSelectedGroup(group) {
      const loadIds = group.loads.map(load => load.id);
      if (self.hasGroupAllLoadsSelectedForCreateFrom(group)) {
        self.removeLoadIdsToCreateFrom(loadIds);
      } else {
        self.addLoadIdsToCreateFrom(loadIds);
      }
    },
    toggleExpanedForCreateForm() {
      self.isExpanedForCreateFrom = !self.isExpanedForCreateFrom;
    },
    setValue(name, value) {
      self[name] = value;
    },
    setValueByHtmlElememt(e) {
      const { value, name, checked, type } = e.target;
      self.setValue(name, type === 'checkbox' ? checked : value);
    },
    remove() {
      getParent(self, 2).removeGroup(self);
    },
    toggleSelect() {
      getParent(self, 3).toggleSelectedGroup(self);
    },
    setValueAndApplySameToLoadsByHtmlElement(e) {
      const { value, name, checked, type } = e.target;
      self.setValueAndApplySameToLoads(name, type === 'checkbox' ? checked : value);
    },
    setValueAndApplySameToLoads(name, value) {
      self[name] = value;
      self.loads.forEach(load => {
        load.setValue(name, value);
      })
    },
    setValueAndApplyToLoadsByHtmlElement(e) {
      const { value, name, checked, type } = e.target;
      self.setValueAndApplyToLoads(name, type === 'checkbox' ? checked : value);
    },
    setValueAndApplyToLoads(name, value, intable = false) {
      self[name] = value;
      self.loads.forEach((load) => {
        let plt;
        plt = (parseFloat(value || 0) / self.loads.length).toFixed(2);
        load.setValue(name, plt);
      });

    },
    setAddCurrentQty(value) {
      self.setValue('add_current_qty', value);

      const totalQty = self.total_current_qty;

      const loads = self.loads.slice(0, -1);
      loads.forEach((load) => {
        let qty;

        if (totalQty) {
          qty = parseInt(((parseInt(load.current_qty || 0) / parseInt(totalQty)) * parseInt(value || 0)).toFixed(4)); // fix like 50 / 193 * 193 = 4.999999999999999
        } else {
          qty = parseInt(parseInt(value || 0) / self.loads.length);
        }

        load.setValue('add_current_qty', qty);
      })

      const lastLoad = self.loads[self.loads.length - 1];
      if (lastLoad) {
        lastLoad.setValue('add_current_qty', parseInt(value) - sumBy(loads, 'add_current_qty'));
      }
    },
    setRemoveCurrentQty(value) {

      const loads = self.loads;
      if (value > sumBy(loads, 'current_qty')) {
        self.root.ui.toast.error('removing qty is greater than current qty');
        return;
      }
      self.setValue('remove_current_qty', value);

      const qtys = loads.map(l => l.current_qty);
      let index = 0;
      let v = value;

      while (v) {
        if (qtys[index] > 0) {
          qtys[index]--;
          v--;
        }
        index = (index + 1) % loads.length;
      }

      qtys.forEach((qty, idx) => {
        loads[idx].setValue('remove_current_qty', loads[idx].current_qty - qty);
      })
      // loads.forEach((load) => {
      //   let qty;

      //   if (totalQty) {
      //     qty = parseInt(((parseInt(load.current_qty || 0) / parseInt(totalQty)) * parseInt(value || 0)).toFixed(4));
      //   } else {
      //     qty = parseInt(parseInt(value || 0) / self.loads.length);
      //   }

      //   qty = Math.min(qty, parseInt(load.current_qty || 0));

      //   load.setValue('remove_current_qty', qty);
      // })

      // const lastLoad = self.loads.reduce((minObject, currentObject) => {
      //   return currentObject.value < minObject.value ? currentObject : minObject;
      // }, self.loads[0])
      // // self.loads[self.loads.length - 1];
      // if (lastLoad) {
      //   lastLoad.setValue('remove_current_qty', ((lastLoad.remove_current_qty || 0) + parseInt(value) - sumBy(loads, 'remove_current_qty')));
      // }
    },
    recalculateFromLoads(attribute) {
      self.setValue(attribute, sumBy(self.loads, load => parseFloat(load[attribute] || 0)).toFixed(2));
      // if (attribute == 'remaining_pallet_count') {
      //   self.setValue('remaining_weight_lb', sumBy(self.loads, load => parseFloat(load['remaining_weight_lb'] || 0)).toFixed(2));
      //   self.setValue('remaining_volume_cbm', sumBy(self.loads, load => parseFloat(load['remaining_volume_cbm'] || 0)).toFixed(2));
      // }
    },

    afterCreate() {
      self.uniqId = uniqueId();
    },
    afterAttach() {
      self.autorunDisposer1 = autorun(() => {
        self.setValue('new_qty', (parseFloat(self.current_qty || 0) + parseFloat(self.add_current_qty || 0) - parseFloat(self.remove_current_qty || 0) - parseFloat(self.destroy_qty || 0)).toFixed(2));
      });
      self.autorunDisposer2 = autorun(() => {
        self.setValue('weight_kg', unit(self.weight_lb || 0, 'lb').toNumber('kg').toFixed(2));
      });
      self.autorunDisposer3 = autorun(() => {
        if (self.is_new) {
          const totalWeightLb = sumBy(self.createFromSelectedLoads, load => parseFloat(load.weight_lb || 0));
          const totalCbm = sumBy(self.createFromSelectedLoads, load => parseFloat(load.volume_cbm || 0));
          const totalCurrentQty = sumBy(self.createFromSelectedLoads, load => parseFloat(load.current_qty || 0));

          const currentQty = self.current_qty;

          self.setValue('weight_lb', totalCurrentQty && currentQty ? (parseFloat(currentQty || 0) / parseFloat(totalCurrentQty) * parseFloat(totalWeightLb || 0)).toFixed(2) : null);
          self.setValue('volume_cbm', totalCurrentQty && currentQty ? (parseFloat(currentQty || 0) / parseFloat(totalCurrentQty) * parseFloat(totalCbm || 0)).toFixed(2) : null);
        }
      });

      self.autorunDisposer4 = autorun(() => {
        if (self.is_new && self.create_from_load_ids.length) {
          self.setIbNumberByCreateFrom(self.create_from_load_ids)
        }
      });
    },
    beforeDetach() {
      self.autorunDisposer1 && self.autorunDisposer1();
      self.autorunDisposer2 && self.autorunDisposer2();
      self.autorunDisposer3 && self.autorunDisposer3();
      self.autorunDisposer4 && self.autorunDisposer4();
    },
  }))

export const WorkOrderDetail = types
  .model({
    id: 0,
    uid: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    status_name: types.maybeNull(types.string),
    type_name: types.maybeNull(types.string),
    ship_type_name: types.maybeNull(types.string),
    is_ready_to_ship: types.maybeNull(types.boolean),
    urgent_name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    confirmed_at: types.maybeNull(types.string),
    completed_at: types.maybeNull(types.string),
    rejected_at: types.maybeNull(types.string),
    canceled_at: types.maybeNull(types.string),
    created_at: types.maybeNull(types.string),
    groups: types.array(WorkOrderDetailGroup),
    tasks: types.array(WorkOrderDetailTask),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get canConfirm() {
      return !self.confirmed_at && !self.rejected_at && !self.canceled_at && !self.completed_at;
    },
    get canUnconfirm() {
      return self.confirmed_at && !self.rejected_at && !self.canceled_at && !self.completed_at;
    },
    get canReject() {
      return !self.confirmed_at && !self.rejected_at && !self.canceled_at && !self.completed_at;
    },
    get canCancel() {
      return self.confirmed_at && !self.canceled_at && !self.completed_at;
    },
    get canComplete() {
      return self.confirmed_at && !self.completed_at;
    },
    get notNewGroups() {
      return self.groups.filter(g => !g.is_new);
    },
    get group_total_qty() {
      return sumBy(self.groups, group => parseFloat(group.total_qty || 0)).toFixed(2);
    },
    get group_total_actual_qty() {
      return sumBy(self.groups, group => parseFloat(group.total_actual_qty || 0)).toFixed(2);
    },
    get group_total_current_qty() {
      return sumBy(self.groups, group => parseFloat(group.total_current_qty || 0)).toFixed(2);
    },
    get group_total_add_current_qty() {
      return sumBy(self.groups, group => parseFloat(group.total_add_current_qty || 0)).toFixed(2);
    },
    get group_total_destroy_current_qty() {
      return sumBy(self.groups, group => parseFloat(group.total_destroy_current_qty || 0)).toFixed(2);
    },
    get group_total_destroy_qty() {
      return sumBy(self.groups, group => parseFloat(group.total_destroy_qty || 0)).toFixed(2);
    },
    get group_total_new_qty() {
      return sumBy(self.groups, group => parseFloat(group.total_new_qty || 0)).toFixed(2);
    },
    get group_total_volume_cbm() {
      return sumBy(self.groups, group => parseFloat(group.total_volume_cbm || 0)).toFixed(2);
    },
    get group_total_weight_kg() {
      return sumBy(self.groups, group => parseFloat(group.total_weight_kg || 0)).toFixed(2);
    },
    get group_total_weight_lb() {
      return sumBy(self.groups, group => parseFloat(group.total_weight_lb || 0)).toFixed(2);
    },
    get group_total_whs_pallet_count() {
      return sumBy(self.groups, group => parseFloat(group.total_whs_pallet_count || 0)).toFixed(2);
    },
    get group_total_remaining_pallet_count() {
      return sumBy(self.groups, group => parseFloat(group.total_remaining_pallet_count || 0)).toFixed(2);
    },
    get group_total_remaining_weight_lb() {
      return sumBy(self.groups, group => parseFloat(group.total_remaining_weight_lb || 0)).toFixed(2);
    },
    get group_total_remaining_volume_cbm() {
      return sumBy(self.groups, group => parseFloat(group.total_remaining_volume_cbm || 0)).toFixed(2);
    },
    get isAllGroupSelected() {
      return every(self.groups, 'isSelected');
    },
  }))
  .actions(self => ({
    confirm() {
      getParent(self).confirm(self.id);
    },
    unconfirm() {
      getParent(self).unconfirm(self.id);
    },
    cancel() {
      getParent(self).cancel(self.id);
    },
    reject() {
      getParent(self).reject(self.id);
    },
    complete() {
      getParent(self).complete(self.id);
    },
    uncomplete() {
      getParent(self).uncomplete(self.id);
    },
    downloadPDF() {
      getParent(self).downloadPDF(self.id);
    },
    addTask() {
      self.tasks.push({});
    },
    removeTask(task) {
      self.tasks.remove(task);
    },
    duplicateTask(task) {
      self.tasks.push({ ...getSnapshot(task), id: 0 });
    },
    updateTasks() {
      getParent(self).updateTasks(self.id, getSnapshot(self.tasks));
    },
    addGroup() {
      self.groups.push({});
    },
    addGroups(groups) {
      self.groups.push(...groups);
    },
    removeGroup(group) {
      self.groups.remove(group);
    },
    toSelectedGroupsByLoadIds(loadIds) {
      const gs = [];
      for (let group of self.groups) {
        for (let load of group.loads) {
          if (loadIds.indexOf(load.id) !== -1) {
            gs.push(group)
            continue;
          }
        }
      }

      return gs
    },
    createFromTextByLoadIds(loadIds) {
      const m = {}
      for (let group of self.groups) {
        for (let load of group.loads) {
          if (loadIds.indexOf(load.id) !== -1) {
            if (m[group.uid]) {
              m[group.uid].push(load.uid);
            } else {
              m[group.uid] = [load.uid];
            }
          }
        }
      }

      return Object.keys(m).map(groupUid => {
        return groupUid + '-' + m[groupUid].join(',');
      }).join(';')
    },
    toLoadsByIds(loadIds) {
      const loads = [];

      for (let group of self.groups) {
        for (let load of group.loads) {
          if (loadIds.indexOf(load.id) !== -1) {
            loads.push(load);
          }
        }
      }

      return loads;
    },
    updateInventory() {
      const groups = getSnapshot(self.groups).map(({ documents, ...g }) => {

        return {
          ...g,
        }
      });
      getParent(self).updateInventory(self.id, groups);
    },
    opclose() {
      getParent(self).opclose(self.id)
    },
    deleteGroup(groupId) {
      getParent(self).deleteGroup(self.id, groupId);
    },
    toggleAllSelectedGroup() {
      getParent(self).toggleAllSelectedGroup();
    },

  }));

export const WorkOrderRowGroup = types
  .model({
    id: 0,
    pivot_id: types.maybeNull(types.number),
    uid: types.maybeNull(types.string),
    ib_number: types.maybeNull(types.string),
    warehouse_alert_id: types.maybeNull(types.number),
    status: types.maybeNull(types.number),
    status_name: types.maybeNull(types.string),
    marking: types.maybeNull(types.string),
    total_qty: types.maybeNull(types.union(types.string, types.number)),
    total_actual_qty: types.maybeNull(types.union(types.string, types.number)),
    total_volume_cbm: types.maybeNull(types.union(types.string, types.number)),
    total_weight_kg: types.maybeNull(types.union(types.string, types.number)),
    total_whs_pallet_count: types.maybeNull(types.union(types.string, types.number)),
  })
  .views(self => ({
  }))
  .actions(self => ({

  }))

export const WorkOrderRow = types
  .model({
    id: types.number,
    uid: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    status_name: types.maybeNull(types.string),
    type_name: types.maybeNull(types.string),
    ship_type_name: types.maybeNull(types.string),
    is_ready_to_ship: types.maybeNull(types.boolean),
    urgent_name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    confirmed_at: types.maybeNull(types.string),
    completed_at: types.maybeNull(types.string),
    rejected_at: types.maybeNull(types.string),
    canceled_at: types.maybeNull(types.string),
    created_at: types.maybeNull(types.string),
    groups: types.array(WorkOrderRowGroup),
  })
  .views(self => ({
    get ib_numbers() {
      return uniq(self.groups.map(g => g.ib_number));
    },
    get shipmentGroups() {
      return uniqBy(self.groups, 'ib_number');
    },
    get markings() {
      return uniq(self.groups.map(g => g.marking));
    },
    get isCompleted() {
      return !!self.completed_at;
    },
    get isCanceled() {
      return !!self.canceled_at;
    },
    get isRejected() {
      return !!self.rejected_at;
    },
  }))

