import request from './request';

export const bol = {
  counts() {
    return request.get('bols/counts');
  },
  index(params) {
    return request.get('bols', { params });
  },
  pickup(id) {
    return request.put(`bols/${id}/pickup`);
  },
  unpickup(id) {
    return request.put(`bols/${id}/unpickup`);
  },
  updateMemo(id, memo) {
    return request.put(`bols/${id}/updateMemo`, { memo });
  },
  updateProductPackages(id, data) {
    return request.put(`bols/${id}/updateProductPackages`, data);
  },
  cancelChanged(id) {
    return request.put(`bols/${id}/cancelChanged`);
  },
  show(id) {
    return request.get(`bols/${id}`);
  },
  viewPDF(id, otrCarrierId = null) {
    return this.exportPDF(id, false, otrCarrierId);
  },
  printPDF(id, otrCarrierId = null) {
    return this.exportPDF(id, true, otrCarrierId);
  },
  exportPDF(id, print, otrCarrierId = null) {
    return request.get(`bols/${id}/pdf`, { params: { otr_carrier_id: otrCarrierId }, responseType: 'blob' })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          {
            type: response.headers['content-type'],
          }
        );
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const w = window.open(fileURL, '_blank');
        if (print) {
          w.print();
        }
      });
  },
  viewPickSlip(id) {
    return this.exportPickSlip(id, false);
  },
  printPickSlip(id) {
    return this.exportPickSlip(id, true);
  },
  exportPickSlip(id, print) {
    return request.get(`bols/${id}/pickslip`, { params: {}, responseType: 'blob' })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
          [response.data],
          {
            type: response.headers['content-type'],
          }
        );
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const w = window.open(fileURL, '_blank');
        if (print) {
          w.print();
        }
      });
  },
  pending() {
    return request.get('bol', { params: { pending: true } });
  },
  search(params) {
    return request.get('bols/search', { params });
  },
  shipout(bolId, data) {
    return request.post(`bols/${bolId}/shipout`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  unshipout(bolId, shipoutId) {
    return request.delete(`bols/${bolId}/unshipout/${shipoutId}`);
  },
  shipoutDeliver(bolId, shipoutId, time) {
    return request.put(`bols/${bolId}/shipoutDeliver/${shipoutId}`, { time });
  },
  shipoutUnDeliver(bolId, shipoutId) {
    return request.put(`bols/${bolId}/shipoutUnDeliver/${shipoutId}`);
  },
  confirm(bolId, data = {}) {
    return request.post(`bols/${bolId}/confirm`, data);
  },
  completePacking(bolId, data = {}) {
    return request.put(`bols/${bolId}/completePacking`, data);
  },
  markUrgent(ids) {
    return request.post(`bols/markUrgent`, {
      ids
    });
  }
}
