import { types, getRoot, applySnapshot, flow } from 'mobx-state-tree';
import { Document } from './models';
import { serialize } from 'object-to-formdata';
import { uniqueId } from 'lodash';

const DocumentsStore = types
  .model('DocumentsStore', {
    defer: false,
    target: types.string,
    targetId: types.maybe(types.union(types.number, types.string)),
    collection: types.array(Document),
  })
  .volatile(self => ({
    loading: false,
    uploading: false,
  }))
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get hasDocuments() {
      return self.collection.length > 0;
    }
  }))
  .actions(self => ({
    clear() {
      self.collection.clear();
    },
    setCollection(collection) {
      applySnapshot(self.collection, collection);
    },
    setDefer(defer) {
      self.defer = !!defer;
    },
    setTarget(target) {
      self.target = target;
    },
    setTargetId(targetId) {
      self.targetId = targetId;
    },
    load: flow(function* (targetId, target) {
      self.targetId = targetId || self.targetId;
      self.target = target || self.target;
      self.loading = true;
      self.collection.clear();

      if (!self.defer) {
        try {
          const response = yield self.root.api.document.documents(self.target, self.targetId);
          self.setCollection(response.data.data);
        } catch (error) {
          self.root.ui.toast.error(error);
        }
      }

      self.loading = false;
    }),
    upload: flow(function* (documents) {

      if (self.defer) {
        self.uploading = true;
        self.collection.push(...documents.map(file => {
          const d = Document.create(self.toDocumentByFile(file))
          d.setOriginal(file)
          return d;
        }));
        self.uploading = false;
      } else {
        self.uploading = true;
        let response = false;
        try {
          const formData = serialize({ documents: [...documents] }, { indices: true, nullsAsUndefineds: true });
          response = yield self.root.api.document.store(self.target, self.targetId, formData);
          self.setCollection(response.data.data);
        } catch (error) {
          self.root.ui.toast.error(error);
        }
        self.uploading = false;
        return response;
      }
    }),
    delete: flow(function* (documentId) {
      if (window.confirm('Are u sure to delete it?')) {
        self.loading = true;

        let response = false;
        if (self.defer) {
          self.setCollection(self.collection.filter(d => d.id != documentId));
        } else {
          try {
            response = yield self.root.api.document.destroy(self.target, self.targetId, documentId);
            self.setCollection(self.collection.filter(d => d.id != documentId));
          } catch (error) {
            self.root.ui.toast.error(error);
          }
        }

        self.loading = false;

        return response;
      }
    }),
    view: flow(function* (document) {
      if (self.defer) {
        return;
      }

      try {
        yield self.root.api.document.show(self.target, self.targetId, document);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    download: flow(function* (document) {
      if (self.defer) {
        return;
      }

      try {
        yield self.root.api.document.download(self.target, self.targetId, document);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
    toOriginalFiles() {
      return self.collection.map(c => c.original);
    },
    toDocumentByFile(file) {
      return {
        id: Number(uniqueId()),
        name: file.name.substr(0, file.name.lastIndexOf(".")),
        file_name: file.name,
        mime_type: file.type,
      }
    },
  }))


export default DocumentsStore;
