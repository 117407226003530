import { types, getRoot } from 'mobx-state-tree';

const WhsPalletReport = types
  .model('WhsPalletReportStore', {
    types: types.array(types.string),
  })
  .views(self => ({
    get root() {
      return getRoot(self);
    },
  }))
  .actions(self => ({
    setTypes(types) {
      self.types = types;
    },
    getTypes() {
      return self.types;
    }
  }))


export default WhsPalletReport;
