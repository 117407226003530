import request from './request';

export const productPackage = {
  verify(id) {
    return request.put(`productPackages/${id}/verify`);
  },
  unverify(id) {
    return request.put(`productPackages/${id}/unverify`);
  },
}
