import { types, flow, getParent, applySnapshot, getRoot } from 'mobx-state-tree';

const TOKEN_KEY = 'whs-v1-token';

const createEmptyUserData = () => ({
  id: null,
  name: null,
  email: null,
});


const Whs = types.model({
  id: types.maybeNull(types.number),
  code: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  bl_address: types.maybeNull(types.string),
  whs_confirm_bol: types.maybeNull(types.boolean),
})

const User = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  whs: types.optional(Whs, {}),
  super_admin: types.maybeNull(types.boolean),
});

const LoginFormStore = types
  .model({
    email: types.maybeNull(types.string),
    password: types.maybeNull(types.string),
  })
  .volatile(self => ({
    submitting: false,
  }))
  .views(self => ({
    get root() {
      return getRoot(self);
    },
  }))
  .actions(self => ({
    setValueByElement(e) {
      const { name, type, checked, value } = e.target;
      self[name] = type === 'checkbox' ? checked : value;
    },
    submit: flow(function* (e) {
      e && e.preventDefault && e.preventDefault();

      let response;
      try {
        self.submitting = true;
        response = yield self.root.api.auth.login(self.email, self.password)

        getParent(self).loggedin(response.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.submitting = false;

      return response;
    })
  }))


const AuthStore = types
  .model('AuthStore', {
    user: types.optional(User, createEmptyUserData),
    loginForm: types.optional(LoginFormStore, {}),
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
    get isAuthenticated() {
      return self.user && !!self.user.id;
    },
    get token() {
      return self.readTokenFromLocalStorage();
    },
  }))
  .actions(self => {
    const readTokenFromLocalStorage = () => {
      return window.localStorage.getItem(TOKEN_KEY) || null;
    };

    const setToken = (token) => {
      window.localStorage.setItem(TOKEN_KEY, token)
    };

    const updateUser = (data) => {
      applySnapshot(self.user, data);
    };

    const loggedin = (data) => {
      self.setToken(data.access_token);
      updateUser(data.user);
      self.root.ui.loadLogo();
    };

    const loggedout = () => {
      self.setToken('');
      updateUser(createEmptyUserData());
    };

    const login = flow(function* (email, password) {
      try {
        const response = yield self.root.api.auth.login(email, password)
        self.loggedin(response.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    });

    const loadUser = flow(function* () {
      if (!self.token) {
        return;
      }

      try {
        const response = yield self.root.api.auth.user();
        updateUser(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    });

    const logout = flow(function* () {
      try {
        yield self.root.api.auth.logout();
        self.loggedout();
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    });

    return {
      readTokenFromLocalStorage,
      setToken,
      loggedin,
      loggedout,
      login,
      loadUser,
      logout,
    };
  });

export default AuthStore;
