import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format, isValid } from 'date-fns';
import { Table } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    padding: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const TransferToWMS = observer(({ inventorys, size = "small", selected, onDone }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(new Date);


  const { loading } = inventorys;

  const handleOpen = () => {
    if (selected.length > 0) {
      setOpen(true);
    } else {
      inventorys.root.ui.toast.error('Please select at least one item.')
    }
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();


    inventorys.transferToWms(
      selected.map(l => l.id),
      format(time, 'yyyy-MM-dd'),
    ).then(response => {
      if (response) {
        setOpen(false);
        onDone && onDone();
      }
    });
  }

  const canSubmit = () => {
    return !loading && isValid(time);
  }

  return <>
    <Button size={size} variant="contained" color="primary" onClick={handleOpen}>Transfer To WMS</Button>
    {open && <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box className={classes.content}>
            <Box mb={2} className={classes.subtitle}>1. Please Fill in Empty Time *</Box>
            <Box mb={2}>
              <FormControl required>
                <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  value={time}
                  onChange={setTime}
                  format="yyyy-MM-dd"
                />
              </FormControl>
            </Box>
            <Box mt={3} className={classes.subtitle}>Are you sure to transfer below groups to WMS?</Box>
            <Box mt={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Group#</TableCell>
                    <TableCell>CNTR#</TableCell>
                    <TableCell>WHS Marking</TableCell>
                    <TableCell>Current Qty</TableCell>
                    <TableCell>WHS PLT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selected.map((group) => (
                    <TableRow key={group.id}>
                      <TableCell>{group.uid}</TableCell>
                      <TableCell>{group.ib_number}</TableCell>
                      <TableCell>{group.marking}</TableCell>
                      <TableCell>{group.total_current_qty}</TableCell>
                      <TableCell>{group.total_whs_pallet_count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!canSubmit()}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>}
  </>
});



export default TransferToWMS;

