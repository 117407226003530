import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { observer } from 'mobx-react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '12px 0',
    cursor: 'pointer',
  },
  text: {
    fontSize: '14px',
    lineHeight: 1.5,
    color: '#001235',
  },
  icon: {
    background: theme.palette.primary.main,
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '32px',
  },
  count: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#80889A',
  },
}))

const InboundCardItem = observer(({ text, Icon, count, onClick }) => {
  const classes = useStyles();


  return <Box className={classes.root} display="flex" onClick={onClick}>
    <Box className={classes.icon}>
      <Icon style={{ color: 'white', fontSize: '18px' }} />
    </Box>
    <Box className={classes.text} ml={1}>
      {text}
    </Box>
    <Box className={classes.count} ml="auto">
      {count}
    </Box>
  </Box>
})


export default InboundCardItem;
