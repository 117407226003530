import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTable, useExpanded, useRowSelect } from 'react-table'
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    fontSize: '14px',
    lineHeight: 1.5,
    color: '#000000',
    borderCollapse: 'collapse',

    '& tr': {
      textAlign: 'center',

      '& td, th': {
        margin: '0px 10px',
        padding: '15px 0',
        borderTop: '0.5px solid rgb(0, 0, 0, 0.2)',
        borderBottom: '0.5px solid rgb(0, 0, 0, 0.2)',
      },

      '& th': {
        color: 'rgb(0, 0, 0, 0.4)',
        fontWeight: 500,
      },

      '& td': {
        fontWeight: 'normal',
      },
    },
  },

  stickyHeader: {
    // TODO
    // position: 'relative',

    // '& > thead th': {
    //   position: 'sticky',
    //   top: 0,
    //   zIndex: 1,
    // },
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, checked, onChange, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <Checkbox
          color="primary"
          checked={checked}
          onChange={onChange}
          ref={resolvedRef}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          inputProps={{ ...rest }} />
      </>
    )
  }
)


// Create a default prop getter
const defaultPropGetter = () => ({})
const defaultGetSubRows = (row) => row.subRows || []
const defaultTrueFunc = () => true;

const Table = ({
  className,
  columns,
  data,
  footerable = false,
  selection = false,
  stickyHeader = false,
  onSelectedChange,
  showRowSelection = defaultTrueFunc,
  getFooterProps = defaultPropGetter,
  getSubRows = defaultGetSubRows,
  getRowProps = defaultPropGetter,
  getRowSubComponentProps = defaultPropGetter,
  renderRowSubComponent,
  autoResetExpanded = true,
  expanded = {},
  selectedRowIds = {},
  state = {},
  topFooter = false,
}) => {
  const classes = useStyles();

  const useTables = useTable(
    {
      columns,
      data,
      getSubRows,
      initialState: {
        autoResetExpanded,
        expanded,
        selectedRowIds,
      },
      useControlledState: s => {
        return React.useMemo(
          () => ({
            ...s,
            ...state,
          }),
          [s, state]
        )
      },
    },
    useExpanded,
    useRowSelect,
    hooks => {
      selection && hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: (values) => showRowSelection(values) ? <div>
            <IndeterminateCheckbox {...values.row.getToggleRowSelectedProps()} />
          </div> : null,
        },
        ...columns,
      ])
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    visibleColumns,
  } = useTables;

  React.useEffect(() => {
    onSelectedChange && onSelectedChange(selectedFlatRows.map(row => row.original));
  }, [onSelectedChange, selectedFlatRows]);


  return <>
    <table {...getTableProps()} className={clsx(classes.root, className, { [classes.stickyHeader]: stickyHeader })}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps([
                  {
                    className: column.headerClassName,
                    style: column.headerStyle,
                  },
                ])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
        {
          footerable && topFooter && footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map(column => (
                <td {...column.getFooterProps(getFooterProps(column))}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))
        }
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)

          const { key, ...rowProps } = row.getRowProps(getRowProps(row));

          return (
            // Merge user row props in
            <React.Fragment key={key}>
              <tr {...rowProps}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.cellClassName,
                          style: cell.column.cellStyle,
                        },
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
              {row.isExpanded && renderRowSubComponent ? (
                <tr {...getRowSubComponentProps(row)}>
                  <td colSpan={visibleColumns.length}>
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              ) : null}
            </React.Fragment>)
        })}

      </tbody>
      {footerable && !topFooter && <tfoot>
        {footerGroups.map(group => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <td {...column.getFooterProps(getFooterProps(column))}>{column.render('Footer')}</td>
            ))}
          </tr>
        ))}
      </tfoot>}
    </table>
  </>
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
}

export default Table;
