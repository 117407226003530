import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, Menu, MenuItem, Popover, Tab, Tabs, Tooltip } from '@material-ui/core';
import { FlagIcon, LeftArrowIcon } from '../../../ui/Icon';
import { useHistory } from 'react-router-dom';
import ConfirmReceive from './ConfirmReceive';
import ConfirmEmpty from './ConfirmEmpty';
import TroubleShootForm from './TroubleShootForm';
import PrintPltLabels from './PrintPltLabels';
import ConfirmComplete from './ConfirmComplete';
import UnloadingTeamModal from './UnloadingTeamModal';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  backIconWrapper: {
    marginRight: '20px',
    lineHeight: '40px',
  },
  backIcon: {
    fontSize: '24px',
    color: '#00000',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  titleWrapper: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#000000',
  },
  title: {
    fontSize: '14px',
  },
  subTitle: {
    fontSize: '9px',
    opacity: 0.5,
  },
  confirmedButtonWrapper: {
    marginLeft: '30px',
    '& > :not(:first-child)': {
      marginLeft: '10px',
    },
  },
  confirmedButton: {
    padding: '4px',
    '&:hover': {
      padding: '4px 10px',
    },
    '& .MuiButton-startIcon': {
      marginRight: '0px',
    },
  },
  confirmedButtonHelperText: {
    fontSize: '10px',
    // opacity: 0.5,
  }
}));

const Header = observer(({ inbound }) => {
  const classes = useStyles();
  const history = useHistory();

  const TABS = ['PDF', 'excel'];
  const [tab, setTab] = React.useState(TABS[0])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorDropdownEl, setDropdownAnchorEl] = React.useState(null);
  const openDropdown = Boolean(anchorDropdownEl);
  const handleClick = (event) => {
    setDropdownAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setDropdownAnchorEl(null);
  };


  const { detail } = inbound;

  return <Box className={classes.root}>
    <Box className={classes.backIconWrapper}>
      <LeftArrowIcon className={classes.backIcon} onClick={() => history.goBack()} />
    </Box>
    <Box className={classes.titleWrapper}>
      <Box className={classes.title}>
        {detail.ib_shipment_number} / {detail.uid} / {detail.total_qty} / {detail.truck_size} / {detail.delivery_date} / {detail.operator_name} / <UnloadingTeamModal inbound={inbound} />
      </Box>
      <Box className={classes.subTitle}>
        # IB Shipment /# WHS Alert / # Pieces / Size / OP / Unloading Team
      </Box>
    </Box>
    <Box display="flex" alignItems="flex-end" className={classes.confirmedButtonWrapper}>
      {!!detail.received_at && <Box>
        <Tooltip title={`at ${detail.received_at} by ${detail.received_at}`}>
          <Button
            onClick={detail.unreceive}
            className={classes.confirmedButton}
            size="small" variant="outlined"
            color="primary"
            startIcon={<FlagIcon />}
          >
            confirmed received
          </Button>
        </Tooltip>
      </Box>
      }
      {!!detail.received_at && !!detail.empty_at && detail.isOcean && <Box>
        <Tooltip title={`at ${detail.empty_at} by ${detail.empty_by}`}>
          <Button
            className={classes.confirmedButton}
            size="small"
            variant="outlined"
            color="primary"
            onClick={detail.unempty}
            startIcon={<FlagIcon />}
          >
            confirmed empty
          </Button>
        </Tooltip>
      </Box>
      }
      {!!detail.received_at && (!!detail.empty_at || !detail.isOcean) && !!detail.completed_at && <Box>
        <Tooltip title={`at ${detail.completed_at} by ${detail.completed_by}`}>
          <Button
            className={classes.confirmedButton}
            size="small"
            variant="outlined"
            color="primary"
            onClick={detail.uncomplete}
            startIcon={<FlagIcon />}
          >
            confirmed IB completed
          </Button>
        </Tooltip>
      </Box>
      }
    </Box>
    <Box display="flex" alignItems="flex-end" ml="auto">
      {!detail.received_at && <Box>
        <ConfirmReceive inbound={inbound} />
      </Box>}
      {!!detail.received_at && !detail.empty_at && detail.isOcean && <Box>
        <ConfirmEmpty inbound={inbound} />
      </Box>}
      {!!detail.received_at && (!!detail.empty_at || !detail.isOcean) && !detail.completed_at && <Box>
        <ConfirmComplete inbound={inbound} />
      </Box>}
      <Box >
        <Button
          style={{ marginLeft: '5px' }}
          variant="outlined" color="primary"
          size="small"
          aria-controls={openDropdown ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openDropdown ? 'true' : undefined}
          onClick={handleClick}
        >
          Actions
        </Button>
        <Menu
          onKeyDown={(e) => e.stopPropagation()}
          anchorEl={anchorDropdownEl}
          open={openDropdown}
          onClose={handleCloseDropdown}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          {!inbound.detail.whs_trouble_shoot && <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <Box style={{ width: '100%' }} ml={2}>
              <TroubleShootForm inbound={inbound}></TroubleShootForm>
            </Box>
          </MenuItem>}
          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <Box style={{ width: '100%' }} ml={2}>
              <Button style={{ width: '100%' }} color="primary" onClick={handleOpen}>VIEW RECEIPT</Button>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box p={1}>
                  <Box className={classes.selectContainer} display="flex" mt={1}>
                    <Box>
                      <Button size="small" variant="contained" color="primary" onClick={detail.viewPDF} >View PDF</Button>
                    </Box>
                    <Box ml={1}>
                      <Button size="small" variant="contained" color="primary" onClick={() => detail.viewExcel('summary')} >View Summary Excel</Button>
                    </Box>
                    <Box ml={1}>
                      <Button size="small" variant="contained" color="primary" onClick={() => detail.viewExcel('details')} >View Detail Excel</Button>
                    </Box>
                  </Box>
                </Box>
              </Popover>
            </Box>
          </MenuItem>
          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <Box style={{ width: '100%' }} ml={2}>
              <PrintPltLabels inbound={inbound} />
            </Box>
          </MenuItem>
          <MenuItem onKeyDown={(e) => e.stopPropagation()}>
            <Box style={{ width: '100%' }} ml={2}>
              <Button style={{ width: '100%' }} color="primary" onClick={detail.exportExcel}>EXPORT</Button>
            </Box>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  </Box >
})

export default Header;
