import React from 'react';
import { observer, inject } from 'mobx-react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { Link, useLocation } from 'react-router-dom';

const INTERVAL_TIME = 1000 * 60 * 10; // 10min

const NotificationBell = inject('root')
  (observer(({ root }) => {

    const location = useLocation();

    const [focus, setFocus] = React.useState(true);
    const [intervalInstance, setIntervalInstance] = React.useState(null);

    const { chatRoomNotification } = root;

    const windowOnFocus = React.useCallback(() => {
      chatRoomNotification.loadCount();
      setFocus(true);
    }, [setFocus]);

    const windowOnBlur = React.useCallback(() => {
      setFocus(false);
    }, [setFocus]);

    React.useEffect(() => {
      chatRoomNotification.loadCount();

      window.addEventListener('focus', windowOnFocus);
      window.addEventListener('blur', windowOnBlur);

      setIntervalInstance(setInterval(() => focus && chatRoomNotification.loadCount(), INTERVAL_TIME));

      return () => {
        if (intervalInstance) {
          clearInterval(intervalInstance);
          setIntervalInstance(null);
        }

        window.removeEventListener('focus', windowOnFocus);
        window.removeEventListener('blur', windowOnBlur);
      }
    }, []);

    return <>
      <Link to={{ pathname: '/notification/chatroom', state: { background: location } }}>
        <Badge badgeContent={chatRoomNotification.unreadCount} color="error">
          <NotificationsIcon style={{ cursor: 'pointer' }} />
        </Badge>
      </Link>
    </>
  }))

export default NotificationBell;
