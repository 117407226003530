import React from 'react';
import { observer } from 'mobx-react';
import { GROUP_STATUS_IN_WHS, GROUP_STATUS_IN_YARD, GROUP_STATUS_NOT_IN_WHS, GROUP_STATUS_TRANSFERED_TO_WMS } from '../../stores/inventory/constants';
import Badge from './Badge';

const WhsGroupBadge = observer(({ group }) => {
  return <>
    {group.status == GROUP_STATUS_IN_WHS && <Badge color="primary">In WHS</Badge>}
    {group.status == GROUP_STATUS_NOT_IN_WHS && <Badge color="secondary">Not In WHS</Badge>}
    {group.status == GROUP_STATUS_IN_YARD && <Badge color="green">In Yard</Badge>}
    {group.status == GROUP_STATUS_TRANSFERED_TO_WMS && <Badge color="yellow">To WMS</Badge>}
  </>
})

export default WhsGroupBadge;
