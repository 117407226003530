import React, { useState, useRef, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Button } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import { format } from 'date-fns-tz'
import CommonDocuments from '../../../common/Documents';
import ChatRoom from '../../../common/ChatRoom';
import AddonServicesTable from '../../addonService/AddonServicesTable';
import EditableAddonServiceTable from '../../addonService/EditableAddonServiceTable';


const CustomeSelect = ({ defaultOption, options = [], onSelected, renderSelected },) => {

  const [selected, setSelected] = useState(options[defaultOption])
  const [drop, setDrop] = useState(false)

  const dropdown = useRef();

  const handleClickOutside = useCallback((ev) => {
    if (dropdown.current.contains(ev.target) === false) {
      setDrop(false);
    }
  }, [])
  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };

  }, [handleClickOutside])

  const onClick = (option) => {
    setSelected(option);
    onSelected(option);
    setDrop(false);
  }

  return <>
    <div
      style={{ position: 'relative' }}
      ref={dropdown}>
      <div style={{
        minWidth: '160px',
        background: '#2E52C5',
        color: 'white',
        border: '1px solid white',
        borderRadius: '3px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
      }}
        onClick={() => setDrop(true)}
      >
        <div style={{
          padding: '0px 30px',
          textAlign: 'center',
          fontWeight: '500',
          flexGrow: '1',
        }}>{renderSelected ? renderSelected(selected) : selected.value}</div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          color: '#2E52C5',
          height: '100%',
          width: '18px'
        }}>
          <ExpandMoreIcon ></ExpandMoreIcon>
        </div>
      </div>
      {drop && <div style={{
        color: '#243967',
        position: 'absolute',
        right: '0',
        zIndex: 100,
        marginTop: '5px',
        background: 'white',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer',

      }}>
        <div style={{
          marginTop: '5px',
          borderTop: '1px solid #F3F5F9',
          width: '100%',
          height: '1px'
        }}></div>
        {options.map((option) => <div
          key={option.key + option.value}
          onClick={() => onClick(option)}
          style={{
            width: '100%',
            minWidth: '176px',
            padding: '10px 8px',
            backgroundColor: option.key === selected.key ? '#F3F5F9' : 'white',
            display: 'flex',
            alignItems: 'center'
          }}>
          <div style={{
            marginLeft: '8px',
            display: 'flex',
            alignItems: 'center'
          }}><input type="radio"
            style={{ margin: '0' }}
            checked={option.key === selected.key}
            onChange={console.log}
            name="dropdown" />
          </div>
          <div style={{
            marginLeft: '7px',
            fontSize: '14px',
          }}>{option.value}</div>
        </div>)}
      </div>}
    </div>
  </>
}

const formatDate = (date) => {
  return format(new Date(date), 'HH:mm a MM/dd/yyyy');
}



const TroubleShoot = inject('root')(observer(({ whs_trouble_shoot, root, tableView }) => {

  const [editable, setEditable] = useState(false);
  const [description, setDescription] = useState(whs_trouble_shoot.description);

  useEffect(() => {
    if (tableView) {
      whs_trouble_shoot.open();
    }
  }, []);

  const updateDescription = () => {
    whs_trouble_shoot.updateTroubleShoot({ description })
      .then(() => setEditable(false))
  }

  const updateStatus = (selected) => {
    whs_trouble_shoot.updateTroubleShoot({ status: selected.key })
      .then(() => setEditable(false))
  }

  const updateHasRevise = (selected) => {
    whs_trouble_shoot.updateTroubleShoot({ has_revise: selected.key })
      .then(() => setEditable(false))
  }

  return <>
    {!tableView && <div
      id="troubleshoot"
      style={{
        background: '#2E52C5',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',

        fontSize: '14px',
        lineHeight: '21px'
      }}>
      <div
        onClick={() => whs_trouble_shoot.switchOpened()}
        style={{ display: 'flex', alignItems: 'center', flex: 1, padding: '10px 40px', }}>
        <InfoIcon />
        <span>Trouble Shoot #{whs_trouble_shoot.id}</span>
        {whs_trouble_shoot.isOpened && <ExpandLessIcon style={{
          fontSize: '24px',
        }} />}
        {!whs_trouble_shoot.isOpened && <ExpandMoreIcon style={{
          fontSize: '24px',
        }} />}
      </div>
      <div style={{ padding: '10px' }}>
        <CustomeSelect
          defaultOption={whs_trouble_shoot.has_revise ? 0 : 1}
          options={[{
            key: true,
            value: 'Yes',
            extra: '',
          }, {
            key: false,
            value: 'No',
            extra: '',
          },]}
          onSelected={(selected) => updateHasRevise(selected)}
          renderSelected={option => 'Revise: ' + option.value}
        />
      </div>
      <div style={{ padding: '10px' }}>
        <CustomeSelect
          defaultOption={whs_trouble_shoot.status}
          options={[{
            key: 0,
            value: 'In Progress',
            extra: '',
          }, {
            key: 1,
            value: 'Solved',
            extra: '',
          }, {
            key: 2,
            value: 'Cancelled',
            extra: '',
          }]}
          onSelected={(selected) => updateStatus(selected)}

        />
      </div>
    </div>}
    {whs_trouble_shoot.isOpened && <div style={{
      width: '100%',
      display: 'flex',
    }}>
      <div style={{
        width: '50%',
        backgroundColor: 'white'
      }}>
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '.5rem 3rem',
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <span><b>Report Trouble Shoot</b></span>
            {!editable ?
              <Button size="small" variant="outlined" onClick={() => setEditable(true)} color="primary">Edit</Button>
              :
              <div>
                <Button size="small" variant="outlined" style={{ marginRight: '5px' }} onClick={() => {
                  setDescription(whs_trouble_shoot.description);
                  setEditable(false)
                }} color="primary">Cancel</Button>
                <Button size="small" style={{ background: '#2E52C5', color: 'white' }} onClick={() => updateDescription()} color="primary">Save</Button>
              </div>}
          </div>
          <div>
            {!editable ? <div style={{
              margin: '5px 0px',
              padding: '15px 10px',
              border: '1px solid #BBBFC8',
              backgroundColor: '#F8F9FB',
              borderRadius: '3px',
              fontSize: '12px',
              position: 'relative',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}>
              {whs_trouble_shoot.description}
              <span style={{
                position: 'absolute',
                bottom: '4px',
                right: '10px',
                color: '#80889A'
              }}>{formatDate(whs_trouble_shoot.updated_at)} by {whs_trouble_shoot.update_by_name}</span>
            </div> :
              <InputBase
                mt={2}
                value={description}
                style={{
                  margin: '5px 0px',
                  padding: '15px 10px',
                  border: '1px solid #BBBFC8',
                  backgroundColor: '#F8F9FB',
                  borderRadius: '3px',
                  fontSize: '12px',
                  position: 'relative',
                  width: '100%'
                }}
                multiline
                rows="3"
                onChange={e => setDescription(e.target.value)}
                placeholder="Write description for the Trouble Shoot here..." />
            }
            <div style={{
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center',

            }}>
              <AttachFileIcon style={{
                color: '#80889A',
                fontSize: '16px',
              }} />
              <span style={{
                color: '#80889A',
                fontSize: '12px',
              }}>Attachment To Admin</span>
            </div>
            <div style={{ marginTop: '10px' }}>
              <CommonDocuments dropzonePosition="top" documents={whs_trouble_shoot.fromWhsDocuments} uploadable={!tableView}></CommonDocuments>
            </div>
            <div style={{
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center',

            }}>
              <AttachFileIcon style={{
                color: '#80889A',
                fontSize: '16px',
              }} />
              <span style={{
                color: '#80889A',
                fontSize: '12px',
              }}>Attachment From Admin</span>
            </div>
            <div style={{ marginTop: '10px' }}>
              <CommonDocuments dropzonePosition="top" documents={whs_trouble_shoot.toWhsDocuments} uploadable={!tableView} ></CommonDocuments>
            </div>
            <div style={{ marginTop: '30px' }}>
              <Box mt={5} mb={1} >
                Addon Service <Button style={{ background: '#2E52C5', color: 'white' }} size="small" onClick={() => whs_trouble_shoot.updateTroubleShoot({
                  addon_services: whs_trouble_shoot.addon_services
                })}>Save</Button>
              </Box>
              <EditableAddonServiceTable data={whs_trouble_shoot.addon_services || []} />
            </div>
          </div>
        </div>
      </div>
      <div style={{
        width: '50%',
        backgroundColor: '#F8F9FB',
        paddingLeft: '26px',
      }}>
        <ChatRoom
          senderId={root.auth.user.id}
          targetId={whs_trouble_shoot.id}
          target="whs_trouble_shoot"
          sendMessage={!tableView}
          header={!tableView} />
        {/* </div> */}
      </div>
    </div>
    }</>
}))

export default TroubleShoot;
