import React from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  btns: {
    marginLeft: 'auto',
  },
}));

const Documents = observer(({ documents }) => {
  const classes = useStyles();

  return <Box>
    {documents.collection.map(document => <Box className={classes.row} key={document.id}>
      <Box className={classes.name} onClick={document.view}>{document.name}</Box>
      <Box className={classes.btns}>
        <Button size="small" variant="outlined" color="primary" onClick={document.view}>View</Button>
      </Box>
    </Box>)}
  </Box>
});

export default Documents;
