import request from './request';

export const task = {
  counts() {
    return request.get('tasks/counts');
  },
  index(params = {}) {
    return request.get('tasks', { params });
  },
  complete(loadId, taskId) {
    return request.put(`tasks/${loadId}/complete/${taskId}`);
  },
  uncomplete(loadId, taskId) {
    return request.put(`tasks/${loadId}/uncomplete/${taskId}`);
  },
  show(id) {
    return request.get(`tasks/${id}`);
  },
}
