import React from "react";
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const ConfirmLoaded = observer(({ outbound }) => {

  const [loading, setLoading] = React.useState(false);

  const handleConfirm = () => {
    if (!window.confirm('Are you sure to Confirm Cargo Loaded')) {
      return;
    }
    setLoading(true);
    outbound.confirmLoaded().then((response) => {
      if (response) {
        outbound.outbounds.loadCollection();
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const cancelLoaded = () => {
    if (!window.confirm('Are you sure to Cancel Cargo Loaded')) {
      return
    }
    setLoading(true);
    outbound.cancelLoaded().then((response) => {
      if (response) {
        outbound.outbounds.loadCollection();
      }
    }).finally(() => {
      setLoading(false);
    });
  }



  return (
    <div>
      {!outbound.shipout_at && (outbound.status != 6 ? < Button disabled={loading} size="small" variant="outlined" color="primary" onClick={handleConfirm}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          "Cargo Loaded"
        )}
      </Button> : < Button size="small" variant="outlined" color="primary" onClick={cancelLoaded}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          "Cancel Cargo Loaded"
        )}
      </Button>)}

    </div >
  );
});

export default ConfirmLoaded;
