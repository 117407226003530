export const LOAD_STATUS_PRE_ALERT = 0;
export const LOAD_STATUS_ARRIVED = 10;
export const LOAD_STATUS_AT_WHS_YARD = 13;
export const LOAD_STATUS_RECEIVED = 15;
export const LOAD_STATUS_DISPATCHED = 20;
export const LOAD_STATUS_IN_TRANSIT = 22;
export const LOAD_STATUS_DELIVERED = 25;
export const LOAD_STATUS_EXCEPTION = 30;
export const LOAD_STATUS_OPERATION_CLOSED = 35;

export const LOAD_STATUSES_MAP = {
    [LOAD_STATUS_PRE_ALERT]: 'Pre-Alert',
    [LOAD_STATUS_ARRIVED]: 'Arrived at Port',
    [LOAD_STATUS_AT_WHS_YARD]: 'At WHS Yard',
    [LOAD_STATUS_RECEIVED]: 'WHS Received',
    [LOAD_STATUS_DISPATCHED]: 'Dispatched',
    [LOAD_STATUS_IN_TRANSIT]: 'In Transit',
    [LOAD_STATUS_DELIVERED]: 'POD',
    [LOAD_STATUS_OPERATION_CLOSED]: 'OP Closed',
    [LOAD_STATUS_EXCEPTION]: 'Exception',
};

export const GROUP_STATUS_IN_YARD = 4;
export const GROUP_STATUS_TRANSFERED_TO_WMS = 3;
export const GROUP_STATUS_IN_WHS = 2;
export const GROUP_STATUS_NOT_IN_WHS = 1;

export const GROUP_STATUSES_MAP = {
    [GROUP_STATUS_IN_YARD]: 'In Yard',
    [GROUP_STATUS_IN_WHS]: 'In WHS',
    [GROUP_STATUS_TRANSFERED_TO_WMS]: 'Transfered To WMS',
    [GROUP_STATUS_NOT_IN_WHS]: 'Not In WHS',
};

export const COLUMN_NAME = 'inventorys';

export const AREA_OPERATION = 0;
export const AREA_STORAGE = 1;

export const AREA_MAP = {
  [AREA_OPERATION] : 'Operation Area',
  [AREA_STORAGE] : 'Storage Area',
}


export const LOAD_TYPE_FBM = 0;
export const LOAD_TYPE_FBA = 1;
export const LOAD_TYPE_UPS = 2;
export const LOAD_TYPE_FEDEX = 3;
export const LOAD_TYPE_FBA_DIRECT = 4;
export const LOAD_TYPE_FBM_DIRECT = 5;
export const LOAD_TYPE_WORK_ORDER = 6;
export const LOAD_TYPE_OTHER = 8;
export const LOAD_TYPE_ORDER_FULFILLMENT = 9;
export const LOAD_TYPE_TEMPORARY_STORAGE = 10;
export const LOAD_TYPE_SELF_PICKUP = 11;


export const LOAD_TYPE_MAP = {
    [LOAD_TYPE_FBM]: 'FBM',
    [LOAD_TYPE_FBA]: 'FBA',
    [LOAD_TYPE_UPS]: 'UPS',
    [LOAD_TYPE_FEDEX]: 'FedEx',
    [LOAD_TYPE_WORK_ORDER]: 'Work Order',
    [LOAD_TYPE_ORDER_FULFILLMENT]: 'Order Fulfilment',
    [LOAD_TYPE_TEMPORARY_STORAGE]: 'Temporary Storage',
    [LOAD_TYPE_SELF_PICKUP]: 'Self Pickup',
    // [LOAD_TYPE_FBA_DIRECT]: 'FBA Direct',
    // [LOAD_TYPE_FBM_DIRECT]: 'FBM Direct',
    // [LOAD_TYPE_OTHER]: 'Other',
}
