import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  title: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '150%',
    color: '#000000',
    marginBottom: '5px',
  },
  input: {
    background: '#FFFFFF',
    border: '1px solid #BDBDBD',
    minWidth: '176px',
    minHeight: '36px',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '14px',
    padding: '10px 15px',
  },
}))


const Input = ({
  title,
  value,
  onChange,
  name,
  inputProps = {},
}) => {
  const classes = useStyles();

  return <Box className={classes.root} >
    <Box className={classes.title}>{title}</Box>
    <Box>
      <Box className={classes.inputBox}>
        <input {...inputProps} name={name} value={value} onChange={onChange} className={classes.input} />
      </Box>
    </Box>
  </Box>
}


export default Input;
