import React from 'react';
import Box from '@material-ui/core/Box';
import Badge from '../../../common/Badge';
import Table from '../../../ui/Table';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import AddonServicesTable from '../../addonService/AddonServicesTable';
import Input from '../../../ui/Input'
import Textarea from '../../../ui/Textarea'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import CollectionText from '../../../common/CollectionText';
import { get } from 'lodash';
import { TrashIcon } from '../../../ui/Icon';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '21px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '300px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '300px',
    },
  },
  subRows: {
    backgroundColor: '#FFFFFF',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: '18px',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
  },
  w100: {
    width: '100%',
  },
  addSku: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const TaskAttributeInput = withStyles(theme => ({
  root: {
    background: '#FFFFFF',
  },
  input: {
    padding: '8px',
    fontSize: '12px',
  }
}))(OutlinedInput);


const TaskAttributeSelect = withStyles(theme => ({
  root: {
    background: '#FFFFFF',
  },
  select: {
    paddingTop: '5.5px',
    paddingBottom: '5.5px',
    fontSize: '12px',
    background: '#FFFFFF',
  }
}))(Select);

const skuColumns = [
  {
    Header: 'SKU',
    accessor: 'sku',
  },
  {
    Header: 'WHS Marking',
    accessor: 'whs_markings',
    Cell: ({ row: { original } }) => <CollectionText texts={original.whs_markings} />
  },
  {
    Header: 'Qty on Hand',
    accessor: 'qty_on_hand',
  },
  {
    Header: 'Qty Outbound',
    accessor: 'qty_outbound',
  },
]

const shipmentColumns = [
  {
    Header: 'PKG ID',
    Cell: ({ row: { index, depth } }) => depth === 0 ? `PKG${index + 1}` : '',
  },
  {
    Header: 'SKU*',
    Cell: observer(({ row: { original, depth }, state: { detail, classes } }) => <Box>
      {depth === 0 && <>
        <Box>
          <FormControl variant="outlined">
            <TaskAttributeSelect
              margin="dense"
              value={get(original, 'products.0.id')}
              onChange={e => original.selectProduct(e.target.value)}
              required
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {detail.products.map(product => <MenuItem key={product.id} value={product.id}>{product.sku}</MenuItem>)}
            </TaskAttributeSelect>
          </FormControl>
        </Box>
        <Box mt={1} className={classes.addSku} onClick={original.addItem}>
          Add New SKU
        </Box>
      </>}
      {depth === 1 && <Box>
        <FormControl variant="outlined">
          <TaskAttributeSelect
            margin="dense"
            value={original.id}
            onChange={e => original.selectProduct(e.target.value)}
            required
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {detail.products.map(product => <MenuItem key={product.id} value={product.id}>{product.sku}</MenuItem>)}
          </TaskAttributeSelect>
        </FormControl>
      </Box>}
    </Box>),
  },
  {
    Header: 'OB Carton Qty*',
    Cell: observer(({ row: { original, depth } }) => <>
      {depth == 0 && <TaskAttributeInput style={{ width: '60px' }} margin="dense" name="carton_qty" value={original.carton_qty} onChange={original.setValueByHtmlElememt} required />}
      {/* {depth == 1 && <TaskAttributeInput style={{ width: '60px' }} margin="dense" name="carton_qty" value={original.carton_qty} onChange={original.setValueByHtmlElememt} required />} */}
    </>),
  },
  {
    Header: 'Carton Size (Inches)*',
    Cell: observer(({ row: { original, depth } }) => <>
      {depth == 0 && <>
        <TaskAttributeInput style={{ width: '60px' }} margin="dense" name="length_in" value={original.length_in} onChange={original.setValueByHtmlElememt} required />
        <TaskAttributeInput style={{ width: '60px' }} margin="dense" name="width_in" value={original.width_in} onChange={original.setValueByHtmlElememt} required />
        <TaskAttributeInput style={{ width: '60px' }} margin="dense" name="height_in" value={original.height_in} onChange={original.setValueByHtmlElememt} required />
      </>}
    </>),
  },
  {
    Header: 'Carton Weight (LB)*',
    Cell: observer(({ row: { original, depth } }) => <>
      {depth == 0 && <>
        <TaskAttributeInput style={{ width: '60px' }} margin="dense" name="weight_lb" value={original.weight_lb} onChange={original.setValueByHtmlElememt} required />
      </>}
    </>),
  },
  {
    Header: 'OB Item Qty*',
    Cell: observer(({ row: { original, depth } }) => <>
      {depth == 0 && <TaskAttributeInput style={{ width: '60px' }} margin="dense" name="total_qty" value={original.products[0].total_qty} onChange={original.products[0].setValueByHtmlElememt} required />}
      {depth == 1 && <TaskAttributeInput style={{ width: '60px' }} margin="dense" name="total_qty" value={original.total_qty} onChange={original.setValueByHtmlElememt} required />}
    </>),
  },
  {
    Header: 'Action',
    Cell: observer(({ row: { original, depth } }) => <>
      {depth === 0 && original.id && <>
        {!original.verified_at && <Button size="small" variant="contained" color="primary" onClick={original.verify}>Verify</Button>}
        {original.verified_at && <Button size="small" variant="outlined" color="primary" onClick={original.unverify}>Verified</Button>}
      </>}
      {depth === 1 && <TrashIcon style={{ cursor: 'pointer', fontSize: '18px' }} onClick={original.remove} />}
    </>)
  },
]


const Detail = observer(({ bol }) => {
  const classes = useStyles();
  const { detail } = bol;

  // force table to render with mobx changed
  const nothing = detail.product_packages.map(i => i.products.length);

  return <Box className={classes.root}>
    <Box>
      <Grid container spacing={3}>
        <Grid item>
          <Input title="Shipping Model" inputProps={{ style: { width: '100%' } }} value={detail.shipping_mode} disabled />
        </Grid>
        <Grid item>
          <Input title="Type" inputProps={{ style: { width: '100%' } }} value={detail.type} disabled />
        </Grid>
        <Grid item>
          <Input title="Payment Type" inputProps={{ style: { width: '100%' } }} value={detail.payment_type} disabled />
        </Grid>
      </Grid>
    </Box>
    <Box mt={1}>
      <Grid container spacing={3}>
        <Grid item>
          <Input title="Delivery Location" inputProps={{ style: { width: '100%' } }} value={detail.delivery_code} disabled />
          <Textarea inputProps={{ style: { width: '100%' } }} value={detail.delivery_address} disabled />
        </Grid>
        <Grid item>
          <Input title="Customer" inputProps={{ style: { width: '100%' } }} value={get(detail, 'customer.code')} disabled />
          <Textarea inputProps={{ style: { width: '100%' } }} value={get(detail, 'customer.bl_address')} disabled />
        </Grid>
      </Grid>
    </Box>

    {detail.addon_services.length > 0 && <>
      <Box mt={1} mb={1} className={classes.title}>Addon Service:</Box>
      <AddonServicesTable data={detail.addon_services} />
    </>}
    <Box mt={3} mb={1} className={classes.title}>SKU Information:</Box>
    <Table
      columns={skuColumns}
      data={detail.products.slice()}
    />

    <Box display="flex" mt={3} mb={1}>
      <Box className={classes.title}>Shipment Information:</Box>
      <Box ml="auto"><Button color="primary" variant="contained" onClick={detail.addProductPackage}>Add Package</Button></Box>
    </Box>
    <Table
      columns={shipmentColumns}
      data={detail.product_packages.slice()}
      autoResetExpanded={false}
      getSubRows={row => row && row.products ? row.products.slice(1) : []}
      state={{
        detail,
        classes,
      }}
    />
    <Box mt={3} className={classes.title}>Packed PLT Count:</Box>
    <Box>
      <Input name="item_packed_pallet_count" value={detail.item_packed_pallet_count} onChange={detail.setValueByHtmlElememt} />
    </Box>
  </Box>
})

export default Detail;
