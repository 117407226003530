import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import ChatMessage from './ChatMessage'
import { chatRoom, document, toast } from '../../../services';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useDropzone } from 'react-dropzone'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { serialize } from 'object-to-formdata';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';

const ChatRoom = inject('root')(observer(({ root, senderId, target, targetId, chatRoomTarget = 'chatroom_files', sendMessage, header, behavior = 'smooth' }) => {
  senderId = senderId || root.auth.user.id;

  const messagesEndRef = useRef(null)
  const [messageText, setMessageText] = useState('')
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);

  const chatRoomId = useMemo(() => get(messages, '0.chat_room_id'), [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior, block: "nearest", inline: "nearest" })
  }
  const onDropped = useCallback(files => {
    const formData = serialize({ documents: [...files] }, { indices: true, nullsAsUndefineds: true });
    setSending(true);
    chatRoom.uploadDocuments(target, targetId, formData)
      .then(response => {
        setMessages(response.data.data);
        setMessageText('');
      })
      .catch(err => toast.error(err))
      .then(() => setSending(false));
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropped })
  const { onDrop, onClick } = getRootProps();

  // const documentsStore = DocumentsStore.create({ target: 'chatroom_file', targetId: chatRoomId })

  useEffect(() => {
    pullMessages();
  }, []);

  const pullMessages = () => {
    setLoading(true);
    chatRoom.pull(target, targetId).then((response) => {
      setMessages(response.data.data);
      root.chatRoomNotification.loadCount();
    })
      .catch(err => toast.error(err))
      .then(() => setLoading(false))
  }

  const handleSendMessage = () => {
    if (messageText === '') {
      toast.error('message cannot be empty!')
      return
    }
    setSending(true);
    chatRoom.sendMessage(target, targetId, {
      message: messageText
    }).then((response) => {
      setMessages(response.data.data);
      setMessageText('');
    })
      .catch(err => toast.error(err))
      .then(() => setSending(false))
  }

  const handleViewFile = (id) => {
    document.show(chatRoomTarget, chatRoomId, { id })
  }

  const handlePaste = async (e) => {
    let { items } = e.clipboardData;
    if (!items || !sendMessage) {
      return;
    }
    let pastedDocuments = []
    setSending(true);
    for (let index in items) {
      let item = items[index];
      if (item.kind === 'file') {
        let file = item.getAsFile();
        pastedDocuments.push(file);
      }
    }
    for (let doc of pastedDocuments) {
      const formData = serialize({ documents: [doc] }, { indices: true, nullsAsUndefineds: true });
      try {
        let response = await chatRoom.uploadDocuments(target, targetId, formData)
        setMessages(response.data.data);

      }
      catch (err) {
        toast.error(err)
      }
    }
    setSending(false)
  }

  const handleEntrepressed = (e) => {
    if (e.charCode === 13) {
      handleSendMessage()
    }
  }

  useEffect(() => { scrollToBottom() }, [messages]);

  return <div onPaste={handlePaste} >
    {header && <div style={{
      padding: '10px 0px 10px 28px',
      background: '#F8F9FB',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '150%',
    }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <QuestionAnswerOutlinedIcon />
        <span style={{
          color: '#001235',
          fontSize: '14px',
          marginLeft: '2px',
        }}>Chat Room</span>
      </div>
      {loading && <div >Loading Message....</div>}
      <RefreshIcon style={{
        marginRight: '28px',
        color: '#2E52C5'
      }} onClick={() => pullMessages()}></RefreshIcon>
    </div>}
    <div>
      <div style={{
        height: '230px',
        overflowY: 'auto',
      }} >

        {messages.map(message => <ChatMessage
          viewFile={handleViewFile}
          key={message.id}
          sender={message.sender_type === 'App\\Models\\SubWarehouse' && message.sender_id === senderId}
          message={message}
        />)}
        <div ref={messagesEndRef} />
      </div>
      <div>
        {
          sendMessage && <div style={{
            border: '1px solid #80889A',
            borderRadius: '3px',
            padding: '5px',
            display: 'flex',
            alignItems: 'center',
            background: '#FFFFFF',
          }}
            onDrop={onDrop}>
            <input style={{
              border: 'none',
              width: '80%',
              outline: 'none',
              background: 'unset',
            }}
              onKeyPress={(e) => handleEntrepressed(e)}
              onChange={(e) => setMessageText(e.target.value)}
              placeholder="Write a message here..."
              value={messageText}
            />
            <input {...getInputProps()} />
            <button style={{
              border: 'none',
              background: 'white',
              color: '#C7C8CE',
              marginLeft: 'auto',
            }}
              onClick={onClick}
            >
              <AttachFileIcon />
            </button>
            <button
              style={{
                padding: '4px 27px',
                background: '#2E52C5',
                border: '1.5px solid #2E52C5',
                color: 'white',
                marginLeft: 'auto',
                borderRadius: '3px',
              }}
              disabled={messageText === '' || sending}
              onClick={() => handleSendMessage()}
            >{sending ? 'Sending...' : 'Send'}</button>
          </div>
        }
      </div>
    </div>
  </div>
}))


export default ChatRoom;
