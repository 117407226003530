import React from 'react'
import Table from '../../ui/Table'
import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Documents from '../../common/Documents'
import { Doc } from './AddonServicesTable'
import { Input } from '@material-ui/core'
import BillingCodeSelect from '../../common/BillingCodeSelect'


const EditableAddonServiceTable = observer(({ data }) => {


  const columns = React.useMemo(() => [
    {
      Header: 'Billing Code',
      accessor: 'code',
      Cell: observer(({ row: { original } }) => {
        return <BillingCodeSelect selected={original.code} onChange={(selected) => original.updateCode(selected)} disabled={!!original.approved} />
      })
    },
    {
      Header: 'Billing Name',
      accessor: 'name',
      Cell: observer(({ row: { original } }) => {
        return <Input value={original.name} name="name" onChange={original.updateRow} disabled={!!original.approved} />
      })
    },
    {
      Header: 'Status',
      Cell: observer(({ row: { original } }) => original.status_name),
    },
    {
      Header: 'Rate',
      accessor: 'rate',
      Cell: observer(({ row: { original } }) => {
        return <Input type='number' value={original.rate} name="rate" onChange={original.updateRow} disabled={!!original.approved} />
      })
    },
    {
      Header: 'Qty',
      accessor: 'qty',
      Cell: observer(({ row: { original } }) => {
        return <Input type='number' value={original.qty} name="qty" onChange={original.updateRow} disabled={!!original.approved} />
      })
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: observer(({ row: { original } }) => {
        return <Input type='number' value={original.amount} name="amount" onChange={original.updateRow} disabled={!!original.approved} />
      })
    },
    {
      Header: 'Approved',
      accessor: 'approved',
      Cell: observer(({ row: { original } }) => {
        return original.approved ? 'Approved' : 'Pending'
      })
    },
    {
      Header: 'Related Doc',
      Cell: ({ row: { original } }) => <Doc model={original} />,
    },
    {
      Header: 'Action',
      Cell: observer(({ row: { original } }) => <>
        {original.status == 0 && <Button variant="contained" color="primary" size="small" onClick={original.complete}>Complete</Button>}
        {original.status == 1 && <Button variant="outlined" color="primary" size="small" onClick={original.uncomplete}>Incomplete</Button>}
      </>),
    },
  ], [])

  return <Table
    columns={columns}
    data={data}
  />
})

export default EditableAddonServiceTable
