import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { FlagIcon, LeftArrowIcon } from '../../../ui/Icon';
import { useHistory } from 'react-router-dom';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { CheckCircleOutline } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  backIconWrapper: {
    marginRight: '20px',
    lineHeight: '40px',
  },
  backIcon: {
    fontSize: '24px',
    color: '#00000',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  titleWrapper: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#000000',
  },
  title: {
    fontSize: '14px',
  },
  subTitle: {
    fontSize: '9px',
    opacity: 0.5,
  },
  headerBadgeBtn: {
    paddingRight: '15px',
    paddingLeft: '15px',

    '&:hover': {
      paddingRight: '15px',
      paddingLeft: '15px',
    },

    '&:focus': {
      paddingRight: '15px',
      paddingLeft: '15px',
    },
  },
  headerDangerBadge: {
    borderColor: theme.palette.red.main,
    color: theme.palette.red.main,
  },
}));

const Header = observer(({ workOrder }) => {
  const classes = useStyles();
  const history = useHistory();

  const { detail } = workOrder;

  return <Box className={classes.root}>
    <Box className={classes.backIconWrapper}>
      <LeftArrowIcon className={classes.backIcon} onClick={() => history.goBack()} />
    </Box>
    <Box className={classes.titleWrapper}>
      <Box className={classes.title}>
        {detail.uid}
      </Box>
      <Box className={classes.subTitle}>
        # Work Order
      </Box>
    </Box>
    <Box display="flex">
      {detail.completed_at && <Box ml={2}>
        <Button className={classes.headerBadgeBtn} variant="outlined" color="primary" size="small" onClick={detail.uncomplete}><CheckCircleOutline style={{ fontSize: '18px', marginRight: '8px' }} />Completed</Button>
      </Box>}
      {detail.completed_at && detail.is_ready_to_ship && <Box ml={2}>
        <Button className={classes.headerBadgeBtn} variant="outlined" color="primary" size="small"><LocalShippingIcon style={{ fontSize: '18px', marginRight: '8px' }} />Ready to Ship</Button>
      </Box>}
      {detail.completed_at && !detail.is_ready_to_ship && <Box ml={2}>
        <Button className={`${classes.headerBadgeBtn} ${classes.headerDangerBadge}`} variant="outlined" color="danger" size="small"><RemoveCircleIcon style={{ fontSize: '18px', marginRight: '8px' }} />Hold until Admin notify</Button>
      </Box>}
    </Box>
    <Box display="flex" alignItems="flex-end" ml="auto">
      {detail.canCancel && <Box ml={2}>
        <Button variant="outlined" color="primary" onClick={detail.cancel}>Cancel WO</Button>
      </Box>}
      {detail.canReject && <Box ml={2}>
        <Button variant="outlined" color="primary" onClick={detail.reject}>Reject WO</Button>
      </Box>}
      <Box ml={2}>
        <Button variant="outlined" color="primary" onClick={detail.downloadPDF}>Print WO</Button>
      </Box>
      {detail.canConfirm && <Box ml={2}>
        <Button variant="contained" color="primary" onClick={detail.confirm}>Confirm WO</Button>
      </Box>}
      {detail.canUnconfirm && <Box ml={2}>
        <Button variant="outlined" color="primary" onClick={detail.unconfirm}>Unconfirm WO</Button>
      </Box>}
      {detail.canComplete && <Box ml={2}>
        <Button variant="outlined" color="primary" onClick={detail.complete}>Complete</Button>
      </Box>}

    </Box>
  </Box>
})

export default Header;
