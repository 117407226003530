import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
// import { getSnapshot } from 'mobx-state-tree';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../ui/Table';
import Box from '@material-ui/core/Box';
// import Filter from './Filter';
import columns from './columns';
// import Actions from './Actions';
import Pagination from './Pagination';
import { product as productService } from '../../../services';
import { Button } from '@material-ui/core';
import Input from '../../ui/Input';
import ChangeInvertory from './ChangeInvertory';
import { toast } from '../../../services';
import Columns from '../../common/Columns';

const useStyles = makeStyles((theme) => ({
  root: {

  },
}));



const InventoryList = ({ root }) => {
  const classes = useStyles();
  const [products, setProducts] = React.useState([]);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [filter, setFilter] = React.useState({});
  const [submitting, setSubmitting] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 50,
    total: 0,
  });

  const {
    column,
  } = root.products;

  useEffect(() => {
    column.load();
    fetchProducts({ page: 1 });
  }, []);



  const fetchProducts = ({ page, params = {} } = {}) => {
    productService.index(
      {
        ...filter,
        ...params,
        page: page || pagination.page,
        per_page: pagination.perPage,
      }
    ).then(({ data }) => {
      setProducts(data.data);
      setPagination({
        page: data.meta.current_page,
        perPage: data.meta.per_page,
        total: Math.ceil(data.meta.total / data.meta.per_page),
      });
    })
  }

  const setSelected = React.useCallback((selected) => {
    setSelectedProducts(selected);
  }, []);

  const handleFilterChange = React.useCallback((e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  })

  const handleSubmit = React.useCallback((values) => {
    setSubmitting(true);
    return productService.bulkUpdateQty(values)
      .then(() => {
        toast.success('Successfully updated');
        fetchProducts();
        setSelectedProducts([]);
      }).catch(() => {
        toast.error('Failed to update');
      }).finally(() => {
        setSubmitting(false);
      })
  })

  return <>
    <Box className={classes.root}>
      <Box display="flex" alignItems={'end'} mb={3}>
        <Box>
          <Input
            title="Search"
            name="searchs"
            value={filter.searchs || ''}
            onChange={handleFilterChange}
          />
        </Box>
        <Box ml="auto" display="flex" >
          <Box ml={1}>
            <Button variant="contained" onClick={fetchProducts} color="primary">Search</Button>
          </Box>
          <Box ml={1}>
            <Button variant="contained" color="primary">Export</Button>
          </Box>
          <Box ml={1}>
            <ChangeInvertory products={selectedProducts} handleSubmit={handleSubmit} />
          </Box>
        </Box>
      </Box>
      <Box ml="auto" mb={1} display="flex" justifyContent={'end'}>
        <Columns
          columns={columns}
          selected={column.selected}
          onSelect={column.updateSelected}
        >
          <Button variant="outlined" color="primary">Manage Column</Button>
        </Columns>
      </Box>
      <Box>
        <Table
          stickyHeader
          footerable
          selection
          onSelectedChange={setSelected}
          columns={React.useMemo(() => columns.filter(c => column.selected.indexOf(c.accessor) !== -1), [column.selected.length])}
          data={products}
        />
      </Box>
      <Box mt={3} >
        <Pagination pagination={pagination} setPagination={setPagination} fetchProducts={fetchProducts} />
      </Box>
    </Box>

  </>
}

export default inject('root')(observer(InventoryList));
