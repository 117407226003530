import React, { useEffect } from 'react';
import clsx from 'clsx';
import Table from '../../../ui/Table';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import ShowMore from './ShowMore';
import DoneIcon from '@material-ui/icons/Done';
import CollectionText from '../../../common/CollectionText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MarkingHistories from './MarkingHistories';
import MarkingLink from './MarkingLink';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  title: {

  },
  titleItem: {
    paddingBottom: '15px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#262626',
    opacity: 0.7,
    position: 'relative',
    cursor: 'pointer',
  },
  titleActiveItem: {
    opacity: 1,
    color: theme.palette.primary.main,

    '&::after': {
      content: '""',
      margin: 'auto',
      background: theme.palette.primary.main,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '2.5px',
      width: '50%',
      maxWidth: '150px',
    },
  },
  showMore: {
    textAlign: 'center',
  },
  completedRow: {
    opacity: 0.5,
  },
  canceledRow: {
    opacity: 0.5,
  },
  subRows: {
    backgroundColor: '#FFFFFF',
  },
}));


const columns = [
  {
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...getToggleAllRowsExpandedProps()}
      >
        <Box>Container Number</Box>
        <Box>{isAllRowsExpanded ? <ArrowDropUpIcon style={{ fontSize: '21px' }} /> : <ArrowDropDownIcon style={{ fontSize: '21px' }} />}</Box>
      </Box>
    </>,
    accessor: 'container_number',
    Cell: ({ row: { original, canExpand, isExpanded, getToggleRowExpandedProps } }) => <>
      {canExpand ? <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...getToggleRowExpandedProps()}
      >
        <Box width={24} mr={1}>{original.isCompleted && <DoneIcon style={{ fontSize: '21px' }} />}</Box>
        <Box>{original.container_number}</Box>
        <Box width={24} ml={1}>{isExpanded ? <ArrowDropUpIcon style={{ fontSize: '21px' }} /> : <ArrowDropDownIcon style={{ fontSize: '21px' }} />}</Box>
      </Box> : null}
    </>,
  },
  {
    Header: 'Carton Marking',
    accessor: 'markings',
    Cell: ({ row: { original, depth } }) => <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {original.marking_histories && original.marking_histories.length > 1 && <Box width={24} mr={1}><MarkingHistories histories={original.marking_histories} /></Box>}
      <Box>
        {original.marking_histories && original.marking_histories.length > 1
          ? <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box color="#80889A">{original.firstMarkingHistory} - </Box>
            <Box><MarkingLink marking={original.marking} loadId={original.id} /></Box>
          </Box>
          : (
            depth === 0
              ? <CollectionText texts={original.markings} />
              : <MarkingLink marking={original.marking} loadId={original.id} />
          )
        }
      </Box>
      {depth > 0 && original.isCompleted && <Box width={24} ml={1}>{<CheckCircleIcon style={{ fontSize: '21px' }} />}</Box>}
    </Box>,
  },
  {
    Header: 'Shipment ID',
    accessor: 'shipment_ids',
    Cell: ({ row: { original } }) => <CollectionText texts={original.shipment_ids} />,
  },
  {
    Header: 'Task Type',
    accessor: 'task_types',
    Cell: ({ row: { original } }) => <CollectionText texts={original.task_types} />,
  },
  {
    Header: 'Task Unit',
    accessor: 'task_units',
    Cell: ({ row: { original } }) => <CollectionText texts={original.task_units} />,
  },
  {
    Header: 'Task Due Date',
    accessor: 'task_due_dates',
    Cell: ({ row: { original } }) => <CollectionText texts={original.task_due_dates} />,
  },
  {
    Header: 'Number of Piece',
    accessor: 'qty',
  },
  {
    Header: 'WHS PLT',
    accessor: 'pallet_count',
  },
];

const List = inject('root')(
  observer(({ root }) => {
    const classes = useStyles();

    const { type, setTypeAndLoad, typeCounts, loadCounts, loadCollection, collection } = root.tasks;

    useEffect(() => {
      loadCounts();
      loadCollection();
    }, []);


    return <Box className={classes.root}>
      <Grid className={classes.title} container alignContent="center" alignItems="center">
        {typeCounts.map(t => <Grid key={t.value} item xs onClick={() => setTypeAndLoad(t.value)} className={clsx(classes.titleItem, { [classes.titleActiveItem]: t.isActive(type) })}>
          {t.text} {t.count}
        </Grid>)}
      </Grid>
      <Table
        columns={columns}
        data={collection.slice()}
        getSubRows={(row) => row && row.loads ? row.loads.slice() : []}
        getRowProps={({ original, depth }) => {
          return {
            className: clsx({
              [classes.subRows]: depth > 0,
              [classes.completedRow]: original.isCompleted,
              [classes.canceledRow]: original.isCanceled,
            })
          }
        }}
      />
      <Box mt={3} className={classes.showMore}>
        <ShowMore tasks={root.tasks} />
      </Box>
    </Box>
  })
)

export default List;
