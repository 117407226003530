import { types, getParent, getRoot, flow } from 'mobx-state-tree';
import { CONTAINER_SIZE_TYPES } from '../../data';
import { isArray } from 'lodash';

const RELEASED_OPTIONS = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Hold',
    value: 'false',
  },
  {
    text: 'Released',
    value: 'true',
  },
];

const OUTBOUND_FULLY_POD_OPTIONS = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Yes',
    value: 'true',
  },
  {
    text: 'No',
    value: 'false',
  },
];

const CONTAINER_SIZE_OPTIONS = CONTAINER_SIZE_TYPES.map(s => ({ text: s, value: s }));
CONTAINER_SIZE_OPTIONS.unshift({ text: 'All', value: '' });

const Option = types
  .model({
    text: types.string,
    value: types.string,
  })

export const FilterStore = types
  .model({
    released: types.optional(Option, { ...RELEASED_OPTIONS[0] }),
    truckers: types.array(Option),
    container_size: types.optional(Option, CONTAINER_SIZE_OPTIONS[0]),
    is_outbound_fully_pod: types.optional(Option, OUTBOUND_FULLY_POD_OPTIONS[0]),
  })
  .volatile(self => ({
    truckerObjects: [],
  }))
  .views(self => ({
    get root() {
      return getRoot(self);
    },
    get releasedOptions() {
      return RELEASED_OPTIONS;
    },
    get containerSizeOptions() {
      return CONTAINER_SIZE_OPTIONS;
    },
    get truckerOptions() {
      return self.truckerObjects.map(d => ({
        text: d.name,
        value: String(d.id),
      }));
    },
    get outboundFullyPodOptions() {
      return OUTBOUND_FULLY_POD_OPTIONS;
    },
  }))
  .actions(self => ({
    loadTruckers: flow(function* () {
      const { data } = yield self.root.api.inbound.truckers();
      self.truckerObjects = data.data;
    }),
    renderSelectOption(o) {
      return o.text
    },
    onSelectSearchFilter(query, o) {
      return self.renderSelectOption(o).match(new RegExp(query, 'i'));
    },
    handleSelect(name) {
      return (option) => {
        self.setValue(name, option);
      }
    },
    isSelectedEqualToOption(selected, option) {
      return selected && selected.value === option.value;
    },
    toValues() {
      const values = { ...self.toJSON() };

      for (const k in values) {
        if (isArray(values[k])) {
          values[k] = values[k].map(o => o['value']);
        } else {
          values[k] = values[k]['value'];
        }
      }

      return values;
    },
    setValue(name, value) {
      self[name] = value;
      getParent(self, 1).loadCollection();
    },
    reset() {
      self.released = RELEASED_OPTIONS[0];
      self.truckers = [];
      self.container_size = CONTAINER_SIZE_OPTIONS[0];
    },
    afterAttach() {
      self.loadTruckers();
    },
  }));
