import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#001235',
    boxShadow: theme.shadows[1],
    fontSize: '12px',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '21px',
    color: theme.palette.primary.main,
  },
  content: {
    padding: '10px',
    fontSize: '12px',
  },
  title: {
    fontSize: '9px',
    fontStyle: 'italic',
    color: '#80889A',
    marginBottom: '10px',
  },
}));

const MarkingHistories = observer(({ histories }) => {
  const classes = useStyles();

  return <Box className={classes.root}>
    <LightTooltip
      arrow
      placement="left"
      title={
        <Box className={classes.content}>
          <Box className={classes.title}>Carton Marking History</Box>
          <Box>{histories.join(' -> ')}</Box>
        </Box>
      }>
      <InfoIcon className={classes.icon} />
    </LightTooltip>
  </Box>
});

export default MarkingHistories;
