import React, { useState } from 'react';
import CommonDocuments from '../../../common/Documents';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import FormControl from '@material-ui/core/FormControl';
import { format } from 'date-fns';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    minHeight: '300px',
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const ConfirmReceive = observer(({ inbound }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [confirmedDatetime, setConfirmedDatetime] = useState(new Date());

  const { detail, unloadingReceiptDocuments, loadUnloadingReceiptDocuments } = inbound;

  const handleOpen = () => {
    loadUnloadingReceiptDocuments();
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = () => {
    if (confirmedDatetime != null && !isNaN(confirmedDatetime.getTime()) && unloadingReceiptDocuments.hasDocuments) {
      detail.receive(format(confirmedDatetime, 'yyyy-MM-dd HH:mm:ss'));
    }
    else {
      alert("Please fill in all required items.");
    }
  }

  return <>
    <Button variant="contained" size="small" color="primary" onClick={handleOpen}>CONFIRM RECEIVED</Button>
    {open && <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Box className={classes.content}>
          <Box mb={2} className={classes.subtitle}>1. Please Select Actual Received Date*</Box>
          <Box mb={2}>
            <FormControl required>
              <KeyboardDateTimePicker
                variant="inline"
                value={confirmedDatetime}
                onChange={setConfirmedDatetime}
                format="yyyy-MM-dd HH:mm"
              />
            </FormControl>
          </Box>
          <Box mb={2} className={classes.subtitle}>2. Uploading Receipt *</Box>
          <CommonDocuments documents={unloadingReceiptDocuments} onUploaded={inbound.loadWhsDocuments} onDeleted={inbound.loadWhsDocuments} />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>}
  </>
});

export default ConfirmReceive;

